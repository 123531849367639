// import { Helmet } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import SystemError from 'app/components/SystemError';

export default function SystemErrorPage() {
  return (
    <>
      <Helmet>
        <title>システムエラー | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="システムエラー | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <SystemError />
      <Footer />
    </>
  );
}
