import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import _ from 'lodash';

type Props = {
  getPlanAndRoomName: (data: any) => {
    plan_name: string;
    room_name: string;
  };
};

export const useGetInfoOrder = (): Props => {
  const history = useHistory();

  const { i18n } = useTranslation();
  const lang = i18n.language;

  const getPlanAndRoomName = (data: any) => {
    const { plan, room_types } = data;
    const room_info = room_types ? room_types[0] : {};
    const room_name = _.find(room_info?.room_type?.localizations, [
      'lang',
      lang,
    ])?.name;
    const plan_name = _.find(plan?.localizations, ['lang', lang])?.name;
    return {
      plan_name,
      room_name,
    };
  };

  return { getPlanAndRoomName };
};
