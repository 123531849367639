import { Box, CardContent, Stack, TextField, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CommonButton } from 'app/components/CommonButton';
import { colors, fontsizes } from 'constants/styles';
import * as React from 'react';
import Path from 'config/clientPath';
import { useHistory } from 'react-router';

import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { map, isEmpty } from 'lodash';
interface IProps {
  email?: string;
  submitLoginForm: (formData: ILoginForm) => void;
}

interface ILoginForm {
  password: string;
}

const LoginAfterRegister = ({ email, ...props }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { cart_items } = useSelector(selectGlobal);
  const {
    changeCurrentCheckoutStep,
    changeCartItemInfo,
    changeShowLoginAfterRegisterModalStatus,
  } = useGlobalSlice().actions;

  const changePaymentMethodOfCart = newPaymentMethod => {
    const newCartItems = map(cart_items, v => {
      return { ...v, ...newPaymentMethod };
    });
    dispatch(changeCartItemInfo({ newCartItems }));
  };
  const handleLoginSubmit = formData => {
    props.submitLoginForm(formData);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Container>
      <FormContainer>
        <CardContent
          className="body"
          sx={{
            maxWidth: {
              md: '375px',
              xs: '100%',
            },
            margin: {
              md: '0 auto',
            },
          }}
        >
          <Box
            component="form"
            id="login_form"
            onSubmit={handleSubmit(handleLoginSubmit)}
          >
            <Stack spacing={5}>
              <BoxHeader fontSize={18}>パスワードの入力 </BoxHeader>
              <Typography
                fontSize={fontsizes.main}
                lineHeight={`24px`}
                color={colors.default}
                textAlign={`center`}
                sx={{
                  whiteSpace: 'break-spaces',
                }}
              >
                ご登録いただいたメールアドレス{'\n'}
                <Typography component="span" color={colors.orange}>
                  {email}
                </Typography>
                に {'\n'} パスワードを送付いたしました。{'\n'}
                受信ボックスをご確認いただき、
                記載されているパスワードを下記に入力してください。
              </Typography>
              <Stack spacing={1}>
                <Typography gutterBottom color={colors.mainContent}>
                  パスワード
                </Typography>
                <TextField
                  {...register<keyof ILoginForm>('password', {
                    required: 'こちらは必須項目です。',
                  })}
                  type="password"
                  placeholder="パスワードを入力してください"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.password ? true : false}
                  helperText={errors.password?.message}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </Stack>
              <Stack direction="column" justifyContent="space-between">
                <CommonButton
                  className="white-btn"
                  variant="contained"
                  size="large"
                  type="submit"
                  form="login_form"
                  sx={{
                    background: colors.blue,
                    boxShadow: 'none',
                    fontSize: '15px',
                    lineHeight: '20px',
                    padding: '10px',
                  }}
                >
                  ログインする
                </CommonButton>
                {!isEmpty(cart_items) && (
                  <CommonButton
                    className="white-btn"
                    variant="contained"
                    size="large"
                    sx={{
                      background: colors.blue,
                      boxShadow: 'none',
                      fontSize: '15px',
                      lineHeight: '20px',
                      padding: '10px',
                      marginTop: '3rem',
                      fontWeight: 'normal !important',
                    }}
                    onClick={() => {
                      changePaymentMethodOfCart({
                        selected_payment_method: { front_desk: true },
                      });
                      dispatch(
                        changeShowLoginAfterRegisterModalStatus({
                          open: false,
                        }),
                      );
                      dispatch(changeCurrentCheckoutStep(3));
                      history.push(Path.ORDER_INFO);
                    }}
                  >
                    ログインせずに予約する
                  </CommonButton>
                )}
              </Stack>
            </Stack>
          </Box>
        </CardContent>
      </FormContainer>
    </Container>
  );
};

LoginAfterRegister.defaultProps = {};

export default LoginAfterRegister;

const Container = styled(Box)``;

const FormContainer = styled(Box)`
  max-width: 800px;
  margin: 0 auto;
  background: ${colors.white};
  .MuiCardContent-root {
    padding-bottom: 40px !important;
    ${dMedia.tablet`
        padding: 3rem 1rem 2rem;
      `}
  }

  .MuiTypography-gutterBottom {
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
  }
  .MuiInputBase-input {
    font-size: 14px;
  }

  .MuiButton-root {
    font-weight: 500;
    display: block;
  }
`;
