import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import useGlobalFunc from './pages/GlobalContainer/useGlobalFunc';
import _ from 'lodash';
interface ContextState {
  activeTab: any;
  setActiveTab: Dispatch<SetStateAction<any>>;
  allPlans: any;
  setAllPlans: Dispatch<SetStateAction<any>>;
  isSynced: boolean;
  setIsSynced: Dispatch<SetStateAction<boolean>>;
  activeCartItem: any;
}

const OrderInfoContext = createContext<ContextState>({} as ContextState);

const OrderInfoContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
  const { cart_items, changeCartItemInfo, handleRedirectPaymentPage } =
    useGlobalFunc();
  const activeLocalId = _.first(cart_items)?.local_id;
  const [activeTab, setActiveTab] = useState<any>(activeLocalId);

  const [allPlans, setAllPlans] = useState<any>({});
  const [isSynced, setIsSynced] = React.useState<boolean>(false);

  const activeCartItem = _.find(cart_items, v => +v?.local_id === +activeTab);

  React.useEffect(() => {
    setActiveTab(activeLocalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart_items.length]);

  return (
    <OrderInfoContext.Provider
      value={{
        activeTab,
        setActiveTab,
        activeCartItem,
        allPlans,
        setAllPlans,
        isSynced,
        setIsSynced,
      }}
    >
      {children}
    </OrderInfoContext.Provider>
  );
};

export const useOrderInfoContext = () => useContext(OrderInfoContext);
export default OrderInfoContextProvider;
