import styled from 'styled-components/macro';
import { Box, Typography } from '@mui/material';

import { fontsizes, colors } from 'constants/styles';
import { MoneyItem } from 'app/components/MoneyItem';

interface Props {
  amount: number;
  name: string;
}

export default function PriceBox({ amount, name }: Props) {
  return (
    <BoxWrapper
      sx={{
        border: `1px solid ${colors.grey6}`,
        padding: '8px 16px',
        borderRadius: '4px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: fontsizes.main,
          lineHeight: '24px',
          color: colors.grey3,
        }}
      >
        {name}
      </Typography>
      <MoneyItem amount={amount} fontSize={18} isBold></MoneyItem>
    </BoxWrapper>
  );
}

const BoxWrapper = styled(Box)`
  .money-item span {
    font-weight: 700;
  }
`;
