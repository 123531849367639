/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CommonButton } from 'app/components/CommonButton';
import { GENDERS } from 'constants/common';
import { colors } from 'constants/styles';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';

interface Props {
  onAgree: () => void;
  onCancel: () => void;
}

export const CompanionInfoForm = ({ onAgree, onCancel, ...props }: Props) => {
  const handleFormSubmit = formData => {
    console.log('formData', formData);
    onAgree();
  };
  const [companionNumber, setCompanionNumber] = React.useState<number>(1);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const resetCompanionForm = () => {
    setCompanionNumber(1);
    reset();
  };

  return (
    <FormContainer>
      <CardContent
        className="body"
        sx={{
          maxWidth: {
            md: '375px',
            xs: '100%',
          },
          margin: {
            md: '0 auto',
          },
        }}
      >
        <Box
          mb={2}
          component="form"
          id="companion_register_form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Stack spacing={2}>
            {Array.from(Array(companionNumber).keys()).map((_, i) => (
              <React.Fragment key={i}>
                <FormField spacing={0.5}>
                  <Typography gutterBottom>氏名（漢字）</Typography>
                  <Controller
                    name={`fullname.${i}`}
                    render={({ field }) => {
                      const { onBlur, onChange, value } = field;
                      return (
                        <TextField
                          {...register('fullname.' + i, {
                            required: 'こちらは必須項目です。',
                          })}
                          placeholder="例）田中 太郎"
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={
                            errors?.fullname && errors.fullname[i]
                              ? true
                              : false
                          }
                          helperText={
                            (errors?.fullname && errors.fullname[i]?.message) ??
                            ''
                          }
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      );
                    }}
                    control={control}
                  />

                  <Typography component="p" mt={2}>
                    ※氏名の間にスペースをいれてください
                  </Typography>
                </FormField>
                <FormField spacing={0.5}>
                  <Typography gutterBottom>氏名（カナ）</Typography>
                  <Controller
                    name={`name_kata.${i}`}
                    render={({ field }) => {
                      const { onBlur, onChange, value } = field;
                      return (
                        <TextField
                          {...register('name_kata.' + i, {
                            required: 'こちらは必須項目です。',
                          })}
                          placeholder="例）タナカ タロウ"
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={
                            errors?.name_kata && errors.name_kata[i]
                              ? true
                              : false
                          }
                          helperText={
                            (errors?.name_kata &&
                              errors.name_kata[i]?.message) ??
                            ''
                          }
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      );
                    }}
                    control={control}
                  />

                  <Typography component="p" mt={2}>
                    ※氏名の間にスペースをいれてください
                  </Typography>
                </FormField>
                <FormField spacing={0.5}>
                  <Typography gutterBottom>性別</Typography>
                  <FormControl fullWidth margin="dense">
                    <Controller
                      control={control}
                      name={`gender.${i}`}
                      defaultValue="1"
                      render={({ field }) => {
                        const { onBlur, onChange, value } = field;
                        return (
                          <RadioGroup
                            row
                            value={value ? value.toString() : null}
                            onBlur={onBlur}
                            onChange={onChange}
                          >
                            {GENDERS.map(v => (
                              <FormControlLabel
                                key={v.id}
                                value={v.value}
                                control={<Radio />}
                                label={v.label}
                              />
                            ))}
                          </RadioGroup>
                        );
                      }}
                    />
                  </FormControl>
                </FormField>
                <FormField spacing={0.5}>
                  <Typography gutterBottom>年齢</Typography>
                  <Controller
                    name={`age.${i}`}
                    render={({ field }) => {
                      const { onBlur, onChange, value } = field;
                      return (
                        <TextField
                          {...register('age.' + i, {
                            required: 'こちらは必須項目です。',
                          })}
                          placeholder="例）田中 太郎"
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={errors?.age && errors.age[i] ? true : false}
                          helperText={errors.age ? errors.age[i]?.message : ''}
                          sx={{ maxWidth: { xs: '100px' } }}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          type="number"
                          InputProps={{ inputProps: { min: 0, max: 100 } }}
                        />
                      );
                    }}
                    control={control}
                  />
                </FormField>
                <Divider sx={{ margin: '1rem 0 2rem !important' }} />
              </React.Fragment>
            ))}

            <Stack direction="column" justifyContent="space-between">
              <CommonButton
                variant="contained"
                size="large"
                sx={{
                  background: 'transparent',
                  boxShadow: 'none',
                  border: '1px solid ' + colors.blue,
                  color: colors.blue,
                  '&:hover': {
                    color: colors.white,
                  },
                }}
                onClick={() => setCompanionNumber(companionNumber + 1)}
              >
                + 同行者を追加する
              </CommonButton>
              <CommonButton
                variant="contained"
                size="large"
                type="submit"
                form="companion_register_form"
                sx={{
                  background: colors.blue,
                  boxShadow: 'none',
                  margin: '3rem 0 2rem',
                  color: colors.white,
                }}
                onClick={() => {
                  onCancel();
                }}
              >
                登録する
              </CommonButton>
              <CommonButton
                variant="contained"
                size="large"
                className="cancel-btn"
                sx={{
                  margin: '0 0 2rem',
                  background: 'transparent',
                  boxShadow: 'none',
                  color: colors.default,
                }}
                onClick={() => {
                  resetCompanionForm();
                  onCancel();
                }}
              >
                キャンセル
              </CommonButton>
            </Stack>
          </Stack>
        </Box>
      </CardContent>
    </FormContainer>
  );
};

const FormContainer = styled(Box)`
  margin: 0 auto;
  .MuiCardContent-root {
    padding-bottom: 8rem;

    ${dMedia.tablet`
        padding: 24px 16px;
    `}
  }

  .MuiTypography-gutterBottom {
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
  }
  .MuiInputBase-input {
    font-size: 14px;
  }
  .MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: inherit;
    border-width: 1px;
  }
  .MuiFormHelperText-root.Mui-error {
    white-space: nowrap;
  }
  .MuiButton-root {
    font-weight: 500;
    display: block;

    ${dMedia.tablet`
      font-size: 15px;
      padding: 6px 22px;
      `}
    a {
      color: inherit;
      display: block;
      text-decoration: none;
    }
  }

  .MuiRadio-root {
    svg {
      color: ${colors.grey4};
    }
    &.Mui-checked svg {
      color: ${colors.grey1};
    }
  }
  input:-internal-autofill-selected {
    background: none;
  }
  .cancel-btn:hover {
    background: transparent;
    box-shadow: none;
  }
`;

const FormField = styled(Stack)`
  margin-bottom: 24px !important;
`;
