import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Calendar from 'app/components/Calendar';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon-sm.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import Path from 'config/clientPath';
import { CART_HANDLE_TYPE, DEFAULT_MEAL, MEALS } from 'constants/common';
import { colors, fontsizes } from 'constants/styles';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { getReservationInfo } from 'utils/helper';
import { commonFormat, convertDateFormatSlash } from 'utils/moment';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import _ from 'lodash';
import SelectItem from '../SelectItem';
import RoomFilter from '../RoomFilter';
import CheckInOutDate from 'app/pages/PlanAndRoomPage/components/CheckInOutDate';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import usePlanAndRoom from 'app/pages/PlanAndRoomPage/usePlanAndRoom';
import { RequestStatus } from 'constants/API';
import ESSnackbar from '../ESSnackbar';
export interface IAdditionInfoFormData {
  // checkin_time?: string;
  meal?: number;
  adult?: number;
  // male?: any;
  // female?: any;
  // child?: number;
  child_A?: number;
  child_B?: number;
  child_C?: number;
  child_D?: number;
  child_E?: number;
  // child_F?: any;
  nights?: number;
  checkin_date?: moment.Moment | null;
  checkout_date?: moment.Moment | null;
}

interface Props {
  // open: boolean;
  onClose?: () => void;
  // formData: IAdditionInfoFormData | null;
  // handleSubmitData: (formData: any) => void;
}

export const AdditionInfoModal = ({ onClose, ...props }: Props) => {
  const { width: pageWidth } = useWindowDimensions(0);
  const history = useHistory();

  const isMobile = pageWidth <= 768;
  const {
    changeShowAdditionInfoModalStatus,
    addItemToCart,
    setSuccessMessages,
    changeShowLoginModalStatus,
    changeReservationStatus,
  } = useGlobalSlice().actions;
  const { showAdditionInfoModal, savedCartItem, isLoggedIn } =
    useSelector(selectGlobal);
  const {
    additionSearchInfo: {
      meal,
      adult,
      // child,
      nights,
      child_A,
      child_B,
      child_C,
      child_D,
      child_E,
    },
    additionSearchInfo,
    checkin_date,
    checkout_date,
    isInPlanRoomPage,
    hotelSlug,
  } = useSelector(selectPlanAndRoom);

  const [submitData, setSubmitData] = useState<any>({});

  const [isSuccessGetStock, setIsSuccessGetStock] = useState(false);
  const { getPlanDetailStatus, plans, getPlanDetailRequest, rooms } =
    usePlanAndRoom();
  const { checkHotelStock, hotelStockStatus, handleAddItemToCart } =
    useGlobalFunc();

  const [pax, setPax] = useState<any>(additionSearchInfo);

  useEffect(() => {
    setPax({
      ...additionSearchInfo,
      child_a_count: additionSearchInfo.child_A,
      child_b_count: additionSearchInfo.child_B,
      child_c_count: additionSearchInfo.child_C,
      child_d_count: additionSearchInfo.child_D,
      child_e_count: additionSearchInfo.child_E,
    });
  }, [additionSearchInfo, setPax]);

  // const getCheckInTime = () => {
  //   return !_.isEmpty(savedCartItem?.checkin_times)
  //     ? _.first(savedCartItem?.checkin_times)
  //     : '';
  // };
  // const checkin_time = getCheckInTime();

  // useEffect(() => {
  //   if (isSuccessGetStock && getPlanDetailStatus === RequestStatus.SUCCESS) {
  //     const today = convertDateFormatSlash(moment());
  //     const _checkin_date = checkin_date || today;
  //     const _checkout_date = checkout_date || checkin_date || today;

  //     dispatch(
  //       addItemToCart(
  //         getReservationInfo(
  //           savedCartItem,
  //           { ...savedCartItem.addition_info, hotelStockStatus, detail_plan },
  //           {
  //             ...submitData,
  //             checkin_date: _checkin_date,
  //             // checkout_date with options if it is null
  //             checkout_date: _checkout_date,
  //             adult,
  //             child_A,
  //             child_B,
  //             child_C,
  //             child_D,
  //             child_E,
  //           },
  //         ),
  //       ),
  //     );
  //     dispatch(setSuccessMessages(['カートに追加しました']));
  //     dispatch(changeShowAdditionInfoModalStatus(false));

  //     if (savedCartItem?.isReserved) {
  //       if (isLoggedIn) {
  //         history.push(`${Path.ORDER_INFO}`);
  //       } else {
  //         dispatch(changeShowLoginModalStatus(true));
  //         dispatch(changeReservationStatus(true));
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSuccessGetStock, getPlanDetailStatus]);

  const handleSubmitData = (data: any) => {
    const { checkin_date, checkout_date } = data;
    const today = convertDateFormatSlash(moment());
    const _checkin_date = checkin_date || today;
    const _checkout_date = checkout_date || checkin_date || today;

    handleAddItemToCart({
      handleType: CART_HANDLE_TYPE.ADDITION_MODAL,
      item: { ...savedCartItem },
      addition_info: { ...savedCartItem.addition_info },
      reservation: {
        ...data,
        meal,
        adult,
        // child,
        nights,
        checkin_date: _checkin_date,
        checkout_date: _checkout_date,
        child_A,
        child_B,
        child_C,
        child_D,
        child_E,
      },
    });

    // setSubmitData(data);
    // const { hotel_id, plan_id, room_id } = savedCartItem.addition_info;
    // getPlanDetailRequest(hotel_id, plan_id);

    // const onSuccess = () => {
    //   setIsSuccessGetStock(true);
    // };

    // checkHotelStock(
    //   {
    //     hotel_id,
    //     plan_id,
    //     room_id,
    //     checkin_date: _checkin_date,
    //     checkout_date: _checkout_date,
    //   },
    //   onSuccess,
    // );
    // onSuccess();
  };

  const defaultFormData = {
    // checkin_time,
    adult: 1,
    // child: 1,
    nights: 1,
    checkin_date: null,
    checkout_date: null,
  };

  const dispatch = useDispatch();

  const [formData, setFormData] = useState<any>({ ...defaultFormData });

  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  // const formData = _.isEmpty(additionSearchInfo) ? : additionSearchInfo;

  const selectDate = data => {
    if (data) {
      switch (data.length) {
        case 1:
          setValue('checkin_date', convertDateFormatSlash(moment(data[0])));
          break;
        case 2:
          setValue('checkin_date', convertDateFormatSlash(moment(data[0])));
          setValue('checkout_date', convertDateFormatSlash(moment(data[1])));
          break;

        default:
          setValue('checkin_date', null);
          setValue('checkout_date', null);
          break;
      }
    }
  };

  const formInfo = useForm();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = formInfo;

  const [selectedDateRange, setSelectedDateRange] = React.useState<string[]>(
    [],
  );

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.checked;
    setValue('is_flexible', val);
    setValue('checkin_date', null);
    setValue('checkout_date', null);
  };

  const validateDateWithRegex = (dateString: string): boolean => {
    return !!(
      dateString &&
      /^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])/.test(dateString)
    );
  };

  const validateDate = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const checkInDate = getValues('checkin_date');
    const checkOutDate = getValues('checkout_date');
    if (
      (checkOutDate && moment(checkInDate) > moment(checkOutDate)) ||
      moment(checkInDate).isBefore(moment(), 'day') ||
      (checkInDate && !validateDateWithRegex(checkInDate)) ||
      (checkOutDate && !validateDateWithRegex(checkOutDate))
    ) {
      setValue('checkin_date', formData?.checkin_date);
      setValue('checkout_date', formData?.checkout_date);
    }
  };

  const getCheckinOutDate = ({ checkin_date, checkout_date }) => {
    // const string_checkin_date = checkin_date
    //   ? checkin_date.format('YYYY/MM/DD')
    //   : '';
    if (!checkin_date) return [];
    // const string_checkout_date = checkout_date
    //   ? checkout_date.format('YYYY/MM/DD')
    //   : '';
    return [checkin_date, checkout_date];
  };

  React.useEffect(() => {
    // get info from plan page if in plan and room page
    const newFormData = isInPlanRoomPage
      ? {
          // checkin_time,
          // adult: adult || 1,
          // child: child || 1,
          // nights: nights || 1,
          checkin_date: checkin_date
            ? convertDateFormatSlash(checkin_date)
            : '',
          checkout_date: checkout_date
            ? convertDateFormatSlash(checkout_date)
            : '',
        }
      : { ...defaultFormData };
    setFormData({ ...newFormData });

    const selectedDates = getCheckinOutDate(newFormData);

    setSelectedDateRange(selectedDates);
    reset({ ...newFormData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAdditionInfoModal]);

  // React.useEffect(() => {
  //   setValue('checkin_time', getCheckInTime());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [savedCartItem?.checkin_times]);

  return (
    <>
      <InfoDialog
        open={showAdditionInfoModal}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Typography
            component="span"
            textAlign="center"
            sx={{
              display: 'block',
              fontSize: '24px',
              fontWeight: 700,
              padding: '50px 24px 0 24px',
            }}
          >
            宿泊情報
          </Typography>
          <CloseIcon
            onClick={() => {
              onClose && onClose();
              dispatch(changeShowAdditionInfoModalStatus(false));
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              margin: { sm: '0 auto', xs: '0 auto' },
              maxWidth: { md: '100%', sm: '340px', xs: '340px' },
            }}
          >
            <Box
              sx={{
                margin: {
                  md: '0 auto 2rem',
                  xs: '0 auto 2rem',
                },
                maxWidth: {
                  md: '700px',
                },
              }}
              component="form"
              id="info_form"
              onSubmit={handleSubmit(handleSubmitData)}
            >
              <Stack
                sx={{
                  flexDirection: 'column',
                  alignItems: { md: 'center', sm: 'center' },
                  justifyContent: {
                    md: 'space-between',
                    sm: 'flex-start',
                  },
                  flexWrap: {
                    md: 'nowrap',
                    xs: 'wrap',
                  },
                  maxWidth: '336px',
                  margin: '0 auto',
                }}
                spacing={4}
              >
                <Stack
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  gap={2}
                  sx={{ width: '100%' }}
                >
                  <RoomFilter isInModal />
                </Stack>
                <Stack>
                  {/* checkin_time */}
                  {/* <Box className="" mb={4}>
                  <InputLabel
                    sx={{
                      fontSize: fontsizes.main,
                      fontWeight: 500,
                      color: colors.mainContent,
                      paddingBottom: '5px',
                    }}
                  >
                    チェックイン時間
                  </InputLabel>

                  <FormControl sx={{ width: { xs: '80px' } }}>
                    <Controller
                      control={control}
                      name="checkin_time"
                      defaultValue={formData?.checkin_time}
                      render={({ field }) => {
                        const { onChange, value } = field;
                        return (
                          <SelectItem
                            onChange={onChange}
                            selected={value}
                            options={
                              savedCartItem?.checkin_times
                                ? savedCartItem?.checkin_times.map(v => ({
                                    label: v,
                                    value: v,
                                  }))
                                : []
                            }
                            maxWidth={'100%'}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Box> */}

                  <CheckInOutDate isFromAdditionModal={true} form={formInfo} />
                </Stack>

                {/* <Box sx={{}} className="">
                <Box
                  sx={{
                    padding: 0.5,
                    border: { xs: '1px solid' + colors.grey6 },
                    borderRadius: 1,
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="stretch">
                    <Box>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: fontsizes.main,
                          fontWeight: 500,
                          color: colors.mainContent,
                        }}
                      >
                        チェックイン
                      </Typography>
                      <FormControl sx={{ width: { xs: '140px' } }}>
                        <Controller
                          control={control}
                          name="checkin_date"
                          defaultValue={formData?.checkin_date || ''}
                          render={({ field }) => {
                            const { onChange, value } = field;
                            return (
                              <TextField
                                {...register<keyof IAdditionInfoFormData>(
                                  'checkin_date',
                                  {
                                    required: 'こちらは必須項目です。',
                                  },
                                )}
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={value}
                                onBlur={validateDate}
                                onChange={onChange}
                                error={errors.checkin_date ? true : false}
                                helperText={errors.checkin_date?.message}
                                disabled={true}
                                placeholder="YYYY/MM/DD"
                                InputProps={{
                                  startAdornment: <CalendarIcon />,
                                }}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: fontsizes.main,
                          fontWeight: 500,
                          color: colors.mainContent,
                        }}
                      >
                        チェックアウト
                      </Typography>
                      <FormControl sx={{ width: { xs: '140px' } }}>
                        <Controller
                          control={control}
                          name="checkout_date"
                          defaultValue={formData?.checkout_date || ''}
                          render={({ field }) => {
                            const { onBlur, onChange, value } = field;
                            return (
                              <TextField
                                {...register<keyof IAdditionInfoFormData>(
                                  'checkout_date',
                                  {
                                    required: 'こちらは必須項目です。',
                                  },
                                )}
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={value}
                                onBlur={validateDate}
                                onChange={onChange}
                                placeholder="YYYY/MM/DD"
                                disabled={true}
                                error={errors.checkout_date ? true : false}
                                helperText={errors.checkout_date?.message}
                                InputProps={{
                                  startAdornment: <CalendarIcon />,
                                }}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Stack>
                </Box>
              </Box> */}
              </Stack>
              <VacancyCalendar mt={4}>
                <Calendar
                  isPlanModal
                  guest_count={adult}
                  pax={pax}
                  viewMode={isMobile ? 'single' : 'single'}
                  selectable={true}
                  month={moment().format('YYYY-MM')}
                  selectedDateRange={selectedDateRange}
                  onSelectedDateRangeChange={checkInOutDate => {
                    if (checkInOutDate.length > 1) {
                      const nights = moment.duration(
                        moment(checkInOutDate[1]).diff(
                          moment(checkInOutDate[0]),
                          'days',
                        ),
                      );
                      const pathname = window.location.pathname;
                      const planDetails = pathname.match(/rooms/)
                        ? _.find(
                            _.get(
                              _.find(rooms, {
                                id: savedCartItem?.addition_info?.room_id,
                              }),
                              'plans',
                            ),
                            { id: savedCartItem?.addition_info?.plan_id },
                          )
                        : _.find(plans, {
                            id: savedCartItem?.addition_info?.plan_id,
                          });

                      if (
                        planDetails?.min_nights &&
                        planDetails?.max_nights &&
                        (nights < planDetails?.min_nights ||
                          nights > planDetails?.max_nights)
                      ) {
                        setShowAlert(true);
                        setAlertMessage(
                          `${planDetails?.min_nights}泊〜${planDetails?.max_nights}泊でご予約ください。`,
                        );
                        setSelectedDateRange([]);
                        return;
                      } else {
                        setShowAlert(false);
                        setAlertMessage('');
                        selectDate(checkInOutDate);
                      }
                    } else {
                      setShowAlert(false);
                      setAlertMessage('');
                      selectDate(checkInOutDate);
                    }
                  }}
                  hotel={hotelSlug}
                  plan_id={savedCartItem?.addition_info?.plan_id}
                  room_id={savedCartItem?.addition_info?.room_id}
                />
              </VacancyCalendar>
              <Box pt={4} pb={4} textAlign="center">
                <CustomButton
                  variant="contained"
                  size="large"
                  type="submit"
                  form="info_form"
                  sx={{
                    background: colors.blue,
                    maxWidth: '375px',
                    width: '100%',
                    boxShadow: 'none',
                    marginBottom: '26px',
                  }}
                >
                  <span>カートに入れる</span>
                </CustomButton>
                <CancelButton
                  onClick={() => {
                    dispatch(changeShowAdditionInfoModalStatus(false));
                  }}
                >
                  キャンセル
                </CancelButton>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </InfoDialog>
      <ESSnackbar isOpen={showAlert} mess={alertMessage} />
    </>
  );
};

const CustomButton = styled(Button)`
  && {
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
    }
  }
`;

const CancelButton = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    cursor: pointer;
  }
`;

const InfoDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 700px;
    .MuiDialogTitle-root {
      position: relative;
      svg {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 20px;
        top: 30px;
        cursor: pointer;
      }
    }
    ${dMedia.tablet`
       width: 100%;
       height: 100%;
       max-height: 100%;
       margin: 0;
       overflow-y: auto;
    `}
  }
  .MuiDialogContent-root {
    padding: 0px 24px !important;
    ${dMedia.tablet`
      overflow-y: initial;
      padding: 0 6px!important;
    `}
  }
  .css-mnuau6-MuiInputBase-root-MuiInput-root {
    &:after,
    &:before {
      border: none;
    }
    .MuiSelect-select {
      padding-left: 10px;
      background: ${colors.grey6};
      height: 26px;
      line-height: 26px;
      border-radius: 4px;
      font-weight: 500;
    }
    &:hover:not(.Mui-disabled):before {
      border: none;
    }
    .MuiSelect-icon {
      right: 5px;
    }
  }

  .MuiTextField-root > .MuiInputBase-root {
    padding-left: 6px;
    font-weight: 500;
    svg {
      padding-right: 5px;
      display: none;
    }
    ${dMedia.tablet`
      svg {
        display: inline-block;
      }
    `}
  }
  .MuiCheckbox-root {
    padding: 0 0 0 9px;
    svg {
      color: ${colors.grey4};
    }
    &.Mui-checked svg {
      color: ${colors.grey1};
    }
  }
  .MuiBox-root.mt0 {
    margin-top: 0;
    .MuiBox-root .MuiBox-root:nth-child(2) {
      margin-left: 8px;
    }
  }
  p.MuiTypography-root {
    font-weight: 500;
  }
`;

const VacancyCalendar = styled(Stack)`
  width: 100%;
  max-width: 351px;
  margin: 0 auto;
  padding: 0 7.5px;
  & > div {
    width: 100%;
  }
`;
