import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { colors } from 'constants/styles';
import * as React from 'react';
import styled from 'styled-components';
interface ICheckBoxItem {
  label?: string | React.ReactNode;
  className?: string;
  isChecked: boolean;
  onChange?: (checked) => void;
  isResetValue?: boolean;
  field?: any;
}

const CheckBoxItem = ({
  className,
  label,
  isChecked,
  onChange,
  isResetValue,
  field,
  ...props
}: ICheckBoxItem) => {
  const [checked, setChecked] = React.useState(isChecked);

  React.useEffect(() => {
    if (isResetValue) {
      setChecked(false);
    }
  }, [isResetValue]);

  React.useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleChange = event => {
    const newValue = event.target.checked;
    setChecked(newValue);
    onChange && onChange(newValue);
  };

  return (
    <Container className={className}>
      <FormControlLabel
        control={
          <Checkbox
            disableRipple
            sx={{
              color: colors.grey,
              '&.Mui-checked': {
                color: colors.grey1,
              },
            }}
            checked={checked}
            onChange={handleChange}
            inputRef={field?.ref}
          />
        }
        label={label}
      />
    </Container>
  );
};

export default CheckBoxItem;

const Container = styled(Box)`
  .MuiCheckbox-root {
    padding: 0;
  }
  .MuiFormControlLabel-root {
    margin-left: -2px;
    margin-right: 0px;
  }
  .MuiFormControlLabel-label {
    margin-left: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.grey1};
  }
`;
