import { Box } from '@mui/material';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { Loader } from 'app/components/Loading';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getLoginTime,
  getToken,
  setLoginTime,
  setToken,
} from 'utils/localStorage';
import { checkTokenNotExpired } from 'utils/helper';

import LoginForm from './components/LoginForm';
import { useLoginSlice } from './slice';
import { selectLogin } from './slice/selectors';

export default function LoginPage({ ...props }) {
  const history = useHistory();
  const { setErrorMessages, login, setIsStayLoggedIn } =
    useGlobalSlice().actions;
  const { loginStatus, accessToken, errorMess } = useSelector(selectLogin);

  const { loginRequest } = useLoginSlice().actions;
  const dispatch = useDispatch();
  const [stayLoggedIn, setStayLoggedIn] = React.useState<boolean>(false);
  const submitLogin = formData => {
    setStayLoggedIn(formData.remember);
    dispatch(loginRequest(formData));
  };

  React.useEffect(() => {
    if (getToken() && checkTokenNotExpired()) {
      history.push(Path.SEARCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = loginStatus === RequestStatus.REQUESTING;

  React.useEffect(() => {
    if (loginStatus === RequestStatus.SUCCESS) {
      dispatch(login());
      setToken(accessToken);
      setLoginTime(new Date().getTime());
      dispatch(setIsStayLoggedIn(stayLoggedIn));
      history.push(Path.SEARCH);
    }
    if (loginStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  return (
    <>
      <Helmet>
        <title>ログイン | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="ログイン | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Box>
        <Loader open={isLoading} />
        <LoginForm id="login-form-page" submitLoginForm={submitLogin} />
      </Box>
      <Footer />
    </>
  );
}
