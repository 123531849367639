import { Box, Button, Typography } from '@mui/material';
import Path from 'config/clientPath';
import { colors } from 'constants/styles';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import CheckBoxItem from 'app/components/CheckBoxItem';
import { useOrderInfoContext } from 'app/OrderInfoContext';
import { useFormContext } from 'react-hook-form';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';

export function SyncOrderInfo() {
  // const [isSynced, setIsSynced] = React.useState<boolean>(false);
  const { cart_items, changeCartItemInfo, handleRedirectPaymentPage } =
    useGlobalFunc();
  const {
    activeTab,
    setActiveTab,
    activeCartItem,
    allPlans,
    setAllPlans,
    isSynced,
    setIsSynced,
  } = useOrderInfoContext();

  // const infoForm = useFormContext();

  // const {
  //   register,
  //   handleSubmit,
  //   control,
  //   reset,
  //   getValues,
  //   watch,
  //   trigger,
  //   setValue,
  //   formState: { errors },
  //   formState,
  // } = infoForm;

  const history = useHistory();
  React.useEffect(() => {
    setIsSynced(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  return (
    <>
      {cart_items.length > 1 && (
        <CheckBoxItem
          onChange={value => {
            // watch
            setIsSynced(value);
          }}
          label={'他の予約にも同じ内容をコピーする'}
          isChecked={isSynced}
        />
      )}
    </>
  );
}

const CustomButton = styled(Button)``;
