import { Box, Stack, Typography } from '@mui/material';
import Calendar from 'app/components/Calendar';
import CalendarNote from 'app/components/CalendarNote';
import RoomFilter from 'app/components/RoomFilter';
import { colors, fontsizes } from 'constants/styles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import AccommodationInfo from './AccommodationInfo';
import CheckInOutDate from './CheckInOutDate';
import UnknownDate from './UnknownDate';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import InputItem from 'app/components/FormItems/InputItem';

//router
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

import { selectPlanAndRoom } from '../slice/selectors';
import moment from 'moment';
import { DEFAULT_GUEST_COUNT } from 'constants/common';
import { getGuestCount } from 'utils/helper';

interface IRightPanel {}

const RightPanel = (props: IRightPanel) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  let params = queryString.parse(window.location.search);
  const [selectedDateRange, setSelectedDateRange] = React.useState<string[]>(
    [],
  );
  const [codeValue, setCodeValue] = React.useState<string | null>(
    params.secret_plan_code,
  );
  const [isResetValue, setResetValueFlag] = React.useState(false);
  const [month, setMonth] = React.useState<any>(
    params.checkin_date
      ? moment(params.checkin_date).format('YYYY-MM')
      : moment().format('YYYY-MM'),
  );

  const [checkinCheckout, setCheckinCheckout] = React.useState<string[]>([
    queryString.parse(window.location.search).checkin_date,
    queryString.parse(window.location.search).checkout_date,
  ]);

  const { plans, active_plan, getPlansStatus, active_room } =
    useSelector(selectPlanAndRoom);

  const {
    actions: { changeCheckInOutDate, changeActiveMonthOnCalendar },
  } = usePlanAndRoomSlice();

  const changeCheckInOutInfo = () => {
    const selectedDates =
      params?.checkin_date && params?.checkout_date
        ? [params?.checkin_date, params?.checkout_date]
        : [];
    dispatch(changeCheckInOutDate(selectedDates));

    setSelectedDateRange(selectedDates);
    const _checkInDate = params?.checkin_date
      ? moment(params?.checkin_date)
      : moment();
    const newMonth = _checkInDate.format('YYYY-MM');
    setMonth(newMonth);
  };

  const handleCheckUnknownDate = value => {
    let params = queryString.parse(window.location.search);
    let qs;
    if (value) {
      setResetValueFlag(false);
      dispatch(changeCheckInOutDate(['', '']));
      qs = queryString.stringify(
        _.omit(params, ['checkin_date', 'checkout_date']),
      );
      setSelectedDateRange([]);
    } else {
      setSelectedDateRange(checkinCheckout);
      dispatch(changeCheckInOutDate(checkinCheckout));
      params.checkin_date = checkinCheckout[0];
      params.checkout_date = checkinCheckout[1];
      qs = queryString.stringify(params);
    }
    history.push(`${location.pathname}?${qs}`);
  };

  React.useEffect(() => {
    changeCheckInOutInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    changeCheckInOutInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_plan, active_room]);

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFillCode = React.useCallback(
    debounce(nextValue => {
      const qs = queryString.stringify({
        ...params,
        secret_plan_code: nextValue,
      });
      history.push(`${location.pathname}?${qs}`);
    }, 500),
    [],
  );

  const handleChangeCode = e => {
    const { value } = e.target;
    setCodeValue(value);
    debounceFillCode(value);
  };

  return (
    <Stack
      sx={{
        width: 351,
        border: `1px solid ${colors.grey}`,
        padding: '24px 0',
        borderRadius: 1,
        height: 'fit-content',
      }}
      direction="column"
      alignItems={'center'}
    >
      <AccommodationInfo></AccommodationInfo>
      <RoomFilter />

      <Stack sx={{ py: 3, px: 2, width: '100%' }} alignItems={'center'}>
        <CheckInOutDate
          isResetValue={isResetValue}
          onCheckUnknownDate={value => handleCheckUnknownDate(value)}
        />
      </Stack>

      <VacancyCalendar sx={{ display: { xs: 'none', sm: 'flex' } }} spacing={1}>
        {/* Price / Vacancy Calendar */}
        <Calendar
          isPlanAndRoomPage
          changeMonth={month => dispatch(changeActiveMonthOnCalendar(month))}
          // guest_count={5}
          selectable={true}
          month={month}
          selectedDateRange={selectedDateRange}
          // selectedDateRange={['2021-12-01', '2021-12-10']}
          onSelectedDateRangeChange={checkInOutDate => {
            dispatch(changeCheckInOutDate(checkInOutDate));
            setResetValueFlag(true);
            let params = queryString.parse(window.location.search);
            if (checkInOutDate && checkInOutDate.length === 2) {
              const qs = queryString.stringify({
                ...params,
                checkin_date: checkInOutDate[0],
                checkout_date: checkInOutDate[1],
              });
              setCheckinCheckout(checkInOutDate);
              history.push(`${location.pathname}?${qs}`);
            } else if (checkInOutDate && checkInOutDate.length === 0) {
              const qs = queryString.stringify(
                _.omit(params, ['checkin_date', 'checkout_date']),
              );
              history.push(`${location.pathname}?${qs}`);
            }
          }}
        />
      </VacancyCalendar>
      <Box
        sx={{
          marginTop: '30px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
            color: '#000',
            textAlign: 'center',
            marginBottom: '24px',
          }}
        >
          プロモーションコードで検索
        </Typography>
        <InputItem value={codeValue} onChange={handleChangeCode} fullWidth />
      </Box>
    </Stack>
  );
};

export default RightPanel;

const VacancyCalendar = styled(Stack)`
  width: 100%;
  padding: 0 7.5px;
  & > div {
    width: 100%;
  }
`;
