import { Box, Button, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CheckoutHeader } from 'app/components/CheckoutHeader';
import { CommonButton } from 'app/components/CommonButton';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import CreditCardForm from './components/CreditCardForm';
import { useHistory } from 'react-router';
import Checkout from 'app/components/Checkout';
import { useDispatch } from 'react-redux';
import { useGlobalSlice } from '../GlobalContainer/slice';

export interface IPaymentPageProps {}

declare global {
  interface Window {
    com_sbps_system: any;
  }
}

export default function PaymentPage(props: IPaymentPageProps) {
  const [showCreditCardForm, setShowCreditCardForm] =
    React.useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { changeCurrentCheckoutStep } = useGlobalSlice().actions;

  React.useEffect(() => {
    dispatch(changeCurrentCheckoutStep(3));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>決済情報登録 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="決済情報登録 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Checkout>
        <CreditCardForm />
      </Checkout>
    </>
  );
}
