// UI
import { Box, Stack, Typography } from '@mui/material';
import { colors } from 'constants/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { commonFormat } from 'utils/moment';
import UnknownDate from './UnknownDate';
import moment from 'moment';
import { dMedia } from 'styles/media';

interface ICheckInOutDate {
  isFromAdditionModal?: boolean;
  form?: any;
  onClick?: () => void;
  onCheckUnknownDate?: (value: any) => void;
  isResetValue?: boolean;
}

const CheckInOutDate = ({
  isFromAdditionModal = false,
  form,
  ...props
}: ICheckInOutDate) => {
  const planAndRoomInfo = useSelector(selectPlanAndRoom);

  // get check in and out date from addition modal or in reducer
  const { checkin_date, checkout_date } = (() => {
    if (!isFromAdditionModal) return planAndRoomInfo;
    const checkin_date = form?.watch()?.checkin_date
      ? moment(form?.watch().checkin_date)
      : null;
    const checkout_date = form?.watch()?.checkout_date
      ? moment(form?.watch().checkout_date)
      : null;
    return {
      checkin_date,
      checkout_date,
    };
  })();

  return (
    <>
      <DateArea
        sx={{
          cursor: {
            xs: 'pointer',
            md: 'unset',
          },
          marginBottom: '8px',
        }}
        onClick={props.onClick}
        spacing={1}
      >
        <GroupDate
          direction="row"
          spacing={2}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <CheckInOutDateTitle>チェックイン</CheckInOutDateTitle>
            <CheckDate sx={{ textAlign: checkin_date ? 'left' : 'center' }}>
              {checkin_date ? commonFormat(checkin_date) : '-'}
            </CheckDate>
          </Box>
          <Typography
            sx={{
              color: colors.grey1,
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '16px',
            }}
          >
            ~
          </Typography>
          <Box>
            <CheckInOutDateTitle>チェックアウト</CheckInOutDateTitle>
            <CheckDate sx={{ textAlign: checkout_date ? 'left' : 'center' }}>
              {checkout_date ? commonFormat(checkout_date) : '-'}
            </CheckDate>
          </Box>
        </GroupDate>
        {/* Accommodation date undecided */}
      </DateArea>
      <Box
        sx={{
          width: '274px',
        }}
      >
        <UnknownDate
          isResetValue={props.isResetValue}
          onCheckUnknownDate={props.onCheckUnknownDate}
        />
      </Box>
    </>
  );
};

export default CheckInOutDate;

const CheckInOutDateTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.grey3};
    margin-bottom: 8px;
  }
`;
const CheckDate = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    color: ${colors.grey1};
    line-height: 20px;
  }
`;
const GroupDate = styled(Stack)``;
const DateArea = styled(Stack)`
  border-radius: 8px;
  background: ${colors.grey6};
  width: 100%;
  display: flex;
  max-width: 282px;
  padding: 16px;
`;
