import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down-icon.svg';
import { fontsizes } from 'constants/styles';
import * as React from 'react';
import styled from 'styled-components/macro';

interface Props {
  title?: React.ReactNode | string;
  subTitle?: React.ReactNode | string;
  tagList?: React.ReactNode;
  children: any;
  isCollapsible?: boolean;
  button?: React.ReactNode;
  className?: string;
  readMoreText?: string;
  showEndIcon?: boolean;
  isViewDetail?: boolean;
  onViewDetail?: () => void;
  idBtn?: string;
}
export interface ICollapsibleParagraphProps {
  description: any;
  isCollapsible: boolean;
}
interface IParaStatus {
  isCollapsed: boolean;
}

export const CollapsibleBox = (props: Props) => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
  const {
    title,
    subTitle,
    tagList,
    children,
    isCollapsible,
    button,
    readMoreText,
    className,
    showEndIcon,
    isViewDetail,
    onViewDetail,
  } = props;
  return (
    <Wrapper className={className}>
      <Typography className={'title_collapse'}> {title}</Typography>
      {subTitle}
      {tagList}
      <ParagraphWrapper isCollapsed={isCollapsed} className={'para_collapse'}>
        <Typography
          fontSize={fontsizes.main}
          textAlign="justify"
          lineHeight={fontsizes.heading3}
        >
          {children}
        </Typography>
        {isCollapsed ? <BlurredBackground isCollapsed={isCollapsed} /> : ''}
      </ParagraphWrapper>
      {isCollapsed && isCollapsible && (
        <Box
          sx={{
            cursor: 'pointer',
          }}
          textAlign="center"
          onClick={() => {
            if (isViewDetail) {
              onViewDetail && onViewDetail();
            } else {
              setIsCollapsed(!isCollapsed);
            }
          }}
          className={'btn_collapse'}
        >
          <CollapsedButton
            id={props.idBtn}
            variant="contained"
            color="inherit"
            endIcon={showEndIcon && <ChevronIcon />}
            className="no-outline"
          >
            <span className={'read_more_text'}>{readMoreText}</span>
          </CollapsedButton>
        </Box>
      )}
      {button}
    </Wrapper>
  );
};

CollapsibleBox.defaultProps = {
  readMoreText: '続きを読む',
  showEndIcon: true,
  isViewDetail: false,
};

export default CollapsibleBox;
const ParagraphWrapper = styled(Box)<IParaStatus>`
  position: relative;
  max-height: ${props => (props.isCollapsed ? '54px' : '100%')};
  overflow: ${props => (props.isCollapsed ? 'hidden' : 'visible')};
  text-shadow: ${props =>
    props.isCollapsed ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'};
`;

const BlurredBackground = styled.div<IParaStatus>`
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  padding: 20px;
  width: 100%;
  background: ${props =>
    props.isCollapsed
      ? 'linear-gradient(to top,#fff 0%,rgba(255,255,255,0.6) 50%,rgba(255,255,255,0) 100%)'
      : 'transparent'};
`;

const CollapsedButton = styled(Button)`
  outline: none !important;
  margin: 10px 0;
  background: white !important;
  box-shadow: none !important;
  .MuiTouchRipple-root {
    display: none;
  }
`;

const Wrapper = styled(Box)``;
