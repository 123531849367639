import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: ${p => p.theme.background};
    font-size: 13px;
    font-weight: 400;
    color: ${p => p.theme.text};
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    iframe{
      display: none;
    }
  }

  body {
    font-family: 'Noto Sans JP', sans-serif;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

  }
  
  /* body.fontLoaded{
    * {
      font-family: ${p => p.theme.fontFamily} !important;
    }
  } */

  a {
    color: ${p => p.theme.primary};
  }

  input:-internal-autofill-selected{
    background-color:none!important;
  }
  
  .MuiOutlinedInput-root {
    background: ${p => p.theme.inputBackground};
    .MuiOutlinedInput-notchedOutline {
      border:none; 
    }
    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border: 1px solid #d32f2f;
    }
  }
  .MuiMenuItem-root {
    min-height: 32px !important;
  }

  a.MuiButton-root {
    box-shadow: unset !important;
  }

  a.MuiButton-root:hover {
    box-shadow: unset !important;
  }

  a.MuiButton-root:active {
    box-shadow: unset !important
  }

  button {
    box-shadow: unset !important;
  }

  button:hover {
    box-shadow: unset !important;
  }

  button:active {
    box-shadow: unset !important
  }

  #toast_add_cart_success{
    justify-content: center;
    background: #4c4c4c;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    border-radius: 4px;
    z-index: 5;
    padding: 10px 20px;
    margin: 0;
    min-height: unset;
    .Toastify__toast-body{
      margin: 0;
      padding: 0;
      justify-content: center;
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      color: #fff;
      .Toastify__toast-icon{
        display: none;
      }
    }
  }
`;
