import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';
import { CommonButton } from 'app/components/CommonButton';
import { TagList } from 'app/components/TagList';
import { colors, fontsizes } from 'constants/styles';
import { useHistory } from 'react-router';

import React from 'react';
import styled from 'styled-components/macro';

export const CardBox = ({ ...props }) => {
  const history = useHistory();
  const { id, image, prefecture, name, title, tags, slug } = props;

  return (
    <Card
      sx={{
        padding: '1rem',
        boxShadow: '0px 0px 1px 0px #00000040',
        borderRadius: '8px',
      }}
    >
      <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardMedia
          component="img"
          sx={{
            width: '100%',
          }}
          image={image}
          alt={name}
        />
        <CardWrapper>
          <CardContent
            sx={{
              flex: '1 0 auto',
              padding: {
                md: '0',
                xs: '0',
              },
            }}
          >
            <Typography
              mb={1}
              component="h5"
              sx={{
                fontSize: '12px',
                color: colors.grey3,
                mt: {
                  md: '2rem',
                  xs: '1rem',
                },
              }}
            >
              {prefecture}
            </Typography>
            <Typography
              variant="h4"
              mb={1}
              component="h4"
              sx={{ fontSize: '16px', fontWeight: 700 }}
            >
              {name}
            </Typography>
            <Typography component="p" mb={1} sx={{ fontSize: '12px' }}>
              {title}
            </Typography>
            <TagList
              data={tags
                .filter(item => item.is_main === 1)
                .map(item => item.tag_name)}
            />
          </CardContent>
        </CardWrapper>
      </Stack>

      <a href={window.location.origin + `/reservation/${slug ? slug : id}/`}>
        <Button
          variant="contained"
          size="large"
          sx={{
            fontSize: fontsizes.main,
            margin: '0 0 1rem',
            padding: 1,
            background: colors.blue,
          }}
        >
          <Typography style={{ color: colors.white, textDecoration: 'none' }}>
            ホテルの詳細を見る
          </Typography>
        </Button>
      </a>
    </Card>
  );
};

const CardWrapper = styled(Box)`
  .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
`;
const Button = styled(CommonButton)`
  a {
    color: ${colors.white};
    text-decoration: none;
  }
`;
