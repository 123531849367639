import { Box, Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { MoneyItem } from 'app/components/MoneyItem';
import { useOrderInfoContext } from 'app/OrderInfoContext';
import { useCheckoutSlice } from 'app/pages/CheckoutPage/slice';
import { selectCheckout } from 'app/pages/CheckoutPage/slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { useWindowDimensions } from 'utils/useWindowDimensions';

export function OrderTabs() {
  const history = useHistory();
  const { activeTab, setActiveTab } = useOrderInfoContext();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;

  const { order_history, getOrderHistoryStatus, cancelOrderStatus } =
    useSelector(selectCheckout);
  const { cart_items, userSessionData } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const [activeOrder, setActiveOrder] = React.useState(null);

  const {
    actions: { getOrderHistoryRequest },
  } = useCheckoutSlice();

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const changeUrl = (url: string) => {
  //   window.history.replaceState(null, '', url);
  // };

  return (
    <>
      <Box sx={{ maxWidth: { xs: 220, sm: 350 }, bgcolor: 'background.paper' }}>
        <CustomTabs
          allowScrollButtonsMobile
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className="custom_tab"
        >
          {cart_items?.map((v, k) => (
            <Tab
              label={
                <Stack alignItems={'center'}>
                  <Box>{`予約 ${k + 1}`}</Box>
                  <MoneyItem fontSize={10} amount={v?.totalPrice || 0} />
                </Stack>
              }
              key={k}
              value={v?.local_id}
            />
          ))}
        </CustomTabs>
      </Box>
    </>
  );
}

const CustomTabs = styled(Tabs)`
  .MuiButtonBase-root.MuiTab-root {
    border: 1px solid #1976d2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #1976d2;
    &.Mui-selected {
      background-color: #1976d2;
      color: #fff;
    }
  }
  .MuiTabs-scrollButtons {
    &:last-child {
      border-bottom: 1px solid #1976d2;
      &.Mui-disabled {
        opacity: 1;
        svg {
          opacity: 0;
        }
      }
    }
    ${dMedia.tablet`
      width: 20px;
    `}
  }
`;
const Wrapper = styled(Box)``;
