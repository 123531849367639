import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { CommonButton } from '../CommonButton';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal';
import { BoxHeader } from 'app/components/BoxHeader';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { selectCheckout } from 'app/pages/CheckoutPage/slice/selectors';
import { RequestStatus } from 'constants/API';
import {
  defaultCustomerInfo,
  useCheckoutSlice,
} from 'app/pages/CheckoutPage/slice';
import {
  checkIsRequesting,
  getReservationId,
  getReservationsList,
  lodash,
} from 'utils/helper';
import { getToken } from 'utils/localStorage';
import { Loader } from '../Loading';
import { useOrderInfoContext } from 'app/OrderInfoContext';
import { Helmet } from 'react-helmet-async';
import Header from '../Header';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';

interface IConfirmOrderModal {
  // onClose: () => void;
  // onAgree: () => void;
  // title: string;
  // content: string;
}

const ConfirmOrderModal = ({
  // open,
  // content,
  // onClose,
  // onAgree,
  ...props
}: IConfirmOrderModal) => {
  const { activeTab, setActiveTab, activeCartItem, allPlans, setAllPlans } =
    useOrderInfoContext();
  const history = useHistory();
  const { createOrderStatus, customerInfo, errorCode } =
    useSelector(selectCheckout);
  const { cacheFormData } = customerInfo?.guest;
  const { changeCartItemInfo } = useGlobalFunc();

  const {
    changeConfirmOrderModalStatus,
    changeCurrentCheckoutStep,
    setSuccessMessages,
    setErrorMessages,
    resetCart,
    changeShowOrderStatusModal,
  } = useGlobalSlice().actions;
  const { changeCustomerInfo, createOrderRequest } = useCheckoutSlice().actions;
  const { showConfirmOrderModal, cart_items, userSessionData } =
    useSelector(selectGlobal);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!showConfirmOrderModal) {
      history.push(Path.NOT_FOUND_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (createOrderStatus === RequestStatus.SUCCESS) {
      dispatch(changeCurrentCheckoutStep(4));
      dispatch(changeConfirmOrderModalStatus(false));
      dispatch(resetCart());
      dispatch(changeCustomerInfo({ ...defaultCustomerInfo.guest }));
      // reset cache form data
      // setCacheFormData({});
      history.push(`${Path.SUCCESS}`);
    } else if (createOrderStatus === RequestStatus.ERROR) {
      if (errorCode) {
        dispatch(changeConfirmOrderModalStatus(false));
        dispatch(changeShowOrderStatusModal(true));
      } else {
        dispatch(
          setErrorMessages([
            <Box sx={{ whiteSpace: 'pre-line' }}>
              {
                'お客様のご予約より先に、他のお客様がご購入されたか、ご予約の保持期限が過ぎてしまった可能性がございます。\n'
              }
              {'恐れ入りますが、'}
              <Box component="span" sx={{ color: '#e51717' }}>
                再度日程とプランをご確認
              </Box>
              し、ご予約に進んでいただきますよう、お願い申し上げます。
            </Box>,
          ]),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderStatus]);

  const isLoading = checkIsRequesting([createOrderStatus]);

  return (
    // <CustomModal
    //   className="room_list_modal"
    //   open={showConfirmOrderModal}
    //   onClose={() => {
    //     dispatch(changeConfirmOrderModalStatus(false));
    //   }}
    // >
    <>
      <Helmet>
        <title>カート | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="カート | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Loader open={isLoading} />
      <Container sx={{ display: { md: 'block', xs: 'block' } }}>
        <Box pb={5}>
          <Box>
            <BoxHeader>予約確認</BoxHeader>
            <Typography
              component="p"
              fontSize={fontsizes.main}
              color={colors.mainContent}
              mt={4}
              mb={4}
            >
              下記の予約完了ボタンを押すと予約が完了します。
              ※予約完了後のキャンセルはキャンセルポリシーを確認ください
            </Typography>
            <CommonButton
              variant="contained"
              size="large"
              sx={{
                background: colors.blue,
                boxShadow: 'none',
                marginBottom: 2,
              }}
              onClick={() => {
                // dispatch(resetCart());
                // const reservations = getReservationsList(
                //   cart_items,
                //   guestInfo,
                // ).map(v => {
                //   let newData = {
                //     ...v.reservation,
                //     enquetes: guestInfo?.enquetes || [],
                //     check_in_time: guestInfo?.check_in_time,
                //   };
                //   if (guestInfo?.payment?.softbank_payment_token) {
                //     newData = { ...newData, ...guestInfo?.payment };
                //   }
                //   return {
                //     ...v,
                //     reservation: {
                //       // ...v.reservation,
                //       // ...guestInfo?.payment,
                //       ...newData,
                //     },
                //   };
                // });

                const guestInfo = customerInfo?.guest;
                const reservations = getReservationsList(
                  cart_items,
                  cacheFormData,
                );

                const newCustomerInfo = lodash.omit(customerInfo, [
                  'guest.payment',
                  'guest.enquetes',
                  'guest.check_in_time',
                  'guest.male_adult_count',
                ]);
                let orderInfo = {
                  // data: {
                  //   reservations: reservations,
                  //   ...newCustomerInfo,
                  //   // use kana and name in this example
                  //   // guest: {
                  //   //   birthday: '1970-01-01',
                  //   //   address: '南一条西',
                  //   //   gender: 'male',
                  //   //   prefecture_code: '01',
                  //   //   city: '札幌市中央区',
                  //   //   name: '山田太郎',
                  //   //   telephone: '00000000000',
                  //   //   kana: 'ヤマダタロウ',
                  //   //   postal_code: '0600061',
                  //   //   email: 'duyhungp+8@gmail.com',
                  //   // },
                  // },
                  data: reservations,
                  account_id: getToken()
                    ? userSessionData?.account?.account_id
                    : '',
                };

                // let orderInfo = {
                //   data: {
                //     reservations: reservations,
                //     ...customerInfo,
                //   },
                //   account_id: getToken()
                //     ? userSessionData?.account?.account_id
                //     : '',
                // };

                // console.log('🚀 ~ mon', orderInfo);
                // console.log('🚀 ~ customerInfo', customerInfo.guest);
                // orderInfo = lodash.omit(orderInfo, ['data.guest.payment']);
                // (orderInfo = lodash.omit('payment'));
                showConfirmOrderModal &&
                  dispatch(createOrderRequest(orderInfo));
              }}
            >
              <span style={{ color: colors.white, textDecoration: 'none' }}>
                予約を完了する
              </span>
            </CommonButton>
          </Box>
        </Box>
      </Container>
    </>
    // </CustomModal>
  );
};

ConfirmOrderModal.defaultProps = {};
export default ConfirmOrderModal;

const CustomModal = styled(Modal)`
  text-align: center;
`;

const Container = styled(Box)`
  text-align: center;
  max-width: 375px;
  margin: 50px auto 0;
`;
const Wrapper = styled(Box)`
  padding-top: 100px;
`;
