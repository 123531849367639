// UI
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AddToCartButton } from 'app/components/Buttons/AddToCartButton';
import { ReserveButton } from 'app/components/Buttons/ReserveButton';
import Modal from 'app/components/Modal';
import RoomFilter from 'app/components/RoomFilter';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { colors } from 'constants/styles';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { dMedia } from 'styles/media';

interface IPlanOrderModal {
  open: boolean;
  onClose: () => void;
  data: any;
  totalAmount: number | null;
}
export interface IParams {
  slug?: string;
  planID?: string;
}

const PlanOrderModal = (props: IPlanOrderModal) => {
  const { data } = props;
  const { dateInfo } = data || {};
  const { checkin_date, checkout_date } = dateInfo || {};

  const {
    additionSearchInfo: {
      meal,
      adult,
      child,
      nights,
      child_A,
      child_B,
      child_C,
      child_D,
      child_E,
    },
  } = useSelector(selectPlanAndRoom);

  const {
    actions: { setActivePlan },
  } = usePlanAndRoomSlice();

  const dispatch = useDispatch();

  return (
    <Box>
      <Modal
        paddingTopTitle={'9px'}
        title={data.addition_info.plan_name}
        className="room_list_modal plan_order"
        open={props.open}
        onClose={props.onClose}
      >
        {/* <Loader open={isLoading} /> */}
        <WrapDetailPlan
          spacing={{ xs: 2, sm: 5 }}
          sx={{
            padding: {
              xs: '0px 0 40px 0',
              sm: '0px 0 80px 0',
            },
            margin: '2rem 0 0',
          }}
        >
          <Box textAlign={`center`} padding={`0 2rem`}>
            <Stack
              direction={`row`}
              justifyContent={`space-evenly`}
              alignItems={`stretch`}
              sx={{
                maxWidth: '680px',
                margin: '0 auto',
                flexDirection: {
                  md: 'row',
                  xs: 'column',
                },
              }}
            >
              <GreyBox>
                <RoomFilter disableClick isInModal />
              </GreyBox>
              <GreyBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: '180px',
                }}
              >
                <Typography
                  component="span"
                  fontSize={`14px`}
                  color={`#333333`}
                  maxWidth={`180px`}
                >
                  {data.addition_info.room_name}
                </Typography>
              </GreyBox>
              <DateArea
                sx={{
                  cursor: {
                    xs: 'pointer',
                    md: 'unset',
                  },
                  marginLeft: {
                    md: '16px',
                    xs: '0 !important',
                  },
                }}
                spacing={1}
              >
                <GroupDate
                  direction="row"
                  spacing={2}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <CheckInOutDateTitle>チェックイン</CheckInOutDateTitle>
                    <CheckDate
                      sx={{ textAlign: checkin_date ? 'left' : 'center' }}
                    >
                      {checkin_date
                        ? moment(checkin_date).format('YYYY/MM/DD')
                        : ''}
                    </CheckDate>
                  </Box>
                  <Typography
                    sx={{
                      color: colors.grey1,
                      fontWeight: 500,
                      fontSize: '24px',
                      lineHeight: '16px',
                    }}
                  >
                    ~
                  </Typography>
                  <Box>
                    <CheckInOutDateTitle>チェックアウト</CheckInOutDateTitle>
                    <CheckDate
                      sx={{
                        textAlign: checkout_date ? 'left' : 'center',
                      }}
                    >
                      {checkout_date
                        ? moment(checkout_date).format('YYYY/MM/DD')
                        : ''}
                    </CheckDate>
                  </Box>
                </GroupDate>
                {/* Accommodation date undecided */}
              </DateArea>
            </Stack>
            <Stack
              direction="row"
              justifyContent={`flex-end`}
              textAlign={`right`}
              margin={'2rem auto 0'}
              maxWidth={`600px`}
              padding={1}
            >
              <Typography
                fontSize={`18px`}
                lineHeight={`26px`}
                color={`#000`}
                fontWeight={700}
                sx={{ borderBottom: '1px solid #000' }}
              >
                合計料金
                <Typography
                  component="span"
                  fontWeight={400}
                  fontSize={`18px`}
                  lineHeight={`26px`}
                  pl={1.5}
                >{`${new Intl.NumberFormat('ja-JP').format(
                  props.totalAmount ?? 0,
                )}円`}</Typography>
              </Typography>
            </Stack>
          </Box>

          <Box textAlign={`center`}>
            <ButtonWrapper
              mt={3}
              direction="row"
              justifyContent={`space-between`}
              maxWidth={`340px`}
              margin={`0 auto`}
            >
              <ReserveButton
                item={{ ...props.data, price: props.totalAmount }}
              ></ReserveButton>
              <AddToCartButton
                item={{ ...props.data, price: props.totalAmount }}
                onClick={() => {
                  props.onClose();
                  dispatch(setActivePlan(null));
                }}
              ></AddToCartButton>
            </ButtonWrapper>
          </Box>
        </WrapDetailPlan>
      </Modal>
    </Box>
  );
};

export default PlanOrderModal;

const WrapDetailPlan = styled(Stack)``;
const GreyBox = styled(Box)`
  background: #f2f2f2;
  border-radius: 8px;
  padding: 1rem;
  > div {
    background: #f2f2f2 !important;
    > div {
      height: auto;
      &:after {
        display: none;
      }
    }
  }
  ${dMedia.tablet`
    margin: 0 0 1rem 0!important;
  `}
`;
const CheckInOutDateTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.grey3};
    margin-bottom: 8px;
  }
`;
const CheckDate = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    color: ${colors.grey1};
    line-height: 20px;
  }
`;
const GroupDate = styled(Stack)``;
const DateArea = styled(Stack)`
  border-radius: 8px;
  background: ${colors.grey6};
  width: 100%;
  display: flex;
  max-width: 256px;
  padding: 16px 8px;
  ${dMedia.tablet`
    max-width: 100%;
  `}
`;

const ButtonWrapper = styled(Stack)`
  @media (max-width: 360px) {
    flex-direction: column !important;
    button {
      margin-bottom: 1rem;
    }
  }
`;
