import { Box, CardContent, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CommonButton } from 'app/components/CommonButton';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useLoginSlice } from 'app/pages/LoginPage/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { defaultCustomerInfo, useCheckoutSlice } from '../CheckoutPage/slice';
import moment from 'moment';
import { convertDate } from 'utils/moment';
import _ from 'lodash';
import {
  BEFORE_PAID_PAYMENT_TYPE,
  CART_HANDLE_TYPE,
  CHILD_PRICE,
} from 'constants/common';
import { IAdditionRoomInfo, IDataHandleCart, IRoomItem } from 'types/common';
import { IAdditionInfoFormData } from 'app/components/Modal/AdditionInfoModal';
import usePlanAndRoom from '../PlanAndRoomPage/usePlanAndRoom';
import { getCheckStockParams, lodash } from 'utils/helper';
import { selectCheckout } from '../CheckoutPage/slice/selectors';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGlobalFunc = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { checkInventoryStatus, errorCode } = useSelector(selectCheckout);
  const {
    logout,
    getHotelStockRequest,
    setErrorMessages,
    changeCurrentCheckoutStep,
    changeConfirmOrderModalStatus,
    changeShowAdditionInfoModalStatus,
    changeSavedCartItem,
    addItemToCartRequest,
    changeShowLoginModalStatus,
    changeReservationStatus,
    resetCheckHotelStockStatus,
  } = useGlobalSlice().actions;
  const { actions } = useGlobalSlice();
  const { resetLoginStatus } = useLoginSlice().actions;
  const { resetCheckoutStatus, changeCustomerInfo, checkInventoryRequest } =
    useCheckoutSlice().actions;
  const {
    cart_items,
    getHotelStockStatus,
    hotelStockStatus,
    isLoggedIn,
    hotelInStockStatus,
    outOfStockMess,
  } = useSelector(selectGlobal);

  const { getPlanDetailStatus, getPlanDetailRequest } = usePlanAndRoom();

  const changeShowOrderStatusModal = data => {
    dispatch(actions.changeShowOrderStatusModal(data));
  };

  const resetCheckHotelStock = () => {
    dispatch(resetCheckHotelStockStatus());
  };

  const handleLogout = () => {
    dispatch(resetCheckoutStatus());
    dispatch(logout());
    dispatch(resetLoginStatus());
  };

  const checkInventory = data => {
    dispatch(checkInventoryRequest(data));
  };

  const changeCartItemInfo = newCartItems => {
    dispatch(actions.changeCartItemInfo({ newCartItems }));
  };

  const displayErrorMessages = (mess: string[]) => {
    dispatch(setErrorMessages(mess));
  };

  const checkHotelStock = () => {
    resetCheckHotelStock();
    _.map(cart_items, v => {
      let checkStockParams = getCheckStockParams(v);

      dispatch(
        getHotelStockRequest({
          ...checkStockParams,
          cart_local_id: v.local_id,
          // onSuccess,
          // onError,
        }),
      );
    });
  };

  const refChangePaymentMethodOfCart = useRef<any>(null);
  const handleChangePaymentMethodOfCart = newPaymentMethod => {
    const newCartItems = _.map(cart_items, v => {
      return { ...v, ...newPaymentMethod };
    });
    changeCartItemInfo(newCartItems);
  };
  refChangePaymentMethodOfCart.current = handleChangePaymentMethodOfCart;

  const changePaymentMethodOfCart = newPaymentMethod => {
    refChangePaymentMethodOfCart.current(newPaymentMethod);
  };

  const handleRedirectPaymentPage = (
    payment_type = BEFORE_PAID_PAYMENT_TYPE,
  ) => {
    let selected_payment_method = {};
    if (payment_type === BEFORE_PAID_PAYMENT_TYPE) {
      selected_payment_method = { softbank_payment: true };
      history.push(`${Path.PAYMENT}`);
    } else {
      selected_payment_method = { front_desk: true };
      // handle 2 task at once
      dispatch(changeConfirmOrderModalStatus(true));
      history.push(Path.CONFIRM);
    }
    // comment this block
    // selected_payment_method = { front_desk: true };
    // dispatch(changeConfirmOrderModalStatus(true));

    changePaymentMethodOfCart({
      selected_payment_method,
    });
    dispatch(changeCurrentCheckoutStep(3));
  };

  const callApiAddCart = data => {
    // dispatch(changeCustomerInfo({ ...defaultCustomerInfo.guest }));
    dispatch(addItemToCartRequest({ ...data, history }));
  };

  const handleAddItemToCart = (data: IDataHandleCart) => {
    // debugger;
    const { handleType, item, addition_info, reservation } = data;

    const {
      hotel_id,
      local_hotel_id,
      plan_id,
      room_id,
      plan_name,
      room_name,
      cancellation_fee_policies,
      max_guests,
      meals,
      payment_method,
      hotel_name,
      hotelStockStatus,
      detail_plan,
    } = addition_info;
    const {
      meal,
      adult,
      child_A,
      child_B,
      child_C,
      child_D,
      child_E,
      nights,
      checkin_date,
      checkout_date,
    } = reservation;

    // if (handleType === CART_HANDLE_TYPE.RESERVED || CART_HANDLE_TYPE.ADD_CART) {

    // }
    // if (cart_items.length >= 1) {
    //   return;
    // }
    // add to cart if has item
    if (
      item &&
      item?.addition_info &&
      Object.keys(item?.addition_info).length
    ) {
      // if has adult,... => add to cart
      // else open modal addition info
      if (adult && checkin_date && checkout_date) {
        const { hotel_id, plan_id, room_id } = item.addition_info;

        if (
          handleType === CART_HANDLE_TYPE.ADD_CART ||
          handleType === CART_HANDLE_TYPE.ADDITION_MODAL
        ) {
          callApiAddCart(data);
        }
        if (handleType === CART_HANDLE_TYPE.RESERVED) {
          callApiAddCart(data);
          // if (isLoggedIn) {
          //   callApiAddCart(data);
          // } else {
          //   dispatch(changeShowLoginModalStatus(true));
          //   dispatch(changeReservationStatus(true));
          // }
        }
      } else {
        // save cart item to redux
        dispatch(
          changeSavedCartItem({
            ...item,
            isReserved: handleType === CART_HANDLE_TYPE.RESERVED,
          }),
        );
        dispatch(changeShowAdditionInfoModalStatus(true));
      }
    }
  };

  const alertConflictPaymentMethod = () => {
    dispatch(
      setErrorMessages([
        '「オンライン事前決済のみ可能」「現地決済のみ可能」のプランが混在しております。一括購入される際は、決済方法を揃えてご購入へとお進みください。',
      ]),
    );
  };

  return {
    handleLogout,
    cart_items,
    checkHotelStock,
    getHotelStockStatus,
    displayErrorMessages,
    changeCartItemInfo,
    hotelStockStatus,
    handleRedirectPaymentPage,
    handleAddItemToCart,
    alertConflictPaymentMethod,
    hotelInStockStatus,
    outOfStockMess,
    resetCheckHotelStock,
    checkInventory,
    checkInventoryStatus,
    errorCode,
    changeShowOrderStatusModal,
  };
};

export default useGlobalFunc;
