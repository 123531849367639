import { fontsizes, colors } from 'constants/styles';
import { Typography } from '@mui/material';

interface Props {
  children: any;
  size?: number;
  spacing?: number;
}

export default function ItemName({ children, size, spacing }: Props) {
  return (
    <Typography
      sx={{
        fontSize: size ? `${size}px` : fontsizes.smallText,
        lineHeight: '14px',
        fontWeight: '400',
        color: colors.grey1,
        margin: 0,
        letterSpacing: spacing ? `${spacing}px` : '-1px',
      }}
    >
      {children}
    </Typography>
  );
}
