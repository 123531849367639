import { Box, Stack, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import Header from 'app/components/Header';
import { Loader } from 'app/components/Loading';
import OrderHistoryItem from 'app/components/OrderHistoryItem';
import { ReactComponent as Calendar24Icon } from 'assets/icons/calendar-icon-24.svg';
import { ReactComponent as Calendar40Icon } from 'assets/icons/calendar-icon-40.svg';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { checkIsRequesting } from 'utils/helper';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { useCheckoutSlice } from '../CheckoutPage/slice';
import { selectCheckout } from '../CheckoutPage/slice/selectors';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import Path from 'config/clientPath';
import { useHistory } from 'react-router';

export function OrderHistory() {
  const history = useHistory();

  const { width: pageWidth } = useWindowDimensions(0);
  const { order_history, getOrderHistoryStatus, cancelOrderStatus } =
    useSelector(selectCheckout);
  const { cart_items, userSessionData } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const [activeOrder, setActiveOrder] = React.useState(null);

  const {
    actions: { getOrderHistoryRequest },
  } = useCheckoutSlice();

  const orders = [
    {
      image: '/img/hotels/room.png',
      room_name: 'VMG グランド（定員4名）',
      stay_date: '2泊',
      stay_period: '2021/08/17(火) ~ 2021/08/19(木)',
      plan_name: '通常ご宿泊プラン',
      meals: ['朝食（朝食）', '夕食（夕食）'],
      price: 203000,
    },
    {
      image: '/img/hotels/room.png',
      room_name: 'VMG グランド（定員4名）',
      stay_date: '2泊',
      stay_period: '2021/08/17(火) ~ 2021/08/19(木)',
      plan_name: '通常ご宿泊プラン',
      meals: ['朝食（朝食）', '夕食（夕食）'],
      price: 203000,
    },
  ];

  const isLoading = checkIsRequesting([
    getOrderHistoryStatus,
    cancelOrderStatus,
  ]);

  React.useEffect(() => {
    dispatch(
      getOrderHistoryRequest({
        account_id: userSessionData?.account?.account_id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const changeUrl = (url: string) => {
  //   window.history.replaceState(null, '', url);
  // };

  return (
    <>
      <Helmet>
        <title>予約情報 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="予約情報 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Loader open={isLoading} />
      <Wrapper alignItems={'center'}>
        <Container spacing={5}>
          <WrapHeader>
            <BoxHeader
              fontSize={24}
              className="box_header"
              icon={pageWidth < 900 ? <Calendar24Icon /> : <Calendar40Icon />}
            >
              予約情報
            </BoxHeader>
          </WrapHeader>
          {order_history?.reservations.map((item, k) => (
            <OrderHistoryItem
              data={item}
              key={k}
              onClick={data => {
                // changeUrl(Path.CANCEL_ORDER_DETAIL + item.reservation_id);
                // setActiveOrder(data);
                history.push(
                  Path.CANCEL_ORDER_DETAIL_PREFIX + item.reservation_id,
                );
              }}
            ></OrderHistoryItem>
          ))}
          {_.isEmpty(order_history?.reservations) && !isLoading ? (
            <Typography>
              条件に合致する予約情報は見つかりませんでした
            </Typography>
          ) : (
            ''
          )}
        </Container>
      </Wrapper>
    </>
  );
}

const WrapHeader = styled(Box)``;

const Container = styled(Stack)`
  max-width: 562px;
  width: 100%;
`;

const Wrapper = styled(Stack)`
  padding: 40px 0;
  margin: 0 auto;
  .box_header {
    padding-left: 10px;
    svg {
      padding-left: 0px;
    }
  }
  ${dMedia.tablet`
    padding: 16px 16px 80px 16px;
  `}
`;
