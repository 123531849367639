// UI
import { Box } from '@mui/material';
import { Loader } from 'app/components/Loading';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import LoginForm from 'app/pages/LoginPage/components/LoginForm';
import { useLoginSlice } from 'app/pages/LoginPage/slice';
import { selectLogin } from 'app/pages/LoginPage/slice/selectors';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { setToken, setLoginTime } from 'utils/localStorage';
import { useLocation } from 'react-router-dom';

import Modal from './';

interface ILoginModal {}

const LoginModal = (props: ILoginModal) => {
  const history = useHistory();
  const location = useLocation();

  const { changeShowLoginModalStatus, login, changeIsRedirectAfterLogin } =
    useGlobalSlice().actions;
  const { showLoginModal, isReservation, isRedirectAfterLogin } =
    useSelector(selectGlobal);
  const { setErrorMessages, setIsStayLoggedIn } = useGlobalSlice().actions;
  const dispatch = useDispatch();
  const { loginStatus, accessToken, errorMess } = useSelector(selectLogin);
  const { loginRequest } = useLoginSlice().actions;
  const [stayLoggedIn, setStayLoggedIn] = React.useState<boolean>(false);
  const submitLogin = formData => {
    dispatch(loginRequest(formData));
    setStayLoggedIn(formData.remember);
  };

  React.useEffect(() => {
    if (loginStatus === RequestStatus.SUCCESS) {
      dispatch(changeShowLoginModalStatus(false));
      dispatch(login());
      setToken(accessToken);
      setLoginTime(new Date().getTime());
      setIsStayLoggedIn(stayLoggedIn);
      if (isRedirectAfterLogin) {
        if (location.pathname === '/reservation/cart/') {
          window.location.href = Path.ORDER_INFO;
        } else {
          history.push(location);
        }
      }
      dispatch(changeIsRedirectAfterLogin(true));
    }
    if (loginStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const isLoading = loginStatus === RequestStatus.REQUESTING;

  return (
    <>
      <Loader open={isLoading} />
      <Container>
        <CustomModal
          title={''}
          className="login_modal"
          open={showLoginModal}
          onClose={() => {
            dispatch(changeShowLoginModalStatus(false));
          }}
        >
          <LoginForm
            className={'login_form'}
            submitLoginForm={submitLogin}
            id="login_popup"
          />
        </CustomModal>
      </Container>
    </>
  );
};

LoginModal.defaultProps = {};
export default LoginModal;

const CustomModal = styled(Modal)`
  .login_form {
    .MuiCardContent-root {
      padding-top: 0px;
    }
  }
`;

const Container = styled(Box)``;
