import APIs from 'APIs';
import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import Slice from '.';

function* getPolicyFlow(action) {
  try {
    const response = yield call(APIs.getPolicy, action.payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getPolicySuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getPolicyFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getPolicyFail });
  }
}

function* policyWatcher() {
  yield takeLatest(Slice.getPolicy, getPolicyFlow);
}

export default policyWatcher;
