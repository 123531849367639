// UI
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useRegisterSlice } from 'app/pages/RegistrationInfoPage/slice';
import { selectRegistration } from 'app/pages/RegistrationInfoPage/slice/selectors';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Loader } from 'app/components/Loading';

import Modal from '../Modal';
import { AccountInfoRegister } from '../Modal/AccountInfoRegister';
import { SUCCESS_MESS } from 'constants/common';

interface IRegisterModal {
  open?: boolean;
}

export function RegisterModal({ open = false, ...props }: IRegisterModal) {
  const history = useHistory();
  const {
    changeShowRegisterModalStatus,
    setSuccessMessages,
    setErrorMessages,
    changeShowLoginAfterRegisterModalStatus,
  } = useGlobalSlice().actions;
  const dispatch = useDispatch();
  const { showRegisterModal, isLoggedIn } = useSelector(selectGlobal);
  const { errorMess, registrationStatus } = useSelector(selectRegistration);
  const { registerRequest } = useRegisterSlice().actions;
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isInRegisterModal, setIsInRegisterModal] = useState<boolean>(false);

  const submitRegisterForm = formData => {
    setIsInRegisterModal(true);
    setEmail(formData.email);
    dispatch(registerRequest(formData));
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push(Path.ORDER_INFO);
    }
  }, []);
  useEffect(() => {
    if (registrationStatus === RequestStatus.SUCCESS) {
      if (isInRegisterModal) {
        dispatch(setSuccessMessages([SUCCESS_MESS.REGISTER_ACCOUNT]));
        // history.push(Path.LOGIN);
        dispatch(
          changeShowLoginAfterRegisterModalStatus({ open: true, email }),
        );
        dispatch(changeShowRegisterModalStatus(false));
        setIsInRegisterModal(false);
      }
    }
    if (registrationStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
      setIsInRegisterModal(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationStatus]);

  const isLoading = registrationStatus === RequestStatus.REQUESTING;

  return (
    <CustomModal
      title={''}
      className="register_modal"
      unsetTitle={true}
      open={showRegisterModal}
      onClose={() => {
        dispatch(changeShowRegisterModalStatus(false));
      }}
    >
      {isInRegisterModal && <Loader open={isLoading} />}
      <AccountInfoRegister
        isRegisterPage
        submitRegisterForm={submitRegisterForm}
      />
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .register_form {
    padding-bottom: 56px;
  }
`;
