// UI
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BoxHeader } from 'app/components/BoxHeader';
import Calendar from 'app/components/Calendar';
import EquipmentList from 'app/components/EquipmentList';
import ImageSlider, { IPaginatePosition } from 'app/components/ImageSlider';
import Modal from 'app/components/Modal';
import RoomInfo from 'app/components/RoomInfo';
import { useHotelSlice } from 'app/pages/HotelDetailsPage/slice';
import { selectHotelDetails } from 'app/pages/HotelDetailsPage/slice/selectors';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import Path from 'config/clientPath';
import { LIMIT_ITEMS } from 'constants/common';
import { colors, fontsizes } from 'constants/styles';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { getImages } from 'utils/helper';
import { useWindowDimensions } from 'utils/useWindowDimensions';

interface IRoomDetailModal {
  isGoBackWhenClose?: boolean;
}

export interface IParams {
  slug?: string;
  roomID?: string;
}

const AMENITY_CATEGORY_ID = 3;

const RoomDetailModal = (props: IRoomDetailModal) => {
  const location = useLocation();
  const history = useHistory();

  const {
    tab_type,
    sort_type,
    rooms,
    active_room,
    getRoomDetailStatus,
    detail_room,
    hotelId,
  } = useSelector(selectPlanAndRoom);
  const { getHotelDetails } = useHotelSlice().actions;
  const hotelData = useSelector(selectHotelDetails);
  const hotelInfo = {
    ...hotelData,
    tags: hotelData?.tags?.filter(v => v.category_id === AMENITY_CATEGORY_ID),
  };

  const dispatch = useDispatch();
  const {
    actions: { setActiveRoom, getRoomDetailRequest, changeCheckInOutDate },
  } = usePlanAndRoomSlice();

  React.useEffect(() => {
    if (active_room) {
      dispatch(
        getRoomDetailRequest({
          hotel_id: active_room.hotel_id,
          room_id: active_room.room_id,
        }),
      );
      dispatch(getHotelDetails(active_room.hotel_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_room]);

  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;

  const { slug, roomID } = useParams<IParams>();

  // const isLoading = checkIsRequesting([getRoomDetailStatus]);

  // const optionsTag = getRoomOptionsTag({ options: detail_room?.options });
  let queryParams = queryString.parse(window.location.search);
  const [selectedDates, setSelectedDates] = React.useState([
    queryParams.checkin_date,
    queryParams.checkout_date,
  ]);

  return (
    <Box>
      <Modal
        title="お部屋詳細"
        paddingTopTitle={'9px'}
        className="room_list_modal"
        open={!!active_room}
        onClose={() => {
          dispatch(setActiveRoom(null));
          if (props.isGoBackWhenClose) {
            let params = queryString.parse(window.location.search);
            let qs = '';
            if (queryString.stringify(params).length > 0) {
              qs = `?${queryString.stringify(params)}`;
            }
            window.history.replaceState(
              window.history.state,
              '',
              `${Path.RESERVATION_PREFIX}/${slug}${Path.ROOMS}/${qs}`,
            );
          }
        }}
      >
        {/* <PartialLoader open={isLoading} /> */}
        <DetailRoom
          spacing={{ xs: 2, sm: 5 }}
          sx={{
            padding: {
              xs: '0px 0 40px 0',
              sm: '0px 0 80px 0',
            },
          }}
        >
          <Stack
            spacing={{ xs: 2, sm: 5 }}
            sx={{
              width: pageWidth <= 768 ? '100%' : '400px',
              margin: { xs: '0 auto', sm: '0 auto' },
            }}
          >
            <Box ml={pageWidth <= 768 ? '-16px' : '0px'}>
              <ImageSlider
                images={getImages(
                  detail_room?.images,
                  LIMIT_ITEMS.PLAN_ROOM_IMAGE,
                )}
                sliderWidth={pageWidth <= 768 ? 'calc(100vw)' : '400px'}
                paginatePosition={IPaginatePosition.OUTSIDE}
                normalPaginateColor={colors.grey5}
                activePaginateColor={colors.grey1}
                iconPrevSize={40}
              />
            </Box>
            <Stack spacing={2}>
              <NameRoomModal mt={{ xs: 2 }}>{detail_room?.name}</NameRoomModal>
              {!!detail_room?.tags.length && (
                <RoomInfo data={detail_room}></RoomInfo>
              )}
              {/* {!_.isEmpty(detail_room?.tags) && (
                <TagList data={detail_room?.tags} type={2} />
              )} */}
            </Stack>
            {detail_room?.content && (
              <DescRoomModal
                dangerouslySetInnerHTML={{
                  __html: detail_room?.content,
                }}
              ></DescRoomModal>
            )}

            <Box>
              <Typography
                sx={{
                  color: colors.heading,
                  marginBottom: '8px',
                  fontWeight: '700',
                  fontSize: fontsizes.main,
                  lineHeight: '14px',
                }}
              >
                {'アメニティ'}
              </Typography>
              {/* {!_.isEmpty(detail_room?.tags) && (
                <Box mb={1}>
                  <Equipments data={getTagsData(detail_room?.tags)} />
                </Box>
              )} */}
              {/* list equipment same as list in hotel detail page */}
              <EquipmentList
                equipmentListData={{ ...hotelInfo }}
                slug={hotelInfo?.slug}
                isInHotelPage={false}
              />
            </Box>
            {/* <Equipments
              data={['ブラウ', 'ブラウ', 'ブラウ']}
              title={'アメニティ'}
            /> */}
          </Stack>
        </DetailRoom>
      </Modal>
    </Box>
  );
};

export default RoomDetailModal;

const WrapModal = styled(Box)``;

const WrapEquipmentList = styled(Stack)`
  .equipment_list {
    margin-bottom: 0px;
    .equipment_list_content {
      margin-bottom: 0px;
      padding: 0;
      .amenities {
        max-width: 400px;
        margin: 0 auto;
      }
      .btn_equipment_group {
        margin-top: 40px;
        text-align: center;
        .btn-equipment {
          font-size: 12px;
          line-height: 14px;
          padding: 13px 28px;
          margin-right: 0px;
          &:first-child {
            margin-right: 16px;
          }
          @media screen and (max-width: 800px) {
            margin-bottom: 16px;
            width: 240px;
            &:first-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
  ${dMedia.tablet`
    margin-top: 0px !important
  `}
`;

const NameRoomModal = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;
const DescRoomModal = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.grey1};
  }
`;
const DetailRoom = styled(Stack)``;
const WrapCalendar = styled(Stack)`
  width: 100%;
  & > div {
    align-items: center;
    width: 100%;
    max-width: 351px;
  }
  ${dMedia.tablet`
    margin-left: -16px !important;
    width: calc(100vw);
    padding: 0 6px;
    & > div {
      width: 100%;
      max-width: none;
    }
  `}
`;
