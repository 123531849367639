import { Box } from '@mui/material';
import Checkout from 'app/components/Checkout';
import { Loader } from 'app/components/Loading';
import { AccountInfoRegister } from 'app/components/Modal/AccountInfoRegister';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { SUCCESS_MESS } from 'constants/common';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { checkIsRequesting, checkTokenNotExpired } from 'utils/helper';
import {
  getLoginTime,
  getToken,
  setLoginTime,
  setToken,
} from 'utils/localStorage';

import LoginAfterRegister from '../LoginPage/components/LoginAfterRegister';
import { useLoginSlice } from '../LoginPage/slice';
import { selectLogin } from '../LoginPage/slice/selectors';
import { useRegisterSlice } from './slice';
import { selectRegistration } from './slice/selectors';

export function RegistrationInfoPage() {
  const history = useHistory();
  const {
    changeCurrentCheckoutStep,
    setErrorMessages,
    setSuccessMessages,
    changeShowLoginAfterRegisterModalStatus,
  } = useGlobalSlice().actions;

  const { cart_items } = useSelector(selectGlobal);
  const { registrationStatus, errorMess } = useSelector(selectRegistration);
  const { registerRequest, resetRegistrationStatus } =
    useRegisterSlice().actions;
  const [email, setEmail] = React.useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  // handle login after register success
  const { login, changeIsRedirectAfterLogin } = useGlobalSlice().actions;
  const { showLoginAfterRegisterModal, isReservation, isRedirectAfterLogin } =
    useSelector(selectGlobal);
  const {
    loginStatus,
    accessToken,
    errorMess: errorMessLogin,
  } = useSelector(selectLogin);
  const { loginRequest } = useLoginSlice().actions;

  const submitLogin = formData => {
    dispatch(loginRequest({ ...formData, email }));
  };

  React.useEffect(() => {
    if (loginStatus === RequestStatus.SUCCESS) {
      // dispatch(changeShowLoginAfterRegisterModalStatus({ open: false }));
      dispatch(login());
      setToken(accessToken);
      setLoginTime(new Date().getTime());

      // no redirect page
      if (isRedirectAfterLogin) {
        // if user reserve room and has not login => login and redirect to order info page
        if (cart_items.length) {
          history.push(Path.ORDER_INFO);
        } else {
          history.push(Path.SEARCH);
        }
      }
      dispatch(changeIsRedirectAfterLogin(true));
    }
    if (loginStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMessLogin));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);
  // end handle login after register success

  const submitRegisterForm = formData => {
    setEmail(formData.email);
    dispatch(registerRequest(formData));
  };
  React.useEffect(() => {
    if (getToken() && checkTokenNotExpired()) {
      history.push(Path.ORDER_INFO);
    }
    dispatch(changeCurrentCheckoutStep(1));
    return () => {
      dispatch(resetRegistrationStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (registrationStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages([SUCCESS_MESS.REGISTER_ACCOUNT]));
      // history.push(Path.ORDER_INFO);
      // dispatch(changeShowLoginAfterRegisterModalStatus({ open: true, email }));
    }
    if (registrationStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationStatus]);

  const isLoading = checkIsRequesting([registrationStatus, loginStatus]);
  // const isLoading = true;

  return (
    <>
      <Helmet>
        <title>アカウント情報 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="アカウント情報 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      {cart_items.length ? (
        <Checkout>
          <Loader open={isLoading} />
          {registrationStatus === RequestStatus.SUCCESS ? (
            <LoginAfterRegister email={email} submitLoginForm={submitLogin} />
          ) : (
            <AccountInfoRegister submitRegisterForm={submitRegisterForm} />
          )}
        </Checkout>
      ) : (
        <Box>
          <Loader open={isLoading} />
          {registrationStatus === RequestStatus.SUCCESS ? (
            <LoginAfterRegister email={email} submitLoginForm={submitLogin} />
          ) : (
            <Box padding={`6rem 1rem`}>
              <AccountInfoRegister submitRegisterForm={submitRegisterForm} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
