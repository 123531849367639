import { Box, Button, Stack } from '@mui/material';
import { GoogleMap } from 'app/components/GoogleMap';
import Header from 'app/components/Header';
import { Loader } from 'app/components/Loading';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { ReactComponent as ArrowIcon } from 'assets/icons/map/arrow.svg';
import { ReactComponent as MarkerIcon } from 'assets/icons/marker.svg';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { colors, fontsizes } from 'constants/styles';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import smoothscroll from 'smoothscroll-polyfill';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { checkIsRequesting } from 'utils/helper';
import { commonFormat } from 'utils/moment';
import { useWindowDimensions } from 'utils/useWindowDimensions';

import { PartialLoader } from 'app/components/PartialLoader';
import { AdvancedSearchForm } from './components/AdvancedSearchForm';
import { FilterForm } from './components/FilterForm';
import { SearchItem } from './components/SearchItem';
import { SearchMenu } from './components/SearchMenu';
import { PRICE_RANGES } from './constants';
import { useSearchSlice } from './slice';
import { selectSearch } from './slice/selectors';

smoothscroll.polyfill();

export const MAX_PRICE = 50000;
export const MIN_PRICE = 19999;
export const LATITUDE = parseFloat('37.88637');
export const LONGITUDE = parseFloat('135.00589');
export const LONGITUDE_FULL_WIDTH = parseFloat('120.00589');
enum SORT_STATUS {
  INCREASE = 'price asc',
  DECREASE = 'price desc',
}
export interface ISearchPageProps {}

export interface QuerySet {
  operator?: string;
  checkout_date?: string;
  checkin_date?: string;
  guest_count: number;
  price_range?: string;
  area?: string;
  tag?: string;
  male?: string;
  female?: string;
  night?: number;
  children?: string;
  infant?: string;
  child?: number;
  is_flexible?: boolean;
  child_a_count?: number;
  child_b_count?: number;
  child_c_count?: number;
  child_d_count?: number;
  child_e_count?: number;
}

export const DEFAULT_SEARCH_VALUE = {
  area: '1,2,3,4,5',
  operator: '未選択',
  guest_count: 2,
  checkout_date: '未定',
};

export const redirectWithQuery = (history, link: string) => {
  let params = queryString.parse(window.location.search);
  params = _.omitBy(params, v => !v || v === -1);
  // remove query param when has not value or value is -1 (all value)
  params = queryString.stringify(params);
  history.push(`${link}?${params}`);
};

/**
 * manually get valid datetime
 */
export const formatDatetime = dateString => {
  return moment(dateString.split('(')[0]).format('YYYY-MM-DD');
};

export default function SearchPage(props: ISearchPageProps) {
  const MENU_HEIGHT = 380;
  const HEADER_HEIGHT = 80;
  const ITEM_GAP = 16;
  const listRef = React.useRef<HTMLDivElement>(null);
  const itemListRef = React.useRef<any[]>([]);
  const { t } = useTranslation();
  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    actions: {
      searchRequest,
      areaRequest,
      tagRequest,
      resetSearchRequestStatus,
      checkAvailabilityRequest,
    },
  } = useSearchSlice();
  const {
    data,
    areas,
    tags,
    searchStatus,
    areaStatus,
    tagStatus,
    availData,
    checkAvailabilityStatus,
  } = useSelector(selectSearch);
  const { updateSearchInfo } = useGlobalSlice().actions;

  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [activeMarker, setActiveMarker] = useState<number | null>(null);

  const [searchItemsVisible, setSearchItemsVisible] = useState<boolean>(true);
  const [query, setQuery] = useState<QuerySet | null>(null);
  const [sortStatus, setSortStatus] = useState<SORT_STATUS | null>(null);
  const [center, setCenter] = useState<{
    lat: number | string;
    lng: number | string;
  }>({ lat: LATITUDE, lng: LONGITUDE_FULL_WIDTH });

  const [requestRoomStatus, setRequestRoomStatus] = useState<any>({});

  /** start of logic */
  useEffect(() => {
    reloadData();
    handleQueryParams();
    return () => {
      dispatch(resetSearchRequestStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    /** to do dispatch search request */
    dispatch(areaRequest({ page: 1 }));
    dispatch(tagRequest({ page: 1 }));

    return () => {
      dispatch(resetSearchRequestStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Todo get price in windows of 5 days */
  useEffect(() => {
    if (data?.length && searchStatus === RequestStatus.SUCCESS) {
      const target = {};
      data.forEach(item => (target[item.id] = true));
      setRequestRoomStatus(target);
      /** passing extra params */
      const params = queryString.parse(window.location.search);
      const obj = _.pick(params, [
        'guest_count',
        'checkin_date',
        'checkout_date',
        'child_a_count',
        'child_b_count',
        'child_c_count',
        'child_d_count',
        'child_e_count',
      ]);

      if (_.isEmpty(obj)) {
        obj.guest_count = 2;
      }

      dispatch(resetSearchRequestStatus());

      data.forEach((it, idx) => {
        dispatch(checkAvailabilityRequest({ ...params, id: it.id }));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchStatus]);

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    if (_.isEmpty(params)) {
      params.guest_count = DEFAULT_SEARCH_VALUE.guest_count;
    }

    dispatch(searchRequest({ ...params }));
  };

  const handleSearch = formData => {
    const params = queryString.parse(window.location.search);

    let newParams = {
      ..._.omit(formData, ['checkin_date', 'checkout_date', 'area']),
      checkin_date: formData.checkin_date
        ? formatDatetime(formData.checkin_date)
        : undefined,
      checkout_date: formData.checkout_date
        ? formatDatetime(formData.checkout_date)
        : undefined,
      area_id: formData.area,
    };
    newParams = params?.page
      ? { ...params, ...newParams, page: 1 }
      : { ...params, ...newParams };
    newParams = params?.order
      ? { ...newParams, order: params?.order }
      : newParams;

    newParams = _.omitBy(newParams, v => !v || v === -1);
    // remove query param when has not value or value is -1 (all value)
    const qs = queryString.stringify(newParams);
    updateSearchInfo(newParams);
    history.push(`${Path.SEARCH}?${qs}`);
    setOpenSearch(false);
  };

  /** todo */
  const handleFilter = formData => {
    const params = queryString.parse(window.location.search);
    let [price_min, price_max] = [null, null];
    if (formData.price_range) {
      [price_min, price_max] = formData.price_range.split(',');
    }
    let newParams = {
      ...params,
      ..._.omit(formData, ['tag', 'area', 'price_range']),
      tag_id: formData.tag?.length ? formData.tag.join(',') : null,
      area_id: formData.area?.length ? formData.area.join(',') : null,
      price_min,
      price_max,
    };

    newParams = params?.page ? { ...newParams, page: 1 } : newParams;
    newParams = params?.order
      ? { ...newParams, order: params?.order }
      : newParams;
    newParams = _.omitBy(newParams, v => !v || v === -1);
    // remove query param when has not value or value is -1 (all value)
    const qs = queryString.stringify(newParams);
    history.push(`${Path.SEARCH}?${qs}`);
    setOpenFilter(false);
  };

  const handleQueryParams = () => {
    let params = queryString.parse(window.location.search);
    const area = params?.area_id ?? DEFAULT_SEARCH_VALUE.area.toString();

    let price_range;
    if (params.price_max > MAX_PRICE) {
      price_range = MAX_PRICE;
    }
    if (params.price_max < MIN_PRICE) {
      price_range = _.get(_.find(PRICE_RANGES, { id: 1 }), 'value');
    }
    if (params.price_min && params.price_max) {
      price_range = params.price_min + ',' + params.price_max;
    }

    setQuery({
      area,
      checkin_date: params?.checkin_date
        ? commonFormat(moment(params.checkin_date), 'YYYY/MM/DD(ddd)')
        : undefined,
      checkout_date: params?.checkout_date
        ? commonFormat(moment(params.checkout_date), 'YYYY/MM/DD(ddd)')
        : undefined,
      guest_count: params?.guest_count ?? DEFAULT_SEARCH_VALUE.guest_count,
      is_flexible: params?.is_flexible ?? false,
      child_a_count: params?.child_a_count ?? 0,
      child_b_count: params?.child_b_count ?? 0,
      child_c_count: params?.child_c_count ?? 0,
      child_d_count: params?.child_d_count ?? 0,
      child_e_count: params?.child_e_count ?? 0,
      child: params?.child ?? 0,

      tag: params?.tag_id,
      price_range,
    });

    if (params.order) {
      setSortStatus(params.order);
    }
  };
  /** end of logic */
  /** scroll map */
  const scrollableDiv = document.getElementById('hotelItemList');

  const handleScroll = idx => {
    if (listRef.current) {
      window.scrollTo({
        top: MENU_HEIGHT,
        behavior: 'smooth',
      });
    }
    let height = 0;
    for (let i = 1; i <= idx; i++) {
      if (itemListRef.current[i - 1]) {
        height +=
          itemListRef.current[i - 1].clientHeight +
          (i < itemListRef.current.length ? ITEM_GAP : 0);
      }
    }
    scrollableDiv?.scrollTo({
      top: height,
      behavior: 'smooth',
    });
  };

  const sortHotels = () => {
    const params = queryString.parse(window.location.search);
    if (sortStatus) {
      params.order =
        sortStatus === SORT_STATUS.INCREASE
          ? SORT_STATUS.DECREASE
          : SORT_STATUS.INCREASE;
    } else {
      params.order = SORT_STATUS.INCREASE;
    }
    setSortStatus(params.order);
    const qs = queryString.stringify(params);
    history.push(`${Path.SEARCH}?${qs}`);
  };

  const isLoading = checkIsRequesting([searchStatus, areaStatus, tagStatus]);

  const checkLoading = React.useMemo(() => {
    return !!_.size(
      _.filter(
        _.values(checkAvailabilityStatus),
        it => it === RequestStatus.REQUESTING,
      ),
    );
  }, [checkAvailabilityStatus]);

  return (
    <>
      <Helmet>
        <title>空室検索 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="空室検索 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Box>
        <Header />
        <Wrapper>
          <Loader open={isLoading} />
          {areas && tags && (
            <>
              <AdvancedSearchForm
                open={openSearch}
                onClose={() => setOpenSearch(!openSearch)}
                formData={query}
                handleSearch={handleSearch}
              />
              <FilterForm
                open={openFilter}
                onClose={() => setOpenFilter(!openFilter)}
                handleFilter={handleFilter}
                formData={query}
                areaData={areas}
                featureData={tags.filter(it => it.is_main === 1)}
              />
            </>
          )}

          {searchItemsVisible ? (
            <Content
              sx={{
                background: 'rgba(233, 233, 233, 0.9)',
                padding: { md: '16px 0', xs: 2 },
              }}
              isMobile={isMobile}
            >
              <Box sx={{ margin: { md: '20px auto' } }} textAlign="center">
                <SearchMenu {...query} openSearch={() => setOpenSearch(true)} />
              </Box>
              {isMobile && (
                <Box textAlign="center" mt={2} mb={2}>
                  <Button
                    sx={{
                      boxShadow: 'none',
                      background: colors.white,
                      color: colors.blue,
                      padding: '8px',
                      width: '256px',
                      border: '1px solid ' + colors.blue,
                      fontSize: fontsizes.main,
                      '&:hover': {
                        color: colors.blue,
                      },
                    }}
                    startIcon={<MarkerIcon />}
                    onClick={() => redirectWithQuery(history, Path.MAP)}
                  >
                    MAPで見る
                  </Button>
                </Box>
              )}
              <Stack
                direction="row"
                sx={{
                  maxWidth: { md: '478px', xs: '100%' },
                  margin: { xs: '2rem auto 1rem' },
                }}
              >
                {/* <Button
                  onClick={sortHotels}
                  sx={{
                    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
                    background: colors.white,
                    color: colors.default,
                    padding: '10px 12px',
                    marginRight: 1,
                    fontSize: '12px',
                    border: '1px solid #BDBDBD',
                  }}
                >
                  価格順
                  {sortStatus && (
                    <SortStatusIcon
                      style={{
                        transform:
                          sortStatus !== SORT_STATUS.DECREASE
                            ? 'rotate(180deg)'
                            : 'none',
                      }}
                    >
                      <ChrevronIcon />
                    </SortStatusIcon>
                  )}
                </Button> */}
                <Button
                  sx={{
                    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
                    padding: '10px 12px',
                    background: colors.white,
                    color: colors.default,
                    marginRight: 2,
                    fontSize: '12px',
                    border: '1px solid #BDBDBD',
                  }}
                  onClick={() => setOpenFilter(!openFilter)}
                >
                  こだわり条件を追加
                </Button>
                <PartialLoader open={checkLoading} />
              </Stack>
              <Box
                id="hotelItemList"
                sx={{
                  position: 'relative',
                  maxHeight: { md: 'calc(100vh - ' + MENU_HEIGHT + 'px)' },
                  overflowY: { md: 'auto', xs: 'visible' },
                  padding: { md: '10px 0 80px', xs: '16px 0 0 0' },
                }}
              >
                {availData && (
                  <Stack
                    direction="column"
                    spacing={2}
                    ref={listRef}
                    sx={{ padding: { md: '0 30px', xs: 0 } }}
                  >
                    {availData?.map((it, idx) => (
                      <SearchItem
                        parentRef={el => (itemListRef.current[idx] = el)}
                        onHover={() => setActiveMarker(idx)}
                        onMouseLeave={() => setActiveMarker(null)}
                        key={idx}
                        isLoading={true}
                        {...it}
                      />
                    ))}
                    {!isLoading &&
                      data?.length === 0 &&
                      '条件に合致する空室は見つかりませんでした。'}
                  </Stack>
                )}
              </Box>
            </Content>
          ) : (
            ''
          )}
          {!isMobile ? (
            <>
              <IconButton
                revert={!searchItemsVisible}
                onClick={() => {
                  setSearchItemsVisible(!searchItemsVisible);
                  if (searchItemsVisible) {
                    setCenter({
                      lat: LATITUDE,
                      lng: LONGITUDE,
                    });
                  } else {
                    setCenter({
                      lat: LATITUDE,
                      lng: LONGITUDE_FULL_WIDTH,
                    });
                  }
                }}
              >
                <ArrowIcon />
              </IconButton>
              <Wrap>
                <GoogleMap
                  data={data ?? []}
                  height={`calc(100vh - ${HEADER_HEIGHT}px)`}
                  width={`${pageWidth}px`}
                  handleScroll={handleScroll}
                  activeMarker={activeMarker}
                  isSetCenter={!searchItemsVisible}
                  key={searchItemsVisible ? '1' : '2'}
                  center={center}
                  isLoading={requestRoomStatus}
                />
              </Wrap>
            </>
          ) : (
            ''
          )}
        </Wrapper>
      </Box>
    </>
  );
}

const Wrapper = styled(Box)`
  margin: 0 auto;
  position: relative;
  height: calc(100vh - 80px);
  a {
    text-decoration: none;
  }
  #hotelItemList money-item span {
    font-weight: 500;
  }
`;
const Content = styled(Box)<{ isMobile: boolean }>`
  position: ${p => (p.isMobile ? 'static' : 'absolute')};
  top: 0;
  bottom: 0;
  left: 0;
  right: calc(100% - 643px);
  z-index: 3;
  height: calc(100vh - 80px);
  ${dMedia.tablet`
    max-width: 100%;
  `}
`;

const IconButton = styled(Box)<{ revert: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 45px;
  cursor: pointer;
  left: ${p => (p.revert ? '10px' : '680px')};
  background: ${colors.white};
  border-radius: 4px;
  z-index: 3;
  ${p => (p.revert ? 'transform: rotate(180deg)' : '')};
  cursor: pointer;
`;
const Wrap = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;
