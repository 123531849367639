import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import hotelDetailsSaga from './saga';
import { HotelState } from './types';

export const initialState: HotelState = {
  getStatus: RequestStatus.IDLE,
  getSuggestPlanStatus: RequestStatus.IDLE,
  data: {
    checkin_time_start: '',
    checkin_time_end: '',
    top_images: [],
    name: '',
    latitude: '',
    longitude: '',
    phonenumber: '',
    room_count: 0,
    checkin_time: '',
    checkout_time: '',
    tags: [],
    address: '',
    gallery: [],
    title: '',
    content: '',
    post_code: '',
    parking: '',
    stay_image_url: '',
    with_pets_url: '',
  },
  suggestPlans: [],
  errorMess: [],
};

const slice = createSlice({
  name: 'hotelDetails',
  initialState,
  reducers: {
    getHotelDetails: (state, action: PayloadAction<any>) => {
      state.getStatus = RequestStatus.REQUESTING;
    },
    getHotelDetailsSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getStatus = RequestStatus.SUCCESS;
        state.data = action.payload;
      }
    },
    getHotelDetailsFail: (state, action: PayloadAction<any>) => {
      if (action) state.getStatus = RequestStatus.ERROR;
    },
    resetGetRequestStatus: state => {
      state.getStatus = RequestStatus.IDLE;
      state.errorMess = [];
    },
    getSuggestPlan: (state, action: PayloadAction<any>) => {
      state.getSuggestPlanStatus = RequestStatus.REQUESTING;
    },
    getSuggestPlanSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getSuggestPlanStatus = RequestStatus.SUCCESS;
        state.suggestPlans = action.payload;
      }
    },
    getSuggestPlanFail: (state, action: PayloadAction<any>) => {
      if (action) state.getSuggestPlanStatus = RequestStatus.ERROR;
    },
  },
});

export default slice.actions;

export const useHotelSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: hotelDetailsSaga });
  return { actions: slice.actions };
};
