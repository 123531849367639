import { Box, Typography } from '@mui/material';
import { GoogleMap } from 'app/components/GoogleMap';
import Header from 'app/components/Header';
import { Loader } from 'app/components/Loading';
import { useSearchSlice } from 'app/pages/SearchPage/slice';
import { selectSearch } from 'app/pages/SearchPage/slice/selectors';
import { ReactComponent as ArrowIcon } from 'assets/icons/map/arrow.svg';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { colors } from 'constants/styles';
import _ from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { checkIsRequesting } from 'utils/helper';
import { redirectWithQuery } from '../SearchPage';

export interface IMapPageProps {}

export default function MapPage(props: IMapPageProps) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    actions: {
      searchRequest,
      resetSearchRequestStatus,
      checkAvailabilityRequest,
    },
  } = useSearchSlice();

  const { data, searchStatus } = useSelector(selectSearch);
  /** start of logic */
  React.useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  React.useEffect(() => {
    return () => {
      dispatch(resetSearchRequestStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /** Todo get price in windows of 5 days */
  React.useEffect(() => {
    if (data?.length && searchStatus === RequestStatus.SUCCESS) {
      /** passing extra params */
      const params = queryString.parse(window.location.search);
      const obj = _.pick(params, [
        'guest_count',
        'checkin_date',
        'checkout_date',
      ]);

      if (_.isEmpty(obj)) {
        obj.guest_count = 2;
      }

      dispatch(resetSearchRequestStatus());

      data.forEach((it, idx) => {
        dispatch(checkAvailabilityRequest({ ...obj, id: it.id }));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchStatus]);

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    dispatch(searchRequest({ ...params }));
  };
  const isLoading = checkIsRequesting([searchStatus]);

  return (
    <Box>
      <Header />
      <Wrapper>
        <Loader open={isLoading} />

        <IconButton onClick={() => redirectWithQuery(history, Path.SEARCH)}>
          <ArrowIcon />
          <Typography fontSize="12px" color={colors.grey1}>
            検索リストに戻る
          </Typography>
        </IconButton>
        <GoogleMap data={data ?? []} key={``} />
      </Wrapper>
    </Box>
  );
}

const Wrapper = styled(Box)`
  margin: 0 auto;
  position: relative;
  a {
    text-decoration: none;
    color: ${colors.grey1};
  }
`;

const IconButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  left: 8px;
  padding: 3px 12px;
  background: ${colors.white};
  border-radius: 4px;
  z-index: 3;
  font-size: 12px;
  color: ${colors.grey1};
  border: 1px solid ${colors.grey4};
  cursor: pointer;
  svg {
    width: 16px;
    margin-right: 6px;
  }
`;
