import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Calendar from 'app/components/Calendar';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon-sm.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { colors, fontsizes } from 'constants/styles';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { commonFormat } from 'utils/moment';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import RoomFilter from 'app/components/RoomFilter';
import { formatDatetime } from '../';
import { QuerySet } from '..';
import { AREAS } from '../constants';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import _ from 'lodash';

interface Props {
  open: boolean;
  onClose: () => void;
  formData: QuerySet | null;
  handleSearch: (formData: any) => void;
}
interface ISearchForm {
  area?: string;
  checkin_date?: string;
  checkout_date?: string;
  pax_number?: number;
  is_flexible?: number;
}

export interface IQuantity {
  adult: number;
  upperGrades: number;
  withMealsAndBedding: number;
  withSameMealsAndBedding: number;
  withoutMealsAndBedding: number;
  withoutSameMealsAndBedding: number;
}

export const AdvancedSearchForm = (props: Props) => {
  const location = useLocation();
  const history = useHistory();

  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;
  const [isflexible, setIsflexible] = React.useState<boolean>(false);
  const [checkinAndCheckout, setCheckinAndCheckout] = React.useState({
    checkin: props.formData?.checkin_date,
    checkout: props.formData?.checkout_date,
  });

  const selectDate = data => {
    if (data && !isflexible) {
      switch (data.length) {
        case 0:
          setValue('checkout_date', '');
          setValue('checkin_date', '');

          break;
        case 1:
          setValue('checkin_date', commonFormat(moment(data[0])) ?? '');
          setValue('checkout_date', '');
          break;
        case 2:
          setValue('checkin_date', commonFormat(moment(data[0])) ?? '');
          setValue('checkout_date', commonFormat(moment(data[1])) ?? '');
          break;
        default:
          setValue('checkout_date', '');
          setValue('checkin_date', '');
      }
    } else {
      setValue('checkout_date', '');
      setValue('checkin_date', '');
    }
  };

  const formDefaultVals = {
    is_flexible: props.formData?.is_flexible,
    checkin_date: props.formData?.checkin_date ?? '',
    checkout_date: props.formData?.checkout_date ?? '',
    area: props.formData?.area ?? '',
    guest_count: props.formData?.guest_count ?? 2,
    child: props.formData?.child ?? 0,
    child_a_count: props.formData?.child_a_count ?? 0,
    child_b_count: props.formData?.child_b_count ?? 0,
    child_c_count: props.formData?.child_c_count ?? 0,
    child_d_count: props.formData?.child_d_count ?? 0,
    child_e_count: props.formData?.child_e_count ?? 0,
  };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: formDefaultVals,
  });

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.checked;

    setIsflexible(!isflexible);
    setValue('is_flexible', val);
    setCheckinAndCheckout({
      checkin: getValues('checkin_date'),
      checkout: getValues('checkout_date'),
    });
    if (val) {
      setValue('checkin_date', '');
      setValue('checkout_date', '');
    } else {
      setValue('checkin_date', checkinAndCheckout.checkin ?? '');
      setValue('checkout_date', checkinAndCheckout.checkout ?? '');
    }
  };

  const validateDateWithRegex = (dateString: string): boolean => {
    return !!(
      dateString &&
      /^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])/.test(dateString)
    );
  };

  const validateDate = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const checkInDate = getValues('checkin_date');
    const checkOutDate = getValues('checkout_date');
    if (
      (checkOutDate && moment(checkInDate) > moment(checkOutDate)) ||
      moment(checkInDate).isBefore(moment(), 'day') ||
      (checkInDate && !validateDateWithRegex(checkInDate)) ||
      (checkOutDate && !validateDateWithRegex(checkOutDate))
    ) {
      setValue('checkin_date', props.formData?.checkin_date ?? '');
      setValue('checkout_date', props.formData?.checkout_date ?? '');
    }
  };

  const [quantity, setQuantity] = React.useState<IQuantity>({
    adult: 2,
    upperGrades: 0,
    withMealsAndBedding: 0,
    withSameMealsAndBedding: 0,
    withoutMealsAndBedding: 0,
    withoutSameMealsAndBedding: 0,
  });

  const updateQuantity = value => {
    setValue('guest_count', +value.adult);
    setValue('child', +value.upperGrades);
    setValue('child_a_count', +value.upperGrades);
    setValue('child_b_count', +value.withMealsAndBedding);
    setValue('child_c_count', +value.withSameMealsAndBedding);
    setValue('child_d_count', +value.withoutMealsAndBedding);
    setValue('child_e_count', +value.withoutSameMealsAndBedding);
  };

  React.useEffect(() => {
    if (props.open) {
      reset(formDefaultVals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  React.useEffect(() => {
    const params = queryString.parse(location.search);
    setQuantity({
      adult: _.toInteger(params?.guest_count) ?? 2,
      upperGrades: _.toInteger(params?.child_a_count) ?? 0,
      withMealsAndBedding: _.toInteger(params?.child_b_count) ?? 0,
      withSameMealsAndBedding: _.toInteger(params?.child_c_count) ?? 0,
      withoutMealsAndBedding: _.toInteger(params?.child_d_count) ?? 0,
      withoutSameMealsAndBedding: _.toInteger(params?.child_e_count) ?? 0,
    });
  }, [location.search]);

  const selectedDateRange: string[] = [];
  if (getValues('checkin_date') && getValues('checkout_date')) {
    selectedDateRange.push(formatDatetime(getValues('checkin_date')));
    selectedDateRange.push(formatDatetime(getValues('checkout_date')));
  }
  /*
  selectedDateRange = getValues('checkout_date')
    ? [
        getValues('checkin_date')
          ? formatDatetime(getValues('checkin_date'))
          : '',
        formatDatetime(getValues('checkout_date')),
      ]
    : [
        getValues('checkin_date')
          ? formatDatetime(getValues('checkin_date'))
          : '',
      ]
  */

  return (
    <SearchDialog open={props.open} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle>
        <Typography
          component="span"
          textAlign="center"
          sx={{
            display: 'block',
            fontSize: '24px',
            fontWeight: 700,
            padding: 5,
          }}
        >
          検索条件
        </Typography>
        <CloseIcon onClick={props.onClose} />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            margin: { sm: '0 auto', xs: '0 auto' },
            maxWidth: { md: '100%', sm: '340px', xs: '340px' },
          }}
        >
          <Box
            sx={{
              margin: {
                md: '0 auto 2rem',
                xs: '0 auto 2rem',
              },
              maxWidth: {
                md: '700px',
              },
            }}
            component="form"
            id="search_form"
            onSubmit={handleSubmit(props.handleSearch)}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: { md: 'flex-start', sm: 'center' },
                justifyContent: {
                  md: 'space-between',
                  sm: 'flex-start',
                },
                flexWrap: {
                  md: 'nowrap',
                  xs: 'wrap',
                },
                marginBottom: { md: 3 },
                maxWidth: '670px',
              }}
              spacing={2}
            >
              <Box
                sx={{ order: { md: 1, xs: 1 }, marginRight: { md: 1, xs: 0 } }}
              >
                <InputLabel
                  sx={{
                    fontSize: fontsizes.main,
                    fontWeight: 500,
                    color: colors.mainContent,
                    paddingBottom: '5px',
                  }}
                >
                  目的地
                </InputLabel>
                <FormControl
                  sx={{
                    width: { md: '240px', xs: '200px' },
                    marginRight: { md: 0, xs: 1 },
                  }}
                >
                  <Controller
                    control={control}
                    name="area"
                    defaultValue={props.formData?.area}
                    render={({ field }) => {
                      const { onBlur, onChange, value } = field;
                      return (
                        <Select
                          fullWidth
                          displayEmpty
                          variant="standard"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={`${value}`}
                          IconComponent={ArrowDown}
                        >
                          {AREAS.map((it, idx) => (
                            <MenuItem key={idx} value={it.id}>
                              {it.name}
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  marginTop: 0,
                  order: { md: 2, xs: 3 },
                  marginRight: { md: 1, xs: 0 },
                  paddingTop: { md: 0, xs: 5 },
                  paddingBottom: { md: 0, xs: 2 },
                }}
                className="mt0"
              >
                <Box
                  sx={{
                    padding: 0.5,
                    border: { xs: '1px solid' + colors.grey6 },
                    borderRadius: 1,
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="stretch">
                    <Box>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: fontsizes.main,
                          fontWeight: 500,
                          color: colors.mainContent,
                        }}
                      >
                        チェックイン
                      </Typography>
                      <FormControl sx={{ width: { xs: '140px' } }}>
                        <Controller
                          control={control}
                          name="checkin_date"
                          defaultValue={props.formData?.checkin_date || ''}
                          render={({ field }) => {
                            const { onChange, value } = field;
                            return (
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={value}
                                onBlur={validateDate}
                                onChange={onChange}
                                error={errors.checkin_date ? true : false}
                                helperText={errors.checkin_date?.message}
                                disabled={isflexible}
                                placeholder="YYYY/MM/DD"
                                InputProps={{
                                  startAdornment: <CalendarIcon />,
                                }}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: fontsizes.main,
                          fontWeight: 500,
                          color: colors.mainContent,
                        }}
                      >
                        チェックアウト
                      </Typography>
                      <FormControl sx={{ width: { xs: '140px' } }}>
                        <Controller
                          control={control}
                          name="checkout_date"
                          defaultValue={props.formData?.checkout_date || ''}
                          render={({ field }) => {
                            const { onBlur, onChange, value } = field;
                            return (
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={value}
                                onBlur={validateDate}
                                onChange={onChange}
                                placeholder="YYYY/MM/DD"
                                disabled={isflexible}
                                error={errors.checkout_date ? true : false}
                                helperText={errors.checkout_date?.message}
                                InputProps={{
                                  startAdornment: <CalendarIcon />,
                                }}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Stack>
                </Box>
                <FormControl margin="dense" sx={{ marginBottom: { md: 1 } }}>
                  <FormControlLabel
                    sx={{
                      fontSize: fontsizes.main,
                      fontWeight: 500,
                      color: colors.mainContent,
                      marginTop: 0,
                      padding: 0,
                    }}
                    control={
                      <Controller
                        name="is_flexible"
                        render={({ field }) => {
                          return (
                            <Checkbox
                              {...field}
                              disableRipple
                              onChange={handleSelect}
                              checked={isflexible}
                              defaultValue={undefined}
                            />
                          );
                        }}
                        control={control}
                      />
                    }
                    label="宿泊日未定"
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  order: { md: 3, xs: 2 },
                  minWidth: { md: '180px', xs: '200px' },
                  marginLeft: { md: '10px !important', xs: '0 !important' },
                  marginTop: {
                    md: '0!important',
                    xs: '15px!important',
                  },
                }}
                className="mt0"
              >
                <InputLabel
                  sx={{
                    fontSize: fontsizes.main,
                    fontWeight: 500,
                    color: colors.mainContent,
                    paddingBottom: '5px',
                  }}
                >
                  人数
                </InputLabel>

                <RoomFilter
                  isFromSearch
                  updateQuantity={updateQuantity}
                  quantity={quantity}
                />
              </Box>
            </Stack>
            <Box
              sx={{
                minWidth: {
                  md: 'auto',
                  xs: '320px',
                },
              }}
            >
              <Calendar
                fromSearch
                viewMode={isMobile ? 'single' : 'double'}
                selectable={true}
                month={moment().format('YYYY-MM')}
                selectedDateRange={selectedDateRange}
                onSelectedDateRangeChange={selectDate}
              />
              {/* <Typography component="p" mt={2}>
                ※1泊1名初日の最低宿泊料を表示しています
              </Typography> */}
            </Box>
            <Box pt={5} pb={5} textAlign="center">
              <Button
                variant="contained"
                size="large"
                type="submit"
                form="search_form"
                sx={{
                  background: colors.blue,
                  maxWidth: '320px',
                  width: '100%',
                  boxShadow: 'none',
                  fontSize: '15px',
                  padding: '7px 22px',
                }}
              >
                検索する
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </SearchDialog>
  );
};

const SearchDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 800px;
    .MuiDialogTitle-root {
      position: relative;
      svg {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 20px;
        top: 30px;
        cursor: pointer;
      }
    }
    ${dMedia.tablet`
       width: 100%;
       height: 100%;
       max-height: 100%;
       margin: 0;
       overflow-y: auto;
    `}
  }
  .MuiDialogContent-root {
    padding: 0px 24px !important;
    overflow-x: hidden;
    ${dMedia.tablet`
      overflow-y: initial;
      padding: 0 6px!important;
      overflow-x: auto;
    `}
  }
  .css-mnuau6-MuiInputBase-root-MuiInput-root {
    &:after,
    &:before {
      border: none;
    }
    .MuiSelect-select {
      padding-left: 10px;
      background: ${colors.grey6};
      height: 26px;
      line-height: 26px;
      border-radius: 4px;
      font-weight: 500;
    }
    &:hover:not(.Mui-disabled):before {
      border: none;
    }
    .MuiSelect-icon {
      right: 5px;
    }
  }

  .MuiTextField-root > .MuiInputBase-root {
    padding-left: 6px;
    font-weight: 500;
    svg {
      padding-right: 5px;
      display: none;
    }
    ${dMedia.tablet`
      svg {
        display: inline-block;
      }
    `}
  }
  .MuiCheckbox-root {
    padding: 0 0 0 9px;
    svg {
      color: ${colors.grey4};
    }
    &.Mui-checked svg {
      color: ${colors.grey1};
    }
  }
  .MuiBox-root.mt0 {
    margin-top: 0;
    .MuiBox-root .MuiBox-root:nth-child(2) {
      margin-left: 8px;
    }
  }
  p.MuiTypography-root {
    font-weight: 500;
  }
`;
