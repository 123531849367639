import APIs from 'APIs';
import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import Slice from '.';

function* getHotelDetailsFlow(action) {
  try {
    const response = yield call(APIs.hotelDetails, action.payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getHotelDetailsSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getHotelDetailsFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getHotelDetailsFail });
  }
}

function* getSuggestPlanFlow(action) {
  try {
    const response = yield call(APIs.suggestPlanByHotel, action.payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getSuggestPlanSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getSuggestPlanFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getSuggestPlanFail });
  }
}

function* hotelDetailsWatcher() {
  yield takeLatest(Slice.getHotelDetails, getHotelDetailsFlow);
  yield takeLatest(Slice.getSuggestPlan, getSuggestPlanFlow);
}

export default hotelDetailsWatcher;
