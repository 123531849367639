import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import actions from 'app/pages/GlobalContainer/slice/';

function* getResetPasswordFlow({ payload }) {
  let errorMess = ['Oops..reset password failed!'];
  try {
    const response = yield call(APIs.getResetPassword, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getResetPasswordSuccess,
        payload: {
          message: `パスワード再設定用確認コードを「${payload.email}」メールアドレスに送りました。`,
        },
      });
    } else {
      const { data, status } = response;
      if (status === StatusCode.BAD_REQUEST) {
        errorMess = [data.message];
      }
      yield put({
        type: Slice.getResetPasswordFail.type,
        payload: { errorMess },
      });
    }
  } catch (error) {
    yield put({
      type: Slice.getResetPasswordFail.type,
      payload: { errorMess },
    });
  }
}

function* postResetPasswordFlow({ payload }) {
  let errorMess = ['Oops..reset password failed!'];
  try {
    const response = yield call(APIs.resetPassword, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.postResetPasswordSuccess,
        payload: {},
      });
    } else {
      const { data, status } = response;
      if (status === StatusCode.BAD_REQUEST) {
        errorMess = [data.message];
      }
      yield put({
        type: Slice.postResetPasswordFail.type,
        payload: { errorMess },
      });
    }
  } catch (error) {
    yield put({
      type: Slice.postResetPasswordFail.type,
      payload: { errorMess },
    });
  }
}

function* resetPasswordWatcher() {
  yield takeLatest(Slice.getResetPasswordRequest, getResetPasswordFlow);
  yield takeLatest(Slice.postResetPasswordRequest, postResetPasswordFlow);
}

export default resetPasswordWatcher;
