// UI
import { Box, Button, Stack, Typography } from '@mui/material';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { IAdditionRoomInfo, IRoomItem } from 'types/common';
import { getReservationInfo, getSingleImage } from 'utils/helper';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { AddToCartButton } from '../Buttons/AddToCartButton';
import { ReserveButton } from '../Buttons/ReserveButton';
import { MoneyItem } from '../MoneyItem';
import RoomInfo from '../RoomInfo';
import SingleImage from '../SingleImage';

interface IPlanItem {
  data: IRoomItem;
  className?: string;
}

const PlanItem = ({
  data: {
    name,
    price = 6000,
    addition_info,
    images,
    price_for_1_person,
    hasStock,
  },
  data,
  className,
}: IPlanItem) => {
  const { room_id, local_hotel_id } = addition_info || {};
  const dispatch = useDispatch();
  const {
    actions: { setActiveRoom },
  } = usePlanAndRoomSlice();

  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;
  const activeRoom = { hotel_id: local_hotel_id, room_id: room_id };

  const renderHeader = () => {
    return (
      <Header
        onClick={() => {
          dispatch(setActiveRoom(activeRoom));
        }}
      >
        {name}
      </Header>
    );
  };
  const renderPriceInfo = () => {
    return (
      <PriceInfo direction="row" spacing={0.5}>
        <RoomNumber> 1室合計</RoomNumber>
        <MoneyItem amount={price} isBold fontSize={18}></MoneyItem>
        {isMobile ? (
          <Total>{`(1名あたり ￥${new Intl.NumberFormat('ja-JP').format(
            price_for_1_person,
          )})`}</Total>
        ) : (
          <Total>{`(1名あたり ￥${new Intl.NumberFormat('ja-JP').format(
            price_for_1_person,
          )})`}</Total>
        )}
      </PriceInfo>
    );
  };

  return (
    <Container className={className} spacing={2}>
      {isMobile && renderHeader()}
      <WrapItem>
        <SingleImage
          width={120}
          height={80}
          borderRadius={4}
          backgroundImage={getSingleImage(images)}
        ></SingleImage>
        <Item spacing={1} ml={1}>
          {!isMobile && renderHeader()}
          <RoomInfo data={data}></RoomInfo>
          {!isMobile && renderPriceInfo()}
        </Item>
      </WrapItem>
      {isMobile && renderPriceInfo()}
      <WrapButton
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 1 : 1}
      >
        <ReserveButton item={data}></ReserveButton>
        <AddToCartButton item={data}></AddToCartButton>
        {!isMobile && (
          <ViewDetailRoomButton
            variant="outlined"
            color="inherit"
            onClick={() => {
              dispatch(setActiveRoom(activeRoom));
            }}
            sx={{
              padding: '5px 17px',
            }}
          >
            <span>お部屋詳細</span>
          </ViewDetailRoomButton>
        )}
      </WrapButton>
    </Container>
  );
};

export default PlanItem;

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;
  background: #fcfcfc;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  ${dMedia.xxs`
    margin-left: -1px !important;
    margin-right: -1px !important;
  `}
`;

const WrapItem = styled(Box)`
  display: flex;
`;
const Header = styled(Box)`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #2f80ed;
  cursor: pointer;
`;

const RoomNumber = styled(Typography)`
  color: #828282;
`;
const Total = styled(Typography)`
  color: #828282;
`;

const ViewDetailRoomButton = styled(Button)`
  border: 1px solid #828282;
  box-shadow: 0px 1px 0px rgb(0 122 255 / 5%);
  border-radius: 4px;
  height: 40px;
  padding: 10px 16px;
  &:hover {
    border: 1px solid #828282;
    background: none;
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
  }
`;
const WrapButton = styled(Stack)`
  @media (max-width: 768px) {
    margin-right: 11px;
  }
`;
const Item = styled(Stack)`
  @media (max-width: 768px) {
    flex: 1;
  }
`;
const PriceInfo = styled(Stack)`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  align-items: center;
  .money-item {
    line-height: 24px;
    span {
      font-weight: 700;
    }
  }
`;
