// UI
import * as React from 'react';
import { Alert, Box, Grid, Snackbar } from '@mui/material';
import { IImage } from 'constants/commonTypes';
import styled from 'styled-components';
import SingleImage from '../SingleImage';
import Viewer from 'react-viewer';

interface IESSnackbar {
  isOpen: boolean;
  mess: string;
}

const ESSnackbar = ({ isOpen, mess, ...props }: IESSnackbar) => {
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    if (open !== isOpen) setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity="warning"
        sx={{ width: '100%', maxWidth: '450px' }}
      >
        {mess}
      </Alert>
    </Snackbar>
  );
};

export default ESSnackbar;

const Container = styled(Box)``;
