import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { colors, fontsizes } from 'constants/styles';
import _ from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { PRICE_RANGES } from '../constants';
import { QuerySet } from '..';

interface CommonData {
  id: number;
  name: string;
  hotel_count: number | null;
}
interface FilterProps {
  open: boolean;
  onClose: () => void;
  handleFilter: (formData: any) => void;
  formData?: QuerySet | null;
  areaData?: CommonData[] | null;
  featureData?: CommonData[] | null;
}

enum TYPE {
  AREA = 'AREA',
  FEATURE = 'FEATURE',
}

export const FilterForm = (props: FilterProps) => {
  const [allFeatureChecked, setAllFeatureChecked] =
    React.useState<boolean>(false);
  const [allAreaChecked, setAllAreaChecked] = React.useState<boolean>(false);
  const [selectedAreas, setSelectedAreas] = React.useState<number[] | []>([]);
  const [selectedFeatures, setSelectedFeatures] = React.useState<number[] | []>(
    [],
  );
  const resetFacilityAndArea = () => {
    if (props.formData?.area) {
      const selectedAreas = props.formData.area
        .toString()
        .split(',')
        ?.map(it => _.toInteger(it));

      setSelectedAreas(selectedAreas);
      if (selectedAreas.length === props.areaData?.length) {
        setAllAreaChecked(true);
      } else {
        setAllAreaChecked(false);
      }
    } else {
      setSelectedAreas([]);
      setAllAreaChecked(false);
    }

    if (props.formData?.tag) {
      const selectedFacility = props.formData.tag
        .split(',')
        ?.map(it => _.toInteger(it));
      setSelectedFeatures(selectedFacility);
      if (setSelectedFeatures.length === props.featureData?.length) {
        setAllFeatureChecked(true);
      } else {
        setAllFeatureChecked(false);
      }
    } else {
      setSelectedFeatures([]);
      setAllFeatureChecked(false);
    }
  };

  React.useEffect(() => {
    resetFacilityAndArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formData]);

  const handleFeatureAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const val = event.target.checked;
    setAllFeatureChecked(val);
    if (val) {
      setSelectedFeatures(
        props.featureData ? props.featureData?.map(it => it.id) : [],
      );
    } else {
      setSelectedFeatures([]);
    }
  };

  const handleAreaAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.checked;
    setAllAreaChecked(val);
    if (val) {
      setSelectedAreas(props.areaData ? props.areaData?.map(it => it.id) : []);
    } else {
      setSelectedAreas([]);
    }
  };

  const handleSelect = (id: number, type: string, selected: boolean) => {
    if (selected) {
      type === TYPE.AREA
        ? setSelectedAreas(
            _.uniq([...selectedAreas, _.toInteger(id)].filter(o => o)),
          )
        : setSelectedFeatures(
            _.uniq([...selectedFeatures, _.toInteger(id)].filter(o => o)),
          );
    } else {
      const remaining =
        type === TYPE.AREA
          ? _.uniq(selectedAreas.filter(item => item !== _.toInteger(id)))
          : _.uniq(selectedFeatures.filter(item => item !== _.toInteger(id)));
      if (type === TYPE.AREA) {
        setSelectedAreas(remaining);
      } else {
        setSelectedFeatures(remaining);
      }
    }
  };

  const resetForm = () => {
    reset();
    resetFacilityAndArea();
  };
  const { reset, handleSubmit, control } = useForm();

  const handleFilter = formData => {
    const formDataParams = { ...formData };
    formDataParams.area = selectedAreas;
    formDataParams.tag = selectedFeatures;
    props.handleFilter(formDataParams);
  };

  return (
    <FilterDialog open={props.open} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle>
        <Typography
          component="span"
          textAlign="center"
          sx={{
            display: 'block',
            fontSize: '24px',
            fontWeight: 700,
            padding: 5,
          }}
        >
          こだわり条件を追加
        </Typography>
        <CloseIcon onClick={props.onClose} />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            margin: { sm: '0 auto', xs: '0 auto' },
            padding: { md: 0, sm: '0 8px', xs: '0 8px' },
            maxWidth: { md: '375px', sm: '340px', xs: '340px' },
          }}
        >
          <Box
            sx={{
              margin: {
                md: '0 auto 2rem',
                xs: '0 auto 2rem',
              },
              maxWidth: {
                md: '500px',
              },
            }}
            component="form"
            id="filter_form"
            onSubmit={handleSubmit(handleFilter)}
          >
            {/* <Box mb={5}>
              <InputLabel
                sx={{
                  fontSize: fontsizes.main,
                  fontWeight: 500,
                  color: colors.mainContent,
                  paddingBottom: '5px',
                }}
              >
                価格帯
              </InputLabel>
              <FormControl
                sx={{
                  width: { md: '160px', xs: '200px' },
                  marginRight: { md: 0, xs: 1 },
                }}
              >
                <Controller
                  control={control}
                  name="price_range"
                  defaultValue={props.formData?.price_range || ''}
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        variant="standard"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={`${value}`}
                        IconComponent={ArrowDown}
                      >
                        {PRICE_RANGES.map((it, idx) => (
                          <MenuItem key={idx} value={it.value}>
                            {it.name}
                          </MenuItem>
                        ))}
                        <MenuItem value="">全価格帯</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
              <Typography
                mt={1}
                sx={{
                  color: '#828282',
                }}
                component="p"
              >
                ※1泊1名での価格
              </Typography>
            </Box> */}
            <Box mb={5}>
              <InputLabel
                sx={{
                  fontSize: fontsizes.main,
                  fontWeight: 500,
                  color: colors.mainContent,
                  paddingBottom: '5px',
                }}
              >
                エリア
              </InputLabel>
              <OptionStack
                direction="row"
                flexWrap="wrap"
                spacing={2}
                sx={{ width: { md: '500px' } }}
              >
                {props.areaData?.map((it, idx) =>
                  idx < 2 ? (
                    <Box
                      key={it.id}
                      onClick={() =>
                        handleSelect(
                          it.id,
                          TYPE.AREA,
                          _.includes(selectedAreas, it.id) ? false : true,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '3px 6px',
                        border: '1px solid ' + colors.grey4,
                        marginBottom: 1,
                        height: '24px',
                        borderRadius: '2px',
                        whiteSpace: 'nowrap',
                        background: _.includes(selectedAreas, it.id)
                          ? colors.grey4
                          : colors.white,
                      }}
                    >
                      <Typography
                        component="span"
                        fontSize={fontsizes.smallText}
                      >
                        {it.name}
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          display: 'inline-block',
                          textAlign: 'center',
                          background: colors.grey6,
                          borderRadius: '50%',
                          fontSize: fontsizes.smallText,
                          padding: '2px',
                          lineHeight: '14px',
                          marginLeft: '5px',
                          width: '18px',
                          height: '18px',
                        }}
                      >
                        {it.hotel_count}
                      </Typography>
                    </Box>
                  ) : (
                    ''
                  ),
                )}
              </OptionStack>
              <OptionStack
                direction="row"
                flexWrap="wrap"
                spacing={2}
                sx={{ width: { md: '500px' } }}
              >
                {props.areaData?.map((it, idx) =>
                  idx > 1 ? (
                    <Box
                      key={it.id}
                      onClick={() =>
                        handleSelect(
                          it.id,
                          TYPE.AREA,
                          _.includes(selectedAreas, it.id) ? false : true,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '3px 6px',
                        border: '1px solid ' + colors.grey4,
                        marginBottom: 1,
                        height: '24px',
                        borderRadius: '2px',
                        whiteSpace: 'nowrap',
                        background: _.includes(selectedAreas, it.id)
                          ? colors.grey4
                          : colors.white,
                      }}
                    >
                      <Typography
                        component="span"
                        fontSize={fontsizes.smallText}
                      >
                        {it.name}
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          display: 'inline-block',
                          textAlign: 'center',
                          background: colors.grey6,
                          borderRadius: '50%',
                          fontSize: fontsizes.smallText,
                          padding: '2px',
                          lineHeight: '14px',
                          marginLeft: '5px',
                          width: '18px',
                          height: '18px',
                        }}
                      >
                        {it.hotel_count}
                      </Typography>
                    </Box>
                  ) : (
                    ''
                  ),
                )}
              </OptionStack>

              <FormControl>
                <FormControlLabel
                  label="すべてのエリアを選択"
                  control={
                    <Checkbox
                      disableRipple
                      checked={allAreaChecked}
                      onChange={handleAreaAllChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </FormControl>
            </Box>
            <Box mb={5}>
              <InputLabel
                sx={{
                  fontSize: fontsizes.main,
                  fontWeight: 500,
                  color: colors.mainContent,
                  paddingBottom: '5px',
                }}
              >
                特徴
              </InputLabel>
              <OptionStack
                className="stack-1"
                direction="row"
                flexWrap="wrap"
                spacing={2}
                sx={{ width: { md: '500px', xs: '300px' } }}
              >
                {props.featureData
                  ?.filter(it => it.hotel_count)
                  .map((it, idx) => (
                    <Box
                      key={it.id}
                      onClick={() =>
                        handleSelect(
                          it.id,
                          TYPE.FEATURE,
                          _.includes(selectedFeatures, it.id) ? false : true,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '3px 6px',
                        border: '1px solid ' + colors.grey3,
                        marginBottom: '1rem !important',
                        height: '24px',
                        borderRadius: '2px',
                        whiteSpace: 'nowrap',
                        background: _.includes(selectedFeatures, it.id)
                          ? colors.grey4
                          : colors.white,
                      }}
                    >
                      <Typography
                        component="span"
                        fontSize={fontsizes.smallText}
                      >
                        {it.name}
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          display: 'inline-block',
                          textAlign: 'center',
                          background: colors.grey6,
                          borderRadius: '50%',
                          padding: '2px',
                          fontSize: fontsizes.smallText,
                          lineHeight: '14px',
                          marginLeft: '5px',
                          width: '18px',
                          height: '18px',
                        }}
                      >
                        {it.hotel_count}
                      </Typography>
                    </Box>
                  ))}
              </OptionStack>

              <FormControl>
                <FormControlLabel
                  label="すべての施設・設備を選択"
                  control={
                    <Checkbox
                      disableRipple
                      checked={allFeatureChecked}
                      onChange={handleFeatureAllChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </FormControl>
            </Box>

            <Stack pb={5} direction="column" alignItems="center">
              <Button
                variant="contained"
                size="large"
                type="submit"
                form="filter_form"
                sx={{
                  background: colors.blue,
                  maxWidth: '320px',
                  width: '100%',
                  boxShadow: 'none',
                }}
              >
                検索する
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginTop: 1.5,
                  background: colors.white,
                  color: colors.blue,
                  border: '1px solid ' + colors.blue,
                  maxWidth: '320px',
                  width: '100%',
                  boxShadow: 'none',
                  '&:hover': {
                    color: colors.white,
                  },
                }}
                onClick={resetForm}
              >
                すべての条件をクリア
              </Button>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </FilterDialog>
  );
};

FilterForm.defaultProps = {
  formData: {},
  areaData: [],
  featureData: [],
};
const FilterDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 800px;
    .MuiDialogTitle-root {
      position: relative;
      svg {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 20px;
        top: 30px;
        cursor: pointer;
      }
    }
    ${dMedia.tablet`
       width: 100%;
       height: 100%;
       max-height: 100%;
       margin: 0;
       overflow-y: auto;
    `}
  }

  .MuiDialogContent-root {
    padding: 0px 24px !important;
    ${dMedia.tablet`
     overflow-y: initial;
      padding: 0 6px!important;
    `}
  }
  .css-mnuau6-MuiInputBase-root-MuiInput-root {
    &:after,
    &:before {
      border: none;
    }
    .MuiSelect-select {
      padding-left: 10px;
      background: ${colors.grey6};
      height: 26px;
      line-height: 26px;
      border-radius: 4px;
      .MuiSelect-icon {
        right: 5px;
      }
    }
  }
  .MuiTextField-root > .MuiInputBase-root {
    padding-left: 6px;
    svg {
      padding-right: 5px;
      display: none;
    }
    ${dMedia.tablet`
      svg {
        display: inline-block;
      }
    `}
  }
  .MuiCheckbox-root {
    padding: 0 0 0 9px;
    svg {
      color: ${colors.grey4};
    }
    &.Mui-checked svg {
      color: ${colors.grey1};
    }
  }
  .MuiBox-root.mt0 {
    margin-top: 0;
    .MuiBox-root .MuiBox-root:nth-child(2) {
      margin-left: 8px;
    }
  }
  p.MuiTypography-root {
    font-weight: 500;
  }
`;

const OptionStack = styled(Stack)`
  .MuiBox-root {
    margin-right: 8px !important;
  }
  .MuiBox-root:not(:first-child) {
    margin-left: 0px !important;
  }
`;
