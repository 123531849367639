import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import Path from 'config/clientPath';
import { colors } from 'constants/styles';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { BoxHeader } from 'app/components/BoxHeader';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { Controller, useForm } from 'react-hook-form';
import InputItem from 'app/components/FormItems/InputItem';
import SelectItem from 'app/components/SelectItem';
import CheckBoxItem from 'app/components/CheckBoxItem';
import { CommonButton } from 'app/components/CommonButton';
import {
  checkIsRequesting,
  getRangeDate,
  getRangeMonth,
  getRangeYear,
} from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegistration } from '../RegistrationInfoPage/slice/selectors';
import { useRegisterSlice } from '../RegistrationInfoPage/slice';
import { useGlobalSlice } from '../GlobalContainer/slice';
import { RequestStatus } from 'constants/API';
import { Loader } from 'app/components/Loading';
import LoginAfterRegister from '../LoginPage/components/LoginAfterRegister';
import { selectLogin } from '../LoginPage/slice/selectors';
import { useLoginSlice } from '../LoginPage/slice';
import { setToken, setLoginTime } from 'utils/localStorage';

export function RegisterMembershipPage() {
  const history = useHistory();

  const dispatch = useDispatch();

  const {
    loginStatus,
    accessToken,
    errorMess: errorMessLogin,
  } = useSelector(selectLogin);

  const {
    changeShowRegisterModalStatus,
    setSuccessMessages,
    setErrorMessages,
    changeShowLoginAfterRegisterModalStatus,
    login,
  } = useGlobalSlice().actions;
  const { loginRequest, resetLoginStatus } = useLoginSlice().actions;

  const { errorMess, registrationStatus } = useSelector(selectRegistration);
  const { registerRequest, resetRegistrationStatus } =
    useRegisterSlice().actions;
  const [email, setEmail] = React.useState<string | undefined>(undefined);

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    trigger,
    setValue,
    formState: { errors },
    formState,
  } = useForm({
    shouldFocusError: true,
    defaultValues: {
      name: '',
      kana: '',
      email: '',
      telephone: '',
      postal_code: '',
      address: '',
      date: '',
      month: '',
      year: '',
      employment_name: false,
    },
  });

  React.useEffect(() => {
    if (registrationStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Successfully registered']));
    }
    if (registrationStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationStatus]);

  const executeSubmit = formData => {
    const { year, month, date, employment_name } = formData;
    const birthday = year && month && date ? [year, month, date].join('-') : '';

    setEmail(formData.email);
    dispatch(
      registerRequest({
        ...formData,
        birthday,
        employment_name: employment_name ? 1 : '',
      }),
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(resetRegistrationStatus());
      dispatch(resetLoginStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (loginStatus === RequestStatus.SUCCESS) {
      dispatch(login());
      setToken(accessToken);
      setLoginTime(new Date().getTime());

      history.push(Path.SEARCH);
    }
    if (loginStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMessLogin));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const submitLogin = formData => {
    dispatch(loginRequest({ ...formData, email }));
  };

  const isLoading = checkIsRequesting([registrationStatus, loginStatus]);

  return (
    <>
      <Helmet>
        <title>VMG Hotel</title>
        <meta name="description" content="VMG Hotel" />
      </Helmet>
      <Header />
      <Container>
        <Loader open={isLoading} />
        {registrationStatus === RequestStatus.SUCCESS ? (
          <LoginAfterRegister email={email} submitLoginForm={submitLogin} />
        ) : (
          <>
            <CustomBoxHeader fontSize={24} className="">
              VMG会員登録情報
            </CustomBoxHeader>
            <Stack
              spacing={3}
              pt={4}
              component="form"
              id="info_form"
              onSubmit={handleSubmit(executeSubmit)}
            >
              <FormField spacing={1}>
                <Controller
                  control={control}
                  name="name"
                  rules={{
                    required: 'こちらは必須項目です。',
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <InputItem
                        value={value}
                        onChange={onChange}
                        fullWidth
                        size="small"
                        placeholder="例）田中 太郎"
                        error={errors.name ? true : false}
                        helperText={errors.name?.message}
                        labelPrimary={'氏名（漢字）'}
                        required
                      />
                    );
                  }}
                />
                <Note>※氏名の間にスペースをいれてください</Note>
              </FormField>

              <FormField spacing={1}>
                <Controller
                  control={control}
                  name="kana"
                  rules={{
                    pattern: {
                      value: /^([ァ-ン]|ー|[ｧ-ﾝﾞﾟ]| |　)+$/,
                      message: '半角全角（カナのみ）で入力してください',
                    },
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <InputItem
                        value={value}
                        onChange={onChange}
                        fullWidth
                        size="small"
                        placeholder="例）タナカ タロウ"
                        error={errors.kana ? true : false}
                        helperText={errors.kana?.message}
                        labelPrimary={'氏名（カナ）'}
                      />
                    );
                  }}
                />
                <Note>※氏名の間にスペースをいれてください</Note>
              </FormField>

              <FormField spacing={1}>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: 'こちらは必須項目です。',
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <InputItem
                        value={value}
                        onChange={onChange}
                        fullWidth
                        size="small"
                        placeholder="例）abcd@vmg.co.jp"
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                        labelPrimary={'メールアドレス '}
                        required
                        type="email"
                      />
                    );
                  }}
                />
              </FormField>

              <FormField spacing={1}>
                <Controller
                  control={control}
                  name="telephone"
                  rules={{
                    required: 'こちらは必須項目です。',
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <InputItem
                        value={value}
                        onChange={onChange}
                        fullWidth
                        size="small"
                        placeholder="例）09012345678"
                        error={errors.telephone ? true : false}
                        helperText={errors.telephone?.message}
                        labelPrimary={'電話番号 '}
                        required
                        type="tel"
                      />
                    );
                  }}
                />
                <Note>※ハイフンなしでご入力ください</Note>
              </FormField>

              <FormField spacing={1}>
                <Controller
                  control={control}
                  name="postal_code"
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <InputItem
                        value={value}
                        onChange={onChange}
                        fullWidth
                        size="small"
                        placeholder="例）1234567"
                        error={errors.postal_code ? true : false}
                        helperText={errors.postal_code?.message}
                        labelPrimary={'郵便番号'}
                      />
                    );
                  }}
                />
              </FormField>

              <FormField spacing={1}>
                <Controller
                  control={control}
                  name="address"
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <InputItem
                        value={value}
                        onChange={onChange}
                        fullWidth
                        size="small"
                        placeholder="お住まいの住所を入力してください"
                        error={errors.address ? true : false}
                        helperText={errors.address?.message}
                        labelPrimary={'ご住所'}
                      />
                    );
                  }}
                />
              </FormField>

              <FormField spacing={3} direction={'row'}>
                <ItemField
                  direction="row"
                  spacing={1}
                  sx={{ maxWidth: '114px' }}
                >
                  <Controller
                    control={control}
                    name={'year'}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <SelectItem
                          onChange={onChange}
                          selected={value}
                          options={getRangeYear(1930, 2020)}
                          maxWidth={'100px'}
                          labelPrimary={'生年月日'}
                          error={!!errors?.year}
                          helperText={errors?.year?.message}
                          field={field}
                          selectPlaceholder={''}
                        />
                      );
                    }}
                  />
                  <TitleField>年</TitleField>
                </ItemField>

                <ItemField
                  direction="row"
                  spacing={1}
                  sx={{ maxWidth: '85px', alignItems: 'flex-end' }}
                >
                  <Controller
                    control={control}
                    name={'month'}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <SelectItem
                          onChange={onChange}
                          selected={value}
                          options={getRangeMonth()}
                          maxWidth={'60px'}
                          labelPrimary={''}
                          error={!!errors?.month}
                          helperText={errors?.month?.message}
                          field={field}
                          selectPlaceholder={''}
                        />
                      );
                    }}
                  />
                  <TitleField>月</TitleField>
                </ItemField>

                <ItemField
                  direction="row"
                  spacing={1}
                  sx={{ maxWidth: '85px', alignItems: 'flex-end' }}
                >
                  <Controller
                    control={control}
                    name={'date'}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <SelectItem
                          onChange={onChange}
                          selected={value}
                          options={getRangeDate()}
                          maxWidth={'60px'}
                          labelPrimary={''}
                          error={!!errors?.date}
                          helperText={errors?.date?.message}
                          field={field}
                          selectPlaceholder={''}
                        />
                      );
                    }}
                  />
                  <TitleField>日</TitleField>
                </ItemField>
              </FormField>

              <Divider></Divider>

              <Desc>
                メールマガジンにて、VMG会員ご優待プランや、メールマガジンにてお得な情報などをご案内しております。
              </Desc>

              <FormControl>
                <Controller
                  control={control}
                  name={'employment_name'}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <CheckBoxItem
                        onChange={onChange}
                        label={'メールマガジンの配信を許可する'}
                        isChecked={!!value}
                        field={field}
                      />
                    );
                  }}
                />
                {/* <FormHelperText>{errors?.[nameField]?.message}</FormHelperText> */}
              </FormControl>

              <Stack direction="row" justifyContent="center">
                <CustomCommonButton
                  variant="contained"
                  size="large"
                  type="submit"
                  form="info_form"
                  sx={{
                    background: colors.blue,
                    boxShadow: 'none',
                  }}
                >
                  <Box component="span">登録する</Box>
                </CustomCommonButton>
              </Stack>
            </Stack>
          </>
        )}
      </Container>
      <Footer />
    </>
  );
}

const Container = styled(Stack)`
  max-width: 375px;
  margin: 50px auto;
`;

const FormField = styled(Stack)``;

const CustomCommonButton = styled(CommonButton)`
  && {
    max-width: 300px;
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }
`;

const ItemField = styled(Stack)`
  width: 100%;
`;

const CustomBoxHeader = styled(BoxHeader)`
  &.box_header {
    justify-content: center;
  }
`;

const Note = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #757575;
    margin-top: 8px;
  }
`;

const Desc = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }
`;

const TitleField = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    color: #000000;
    padding-bottom: 9px;
  }
`;
