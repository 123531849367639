import { Box, Stack } from '@mui/material';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { Loader } from 'app/components/Loading';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { GTM } from 'constants/GTM';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkIsRequesting,
  getGtmParams,
  lodash,
  removeOldGtm,
} from 'utils/helper';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import Banner from './components/Banner';
import PlanDetailModal from './components/Modal/PlanDetailModal';
import RoomDetailModal from './components/Modal/RoomDetailModal';
import RightPanel from './components/RightPanel';
import AllTabs from './components/Tabs/AllTabs';
import { usePlanAndRoomSlice } from './slice';
import { selectPlanAndRoom } from './slice/selectors';
import { useParams } from 'react-router-dom';
import { IParamWithSlug } from 'types/common';
import { selectGetDetailStatus } from '../HotelDetailsPage/slice/selectors';
import ESSnackbar from 'app/components/ESSnackbar';
import { RequestStatus } from 'constants/API';
import { GTM_CLASS_NAME } from 'constants/common';

export interface IParams {
  slug?: string;
  planID?: string;
  roomID?: string;
}

export function PlanAndRoomPage() {
  // const [isShowAlertLoading, setIsShowAlertLoading] = React.useState(true);
  const [isShowAlertLoading, setIsShowAlertLoading] = React.useState(false);
  const { width: pageWidth } = useWindowDimensions(0);
  const isLargeDesktop = pageWidth > 1024;
  const isMobile = pageWidth <= 768;

  const {
    plans,
    active_plan,
    getPlansStatus,
    getRoomsStatus,
    getPlanDetailStatus,
    getRoomDetailStatus,
    tab_type,
    hotelName,
    best_rate,
    banner,
    banner_sp,
    getPlansWithStockStatus,
    getRoomsWithStockStatus,
  } = useSelector(selectPlanAndRoom);
  const dispatch = useDispatch();
  const {
    actions: {
      resetPlanAndRoomStatus,
      changeIsInPlanRoomPage,
      getHotelNameRequest,
      setActivePlan,
      setActiveRoom,
      setHotelSlug,
    },
  } = usePlanAndRoomSlice();
  const statusDetailRequest = useSelector(selectGetDetailStatus);

  const isLoading = checkIsRequesting([
    getPlansStatus,
    getRoomsStatus,
    getPlanDetailStatus,
    getRoomDetailStatus,
    statusDetailRequest,
  ]);

  const { slug, planID, roomID } = useParams<IParams>();

  const checkIsShowAlertLoading = data => {
    let isShowAlert = false;
    if (data && Object.keys(data).length) {
      lodash.forEach(data, (v, k) => {
        if (v === RequestStatus.REQUESTING) {
          isShowAlert = true;
          return;
        }
      });
    }
    return isShowAlert;
  };

  const checkDisplayAlert = data => {
    const isShowAlert = checkIsShowAlertLoading(data);

    if (isShowAlert !== isShowAlertLoading) {
      setIsShowAlertLoading(isShowAlert);
    }
  };

  React.useEffect(() => {
    checkDisplayAlert(getPlansWithStockStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlansWithStockStatus]);

  React.useEffect(() => {
    checkDisplayAlert(getRoomsWithStockStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoomsWithStockStatus]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(changeIsInPlanRoomPage(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(getHotelNameRequest(slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderGTMId = () => {
    let hotelItem = GTM.find(item => item.slug === slug);
    if (hotelItem) {
      return hotelItem.tagId;
    }
    return null;
  };

  React.useEffect(() => {
    if (planID) {
      dispatch(
        setActivePlan({
          hotel_id: slug,
          plan_id: planID,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (roomID) {
      dispatch(setActiveRoom({ hotel_id: slug, room_id: roomID }));
    }
    return () => {
      removeOldGtm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let otherGTM = renderGTMId();
    // let gtmTimeout;
    if (otherGTM) {
      removeOldGtm();
      TagManager.initialize(getGtmParams(otherGTM, GTM_CLASS_NAME));
      // gtmTimeout = setTimeout(
      //   () => TagManager.initialize(getGtmParams(otherGTM, GTM_CLASS_NAME)),
      //   1000,
      // );
    }
    if (slug) {
      dispatch(setHotelSlug(slug));
    }
    return () => {
      // gtmTimeout && clearTimeout(gtmTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(
    () => () => {
      dispatch(resetPlanAndRoomStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const bannerUrl = isMobile ? banner_sp : banner;

  return (
    <>
      <Helmet>
        <title>
          {hotelName} {tab_type === 0 ? 'のプラン一覧' : 'の部屋一覧'} | VMG
          HOTELS & UNIQUE VENUES
        </title>
        <meta name="description" content="プラン一覧" />
      </Helmet>
      <Header />
      {!!bannerUrl && <Banner bannerUrl={bannerUrl} />}
      <PlanDetailModal isGoBackWhenClose />
      <RoomDetailModal isGoBackWhenClose />
      <Loader open={isLoading} />
      <ESSnackbar
        isOpen={isShowAlertLoading}
        mess={
          'ただ今、直前の検索条件を反映中です。別の検索条件を適用する場合は、条件の反映後に再検索をお願いいたします。'
        }
      ></ESSnackbar>
      <Stack
        direction="row"
        justifyContent={'center'}
        spacing={5}
        px={{ xs: '0px', sm: '16px' }}
        sx={{ marginTop: { xs: '16px', sm: '40px' } }}
      >
        <AllTabs url={best_rate} />
        {isLargeDesktop && (
          <Box>
            <RightPanel />
          </Box>
        )}
      </Stack>
      <Footer />
    </>
  );
}
