import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import styled from 'styled-components';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  width?: number | string;
  height?: number | string;
  backgroundImage?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  borderRadius?: number;
}

function SimpleDialog(props: SimpleDialogProps) {
  const {
    onClose,
    open,
    width,
    height,
    backgroundSize,
    backgroundImage,
    backgroundPosition,
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box
        style={{
          backgroundImage: backgroundImage
            ? `url(${backgroundImage})`
            : 'unset',
          backgroundSize,
          backgroundPosition,
          width,
          height,
          border: backgroundImage ? 'unset' : '1px solid #ccc',
        }}
      ></Box>
    </Dialog>
  );
}

SimpleDialog.defaultProps = {
  width: 500,
  height: 500,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // backgroundImage: '/img/hotels/room.png',
  borderRadius: 'unset',
};

interface ISingleImage {
  width?: number | string;
  height?: number | string;
  backgroundImage?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  borderRadius?: number;
  showOnFullScreen?: boolean;
  showFullGallery?: boolean;
}

const SingleImage = (props: ISingleImage) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    borderRadius,
    width,
    height,
    backgroundSize,
    backgroundImage,
    backgroundPosition,
    showOnFullScreen,
    showFullGallery,
  } = props;
  return (
    <>
      <Container
        className="single-img"
        style={{
          backgroundImage: backgroundImage
            ? `url(${backgroundImage})`
            : 'unset',
          backgroundSize,
          backgroundPosition,
          width,
          height,
          borderRadius,
          cursor: showOnFullScreen || showFullGallery ? 'pointer' : 'auto',
          border: backgroundImage ? 'unset' : '1px solid #ccc',
        }}
        onClick={() => {
          if (showOnFullScreen && !showFullGallery) handleClickOpen();
        }}
      ></Container>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        width={width}
        height={height}
        backgroundImage={backgroundImage}
        borderRadius={borderRadius}
      />
    </>
  );
};

SingleImage.defaultProps = {
  width: 500,
  height: 500,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // backgroundImage: '/img/hotels/room.png',
  borderRadius: 'unset',
  showOnFullScreen: false,
  showFullGallery: false,
};
export default SingleImage;

const Container = styled(Box)`
  background-repeat: no-repeat;
`;
