import { Box, Button, Card, CardMedia, Stack, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CardPreview } from 'app/components/CardPreview';
import Checkout from 'app/components/Checkout';
import { CheckoutHeader } from 'app/components/CheckoutHeader';
import { CommonButton } from 'app/components/CommonButton';
import { MoneyItem } from 'app/components/MoneyItem';
import { ReactComponent as CardIcon } from 'assets/icons/card_icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-down-icon.svg';
import HotelImg from 'assets/imgs/hotel_1.png';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { commonFormat } from 'utils/moment';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from '../GlobalContainer/slice';
import { CartInfo } from './CartInfo';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import { getUrlPlansPage } from 'utils/helper';
export interface IOrderSuccessPageProps {
  img: string;
  name: string;
  checkin_time: string;
  checkout_time: string;
  pax_info: string;
  plan_name: string;
  meals: string[];
  room_name: string;
  total: number;
  cost_breakdown: any;
}

export default function OrderSuccessPage(props: IOrderSuccessPageProps) {
  const [showCostBreakdown, setShowCostBreakdown] =
    React.useState<boolean>(false);
  const history = useHistory();

  const dispatch = useDispatch();
  const { changeCurrentCheckoutStep } = useGlobalSlice().actions;

  const location = useLocation();
  const { cart_items, cart_items_deleted } = useSelector(selectGlobal);
  const cart_data =
    location.pathname === Path.SUCCESS ? cart_items_deleted : cart_items;

  const slug = cart_data[0]?.local_hotel_id;

  React.useEffect(() => {
    dispatch(changeCurrentCheckoutStep(4));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const prop = {
    img: HotelImg,
    name: '佐原商家町ホテルNIPPONIA',
    checkin_time: '2020-02-01',
    checkout_time: '2020-02-09',
    pax_info: '大人2名（男性、女性）子供2名（男性、女性）',
    plan_name: '通常ご宿泊プラン',
    meals: ['朝食（朝食）', '夕食（夕食）'],
    room_name: 'VMG グランド（定員4名）',
    total: 240000,
    cost_breakdown: [
      {
        name: 'グランド',
        amount: 30000,
      },
      {
        name: 'グランド',
        amount: 210000,
      },
      {
        name: 'グランド',
        amount: 210000,
      },
    ],
  };
  const {
    img,
    name,
    checkin_time,
    checkout_time,
    pax_info,
    plan_name,
    meals,
    room_name,
    total,
    cost_breakdown,
  } = prop;

  const paddingXProps = {
    paddingLeft: { xs: 2, sm: 0 },
    paddingRight: { xs: 2, sm: 0 },
  };
  return (
    <>
      <Helmet>
        <title>予約完了 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="予約完了 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Box>
        <CheckoutHeader />
        <OrderDetail
          sx={{
            maxWidth: { md: '400px', xs: '100%' },
            margin: { xs: '40px auto' },
          }}
        >
          <Box sx={{ ...paddingXProps }}>
            <BoxHeader>予約完了</BoxHeader>
            <Typography
              sx={{
                margin: { md: '40px 0', xs: '24px 0' },
                whiteSpace: 'break-spaces',
              }}
              component="p"
              fontSize={fontsizes.main}
              color={colors.default}
            >
              予約が完了しました。{'\n'} メールをお送りしておりますので、{'\n'}
              ご確認ください。
            </Typography>
          </Box>

          <CartInfo />
          <Box sx={{ ...paddingXProps }}>
            <ScrollUpButton
              sx={{
                background: colors.blue,
                boxShadow: 'none',
                // marginTop: { xs: 0, sm: 4 },
                color: colors.white,
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '22px',
              }}
              onClick={() => {
                history.push(getUrlPlansPage(slug));
              }}
            >
              TOPへ
            </ScrollUpButton>
          </Box>
        </OrderDetail>
      </Box>
    </>
  );
}

const OrderDetail = styled(Box)`
  .money-item {
    padding-right: 20px;
    span {
      font-weight: 700;
    }
  }

  .icon {
    padding-right: 5px;
  }
  .icon-arrow {
    svg {
      margin-left: 10px;
      overflow: visible;
      display: inline-block;
    }
  }
  ${dMedia.tablet`
    .icon {
      display: none;
    }
  `}
`;

const ScrollUpButton = styled(CommonButton)`
  &:hover {
    background: ${colors.blue} !important;
  }
`;
