import { Box, Typography, TextField } from '@mui/material';
import { CommonButton } from 'app/components/CommonButton';
import { FORM_ERROR_TEXT } from 'constants/common';
import { colors } from 'constants/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';

interface IResetPassword {
  code: string;
  password: string;
  confirm_password: string;
}
export interface IResetPasswordFormProps {
  submitResetPassword: (formData) => void;
}

export default function ResetPasswordForm(props: IResetPasswordFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleResetPasswordSubmit = formData => {
    props.submitResetPassword(formData);
  };

  return (
    <Box
      sx={{}}
      component="form"
      id="reset_password_form"
      onSubmit={handleSubmit(handleResetPasswordSubmit)}
    >
      <Typography
        gutterBottom
        color={colors.mainContent}
        fontWeight={500}
        fontSize="14px"
        mt={5}
      >
        確認コード
      </Typography>
      <TextField
        {...register<keyof IResetPassword>('code', {
          required: 'こちらは必須項目です。',
        })}
        placeholder="xxxxx"
        fullWidth
        variant="outlined"
        size="small"
        error={errors.code ? true : false}
        helperText={errors.code?.message}
        inputProps={{
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
      />
      <Typography
        gutterBottom
        color={colors.mainContent}
        fontWeight={500}
        fontSize="14px"
        mt={5}
      >
        新しいパスワード
      </Typography>

      <TextField
        {...register<keyof IResetPassword>('password', {
          required: 'こちらは必須項目です。',
          minLength: {
            value: 8,
            message: FORM_ERROR_TEXT.PASSWORD_RULE,
          },
          pattern: {
            value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
            message: FORM_ERROR_TEXT.PASSWORD_RULE,
          },
        })}
        inputProps={{
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
        type="password"
        fullWidth
        variant="outlined"
        size="small"
        error={errors.password ? true : false}
        helperText={errors.password?.message}
      />

      <Typography
        gutterBottom
        color={colors.mainContent}
        fontWeight={500}
        fontSize="14px"
        mt={5}
      >
        新しいパスワード（確認）
      </Typography>
      <TextField
        {...register<keyof IResetPassword>('confirm_password', {
          required: 'こちらは必須項目です。',
          validate: value =>
            value === watch('password') || FORM_ERROR_TEXT.PASS_NOT_MATCH,
        })}
        inputProps={{
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
        type="password"
        fullWidth
        variant="outlined"
        size="small"
        error={errors.confirm_password ? true : false}
        helperText={errors.confirm_password?.message}
      />

      <CommonButton
        className="white-btn"
        variant="contained"
        size="large"
        type="submit"
        form="reset_password_form"
        sx={{
          background: colors.blue,
          boxShadow: 'none',
          fontSize: '15px',
          marginBottom: 1,
          lineHeight: '20px',
          padding: '10px',
          marginTop: 5,
        }}
      >
        送信する
      </CommonButton>
    </Box>
  );
}
