import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import {
  DEFAULT_MEAL,
  MEALS,
  TYPE_SORT_SUGGESTION,
  TYPE_TAB_PLAN,
  TYPE_TAB_ROOM,
} from 'constants/common';
import _ from 'lodash';
import moment from 'moment';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { isDevelopEnv } from 'utils/helper';
import { commonFormat } from 'utils/moment';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import planAndRoomSaga from './saga';
import { PlanAndRoomState } from './types';

const plans = [
  {
    id: 1,
    images: [
      {
        path: 'https://econawstest.e-concierge.net/pic/thumb/99-1-1.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/thumb/99-1-2.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/thumb/99-1-3.jpg',
      },
    ],
    name: 'おすすめプラン',
    tags: [],
    content: '',
    price: 6500,
    rooms: [],
    checkin_times: ['17:00', '18:00', '19:00', '20:00'],
    checkout_time: '11:00',
  },
  {
    id: 7,
    images: [],
    name: 'オプションなしプラン',
    tags: [],
    content: '',
    price: 10000,
    rooms: [],
    checkin_times: ['17:00', '18:00', '19:00', '20:00'],
    checkout_time: '11:00',
  },
  {
    id: 8,
    images: [],
    name: 'おすすめプランフラガールインクルード',
    tags: [],
    content: '',
    price: 5000,
    rooms: [],
    checkin_times: ['17:00', '18:00', '19:00', '20:00'],
    checkout_time: '11:00',
  },
];

const rooms = [
  {
    id: 1,
    images: [
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-1-1.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-1-2.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-1-3.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-1-4.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-1-5.jpg',
      },
    ],
    name: 'シングルルーム',
    options: {
      bath: true,
      toilet: false,
      no_smoking: false,
      internet: false,
      premium_room: true,
      private_open_air_bath: true,
    },
    tags: [],
    content: '',
    plans: [],
    price: 6500,
  },
  {
    id: 2,
    images: [
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-2-1.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-2-2.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-2-3.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-2-4.jpg',
      },
    ],
    name: '備品のみ用の部屋',
    options: {
      bath: true,
      toilet: false,
      no_smoking: false,
      internet: false,
      premium_room: true,
      private_open_air_bath: false,
    },
    tags: [],
    content: '備品のみ用の部屋',
    plans: [],
    price: 6500,
  },
  {
    price: 6500,
    id: 3,
    images: [
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-3-1.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-3-2.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-3-3.jpg',
      },
      {
        path: 'https://econawstest.e-concierge.net/pic/room/99-3-4.jpg',
      },
    ],
    name: 'ダブルルーム',
    options: {
      bath: false,
      toilet: false,
      no_smoking: false,
      internet: false,
      premium_room: false,
      private_open_air_bath: false,
    },
    tags: [],
    content: '',
    plans: [],
  },
];

export const initialState: PlanAndRoomState = {
  plans: [],
  originalPlans: [],
  originalRoomTypes: [],
  rooms: [],
  tab_type: TYPE_TAB_ROOM,
  sort_type: TYPE_SORT_SUGGESTION,
  active_plan: null,
  detail_plan: null,
  active_room: null,
  detail_room: null,
  checkin_date: null,
  checkout_date: null,
  additionSearchInfo: {
    // tag_id is meal
    meal: DEFAULT_MEAL,
    adult: 0,
    child: 0,
    nights: 0,
    child_A: 0,
    child_B: 0,
    child_C: 0,
    child_D: 0,
    child_E: 0,
  },
  planDetailModalStatus: false,
  isInPlanRoomPage: false,
  getPlansStatus: RequestStatus.IDLE,
  getRoomsStatus: RequestStatus.IDLE,
  getPlansWithStockStatus: {},
  getRoomsWithStockStatus: {},
  getPlansWithStockInModalStatus: RequestStatus.IDLE,
  getPlanDetailStatus: RequestStatus.IDLE,
  getPlanForOrderStatus: {},
  planForOrder: {},
  getRoomDetailStatus: RequestStatus.IDLE,
  hotelName: '',
  best_rate: null,
  banner: null,
  banner_sp: null,
};

const slice = createSlice({
  name: 'plan_and_room',
  initialState,
  reducers: {
    getRoomDetailRequest: (state, action: PayloadAction<any>) => {
      state.getRoomDetailStatus = RequestStatus.REQUESTING;
      state.detail_room = null;
    },
    getRoomDetailSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getRoomDetailStatus = RequestStatus.SUCCESS;
        state.detail_room = action.payload;
      }
    },
    getRoomDetailFail: (state, action: PayloadAction<any>) => {
      state.getRoomDetailStatus = RequestStatus.ERROR;
    },

    getPlanDetailRequest: (state, action: PayloadAction<any>) => {
      state.getPlanDetailStatus = RequestStatus.REQUESTING;
      state.detail_plan = null;
      const { planId } = action.payload;
      if (planId) {
        const newData = {
          ...state.getPlanForOrderStatus,
          [planId]: RequestStatus.REQUESTING,
        };
        state.getPlanForOrderStatus = newData;
      }
    },
    getPlanDetailSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getPlanDetailStatus = RequestStatus.SUCCESS;
        state.detail_plan = action.payload;
        const { planId } = action.payload;

        if (planId) {
          state.getPlanForOrderStatus = {
            ...state.getPlanForOrderStatus,
            [planId]: RequestStatus.SUCCESS,
          };
          state.planForOrder = {
            ...state.planForOrder,
            [planId]: action.payload,
          };
        }
      }
    },
    getPlanDetailFail: (state, action: PayloadAction<any>) => {
      state.getPlanDetailStatus = RequestStatus.ERROR;
      const { planId } = action.payload;
      if (action.payload.planId) {
        state.getPlanForOrderStatus = {
          ...state.getPlanForOrderStatus,
          [planId]: RequestStatus.ERROR,
        };
      }
    },

    getRoomsRequest: (state, action: PayloadAction<any>) => {
      state.getRoomsStatus = RequestStatus.REQUESTING;
    },
    getRoomsSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getRoomsStatus = RequestStatus.SUCCESS;
        // const roomsData = isDevelopEnv()
        //   ? action.payload.map(item => ({
        //       ...item,
        //       plans,
        //     }))
        //   : action.payload;
        const roomsData = action.payload;

        state.rooms = roomsData;
        state.getRoomsWithStockStatus = {};
      }
    },
    getRoomsFail: (state, action: PayloadAction<any>) => {
      state.getRoomsStatus = RequestStatus.ERROR;
    },

    getPlansRequest: (state, action: PayloadAction<any>) => {
      state.getPlansStatus = RequestStatus.REQUESTING;
    },
    getPlansSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getPlansStatus = RequestStatus.SUCCESS;
        // const plansData = isDevelopEnv()
        //   ? action.payload.map(item => ({
        //       ...item,
        //       rooms,
        //     }))
        //   : action.payload;
        const plansData = action.payload;

        state.plans = plansData;

        state.getPlansWithStockStatus = {};
      }
    },
    getPlansFail: (state, action: PayloadAction<any>) => {
      state.getPlansStatus = RequestStatus.ERROR;
    },
    getRoomsWithStockRequest: (state, action: PayloadAction<any>) => {
      state.getRoomsWithStockStatus[action.payload.id] =
        RequestStatus.REQUESTING;
    },
    getRoomsWithStockSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getRoomsWithStockStatus[action.payload.id] =
          RequestStatus.SUCCESS;
        const rooms = _.cloneDeep(state.rooms);
        const idx = _.findIndex(rooms, { id: action.payload.id });
        if (idx >= 0) {
          let room = rooms[idx];
          room = { ...room, ..._.pick(action.payload.data, ['plans']) };
          rooms[idx] = room;
        }

        state.rooms = rooms;
      }
    },
    getRoomsWithStockFail: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getRoomsWithStockStatus[action.payload.id] = RequestStatus.IDLE;
        const rooms = _.cloneDeep(state.rooms);
        const idx = _.findIndex(rooms, { id: action.payload.id });
        if (!_.isEmpty(rooms[idx])) {
          rooms[idx].plans = [];
        }
        state.rooms = rooms;
      }
    },
    getPlansWithStockRequest: (state, action: PayloadAction<any>) => {
      if (action && action.payload.fromModal) {
        state.getPlansWithStockInModalStatus = RequestStatus.REQUESTING;
      } else {
        state.getPlansWithStockStatus[action.payload.id] =
          RequestStatus.REQUESTING;
      }
    },

    getPlansWithStockSuccess: (state, action: PayloadAction<any>) => {
      const plans = _.cloneDeep(state.plans);
      const idx = _.findIndex(plans, { id: action.payload.id });
      let plan;
      if (idx >= 0) {
        plan = plans[idx];
        plan = { ...plan, ..._.pick(action.payload.data, ['rooms']) };
        plans[idx] = plan;
      }
      if (action && !action.payload.fromModal) {
        state.getPlansWithStockStatus[action.payload.id] =
          RequestStatus.SUCCESS;
        state.plans = plans;
      } else if (action.payload.fromModal) {
        state.getPlansWithStockInModalStatus = RequestStatus.SUCCESS;
        state.planForModal = !_.isEmpty(plan) ? plan : action.payload.data;
      }
    },
    getPlansWithStockFail: (state, action: PayloadAction<any>) => {
      if (action && !action.payload.fromModal) {
        state.getPlansWithStockStatus[action.payload.id] = RequestStatus.IDLE;
        const plans = _.cloneDeep(state.plans);
        const idx = _.findIndex(plans, { id: action.payload.id });
        if (!_.isEmpty(plans[idx])) {
          plans[idx].rooms = [];
        }
        state.plans = plans;
      } else if (action.payload.fromModal) {
        state.getPlansWithStockInModalStatus = RequestStatus.IDLE;
      }
    },
    resetPlanFromModal: state => {
      state.planForModal = undefined;
    },
    getHotelNameRequest: (state, action: PayloadAction<any>) => {},
    getHotelNameSuccess: (state, action: PayloadAction<any>) => {
      const { name, best_rate, banner, banner_sp, id } = action.payload;

      state.hotelName = name;
      state.best_rate = best_rate;
      state.banner = banner;
      state.banner_sp = banner_sp;
      state.hotelId = id;
    },
    getHotelNameFail: (state, action: PayloadAction<any>) => {},
    changeTabType: (state, action: PayloadAction<any>) => {
      state.tab_type = action.payload;
    },
    sortPlans: (state, action: PayloadAction<{ sort_type: string }>) => {
      const { sort_type } = action.payload;
      let _sort_type = '';
      if (state.tab_type === TYPE_TAB_PLAN) {
        _sort_type =
          sort_type === TYPE_SORT_SUGGESTION
            ? 'plan.suggestion'
            : 'plan.oneNightPrice';
        state.sort_type = action.payload.sort_type;
        const newPlans = _.orderBy(state.originalPlans, [_sort_type], ['asc']);
        state.plans = [...newPlans];
      } else {
        _sort_type =
          sort_type === TYPE_SORT_SUGGESTION
            ? 'room_type.suggestion'
            : 'room_type.oneNightPrice';

        state.sort_type = action.payload.sort_type;
        const newRooms = _.orderBy(
          state.originalRoomTypes,
          [_sort_type],
          ['asc'],
        );
        state.rooms = [...newRooms];
      }
    },
    filterPlans: (state, action: PayloadAction<{ meal: any }>) => {
      const { meal } = action.payload;

      const newPlans = _.filter(state.originalPlans, ['plan.meal', meal]);
      state.plans = [...newPlans];
    },
    // set active plan and reset detail plan when close modal detail plan
    setActivePlan: (state, action: PayloadAction<any>) => {
      const newActivePlan = action.payload;

      if (!newActivePlan) {
        state.detail_plan = null;
      }
      state.active_plan = action.payload;
    },
    setActiveRoom: (state, action: PayloadAction<any>) => {
      state.active_room = action.payload;
    },
    changePlanDetailModalStatus: (state, action: PayloadAction<any>) => {
      state.planDetailModalStatus = action.payload;
    },
    changeIsInPlanRoomPage: (state, action: PayloadAction<any>) => {
      state.isInPlanRoomPage = action.payload;
    },
    changeAdditionSearchInfo: (state, action: PayloadAction<any>) => {
      state.additionSearchInfo = {
        ...state.additionSearchInfo,
        ...action.payload,
      };
    },
    changeCheckInOutDate: (state, action: PayloadAction<any>) => {
      const checkInOutDate = action.payload;

      const newCheckInDate = _.head(checkInOutDate)
        ? moment(_.head(checkInOutDate))
        : null;
      state.checkin_date = newCheckInDate;
      if (checkInOutDate.length === 2) {
        state.checkout_date = _.last(checkInOutDate)
          ? moment(_.last(checkInOutDate))
          : null;
      } else {
        state.checkout_date = null;
      }
    },
    changeActiveMonthOnCalendar: (state, action: PayloadAction<any>) => {
      if (action) {
        state.activeMonthOnCalendar = action.payload;
      }
    },
    setHotelSlug: (state, action: PayloadAction<any>) => {
      state.hotelSlug = action.payload;
    },
    resetPlanAndRoomStatus: state => {
      state.plans = [];
      state.hotelSlug = '';
      state.originalPlans = null;
      state.rooms = [];
      state.tab_type = TYPE_TAB_PLAN;
      state.sort_type = TYPE_SORT_SUGGESTION;
      state.active_plan = null;
      state.active_room = null;
      state.additionSearchInfo = {};
      state.isInPlanRoomPage = false;
      state.getRoomsWithStockStatus = {};
      state.getPlansWithStockStatus = {};
      state.planForOrder = {};
      state.getPlanForOrderStatus = {};
    },
    resetStatus: state => {
      state.getPlansStatus = RequestStatus.IDLE;
      state.getRoomsStatus = RequestStatus.IDLE;
    },
    resetPlanRoomStockStatus: (state, action: PayloadAction<boolean>) => {
      state.resetStockStatus = action.payload;
    },
  },
});

export default slice.actions;

export const usePlanAndRoomSlice = () => {
  // src/store/reducer
  try {
    useInjectSaga({ key: slice.name, saga: planAndRoomSaga });
  } catch (e) {
    console.log(e);
  }
  return { actions: slice.actions };
};

export const planAndRoomSlice = () => {
  return slice;
};
