// UI
import { Box, Button, Grid, Stack } from '@mui/material';
import { IImage } from 'constants/commonTypes';
import React from 'react';
import styled from 'styled-components';
import SingleImage from '../SingleImage';
import { ReactComponent as IconShowContent } from 'assets/icons/chevron-down-icon.svg';
import Collapse from '@mui/material/Collapse';
import { colors } from 'constants/styles';

export enum ButtonPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}
interface ICollapsibleData {
  closeText?: string;
  children: any;
  isOpen?: boolean;
  buttonPosition?: ButtonPosition;
  viewMoreText?: string;
  itemsAlwaysDisplayed?: any;
  idScrollTo?: any;
}

const CollapsePanel = ({
  closeText = '',
  children,
  isOpen = false,
  viewMoreText = '',
  buttonPosition = ButtonPosition.TOP,
  itemsAlwaysDisplayed,
  idScrollTo,
  ...props
}: ICollapsibleData) => {
  const [open, setOpen] = React.useState(isOpen);

  const renderButton = () => {
    return (
      <CollapsedButton
        variant="contained"
        color="inherit"
        endIcon={<IconShowContent />}
        className={open ? 'rotate_icon' : ''}
        onClick={() => {
          if (!idScrollTo || !open) {
            setOpen(!open);
          } else {
            let ele = document.getElementById(idScrollTo);
            window.scrollTo({
              top: ele?.offsetTop,
              behavior: 'smooth',
            });
            setTimeout(() => {
              setOpen(!open);
            }, 200);
          }
        }}
      >
        <span className={'btn_collapse'}>
          {open ? closeText || viewMoreText : viewMoreText || closeText}
        </span>
      </CollapsedButton>
    );
  };

  return (
    <Container>
      <CollapseItem spacing={ButtonPosition.TOP ? 2 : 3}>
        {buttonPosition === ButtonPosition.TOP ? renderButton() : ''}
        {itemsAlwaysDisplayed || ''}
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
        {buttonPosition === ButtonPosition.BOTTOM ? renderButton() : ''}
      </CollapseItem>
    </Container>
  );
};

export default CollapsePanel;

const Container = styled(Stack)``;

const CollapsedButton = styled(Button)`
  &.MuiButtonBase-root {
    outline: none !important;
    background: white !important;
    box-shadow: none !important;
    border: 1px solid ${colors.grey5};
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn_collapse {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${colors.grey1};
    }
    .MuiButton-endIcon {
      margin-left: 17px;
    }
    .MuiTouchRipple-root {
      display: none;
    }
    &.rotate_icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

const CollapseItem = styled(Stack)``;
