/**
 *
 * GlobalContainer
 *
 */
import React, { memo } from 'react';
import { LoadingBackgound } from './styles';
import Toast from 'app/components/ToastMessage';
import { ToastContainer } from 'react-toastify';
import useDisplayMessagesFactory from './useDispayMessages';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

// Redux & Sagas
import { useSelector, useDispatch } from 'react-redux';
import { useGlobalSlice } from './slice';
import { selectGlobal } from './slice/selectors';
import { getToken, getLoginTime, removeLoginTime } from 'utils/localStorage';
import { useCheckoutSlice } from '../CheckoutPage/slice';
import { checkTokenNotExpired } from 'utils/helper';

interface Props {
  children: React.ReactNode;
}

export const GlobalContainer = memo(({ children }: Props) => {
  const dispatch = useDispatch();
  const {
    actions: {
      login,
      setErrorMessages,
      setInfoMessages,
      setSuccessMessages,
      setWarningMessages,
    },
  } = useGlobalSlice();

  const {
    errorMessages,
    infoMessages,
    warningMessages,
    successMessages,
    isGlobalLoading,
  } = useSelector(selectGlobal);

  const { resetCheckoutStatus, createOrderRequest } =
    useCheckoutSlice().actions;

  useDisplayMessagesFactory(errorMessages, Toast.error, setErrorMessages);
  useDisplayMessagesFactory(warningMessages, Toast.warn, setWarningMessages);
  useDisplayMessagesFactory(infoMessages, Toast.inform, setInfoMessages);
  useDisplayMessagesFactory(successMessages, Toast.success, setSuccessMessages);

  React.useEffect(() => {
    if (getToken() && checkTokenNotExpired()) {
      dispatch(login());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!getToken()) {
      // dispatch(resetCheckoutStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken()]);

  return (
    <>
      {isGlobalLoading && (
        <LoadingBackgound>
          <LoadingIndicator />
        </LoadingBackgound>
      )}
      <ToastContainer />
      {children}
    </>
  );
});
