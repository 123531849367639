import { Helmet } from 'react-helmet-async';

import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { Box, Typography } from '@mui/material';

import { fontsizes, colors } from 'constants/styles';

export default function MaintenancePage() {
  return (
    <>
      <Helmet>
        <title>メンテナンス中 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="メンテナンス中 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Box
        sx={{
          height: 'calc(100vh - 82px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: {
              xs: fontsizes.heading2,
              md: '36px',
            },
            lineHeight: {
              xs: '34px',
              md: '52px',
            },
            fontWeight: '700',
            color: colors.heading,
            marginBottom: '16px',
            textAlign: 'center',
          }}
        >
          ただいま
          <Box
            sx={{
              display: {
                xs: 'block',
                md: 'none',
              },
            }}
          ></Box>
          メンテナンス中です
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: fontsizes.main,
            lineHeight: '20px',
            color: colors.grey1,
            textAlign: 'center',
          }}
        >
          申し訳ございません。
          <Box
            sx={{
              display: {
                xs: 'block',
                md: 'none',
              },
            }}
          ></Box>
          ただいまメンテナンス中です。
          <br />
          メンテナンスの終了は
          <Box
            sx={{
              display: {
                xs: 'block',
                md: 'none',
              },
            }}
          ></Box>
          午前12時を予定しております
        </Typography>
      </Box>
      <Footer />
    </>
  );
}
