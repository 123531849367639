import { Box, Typography } from '@mui/material';

import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';

import isEmpty from 'lodash/isEmpty';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { BoxHeader } from 'app/components/BoxHeader';
import { ReactComponent as Brightness } from 'assets/icons/header/brightness.svg';

import SpecialOfferItem from './SpecialOfferItem';

const data = [
  {
    packages: [
      {
        name: '1名初日',
        amount: 46000,
      },
      {
        name: '2泊1室合計',
        amount: 184000,
      },
    ],
    url: 'https://i.vdoc.vn/data/image/2021/09/13/100-hinh-nen-background-zoom-dep-mien-phi-4.jpg',
    title: 'VMGスイート（ペットと泊まれる・4名定員)',
  },
  {
    packages: [
      {
        name: '1名初日',
        amount: 46000,
      },
      {
        name: '2泊1室合計',
        amount: 184000,
      },
    ],
    url: 'https://i.vdoc.vn/data/image/2021/09/13/100-hinh-nen-background-zoom-dep-mien-phi-4.jpg',
    title: 'VMGプレミア',
  },
  {
    packages: [
      {
        name: '1名初日',
        amount: 46000,
      },
      {
        name: '2泊1室合計',
        amount: 184000,
      },
    ],
    url: 'https://i.vdoc.vn/data/image/2021/09/13/100-hinh-nen-background-zoom-dep-mien-phi-4.jpg',
    title: 'VMGプレミア',
  },
];

export default function SpecialOfferPage() {
  return (
    <>
      <Helmet>
        <title>特別会員オファー | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="特別会員オファー | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Box
        sx={{
          maxWidth: '562px',
          margin: '0 auto',
          marginTop: '40px',
          padding: {
            xs: '0 16px',
            md: 0,
          },
        }}
      >
        <BoxHeader fontSize={24} icon={<MenuIcon />}>
          特別会員オファー
        </BoxHeader>
        <Box
          sx={{
            marginTop: '50px',
            display: 'grid',
            gap: '40px',
          }}
        >
          {data.map((item, index) => (
            <SpecialOfferItem key={index} data={item} />
          ))}
          {isEmpty(data) ? (
            <Typography>
              条件に合致する特別会員オファーは見つかりませんでした
            </Typography>
          ) : (
            ''
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
}

const MenuIcon = styled(Brightness)`
  width: 40px;
  height: 40px;
  box-sizing: content-box;
  @media screen and (max-width: 900px) {
    width: 24px;
    height: 24px;
  }
`;
