import { Box, Stack } from '@mui/material';
import { CardPreview } from 'app/components/CardPreview';
import { CheckoutHeader } from 'app/components/CheckoutHeader';
import { CommonButton } from 'app/components/CommonButton';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { OrderTabs } from 'app/pages/OrderInfoPage/components/OrderTabs';
import Path from 'config/clientPath';
import { hideOnPCProps, hideOnSPProps } from 'constants/common';
import { colors } from 'constants/styles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { getTotalPriceOfRoom } from 'utils/helper';
import { useWindowDimensions } from 'utils/useWindowDimensions';

import { MoneyItem } from '../MoneyItem';
import { SyncOrderInfo } from './components/SyncOrderInfo';

// reducer
// end reducer

interface ICheckout {
  children: React.ReactNode;
}

const Checkout = ({ children, ...props }: ICheckout) => {
  const { changeShowCardPreviewStatus } = useGlobalSlice().actions;
  const { showCardPreview, cart_items } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;

  const hasScrollTab = cart_items.length > 3;

  const isOrderInfoPage = window.location.pathname === Path.ORDER_INFO;
  const getStyleTabs = () => {
    let styles: any = {
      flexDirection: isMobile ? 'row' : 'row',
    };
    if (isMobile) {
      styles = {
        ...styles,
        marginTop: '16px',
        transform: hasScrollTab ? 'translateX(-10px)' : 'translateX(0px)',
        width: !hasScrollTab ? 'calc(100%)' : 'calc(100% + 20px)',
      };
    } else {
      styles = {
        ...styles,
        transform: hasScrollTab ? 'translateX(-40px)' : 'translateX(0px)',
        width: !hasScrollTab ? 'calc(100%)' : 'calc(100% + 40px)',
      };
    }
    return styles;
  };

  return (
    <>
      {/* only show card preview on modal on sp */}
      {showCardPreview ? (
        <WrapCardPreview sx={{ ...hideOnPCProps }}>
          <CardPreview />
        </WrapCardPreview>
      ) : (
        <Box>
          <CheckoutHeader />

          <Container>
            {isOrderInfoPage && (
              <WrapTabs mb={2} sx={{ ...getStyleTabs() }}>
                <OrderTabs />
                <WrapPrice>
                  {!isMobile && (
                    <Box ml={hasScrollTab ? 0 : 2}>
                      <SyncOrderInfo />
                    </Box>
                  )}
                  <PriceArea>
                    <LabelPrice>予約合計</LabelPrice>
                    <MoneyItem
                      fontSize={18}
                      amount={getTotalPriceOfRoom(cart_items)}
                    />
                  </PriceArea>
                </WrapPrice>
              </WrapTabs>
            )}
            <WrapContent>
              <CommonButton
                className="white-btn"
                variant="contained"
                sx={{
                  background: colors.white,
                  color: colors.blue,
                  border: `1px solid ${colors.blue}`,
                  margin: {
                    xs: '24px 0 24px',
                  },
                  fontSize: '15px',
                  boxShadow: 'none',
                  lineHeight: '20px',
                  padding: '10px',
                  maxWidth: isMobile ? 'unset' : '375px',
                  ...hideOnPCProps,
                }}
                // toggle show card preview
                onClick={() => {
                  dispatch(changeShowCardPreviewStatus(true));
                }}
              >
                プランを確認する
              </CommonButton>
              <LeftContainer>{children}</LeftContainer>
              {/* no show card preview on sp, only show it on modal */}
              <RightContainer sx={{ ...hideOnSPProps }}>
                <CardPreview />
              </RightContainer>
            </WrapContent>
          </Container>
        </Box>
      )}
    </>
  );
};

Checkout.defaultProps = {};
export default Checkout;

const WrapCardPreview = styled(Box)`
  justify-content: center;
  margin-top: 40px;
  padding: 0 16px;
`;

const LeftContainer = styled(Box)`
  width: 100%;
  max-width: 375px;
`;
const WrapPrice = styled(Box)`
  border-bottom: 1px solid #1976d2;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${dMedia.tablet`
    justify-content: flex-end;
  `}
`;
const WrapContent = styled(Stack)`
  flex-direction: row !important;
  gap: 32px;
  ${dMedia.tablet`
    flex-direction: column !important;
    width: 100%;
  `}
`;
const PriceArea = styled(Box)`
  padding-right: 16px;
  display: flex;
  align-items: baseline;
  gap: 10px;
  .money-item span {
    font-weight: 700;
  }
`;
const LabelPrice = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
`;
const WrapTabs = styled(Box)`
  display: flex;
  ${dMedia.tablet`
    flex-direction: column
  `}
`;

const RightContainer = styled(Box)`
  position: relative;
  ${dMedia.large`
    position: relative;
    left: 0px;
  `}
`;

const Container = styled(Stack)`
  position: relative;
  margin: 32px auto 32px auto;
  justify-content: center;
  max-width: 838px;
  /* gap: 32px; */
  /* ${dMedia.large`
    flex-direction: row !important;
    max-width: none;
    justify-content: center;
    gap: 32px;
  `} */
  ${dMedia.tablet`
    flex-direction: column !important;
    align-items: center;
    gap: 0px;
    margin-top: 0px;
    padding: 0 20px;
    width: 100%;
  `}
`;
