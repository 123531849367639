// UI
import { Box } from '@mui/material';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export enum CartInfoPosition {
  TOP = 'TOP',
  NORMAL = 'NORMAL',
}

interface ICartInfo {
  onClick?: () => void;
  position?: number | string;
}

export function CartInfo({
  position = CartInfoPosition.NORMAL,
  ...props
}: ICartInfo) {
  const { cart_items } = useSelector(selectGlobal);

  return cart_items.length ? (
    <Container
      className={position === CartInfoPosition.TOP ? 'top_position' : ''}
    >
      {cart_items.length}
    </Container>
  ) : (
    <></>
  );
}

const Container = styled(Box)`
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  background: #eb5757;
  border-radius: 19px;
  padding: 0px 4px;
  color: #fff;
  &.top_position {
    position: absolute;
    top: -5px;
    right: -5px;
  }
`;
