import { StatusCode } from 'constants/API';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import actions from 'app/pages/GlobalContainer/slice/';

function* getHotelNameFlow({ payload }) {
  // const { hotel_id, data } = payload;
  try {
    const response = yield call(APIs.getHotelName, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getHotelNameSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getHotelNameFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getHotelNameFail.type });
  }
}

function* getPlansFlow({ payload }) {
  // const { hotel_id, data } = payload;

  try {
    const { hotel_id, data } = payload;
    const response = yield call(APIs.getPlans, hotel_id, data);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getPlansSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getPlansFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getPlansFail.type });
  }
}

function* getRoomsFlow({ payload }) {
  try {
    const { hotel_id, data } = payload;
    const response = yield call(APIs.getRooms, hotel_id, data);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getRoomsSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getRoomsFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getRoomsFail.type });
  }
}

function* getPlansWithStockFlow({ payload }) {
  // const { hotel_id, data } = payload;

  try {
    const response = yield call(APIs.getPlansWithStock, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getPlansWithStockSuccess,
        payload: {
          id: payload.id,
          data: response.data,
          fromModal: !!payload.fromModal,
        },
      });
    } else {
      yield put({
        type: Slice.getPlansWithStockFail.type,
        payload: { id: payload.id, fromModal: !!payload.fromModal },
      });
    }
  } catch (error) {
    yield put({
      type: Slice.getPlansWithStockFail.type,
      payload: { id: payload.id, fromModal: !!payload.fromModal },
    });
  }
}

function* getRoomsWithStockFlow({ payload }) {
  try {
    const response = yield call(APIs.getRoomsWithStock, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getRoomsWithStockSuccess,
        payload: { id: payload.id, data: response.data },
      });
    } else {
      yield put({
        type: Slice.getRoomsWithStockFail.type,
        payload: { id: payload.id },
      });
    }
  } catch (error) {
    yield put({
      type: Slice.getRoomsWithStockFail.type,
      payload: { id: payload.id },
    });
  }
}

function* getPlanDetailFlow({ payload }) {
  try {
    const response = yield call(APIs.getPlanDetail, payload);
    const { planId } = payload;
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getPlanDetailSuccess,
        payload: { ...response.data, planId },
      });
    } else {
      yield put({ type: Slice.getPlanDetailFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getPlanDetailFail.type });
  }
}

function* getRoomDetailFlow({ payload }) {
  try {
    const response = yield call(APIs.getRoomDetail, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getRoomDetailSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getRoomDetailFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getRoomDetailFail.type });
  }
}

function* planAndRoomWatcher() {
  yield takeLatest(Slice.getPlansRequest, getPlansFlow);
  yield takeLatest(Slice.getRoomsRequest, getRoomsFlow);
  yield takeEvery(Slice.getPlanDetailRequest, getPlanDetailFlow);
  yield takeLatest(Slice.getRoomDetailRequest, getRoomDetailFlow);
  yield takeLatest(Slice.getHotelNameRequest, getHotelNameFlow);
  yield takeEvery(Slice.getRoomsWithStockRequest, getRoomsWithStockFlow);
  yield takeEvery(Slice.getPlansWithStockRequest, getPlansWithStockFlow);
}

export default planAndRoomWatcher;
