import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import { DEFAULT_STORE_HOTEL_ID } from 'constants/common';
import _ from 'lodash';
import queryString from 'query-string';
import { ICartItem, IDataHandleCart } from 'types/common';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  getCartItemsFromStorage,
  getCurrentCheckoutStep,
  getOrderListFromStorage,
  getSearchInfoFromStorage,
  getUserCachedDataFromLocalStorage,
  isShowSidebar,
  removeLoginTime,
  removeToken,
  removeUserCachedDataFromLocalStorage,
  setCartItemsToStorage,
  setCurrentCheckoutStep,
  setIsShowSidebar,
  setIsStayLoggedIn,
  setSearchInfoToStorage,
  setUserCachedDataFromLocalStorage,
} from 'utils/localStorage';
import { useInjectSaga } from 'utils/redux-injectors';

import { removeCartItemsFromStorage } from './../../../../utils/localStorage';
import { changeErrors } from './actions';
import commonSaga from './saga';
import { GlobalState, IBreadcumbs, IUserSessionData } from './types';

const defaultUserInfo = {
  id_token: '',
  account: {
    account_id: 0,
    address: '',
    city: '',
    kana: '',
    name: '',
    prefecture_code: '',
    postal_code: '',
    telephone: '',
    gender: '',
    birthday: '',
    employment_name: '',
    employment_kana: '',
    email: '',
    hotel_id: DEFAULT_STORE_HOTEL_ID,
    old_password: '',
    new_password: '',
  },
  roles: [],
};

export const retrieveUserCachedDataFromStorage = () => {
  let userInfo = getUserCachedDataFromLocalStorage();
  if (!userInfo) {
    userInfo = { ...defaultUserInfo };
  }
  return userInfo;
};

export const initialState: GlobalState = {
  isGlobalLoading: false,
  isShowSidebar: isShowSidebar(),
  /* Messages */
  errorMessages: [],
  successMessages: [],
  infoMessages: [],
  warningMessages: [],
  isStayLoggedIn: false,
  userSessionData: retrieveUserCachedDataFromStorage(),
  breadcumbs: [],
  cart_items: getCartItemsFromStorage() ?? [],
  cart_items_deleted: [],
  order_list: getOrderListFromStorage() ?? [],
  // isLoggedIn: isHavingToken(),
  isLoggedIn: false,
  showLoginModal: false,
  showAdditionInfoModal: false,
  showRegisterModal: false,
  showLoginAfterRegisterModal: { open: false },
  showConfirmOrderModal: false,
  showCardPreview: false,
  isReservation: false,
  isRedirectAfterLogin: true,
  savedCartItem: {},
  currentCheckoutStep: getCurrentCheckoutStep() ?? 1,
  searchInfo: getSearchInfoFromStorage() ?? {},
  errors: null,
  getHotelStockStatus: {},
  hotelStockStatus: null,
  getHotelListStatus: RequestStatus.IDLE,
  hotelList: [],
  getAccountInfoStatus: RequestStatus.IDLE,
  editProfileStatus: RequestStatus.IDLE,
  addItemToCartStatus: RequestStatus.IDLE,
  showOrderStatusModal: false,
  hotelInStockStatus: {},
  outOfStockMess: '',
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    addItemToCartRequest: (state, action: PayloadAction<IDataHandleCart>) => {
      state.addItemToCartStatus = RequestStatus.REQUESTING;
    },
    addItemToCartSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.addItemToCartStatus = RequestStatus.SUCCESS;
        // state.hotelStockStatus = action.payload.calendars;
      }
    },
    addItemToCartFail: (state, action: PayloadAction<any>) => {
      if (action) state.addItemToCartStatus = RequestStatus.ERROR;
    },

    getHotelStockRequest: (state, action: PayloadAction<any>) => {
      state.getHotelStockStatus[action.payload.cart_local_id] =
        RequestStatus.REQUESTING;
      state.hotelInStockStatus[action.payload.cart_local_id] = false;
    },
    getHotelStockSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getHotelStockStatus[action.payload.cart_local_id] =
          RequestStatus.SUCCESS;
        const isInStock = !!action.payload?.stockInfo?.ok;
        state.hotelInStockStatus[action.payload.cart_local_id] = isInStock;
        // state.hotelStockStatus = action.payload.calendars;
      }
    },
    getHotelStockFail: (state, action: PayloadAction<any>) => {
      if (action)
        state.getHotelStockStatus[action.payload.cart_local_id] =
          RequestStatus.ERROR;
      state.hotelInStockStatus[action.payload.cart_local_id] = false;

      const { stockInfo } = action.payload;
      const { ok, message } = stockInfo || {};
      const isInStock = !!ok;
      if (!isInStock && message) {
        state.outOfStockMess = message;
      }
    },
    resetCheckHotelStockStatus: state => {
      state.getHotelStockStatus = {};
      state.hotelInStockStatus = {};
      // state.outOfStockMess = '';
      // state.checkInventoryStatus = RequestStatus.IDLE;
    },

    setIsGlobalLoading: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action.payload === 'boolean') {
        state.isGlobalLoading = action.payload;
      } else {
        state.isGlobalLoading = !state.isGlobalLoading;
      }
    },
    setSuccessMessages: (
      state,
      action: PayloadAction<Array<string | undefined | React.ReactNode>>,
    ) => {
      state.successMessages = action.payload;
    },
    setInfoMessages: (
      state,
      action: PayloadAction<Array<string | undefined | React.ReactNode>>,
    ) => {
      state.infoMessages = action.payload;
    },
    setWarningMessages: (
      state,
      action: PayloadAction<Array<string | undefined | React.ReactNode>>,
    ) => {
      state.warningMessages = action.payload;
    },
    setErrorMessages: (
      state,
      action: PayloadAction<Array<string | undefined | React.ReactNode>>,
    ) => {
      state.errorMessages = action.payload;
    },
    setIsStayLoggedIn: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.isStayLoggedIn = false;
      } else {
        state.isStayLoggedIn = action.payload;
      }
      setIsStayLoggedIn(state.isStayLoggedIn);
    },
    setUserSessionData: (state, action: PayloadAction<IUserSessionData>) => {
      state.userSessionData = action.payload;
      setUserCachedDataFromLocalStorage(action.payload);
    },

    login: state => {
      state.isLoggedIn = true;
    },
    logout: state => {
      state.isLoggedIn = false;
      removeToken();
      removeLoginTime();
      removeUserCachedDataFromLocalStorage();
      state.userSessionData = defaultUserInfo;
    },
    setBreadcumbs: (state, action: PayloadAction<IBreadcumbs[]>) => {
      state.breadcumbs = action.payload;
    },
    setIsShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.isShowSidebar = action.payload;
      setIsShowSidebar(action.payload);
    },
    addItemToCart: (state, action: PayloadAction<any>) => {
      let params = queryString.parse(window.location.search);
      const replaced_cart_item_key = params?.cart_item_key;
      let oldItems = [...state.cart_items];
      let newItems = { ...action.payload };
      let cartItems: ICartItem[] = [];
      // replace old cart
      if (replaced_cart_item_key) {
        cartItems = _.map(oldItems, (item, key) => {
          // replace old cart item = new cart item
          if (key === +replaced_cart_item_key) {
            return { ...newItems };
          } else {
            return { ...item };
          }
        });
      } else {
        // add 1 item to cart
        cartItems = [...oldItems, { ...newItems }];
      }
      state.cart_items = cartItems;
      setCartItemsToStorage(cartItems);
    },
    resetCart: (state, action: PayloadAction) => {
      state.cart_items_deleted = [...state.cart_items];
      state.cart_items = [];
      removeCartItemsFromStorage();
    },
    // delete all cart items deleted after go out from order success page
    resetDeletedCartItems: (state, action: PayloadAction) => {
      state.cart_items_deleted = [];
    },
    removeItemToCart: (
      state,
      action: PayloadAction<{ key: number; local_id: string }>,
    ) => {
      const { key, local_id } = action.payload;
      const newItems = _.filter(state.cart_items, (it, k) => {
        // remove cart item if has local_id (with new cart item)
        if (local_id) {
          if (it?.local_id !== local_id) {
            return it;
          }
        } else {
          // remove cart item using key if has not property local_id (with old cart item)
          if (k !== key) {
            return it;
          }
        }
      });
      state.cart_items = newItems;
      setCartItemsToStorage(newItems);
    },
    changeCartItemInfo: (
      state,
      action: PayloadAction<{ newCartItems: any }>,
    ) => {
      const { newCartItems } = action.payload;

      state.cart_items = newCartItems;
      setCartItemsToStorage(newCartItems);
    },
    updateSearchInfo: (state, action: PayloadAction<any>) => {
      state.searchInfo = action.payload;
      setSearchInfoToStorage(action.payload);
    },
    changeShowLoginModalStatus: (state, action: PayloadAction<boolean>) => {
      state.showLoginModal = action.payload;
    },
    changeShowOrderStatusModal: (state, action: PayloadAction<boolean>) => {
      state.showOrderStatusModal = action.payload;
    },
    changeShowAdditionInfoModalStatus: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showAdditionInfoModal = action.payload;
    },
    changeShowRegisterModalStatus: (state, action: PayloadAction<boolean>) => {
      state.showRegisterModal = action.payload;
    },
    changeSavedCartItem: (state, action: PayloadAction<any>) => {
      state.savedCartItem = action.payload;
    },
    changeShowLoginAfterRegisterModalStatus: (
      state,
      action: PayloadAction<{ open: boolean; email?: string }>,
    ) => {
      state.showLoginAfterRegisterModal = action.payload;
    },
    changeCurrentCheckoutStep: (state, action: PayloadAction<number>) => {
      state.currentCheckoutStep = action.payload;
      setCurrentCheckoutStep(action.payload);
    },
    changeShowCardPreviewStatus: (state, action: PayloadAction<boolean>) => {
      state.showCardPreview = action.payload;
    },
    changeConfirmOrderModalStatus: (state, action: PayloadAction<boolean>) => {
      state.showConfirmOrderModal = action.payload;
    },
    changeReservationStatus: (state, action: PayloadAction<boolean>) => {
      state.isReservation = action.payload;
    },
    changeIsRedirectAfterLogin: (state, action: PayloadAction<boolean>) => {
      state.isRedirectAfterLogin = action.payload;
    },
    editProfile: (state, action: PayloadAction<any>) => {
      state.editProfileStatus = RequestStatus.REQUESTING;
    },
    editProfileSuccess: (state, action: PayloadAction<any>) => {
      state.editProfileStatus = RequestStatus.SUCCESS;
      if (action) {
        state.userSessionData.account = {
          ...state.userSessionData.account,
          ...action.payload.account,
        };
        state.successMessages = ['正常に更新されました'];
      }
    },
    editProfileFail: (state, action: PayloadAction<any>) => {
      state.editProfileStatus = RequestStatus.ERROR;
    },
    getHotelListRequest: (state, action: PayloadAction<any>) => {
      state.getHotelListStatus = RequestStatus.REQUESTING;
    },
    getHotelListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getHotelListStatus = RequestStatus.SUCCESS;
        state.hotelList = action.payload;
      }
    },
    getHotelListFail: (state, action: PayloadAction<any>) => {
      if (action) state.getHotelListStatus = RequestStatus.ERROR;
    },
    getAccountInfoRequest: (state, action: PayloadAction<any>) => {
      state.getAccountInfoStatus = RequestStatus.REQUESTING;
    },
    getAccountInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getAccountInfoStatus = RequestStatus.SUCCESS;
        state.userSessionData.account = action.payload;
      }
    },
    getAccountInfoFail: (state, action: PayloadAction<any>) => {
      if (action) state.getAccountInfoStatus = RequestStatus.ERROR;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(changeErrors, (state, action) => {
        return { ...state, errors: action.payload || null };
      })
      // and provide a default case if no other handlers matched
      .addDefaultCase((state, action) => {});
  },
});

export default slice.actions;

export const useGlobalSlice = () => {
  // useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: commonSaga });
  return { actions: slice.actions };
};

export const globalSlice = () => {
  return slice;
};
