import { ReactComponent as AccountCircle } from 'assets/icons/header/account_circle.svg';
import { ReactComponent as Brightness } from 'assets/icons/header/brightness.svg';
import { ReactComponent as Calendar } from 'assets/icons/header/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/header/close.svg';
import { ReactComponent as HamburgerIcon } from 'assets/icons/header/hamburger.svg';
import { ReactComponent as History } from 'assets/icons/header/history.svg';
import { ReactComponent as ListHotels } from 'assets/icons/header/list_hotels.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/header/phone.svg';
import { ReactComponent as CartBlack } from 'assets/icons/header/shopping_cart_black.svg';
import { ReactComponent as HotelSearch } from 'assets/icons/header/reservation.svg';
import { ReactComponent as OrderHistory } from 'assets/icons/header/order_history.svg';
import { ReactComponent as Logo } from 'assets/img/logo.svg';

import Path from 'config/clientPath';
import { ROUTES_INFO } from './common';

export const USER_LOGGED_IN_MENU_LIST = [
  {
    path: '',
    menuName: 'プラン一覧',
    icon: <Calendar />,
  },
  {
    path: Path.SPECIAL_OFFER,
    menuName: '特別会員オファー',
    icon: <Brightness />,
  },
  // {
  //   path: Path.HOTEL_HISTORY,
  //   menuName: '泊まったことのある宿',
  //   icon: <History />,
  // },
  {
    path: Path.PROFILE,
    menuName: '会員情報',
    icon: <AccountCircle />,
  },
];

export const GUEST_MENU_LIST = [
  {
    path: Path.SEARCH,
    menuName: 'ホテル詳細',
    icon: <ListHotels />,
  },
  {
    path: Path.CART,
    menuName: 'カート',
    icon: <CartBlack />,
    className: 'icons_no_login',
  },
];

export const AUTH_MENU_LIST = [
  {
    path: Path.ACCOUNT_REGISTRATION,
    menuName: '会員登録',
  },
  {
    path: Path.LOGIN,
    menuName: 'ログイン',
  },
];

export const MENU_LIST_LOGGED_IN = [
  {
    path: Path.SEARCH,
    menuName: 'ホテル一覧',
    icon: <HotelSearch />,
    isHotelList: true,
  },
  {
    path: '',
    menuName: 'ホテル詳細',
    icon: <ListHotels />,
  },
  {
    path: '',
    menuName: 'プラン一覧',
    icon: <Calendar />,
  },
  {
    path: Path.ORDER_HISTORY,
    menuName: '予約情報',
    icon: <OrderHistory />,
  },
  // {
  //   path: Path.HOTEL_HISTORY,
  //   menuName: '泊まったことのある宿',
  //   icon: <History />,
  // },
  {
    path: Path.PROFILE,
    menuName: '会員情報',
    icon: <AccountCircle />,
  },
  {
    path: Path.CART,
    menuName: 'カート',
    icon: <CartBlack />,
  },
];

export const MENU_LIST_LOGGED_IN_MOBILE = [
  {
    path: Path.CART,
    menuName: 'カート',
    icon: <CartBlack />,
  },
  {
    path: '',
    menuName: 'プラン一覧',
    icon: <Calendar />,
  },
  {
    path: '',
    menuName: 'ホテル詳細',
    icon: <ListHotels />,
  },
  {
    path: Path.SEARCH,
    menuName: 'ホテル一覧',
    icon: <HotelSearch />,
    isHotelList: true,
  },
  {
    path: Path.PROFILE,
    menuName: '会員情報',
    icon: <AccountCircle />,
  },
];

export const MENU_LIST_GUEST = [
  {
    path: Path.SEARCH,
    menuName: 'ホテル一覧',
    icon: <HotelSearch />,
    isHotelList: true,
  },
  {
    path: '',
    menuName: 'ホテル詳細',
    icon: <ListHotels />,
  },
  {
    path: Path.CART,
    menuName: 'カート',
    icon: <CartBlack />,
  },
];

export const MENU_LIST_GUEST_MOBILE = [
  {
    path: Path.CART,
    menuName: 'カート',
    icon: <CartBlack />,
  },
  {
    path: '',
    menuName: 'ホテル詳細',
    icon: <ListHotels />,
  },
  {
    path: Path.SEARCH,
    menuName: 'ホテル一覧',
    icon: <HotelSearch />,
    isHotelList: true,
  },
];
