import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import _ from 'lodash';

function* registerFlow({ payload }) {
  let errorMess = ['Oops..Register failed!'];
  try {
    const response = yield call(APIs.register, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.registerSuccess,
        payload: {},
      });
    } else {
      const { data, status } = response;

      if (
        status === StatusCode.BAD_REQUEST &&
        !_.isEmpty(data?.errors) &&
        data?.errors[0]?.detail === 'value_error.email.duplication'
      ) {
        errorMess = [
          '無効なメールアドレス、もしくは既に登録されているメールアドレスです。',
        ];
      }
      yield put({ type: Slice.registerFail.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: Slice.registerFail.type, payload: { errorMess } });
  }
}

function* registerWatcher() {
  yield takeLatest(Slice.registerRequest, registerFlow);
}

export default registerWatcher;
