import { Typography } from '@mui/material';
import * as React from 'react';

export interface ITagProps {
  isCheckin?: boolean;
  setDragging?: Function;
}

export default function Tag(props: ITagProps) {
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: ItemTypes.BOX,
  //   item: { isCheckin: props.isCheckin },
  //   end: (item, monitor) => {
  //     const dropResult = monitor.getDropResult<DropResult>();
  //     if (item && dropResult) {
  //       /** handle logic over here */
  //     }
  //   },
  //   collect: monitor => ({
  //     isDragging: monitor.isDragging(),
  //     handlerId: monitor.getHandlerId(),
  //   }),
  // }));

  // React.useEffect(() => {
  //   if (props.setDragging) {
  //     props.setDragging(isDragging);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isDragging]);
  return (
    <>
      <Typography
        component={`span`}
        fontSize={`6px`}
        color={`#fff`}
        textAlign={`center`}
        lineHeight={`12px`}
        sx={{
          padding: '1px 2px',
          background: '#2f80ed',
          position: 'absolute',
          top: '-18px',
          whiteSpace: 'nowrap',
          fontWeight: 700,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '2px',
          // cursor: 'move',
          zIndex: 20,
        }}
      >
        {props.isCheckin ? 'チェックイン' : 'チェックアウト'}
      </Typography>
      {/* <Box
        ref={drag}
        sx={{
          padding: '1px 2px',
          position: 'absolute',
          top: '-18px',
          left: 0,
          right: 0,
          bottom: 0,
          whiteSpace: 'nowrap',
          fontWeight: 700,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'move',
          zIndex: 22,
        }}
      ></Box> */}
    </>
  );
}
