import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import actions from 'app/pages/GlobalContainer/slice/';
import actionsCheckoutPage, {
  retrieveCustomerInfoFromStorage,
} from 'app/pages/CheckoutPage/slice/';
import _ from 'lodash';
import { initCustomerInfo } from 'constants/common';

function* loginFlow({ payload }) {
  let errorMess = ['Oops..Login failed!'];
  try {
    const response = yield call(APIs.login, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      let sessionData = response.data;

      yield put({
        type: actions.setUserSessionData.type,
        payload: _.omit(sessionData, ['id_token']),
      });
      const customerInfo = retrieveCustomerInfoFromStorage();

      // hold customer info old with user info
      yield put({
        type: actionsCheckoutPage.changeCustomerInfo.type,
        payload: {
          ...customerInfo.guest,
          ...sessionData?.account,
        },
      });
      yield put({
        type: Slice.loginSuccess,
        payload: { access: response.data.id_token },
      });
    } else {
      const { data, status } = response;
      if (status === StatusCode.BAD_REQUEST && !data?.ok) {
        errorMess = [
          '入力情報に誤りがあります。再度正しいメールアドレス、もしくはパスワードをご入力ください。',
        ];
      }
      yield put({ type: Slice.loginFail.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: Slice.loginFail.type, payload: { errorMess } });
  }
}

function* loginWatcher() {
  yield takeLatest(Slice.loginRequest, loginFlow);
}

export default loginWatcher;
