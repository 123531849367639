import { Box, CardContent, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CommonButton } from 'app/components/CommonButton';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useCheckoutSlice } from '../CheckoutPage/slice';
import { selectPlanAndRoom } from './slice/selectors';
import { usePlanAndRoomSlice } from './slice';

const usePlanAndRoom = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    plans,
    active_plan,
    getPlansStatus,
    getRoomsStatus,
    getPlanDetailStatus,
    getRoomDetailStatus,
    detail_plan,
    getPlanForOrderStatus,
    planForOrder,
    rooms,
  } = useSelector(selectPlanAndRoom);
  const { actions } = usePlanAndRoomSlice();

  const getPlanDetailRequest = (hotel_id, plan_id, planIdForOrder = '') => {
    dispatch(
      actions.getPlanDetailRequest({
        hotel_id,
        plan_id,
        planId: planIdForOrder,
      }),
    );
  };

  return {
    getPlanDetailRequest,
    getPlanDetailStatus,
    detail_plan,
    getPlanForOrderStatus,
    planForOrder,
    plans,
    rooms,
  };
};

export default usePlanAndRoom;
