// UI
import { Typography } from '@mui/material';
import { colors } from 'constants/styles';
import styled from 'styled-components';

interface IAccommodationInfo {}

const AccommodationInfo = (props: IAccommodationInfo) => {
  return <Container>宿泊情報</Container>;
};

export default AccommodationInfo;

const Container = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: ${colors.heading};
  }
`;
