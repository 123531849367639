import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { colors, fontsizes } from 'constants/styles';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { commonFormat } from 'utils/moment';
import { AREAS } from '../constants';
import _ from 'lodash';

interface Props {
  area?: string;
  checkout_date?: string;
  checkin_date?: string | null;
  guest_count?: number | number;
  openSearch: () => void;
}

export const SearchMenu = ({ guest_count = 2, ...props }: Props) => {
  const { area, checkout_date, checkin_date, openSearch } = props;
  return (
    <BoxItem
      sx={{
        padding: '1rem',
        boxShadow: '0px 0px 1px 0px #00000040',
        textAlign: 'center',
        margin: '0 auto 2rem',
        maxWidth: '478px',
        borderRadius: '8px',
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'center',
          flexWrap: 'wrap',
          textAlign: 'left',
        }}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Box sx={{ marginBottom: { md: 0, xs: 1 }, order: { md: 1, xs: 1 } }}>
          <Typography
            component="span"
            mb={0.5}
            sx={{
              display: 'block',
              color: colors.grey,
              fontSize: { md: fontsizes.smallText },
            }}
          >
            目的地
          </Typography>
          <Typography
            component="span"
            sx={{
              display: 'block',
              fontSize: { md: fontsizes.main, xs: fontsizes.smallText },
            }}
          >
            {_.get(
              _.find(AREAS, {
                id:
                  area === '1,2,3,4,5'
                    ? area
                    : _.toInteger(area?.split(',')[0]),
              }),
              'name',
            )}
          </Typography>
        </Box>

        <Box sx={{ marginBottom: { md: 0, xs: 1 }, order: { md: 3, xs: 5 } }}>
          <Typography
            component="span"
            mb={0.5}
            sx={{
              display: 'block',
              color: colors.grey,
              fontSize: { md: fontsizes.smallText },
            }}
          >
            チェックイン
          </Typography>
          <Typography
            component="span"
            sx={{
              display: 'block',
              fontSize: { md: fontsizes.main, xs: fontsizes.smallText },
            }}
          >
            {checkin_date ?? '未選択'}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: { md: 0, xs: 1 }, order: { md: 5, xs: 7 } }}>
          <Typography
            component="span"
            mb={0.5}
            sx={{
              display: 'block',
              color: colors.grey,
              fontSize: { md: fontsizes.smallText },
            }}
          >
            チェックアウト
          </Typography>
          <Typography
            component="span"
            sx={{
              display: 'block',
              fontSize: { md: fontsizes.main, xs: fontsizes.smallText },
            }}
          >
            {checkout_date ?? '未選択'}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: { md: 0, xs: 1 }, order: { md: 7, xs: 3 } }}>
          <Typography
            component="span"
            mb={0.5}
            sx={{
              display: 'block',
              color: colors.grey,
              fontSize: { md: fontsizes.smallText },
            }}
          >
            人数
          </Typography>
          <Typography
            component="span"
            sx={{
              display: 'block',
              fontSize: { md: fontsizes.main, xs: fontsizes.smallText },
            }}
          >
            {`${guest_count}人`}
          </Typography>
        </Box>
      </Stack>
      <Button
        onClick={openSearch}
        variant="contained"
        sx={{
          background: colors.blue,
          color: colors.white,
          fontSize: fontsizes.main,
          padding: 1,
          margin: {
            xs: '1rem 0 0',
          },
          width: {
            md: '100%',
            xs: '256px',
          },
          maxWidth: { md: '320px' },
        }}
      >
        検索条件を変更する
      </Button>
    </BoxItem>
  );
};

const BoxItem = styled(Card)`
  hr {
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(4) {
      order: 4;
    }
    &:nth-child(6) {
      order: 6;
    }
    &:nth-child(8) {
      order: 8;
    }
  }
  ${dMedia.tablet`
    hr {
      height: 40px;
    }
`}
`;
