// UI
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from 'constants/styles';

interface ILabelItem {
  label?: string;
  textRequire?: string;
  required?: boolean;
}

const LabelItem = ({
  label,
  required,
  textRequire = '必須',
  ...props
}: ILabelItem) => {
  return (
    <Container direction={'row'} spacing={1}>
      <Label>{label}</Label>
      {required && <TextRequired>{textRequire}</TextRequired>}
    </Container>
  );
};

export default LabelItem;

const Container = styled(Stack)``;
const Label = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
    display: flex;
  }
`;

const TextRequired = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #f2994a;
  }
`;
