import { Box, Button, Typography } from '@mui/material';
import Path from 'config/clientPath';
import { colors } from 'constants/styles';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router';

export function HomePage() {
  const history = useHistory();
  React.useEffect(() => {
    history.push(Path.SEARCH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>VMG Hotel</title>
        <meta name="description" content="VMG Hotel" />
      </Helmet>
      <Box
        display="flex"
        justifyContent={'flex-end'}
        style={{ padding: '16px 40px 0 0' }}
      >
        <CustomButton
          onClick={() => history.push(Path.SEARCH)}
          variant="contained"
          color="inherit"
        >
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '20px',
              color: colors.grey1,
              textDecoration: 'none',
            }}
          >
            空室検索
          </Typography>
        </CustomButton>
      </Box>
    </>
  );
}

const CustomButton = styled(Button)`
  && {
    border-radius: 4px;
    outline: none !important;
    background: white !important;
    box-shadow: none !important;
    border: 1px solid #babfc3;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    width: 234px;
    .MuiTouchRipple-root {
      display: none;
    }
  }
`;
