export const PRICE_RANGES = [
  {
    id: 1,
    value: '0,19999',
    name: '~ ￥19,999',
  },
  {
    id: 2,
    value: '20000,29999',
    name: '￥20,000 ~ ￥29,999',
  },
  {
    id: 3,
    value: '30000,39999',
    name: '￥30,000 ~ ￥39,999',
  },
  {
    id: 4,
    value: '40000,49999',
    name: '￥40,000 ~ ￥49,999',
  },
  {
    id: 5,
    value: '50000',
    name: '￥50,000 ~',
  },
];
export const AREAS = [
  {
    id: 1,
    name: '北海道',
  },
  {
    id: 2,
    name: '関東',
  },
  {
    id: 3,
    name: '関西',
  },
  {
    id: 4,
    name: '中国・四国',
  },
  {
    id: 5,
    name: '九州',
  },
  {
    id: '1,2,3,4,5',
    name: '全て',
  },
];

export const FEATURES = [
  {
    id: 1,
    name: '分散型ホテル',
  },
  {
    id: 2,
    name: '愛犬と泊まれる',
  },
  {
    id: 3,
    name: '一等貸しができる',
  },
  {
    id: 4,
    name: 'ウェディングのできるホテル',
  },
  {
    id: 5,
    name: '文化財',
  },
  {
    id: 6,
    name: '食事：ルームサービスあり',
  },
];
