import { BoxHeader } from 'app/components/BoxHeader';
import ImageGallery from 'app/components/ImageGallery';
import ImageSlider from 'app/components/ImageSlider';
import { Box } from '@mui/material';

interface IProps {
  galleryData: {
    full_image: string;
    image: string;
    view_order: number;
  }[];
}

export default function Gallery(props: IProps) {
  return (
    <Box
      sx={{
        marginTop: '50px',
      }}
    >
      <BoxHeader fontSize={18}>ギャラリー</BoxHeader>
      <Box
        sx={{
          margin: '40px 0',
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <ImageGallery
          images={props.galleryData.map(item => {
            return { src: item.image, imgFull: item.full_image };
          })}
          showFullGallery
        ></ImageGallery>
      </Box>
      <Box
        sx={{
          margin: '40px 0',
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        <ImageSlider
          images={props.galleryData.map(item => {
            return { src: item.full_image };
          })}
        ></ImageSlider>
      </Box>
    </Box>
  );
}
