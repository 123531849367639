import APIs from 'APIs';
import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import Slice from '.';

function* getHotelHistoryFlow(action) {
  try {
    const response = yield call(APIs.getHotelHistory, action.payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getHotelHistorySuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getHotelHistoryFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getHotelHistoryFail });
  }
}

function* hotelHistoryWatcher() {
  yield takeLatest(Slice.getHotelHistory, getHotelHistoryFlow);
}

export default hotelHistoryWatcher;
