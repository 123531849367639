import _ from 'lodash';
import moment from 'moment';

export const getCalendar = (
  month: moment.Moment,
  displayDateOfWeeks: string[],
): string[][] => {
  if (!month.isValid()) {
    return [];
  }

  const CALENDAR: any = [_.clone(displayDateOfWeeks)];

  new Array(month.daysInMonth()).fill(null).forEach((x, i) => {
    const item = CALENDAR[CALENDAR.length - 1];

    const d = month.startOf('month').add(i, 'days');
    const dayOfWeek = d.format('ddd');
    const isLastDayOfWeek = dayOfWeek === _.last(displayDateOfWeeks);
    item[_.indexOf(item, dayOfWeek)] = d.format('YYYY-MM-DD');

    if (isLastDayOfWeek) {
      CALENDAR.push(_.clone(displayDateOfWeeks));
    }
  });

  return CALENDAR.map(week => {
    return week.map(day => {
      if (day.length === 1) return '';
      return day;
    });
  });
};
