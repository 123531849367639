import { Stack, CardMedia, Typography, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import Path from 'config/clientPath';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'constants/styles';
import { useHistory } from 'react-router';
import { setRecentlyHotel } from 'utils/localStorage';
// import { LanguageSwitch } from 'app/components/LanguageSwitch';

interface IHotelCard {
  data: any;
  onClose: () => void;
}

const HotelCard = ({ data, ...props }: IHotelCard) => {
  const history = useHistory();
  return (
    <Stack
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        '&:hover': {
          backgroundColor: 'whitesmoke',
        },
        padding: '5px 0',
      }}
      onClick={e => {
        e.stopPropagation();
        props.onClose();
        setRecentlyHotel(data.slug);
        window.location.href = `${Path.RESERVATION_PREFIX}/${data.slug}/`;
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '140px',
          minWidth: '140px',
          height: '100px',
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: {
              md: 140,
            },
          }}
          image={data.image}
          alt={data.name}
        />
      </Box>

      <CardWrapper>
        <CardContent
          sx={{
            flex: '1 0 auto',
            paddingTop: 0,
          }}
        >
          <Typography
            component="h5"
            sx={{
              fontSize: '12px',
              color: colors.grey3,
              marginBottom: '5px',
              mt: 0,
            }}
          >
            {data.prefecture}
          </Typography>
          <Typography
            variant="h4"
            component="h4"
            sx={{
              fontSize: {
                md: '15px',
                xs: '14px',
              },
              marginBottom: '8px',
              fontWeight: 700,
            }}
          >
            {data.name}
          </Typography>
          <Description sx={{ fontSize: '12px' }}>{data.title}</Description>
        </CardContent>
      </CardWrapper>
    </Stack>
  );
};

export default HotelCard;

const Description = styled(Typography)`
  width: 100%;
  height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const CardWrapper = styled(Box)`
  .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
`;
