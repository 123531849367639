// UI
import { Box, Grid } from '@mui/material';
import { IImage } from 'constants/commonTypes';
import styled, { css } from 'styled-components';
import SingleImage from '../SingleImage';
import { ReactComponent as AreaIcon } from 'assets/icons/area.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { MoneyItem } from '../MoneyItem';
import { dMedia } from 'styles/media';
import { Check } from '@mui/icons-material';
import { getRoomOptions } from 'utils/helper';
import { IRoomItem } from 'types/common';

interface IRoomInfo {
  data: IRoomItem | null;
  // change display on sp or not
  isChangedDisplaySP?: boolean;
}

export interface IRoomOptionsInfo {
  text: string;
  isDisplayed: boolean;
  icon?: any;
}

const RoomInfo = ({
  // data: {
  //   personNumber = '1~4名',
  //   area = '45~50㎡',
  //   detailInfo = '一棟貸し、檜風呂',
  //   options: {
  //     bath = false,
  //     premium_room = false,
  //     private_open_air_bath = false,
  //   },
  // },
  // data: { options },
  data,
  isChangedDisplaySP = true,
}: IRoomInfo) => {
  const { tags } = data || {};
  // const { bath, premium_room, private_open_air_bath } = options || {};
  // const displayedOptions = getRoomOptions({ options: options || {} });

  const renderOptionsData = (v, k) => {
    return (
      <Persons key={k}>
        <span>{v.name}</span>
      </Persons>
    );
  };

  return tags && !!tags.length ? (
    <Container isChangedDisplaySP={isChangedDisplaySP}>
      {/* <Area>
        <AreaIcon />
        <span>{area}</span>
      </Area>
      <Persons>
        <PersonIcon />
        <span>{personNumber}</span>
      </Persons>
      <Detail>
        <span>{detailInfo}</span>
      </Detail> */}
      {tags.map((item, k) => renderOptionsData(item, k))}
    </Container>
  ) : (
    <></>
  );
};

RoomInfo.defaultProps = {
  data: {
    personNumber: '1~4名',
    area: '45~50㎡',
    detailInfo: '一棟貸し、檜風呂',
    options: {
      bath: false,
      premium_room: false,
      private_open_air_bath: false,
    },
  },
};
export default RoomInfo;

const Container = styled(Box)<{
  isChangedDisplaySP: Boolean;
}>`
  display: flex;
  background: #f2f2f2;
  border-radius: 37px;
  padding: 4px 16px;
  color: #4f4f4f;
  display: flex;
  align-items: center;
  width: fit-content;
  svg {
    margin-right: 4px;
    font-size: 17px;
  }
  /* div:not(:first-child) {
    margin-left: 16px;
  } */
  gap: 16px;
  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    padding-top: 1px;
  }
  ${dMedia.tablet`
    ${props =>
      props.isChangedDisplaySP &&
      css`
        height: 100%;
        border-radius: 4px;
        flex-direction: column;
        align-items: flex-start;
        padding: 6.5px 16px;
        div:not(:first-child) {
          margin-left: 0px;
        }
      `}
  `}
  ${dMedia.tablet`
    gap: 8px;
  `}
`;

const Area = styled(Box)`
  display: flex;
  align-items: center;
`;

const Persons = styled(Box)`
  line-height: 17px;
  /* height: 17px; */
  display: flex;
  align-items: center;
  ${dMedia.tablet`
    align-items: flex-start;
  `}
`;

const Detail = styled(Box)``;
