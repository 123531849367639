import _ from 'lodash';

export const GTM = [
  // {
  //   name: 'ＶＭＧ',
  //   hotelId: '',
  //   tagId: 'GTM-56WRBJJ',
  //   slug: '',
  // },
  {
    name: 'NIPPONIA HOTEL 函館 港町',
    hotelId: 1,
    tagId: 'GTM-NPBB5Q9',
    slug: 'hakodate',
  },
  {
    name: '佐原商家町ホテル NIPPONIA',
    hotelId: 2,
    tagId: 'GTM-WBJ9KF5',
    slug: 'sawara',
  },
  {
    name: 'NIPPONIA HOTEL 伊賀上野 城下町',
    hotelId: 3,
    tagId: 'GTM-MMJBLRC',
    slug: 'ozu',
  },
  {
    name: 'NIPPONIA HOTEL 奈良 ならまち',
    hotelId: 4,
    tagId: 'GTM-PV2HBV8',
    slug: 'naramachi',
  },
  {
    name: 'HOTEL VMG RESORT KYOTO',
    hotelId: 5,
    tagId: 'GTM-MDCTQZH',
    slug: 'kyoto',
  },
  {
    name: '篠山城下町ホテル NIPPONIA',
    hotelId: 6,
    tagId: 'GTM-N744HCN',
    slug: 'sasayama',
  },
  {
    name: '福住宿場町ホテル NIPPONIA',
    hotelId: 7,
    tagId: 'GTM-5PW4MTQ',
    slug: 'fukusumi',
  },
  {
    name: '竹田城 城下町 ホテルEN',
    hotelId: 8,
    tagId: 'GTM-PSLMMKP',
    slug: 'takedacastle',
  },
  {
    name: 'オーベルジュ豊岡1925',
    hotelId: 9,
    tagId: 'GTM-TQK7CRS',
    slug: 'toyooka',
  },
  {
    name: 'NIPPONIA HOTEL 竹原 製塩町',
    hotelId: 10,
    tagId: 'GTM-KT4MJGL',
    slug: 'takehara',
  },
  {
    name: 'NIPPONIA HOTEL 大洲 城下町',
    hotelId: 11,
    tagId: 'GTM-PCXXJKF',
    slug: 'igaueno',
  },
  {
    name: 'HOTEL CULTIA 太宰府',
    hotelId: 12,
    tagId: 'GTM-5BRWJ9C',
    slug: 'dazaifu',
  },
  {
    name: 'NIPPONIA HOTEL 八女福島 商家町',
    hotelId: 13,
    tagId: 'GTM-P638BDV',
    slug: 'yamefukushima',
  },
];

export const GTM_DEFAULT = 'GTM-56WRBJJ';
export const GTM_SEARCH_DEFAULT = 'GTM-NH59KDM';

export const HOTEL_SLUGS = _.map(GTM, 'slug');
