enum Path {
  HOME = '/',
  CUSTOMERS = '/customers/',
  LOGIN = '/reservation/login/',
  RESET_PASSWORD = '/reservation/lost-password/',
  CHANGE_PASSWORD = '/change-password/',
  COMPONENTS = '/components',
  SEARCH = '/reservation/',
  HISTORY = '/reservation/history/',
  HOTEL_DETAILS = '/reservation/:slug/',
  HOTELS = '/hotels',
  ROOMS = '/rooms',
  PLANS = '/plans',
  ROOMS_LIST = '/reservation/:slug/rooms/',
  PLANS_LIST = '/reservation/:slug/plans/',
  CART = '/reservation/cart/',
  SPECIAL_OFFER = '/reservation/special-offer/',
  MAP = '/reservation/map/',
  PROFILE = '/reservation/account/',
  EDIT_PROFILE = '/edit-profile/',
  PAYMENT = '/reservation/payment/',
  SUCCESS = '/reservation/thanks/',
  CONFIRM = '/reservation/confirm/',
  ORDER_HISTORY = '/reservation/order-history/',
  SYSTEM_ERROR = '/reservation/error/',
  PRIVACY_POLICY = '/policy/',
  SPECIFIED_COMMERCIAL = '/specified-commercial/',
  MAINTENANCE = '/reservation/maintenance/',
  ORDER_INFO = '/reservation/contents/',
  HOTEL_HISTORY = '/reservation/history/',
  LOGOUT = '/logout/',
  REGISTRATION_INFO = '/reservation/registration/',
  ACCOUNT_REGISTRATION = 'hotels/registration/',
  CANCEL_ORDER = '/cancel-order/',
  RESERVATION_PREFIX = '/reservation',
  RESERVATION = '/reservation/',
  PLANS_MODAL = '/reservation/:slug/plans/:planID/',
  ROOM_MODAL = '/reservation/:slug/rooms/:roomID/',
  REGISTER_MEMBERSHIP = '/membership/regist/',
  CANCEL_ORDER_DETAIL_PREFIX = '/reservation/cancel/',
  CANCEL_ORDER_DETAIL = '/reservation/cancel/:order_id',
  NO_NEED_LOGIN_CANCEL_ORDER_DETAIL = '/reservation/cancel/:hotel_id/:order_id',
  NOT_FOUND_PAGE = '/404',
}

export default Path;
