import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import metaDataSaga from './saga';
import { CalendarState } from './types';

export const initialState: CalendarState = {
  metaDataStatus: RequestStatus.IDLE,
  stockDataStatus: RequestStatus.IDLE,
  calendarDataStatus: RequestStatus.IDLE,
  errorMess: [],
  metaData: {},
  stockData: null,
  calendarData: [],
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getMetaDataRequest: (state, action: PayloadAction<any>) => {
      if (action) state.metaDataStatus = RequestStatus.REQUESTING;
    },
    getMetaDataSuccess: (state, action: PayloadAction<any>) => {
      state.metaDataStatus = RequestStatus.SUCCESS;
      state.metaData[action.payload.range] = action.payload.data;
    },
    getMetaDataFail: (state, action: PayloadAction<any>) => {
      if (action) state.metaDataStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    getStockDataRequest: (state, action: PayloadAction<any>) => {
      if (action) state.stockDataStatus = RequestStatus.REQUESTING;
    },
    getStockDataSuccess: (state, action: PayloadAction<any>) => {
      state.stockDataStatus = RequestStatus.SUCCESS;
      state.stockData = action.payload;
    },
    getStockDataFail: (state, action: PayloadAction<any>) => {
      if (action) state.stockDataStatus = RequestStatus.ERROR;
    },
    resetgetMetaDataStatus: state => {
      state.errorMess = [];
      state.metaData = {};
      state.metaDataStatus = RequestStatus.IDLE;
    },
    getCalendarDataRequest: (state, action: PayloadAction<any>) => {
      if (action) state.calendarDataStatus = RequestStatus.REQUESTING;
    },
    getCalendarDataSuccess: (state, action: PayloadAction<any>) => {
      state.calendarDataStatus = RequestStatus.SUCCESS;
      state.calendarData = action.payload;
    },
    getCalendarDataFail: (state, action: PayloadAction<any>) => {
      if (action) state.calendarDataStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    resetCalendarData: (state, action: PayloadAction<any>) => {
      state.calendarDataStatus = RequestStatus.IDLE;
      state.calendarData = [];
    },
  },
});

export default slice.actions;

export const useCalendarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: metaDataSaga });
  return { actions: slice.actions };
};
