// UI
import { Button } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as AddToCartIcon } from 'assets/icons/add_to_cart.svg';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useDispatch, useSelector } from 'react-redux';
import { getReservationInfo } from 'utils/helper';
import { AdditionInfoModal } from 'app/components/Modal/AdditionInfoModal';
import { IAdditionRoomInfo, IRoomItem } from 'types/common';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { RequestStatus } from 'constants/API';
import usePlanAndRoom from 'app/pages/PlanAndRoomPage/usePlanAndRoom';
import { CART_HANDLE_TYPE } from 'constants/common';

interface IAddToCartButton {
  onClick?: () => void;
  // item?: any;
  item?: IRoomItem;
}

export function AddToCartButton({ item, onClick, ...props }: IAddToCartButton) {
  const [isSuccessGetStock, setIsSuccessGetStock] = useState(false);

  const {
    addItemToCart,
    setSuccessMessages,
    changeShowAdditionInfoModalStatus,
    changeSavedCartItem,
  } = useGlobalSlice().actions;
  const dispatch = useDispatch();

  const {
    plans,
    active_plan,
    getPlansStatus,
    additionSearchInfo: {
      meal,
      adult,
      child,
      nights,
      child_A,
      child_B,
      child_C,
      child_D,
      child_E,
    },
    checkin_date,
    checkout_date,
  } = useSelector(selectPlanAndRoom);
  const {
    actions: { changeAdditionSearchInfo },
  } = usePlanAndRoomSlice();
  const { cart_items } = useSelector(selectGlobal);

  const { getPlanDetailStatus, detail_plan, getPlanDetailRequest } =
    usePlanAndRoom();
  const { checkHotelStock, hotelStockStatus, handleAddItemToCart } =
    useGlobalFunc();

  // useEffect(() => {
  //   if (isSuccessGetStock && getPlanDetailStatus === RequestStatus.SUCCESS) {
  //     // transform cart item with reservation info
  //     dispatch(
  //       addItemToCart(
  //         getReservationInfo(
  //           item,
  //           { ...item?.addition_info, hotelStockStatus, detail_plan },
  //           {
  //             meal,
  //             adult,
  //             // child,
  //             nights,
  //             checkin_date,
  //             checkout_date,
  //             child_A,
  //             child_B,
  //             child_C,
  //             child_D,
  //             child_E,
  //           },
  //         ),
  //       ),
  //     );
  //     dispatch(setSuccessMessages(['カートに追加しました']));
  //     setIsSuccessGetStock(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSuccessGetStock, getPlanDetailStatus]);

  const onSuccess = () => {
    setIsSuccessGetStock(true);
  };

  return (
    <>
      <CustomButton
        onClick={() => {
          handleAddItemToCart({
            handleType: CART_HANDLE_TYPE.ADD_CART,
            item: item || {},
            addition_info: item?.addition_info || {},
            reservation: {
              meal,
              adult,
              // child,
              nights,
              checkin_date,
              checkout_date,
              child_A,
              child_B,
              child_C,
              child_D,
              child_E,
            },
          });
          // if (cart_items.length >= 1) {
          //   return;
          // }
          // // add to cart if has item
          // if (
          //   item &&
          //   item?.addition_info &&
          //   Object.keys(item?.addition_info).length
          // ) {
          //   // if has adult,... => add to cart
          //   // else open modal addition info
          //   if (adult && checkin_date && checkout_date) {
          //     const { hotel_id, plan_id, room_id } = item.addition_info;
          //     getPlanDetailRequest(hotel_id, plan_id);

          //     checkHotelStock(
          //       {
          //         hotel_id,
          //         plan_id,
          //         room_id,
          //         checkin_date,
          //         checkout_date,
          //       },
          //       onSuccess,
          //     );
          //   } else {
          //     // save cart item to redux
          //     dispatch(changeSavedCartItem(item));
          //     dispatch(changeShowAdditionInfoModalStatus(true));
          //   }
          // }
          onClick && onClick();
        }}
        variant="outlined"
        color="primary"
      >
        <AddToCartIcon></AddToCartIcon>
        <span>カートに入れる</span>
      </CustomButton>
    </>
  );
}

const CustomButton = styled(Button)`
  border: 1px solid #2f80ed;
  box-shadow: 0px 1px 0px rgb(0 122 255 / 5%);
  border-radius: 4px;
  height: 40px;
  padding: 8px 16px;
  &:hover {
    border: 1px solid #2f80ed;
    background: none;
  }
  span {
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #2f80ed;
  }
`;
