import { fontsizes, colors } from 'constants/styles';
import { Typography } from '@mui/material';

interface Props {
  text: string;
  fontWeight?: number;
  marginBottom?: string;
}

export default function Title({ text, fontWeight, marginBottom }: Props) {
  return (
    <Typography
      sx={{
        fontWeight: fontWeight ? fontWeight : 500,
        fontSize: fontsizes.main,
        lineHeight: '16px',
        color: colors.heading,
        margin: 0,
        marginBottom: marginBottom ? marginBottom : 0,
      }}
    >
      {text}
    </Typography>
  );
}
