import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  amount: number;
  isBold?: boolean;
  fontSize?: number;
  space?: number;
  hasMin?: boolean;
}

export const PriceItem = ({
  isBold = true,
  hasMin = false,
  fontSize = 14,
  space = 0,
  ...props
}: Props) => {
  const YEN = '円';
  const { amount } = props;

  return (
    <Typography
      component="p"
      className="price_item"
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        fontSize: fontSize + 'px',
        fontWeight: isBold ? '600' : 'normal',
        whiteSpace: 'nowrap',
      }}
    >
      {hasMin ? ' ~' : ''}
      <Typography component="span" sx={{ fontSize: fontSize + 'px' }}>
        {new Intl.NumberFormat('ja-JP').format(amount)}
      </Typography>
      <Typography
        component="span"
        pr={space + 'px'}
        sx={{ fontSize: fontSize + 'px' }}
      >
        {YEN}
      </Typography>
    </Typography>
  );
};
