import { Box, CardContent, Stack, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CommonButton } from 'app/components/CommonButton';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import {
  AFTER_PAID_PAYMENT_TYPE,
  BEFORE_PAID_PAYMENT_TYPE,
} from 'constants/common';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import Modal from '.';
import { selectCheckout } from 'app/pages/CheckoutPage/slice/selectors';
import { ReactComponent as AddToCartIcon } from 'assets/icons/add_to_cart.svg';

interface Props {}

export const OrderStatusModal = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentCheckoutStep, cart_items, showOrderStatusModal } =
    useSelector(selectGlobal);
  const {
    login,
    changeConfirmOrderModalStatus,
    changeCurrentCheckoutStep,
    changeCartItemInfo,
    changeShowOrderStatusModal,
  } = useGlobalSlice().actions;
  const { handleRedirectPaymentPage } = useGlobalFunc();
  const { errorCode, reservation_statuses_info } = useSelector(selectCheckout);

  const changePaymentMethodOfCart = newPaymentMethod => {
    const newCartItems = _.map(cart_items, v => {
      return { ...v, ...newPaymentMethod };
    });
    dispatch(changeCartItemInfo({ newCartItems }));
  };

  const errorTextForPage =
    window.location.pathname === Path.CART ? 'お申込み手続き' : '決済される';
  const errorColor = '#e51717';
  const ORDER_ERROR_INFO = {
    out_of_stock: {
      title: (
        <Box>
          ご希望の
          <Box component="span" sx={{ color: errorColor }}>
            ご予約枠のエラー
          </Box>
          が発生しました
        </Box>
      ),
      msg: (
        // <Box>
        //   {
        //     'お客様のご予約より先に、他のお客様がご購入されたか、ご予約の保持期限が過ぎてしまった可能性がございます。\n'
        //   }
        //   {'恐れ入りますが、'}
        //   <Box component="span" sx={{ color: errorColor }}>
        //     再度日程とプランをご確認
        //   </Box>
        //   し、ご予約に進んでいただきますよう、お願い申し上げます。
        // </Box>
        <>
          <Stack spacing={1} ml={3} mb={3}>
            {reservation_statuses_info.map((v, k) => (
              <ReservationStatusArea>
                <Label>{v.name}</Label>
                <Box
                  sx={{
                    color: v.status === 'out_of_stock' ? errorColor : 'inherit',
                  }}
                >
                  {v.status === 'out_of_stock' ? '在庫なし' : '正常'}
                </Box>
              </ReservationStatusArea>
            ))}
          </Stack>
          <Box>
            {'在庫なしの予約に関して、カートに入れてから' +
              errorTextForPage +
              'までに、他のお客様がご購入されたが、ご予約の保持期限が過ぎてしまった可能性がございます。\n'}
          </Box>
          <Box>
            {'恐れ入りますが、'}
            <Box component="span" sx={{ color: errorColor }}>
              {`カートから${_.filter(
                reservation_statuses_info,
                v => v.status === 'out_of_stock',
              )
                .map(v => v.name)
                .join('と')}を削除いただき、`}
            </Box>
            <Box>
              {'改めて一覧からお選びくださいますよう、お願い申し上げます。'}
            </Box>
          </Box>
          <Box>
            ※ 正常と表示されているご予約に関しても、
            <Box component="span" sx={{ color: errorColor }}>
              決済（ご予約）が完了しておりません
            </Box>
            ので、上記ご修正のもと、再度ご予約にお進みください。
          </Box>
        </>
      ),
    },
    plan_rate_changes: {
      title: (
        <Box>
          ご希望の
          <Box component="span" sx={{ color: errorColor }}>
            ご予約枠のエラー
          </Box>
          が発生しました
        </Box>
      ),
      msg: (
        <Box>
          {
            'お客様のご予約より先に、他のお客様がご購入されたか、ご予約の保持期限が過ぎてしまった可能性がございます。\n'
          }
          {'恐れ入りますが、'}
          <Box component="span" sx={{ color: errorColor }}>
            再度日程とプランをご確認
          </Box>
          し、ご予約に進んでいただきますよう、お願い申し上げます。
        </Box>
      ),
    },
    payment_error: {
      title: (
        <Box>
          <Box component="span" sx={{ color: errorColor }}>
            決済上のエラー
          </Box>
          が発生しました
        </Box>
      ),
      msg: (
        <Box>
          {'決済・ご予約は、まだ完了しておりません。\n'}
          {'恐れ入りますが、今一度'}
          <Box component="span" sx={{ color: errorColor }}>
            カード情報を正しくご確認いただき、再度ご入力いただく
          </Box>
          か、
          <Box component="span" sx={{ color: errorColor }}>
            別のカードでの決済をお試しくださいますよう
          </Box>
          、お願い申し上げます。
        </Box>
      ),
    },
    fatal_error: {
      title: 'ご予約が完了しておりません',
      msg: '決済上のエラーにより、決済、ご予約が完了しておりません。恐れ入りますが、今一度カード情報を正しくご確認いただき、再度ご入力いただくか、別のカードでの決済をお試しくださいますよう、お願い申し上げます。',
    },
  };

  // const order_error_info = ORDER_ERROR_INFO?.out_of_stock;
  const order_error_info = ORDER_ERROR_INFO?.[errorCode];

  const isOutOfStock = errorCode === 'out_of_stock';
  return (
    <>
      <CustomModal
        // open={true}
        open={showOrderStatusModal}
        onClose={() => {
          dispatch(changeShowOrderStatusModal(false));
        }}
      >
        <Container>
          <CardContent
            className="body"
            sx={{
              maxWidth: {
                md: '400px',
                xs: '100%',
              },
              margin: {
                md: '0 auto',
              },
            }}
          >
            <BoxHeader>{order_error_info?.title}</BoxHeader>
            <Typography
              component="p"
              fontSize={fontsizes.main}
              color={colors.mainContent}
              mt={4}
              mb={4}
              sx={{ whiteSpace: 'pre-line' }}
            >
              {order_error_info?.msg}
            </Typography>
            <CommonButton
              variant="contained"
              size="large"
              sx={{
                background: colors.blue,
                boxShadow: 'none',
                marginBottom: 2,
                '& path': {
                  fill: '#e2e3e5 !important',
                },
                '& svg': {
                  marginRight: '6px',
                },
              }}
              onClick={() => {
                dispatch(changeShowOrderStatusModal(false));
                history.push(isOutOfStock ? Path.CART : Path.ORDER_INFO);
              }}
            >
              {isOutOfStock && <AddToCartIcon></AddToCartIcon>}
              <span style={{ color: colors.white, textDecoration: 'none' }}>
                {isOutOfStock ? 'カートを見る' : 'OK'}
              </span>
            </CommonButton>
          </CardContent>
        </Container>
      </CustomModal>
    </>
  );
};

const Container = styled(Box)`
  margin: 0 auto;
  ${dMedia.tablet`
    width: 100%;
    margin: 35px 0 0 0;
    max-width: 375px;
  `}
  .MuiCardContent-root {
    padding-bottom: 8rem;
    ${dMedia.tablet`
        padding: 0px;
    `}
  }
`;

const ReservationStatusArea = styled(Box)`
  display: flex;
  gap: 15px;
`;
const Label = styled(Box)`
  width: 60px;
`;

const CustomModal = styled(Modal)``;
