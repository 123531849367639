import { fontsizes, colors } from 'constants/styles';
import { Typography } from '@mui/material';

interface Props {
  children: any;
  sx?: any;
}

export default function Description({ children, sx }: Props) {
  return (
    <Typography
      sx={{
        fontWeight: '400',
        fontSize: fontsizes.main,
        lineHeight: '20px',
        color: colors.heading,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}
