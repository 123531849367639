import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import policySaga from './saga';
import { PolicyState } from './types';

export const initialState: PolicyState = {
  getStatus: RequestStatus.IDLE,
  data: {
    title: '',
    content: '',
    created_at: '',
    updated_at: '',
  },
  errorMess: [],
};

const slice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    getPolicy: state => {
      state.getStatus = RequestStatus.REQUESTING;
    },
    getPolicySuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getStatus = RequestStatus.SUCCESS;
        state.data = action.payload;
      }
    },
    getPolicyFail: (state, action: PayloadAction<any>) => {
      if (action) state.getStatus = RequestStatus.ERROR;
    },
    resetGetRequestStatus: state => {
      state.getStatus = RequestStatus.IDLE;
      state.errorMess = [];
    },
  },
});

export default slice.actions;

export const usePolicySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: policySaga });
  return { actions: slice.actions };
};
