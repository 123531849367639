import APIs from 'APIs';
import { StatusCode } from 'constants/API';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import Slice from '.';

function* searchFlow({ payload }) {
  try {
    const response = yield call(APIs.search, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.searchSuccess,
        payload: response.data.data,
      });
    } else {
      yield put({ type: Slice.searchFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.searchFail });
  }
}

function* checkAvailabilityFlow({ payload }) {
  console.log('checkAvailabilityRequest', payload);
  try {
    const response = yield call(APIs.checkAvailability, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.checkAvailabilitySuccess,
        payload: {
          hotel_id: payload.id,
          data: response.data,
        },
      });
    } else {
      yield put({
        type: Slice.checkAvailabilityFail.type,
        payload: { hotel_id: payload.id },
      });
    }
  } catch (error) {
    yield put({
      type: Slice.checkAvailabilityFail.type,
      payload: { hotel_id: payload.id },
    });
  }
}

function* areaFlow({ payload }) {
  try {
    const response = yield call(APIs.area, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.areaSuccess,
        payload: response.data.data,
      });
    } else {
      yield put({ type: Slice.areaFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.areaFail });
  }
}

function* tagFlow({ payload }) {
  try {
    const response = yield call(APIs.tag, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.tagSuccess,
        payload: response.data.data,
      });
    } else {
      yield put({ type: Slice.tagFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.tagFail });
  }
}

function* searchWatcher() {
  yield takeLatest(Slice.searchRequest, searchFlow);
  yield takeLatest(Slice.areaRequest, areaFlow);
  yield takeLatest(Slice.tagRequest, tagFlow);
  yield takeEvery(Slice.checkAvailabilityRequest, checkAvailabilityFlow);
}

export default searchWatcher;
