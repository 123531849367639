import { fontsizes } from 'constants/styles';
import { Typography } from '@mui/material';

interface Props {
  text: string;
}

export default function Notification({ text }: Props) {
  return (
    <Typography
      sx={{
        fontWeight: '400',
        fontSize: fontsizes.main,
        color: '#757575',
        marginTop: '8px',
        lineHeight: '14px',
      }}
    >
      {text}
    </Typography>
  );
}
