import config from 'config';
import axios, { Method as AxiosMethod } from 'axios';
import { getToken, getLang, removeLoginTime } from 'utils/localStorage';
import Path from 'config/clientPath';

import {
  removeToken,
  removeUserCachedDataFromLocalStorage,
} from 'utils/localStorage';
import { store } from 'index';
import { changeErrors } from 'app/pages/GlobalContainer/slice/actions';

export type VMG_Hotel_Method = Extract<
  AxiosMethod,
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'options'
  | 'OPTIONS'
>;

const mainRequestConfig = {
  // Mock baseURL is from a local Postman Mock Server
  baseURL: config.serviceBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const mainAxiosInstance = axios.create(mainRequestConfig);

mainAxiosInstance.interceptors.request.use(
  config => {
    const token = getToken();
    const lang = getLang();
    if (token) config.headers['X-Authorization'] = token;
    if (lang) {
      if (lang === 'en') {
        config.headers['Accept-Language'] = 'en-US';
      } else {
        config.headers['Accept-Language'] = 'ja-JP';
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

mainAxiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { status } = error.response;
    if (status === 401) {
      removeToken();
      removeLoginTime();
      removeUserCachedDataFromLocalStorage();
      window.location.href = Path.LOGIN;
      return Promise.resolve(error);
    }
    if (status === 503) {
      window.location.href = Path.MAINTENANCE;
    }
    try {
      if (status === 403) {
        store.dispatch(changeErrors(error));
      }
    } catch (e) {}

    return error.response;
  },
);

export const mainRequest = (
  url,
  payload,
  method: VMG_Hotel_Method,
  headers: Object = {
    'X-Requested-With': 'XMLHttpRequest',
  },
) => {
  const data = payload;

  let params;
  if (method === 'get') {
    params = payload;
  }
  return mainAxiosInstance({ url, data, params, method, headers });
};

const APIs = {
  /* <CUSTOMER> */
  getCustomerList: payload => mainRequest('/customers/', payload, 'get'),
  /* </CUSTOMER> */

  /* <AUTH> */
  login: payload => mainRequest('/tap/login/', payload, 'post'),

  register: payload => mainRequest('/tap/signup/', payload, 'post'),

  account: payload => mainRequest(`/tap/account/${payload.id}`, payload, 'get'),

  updateAccount: payload =>
    mainRequest(`/tap/account/${payload.id}/`, payload, 'post'),

  getResetPassword: payload =>
    mainRequest(
      `/tap/reset_pw/?email=${encodeURIComponent(payload.email)}`,
      null,
      'get',
    ),

  resetPassword: payload =>
    mainRequest(
      `/tap/reset_pw/?email=${encodeURIComponent(payload.email)}`,
      payload,
      'post',
    ),

  /* </AUTH> */

  /* <SEARCH> */
  search: payload => mainRequest('/hotels/', payload, 'get'),

  area: payload => mainRequest('/area/', payload, 'get'),

  tag: payload => mainRequest('/tag/', payload, 'get'),

  latestRoomStatus: payload =>
    mainRequest(
      `/tap/hotels/${payload.id}/latest_room_status/`,
      payload,
      'get',
    ),

  /* </SEARCH> */

  // <HotelDetails>
  hotelDetails: payload => mainRequest(`/hotels/${payload}/`, '', 'get'),
  suggestPlanByHotel: payload =>
    mainRequest(`/tap/hotels/${payload}/suggest_plans/`, '', 'get'),
  getPrice: (hotel_slug, payload) =>
    mainRequest(
      `/tap/hotels/${hotel_slug}/latest_room_status/`,
      payload,
      'get',
    ),
  // </HotelDetails>

  // <Plans>
  getPlans: (hotel_id, payload) =>
    mainRequest(`/tap/hotels/${hotel_id}/plans/`, payload, 'get'),
  getPlanDetail: payload =>
    mainRequest(
      `/tap/hotels/${payload.hotel_id}/plans/${payload.plan_id}/`,
      '',
      'get',
    ),
  getPlansWithStock: payload =>
    mainRequest(
      `/tap/hotels/${payload.hotel_id}/plans/${payload.plan_id}/rooms/`,
      payload,
      'get',
    ),
  // </Plans>

  // <Rooms>
  getRooms: (hotel_id, payload) =>
    mainRequest(`/tap/hotels/${hotel_id}/rooms/`, payload, 'get'),
  getRoomDetail: payload =>
    mainRequest(
      `/tap/hotels/${payload.hotel_id}/rooms/${payload.room_id}/`,
      '',
      'get',
    ),
  getRoomsWithStock: payload =>
    mainRequest(
      `/tap/hotels/${payload.hotel_id}/rooms/${payload.plan_id}/plans/`,
      payload,
      'get',
    ),
  // </Rooms>
  getPolicy: payload => mainRequest('/pages/policy/', payload, 'get'),

  // <Checkout>
  createOrderTest: payload =>
    mainRequest('/tap/reservations/?account_id=47', payload, 'post'),

  createOrder: payload =>
    mainRequest(
      `/tap/reservations/${
        payload?.account_id ? '?account_id=' + payload?.account_id : ''
      }`,
      payload?.data,
      'post',
    ),
  getOrderHistory: payload =>
    mainRequest(
      `/tap/reservations/${
        payload?.account_id ? '?account_id=' + payload?.account_id : ''
      }`,
      '',
      'get',
    ),

  cancelOrder: payload =>
    mainRequest(
      `/tap/reservations/${payload?.account_id}/${payload?.reservation_id}`,
      payload?.data,
      'post',
    ),
  cancelOrderNoLogin: payload =>
    mainRequest(
      `/tap/reservations/hotel/${payload?.hotel_id}/${payload?.reservation_id}`,
      payload?.data,
      'post',
    ),
  getOrderDetail: payload =>
    mainRequest(
      `/tap/reservations/${payload?.account_id}/${payload?.reservation_id}`,
      payload?.data,
      'get',
    ),
  getOrderDetailNologin: payload =>
    mainRequest(
      `/tap/reservations/hotel/${payload?.hotel_id}/${payload?.reservation_id}`,
      payload?.data,
      'get',
    ),
  //</Checkout>
  getHotelHistory: payload =>
    mainRequest(`/tap/reservations/${payload}/hotels/`, '', 'get'),

  getHotelName: payload => mainRequest(`/hotel_name/${payload}/`, '', 'get'),
  getHotelStock: payload =>
    mainRequest(
      `/tap/stock/${payload.hotel_id}/${payload.plan_id}/${payload.room_id}/`,
      payload,
      'get',
    ),
  checkInventory: payload =>
    mainRequest(`/tap/hotels/inventory/`, payload, 'post'),

  checkAvailability: payload =>
    mainRequest(`/tap/hotels/${payload.id}/stock_status/`, payload, 'get'),

  getHotelStockCalendar: payload =>
    mainRequest(
      `/tap/stock/${payload.hotel_id}/${payload.plan_id}/${payload.room_id}/`,
      payload,
      'get',
    ),

  getStockCalendar: payload =>
    mainRequest(
      `/tap/calendar/${payload.hotel_id}/${payload.plan_id}/${payload.room_id}/`,
      payload,
      'get',
    ),
  // Account
  getAccountInfo: payload => mainRequest(`/tap/account/${payload}/`, '', 'get'),
};

export default APIs;
