// UI
import { Box, Stack, Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BoxHeader } from 'app/components/BoxHeader';
import Calendar from 'app/components/Calendar';
import CalendarNote from 'app/components/CalendarNote';
import ImageSlider, { IPaginatePosition } from 'app/components/ImageSlider';
import Modal from 'app/components/Modal';
import { TagList } from 'app/components/TagList';
import { LIMIT_ITEMS } from 'constants/common';
import { colors } from 'constants/styles';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { useCalendarSlice } from 'app/components/Calendar/slice';
import { selectMetaData } from 'app/components/Calendar/slice/selectors';
import PlanDetail from 'app/pages/PlanAndRoomPage/components/PlanDetail';
import { checkIsRequesting, getImages, getTagsData } from 'utils/helper';
import { PartialLoader } from 'app/components/PartialLoader';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { ReactComponent as CheckIcon } from 'assets/icons/room/check.svg';
import { ORDER_ERROR_INFO } from 'constants/common';
import { useParams } from 'react-router-dom';
import Path from 'config/clientPath';
import InputItem from 'app/components/FormItems/InputItem';
import Button from '@mui/material/Button';
import RoomFilter from 'app/components/RoomFilter';
import PlanOrderModal from './PlanOrderModal';
import { RequestStatus } from 'constants/API';
import ESSnackbar from 'app/components/ESSnackbar';

interface IPlanDetailModal {
  isGoBackWhenClose?: boolean;
  fromHotel?: boolean;
}
export interface IParams {
  slug?: string;
  planID?: string;
}

const PlanDetailModal = ({ ...props }: IPlanDetailModal) => {
  const location = useLocation();
  const history = useHistory();
  let queryParams = queryString.parse(window.location.search);
  const { slug, planID } = useParams<IParams>();

  const {
    tab_type,
    sort_type,
    plans,
    active_plan,
    detail_plan,
    best_rate,
    planForModal,
    getPlansWithStockInModalStatus,
    hotelId,
  } = useSelector(selectPlanAndRoom);

  const {
    checkin_times,
    checkout_times,
    hotel_name,
    name,
    cancellation_fee_policies,
    meals,
    payment_method,
  } = detail_plan || {};

  const {
    actions: {
      getPlanDetailRequest,
      setActivePlan,
      changeCheckInOutDate,
      getPlansWithStockRequest,
    },
  } = usePlanAndRoomSlice();

  // calendar
  const { stockData, stockDataStatus } = useSelector(selectMetaData);

  const {
    actions: {
      getStockDataRequest,
      getStockDataSuccess,
      getCalendarDataSuccess,
    },
  } = useCalendarSlice();

  const {
    actions: { setErrorMessages },
  } = useGlobalSlice();

  const dispatch = useDispatch();
  const [displayCopiedMessage, setDisplayCopiedMessage] =
    React.useState<boolean>(false);

  const [currentPlan, setCurrentPlan] = React.useState(
    _.find(plans, {
      id: _.toInteger(active_plan?.plan_id) ?? _.toInteger(planID),
    }),
  );

  const [activeRoom, setActiveRoom] = React.useState<number>(
    _.get(
      _.first(_.get(_.find(plans, { id: active_plan?.plan_id }), 'rooms')),
      'id',
    ),
  );
  const [changeRooms, setChangeRooms] = React.useState<boolean>(false);
  const [checkCalendar, setCheckCalendar] = React.useState<boolean>(false);
  const [dateInfo, setDateInfo] = React.useState<{
    checkin_date: string;
    checkout_date: string;
  }>({
    checkin_date: '',
    checkout_date: '',
  });

  React.useEffect(() => {
    if (active_plan) {
      let params = queryString.parse(window.location.search);

      dispatch(
        getPlanDetailRequest({
          hotel_id: active_plan?.hotel_id,
          plan_id: active_plan?.plan_id,
        }),
      );
      dispatch(
        getPlansWithStockRequest({
          hotel_id: active_plan?.hotel_id,
          checkin_date:
            params.checkin_date ??
            moment().startOf('month').format('YYYY-MM-DD'),
          checkout_date:
            params.checkout_date ??
            moment().endOf('month').format('YYYY-MM-DD'),
          plan_id: active_plan?.plan_id,
          id: active_plan?.plan_id,
          guest_count: params.guest_count ?? 2,
          child_a_count: paxInfo.child_a_count ?? params.child_a_count ?? 0,
          child_b_count: paxInfo.child_b_count ?? params.child_b_count ?? 0,
          child_c_count: paxInfo.child_c_count ?? params.child_c_count ?? 0,
          child_d_count: paxInfo.child_d_count ?? params.child_d_count ?? 0,
          child_e_count: paxInfo.child_e_count ?? params.child_e_count ?? 0,
          fromModal: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_plan]);

  React.useEffect(() => {
    if (
      active_plan?.isScrolled &&
      getPlansWithStockInModalStatus === RequestStatus.SUCCESS
    ) {
      let ele = document.getElementById('wrap_calendar');
      const scrollableDiv = document.getElementById('dialog_content');
      scrollableDiv?.scrollTo({
        top: ele?.offsetTop,
        behavior: 'smooth',
      });
      if (_.isEmpty(planForModal?.rooms)) {
        getCalendarDataSuccess([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlansWithStockInModalStatus]);

  React.useEffect(() => {
    setActiveRoom(_.get(_.first(_.get(currentPlan, 'rooms')), 'id'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan]);

  React.useEffect(() => {
    if (!checkCalendar) {
      setCurrentPlan(
        _.find(
          !_.isEmpty(currentPlan)
            ? _.unionBy([currentPlan], plans, 'id')
            : plans,
          {
            id: _.toInteger(active_plan?.plan_id) ?? _.toInteger(planID),
          },
        ),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans, active_plan?.plan_id]);

  React.useEffect(() => {
    if (planForModal) {
      setCurrentPlan(planForModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planForModal]);

  React.useEffect(() => {
    if (stockDataStatus === RequestStatus.SUCCESS) {
      if (stockData?.ok) {
        setOpenOrderModal(true);
      }
    }
    if (stockDataStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([ORDER_ERROR_INFO.out_of_stock.msg]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockData, stockDataStatus]);

  React.useEffect(() => {
    setOpenOrderModal(false);
    setChangeRooms(true);
  }, []);

  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;

  const defaultPaxInfo = {
    adult: queryParams.guest_count ?? 2,
    child_a_count: queryParams.child_a_count ?? 0,
    child_b_count: queryParams.child_b_count ?? 0,
    child_c_count: queryParams.child_c_count ?? 0,
    child_d_count: queryParams.child_d_count ?? 0,
    child_e_count: queryParams.child_e_count ?? 0,
  };

  const [selectedDates, setSelectedDates] = React.useState([]);
  const [openOrderModal, setOpenOrderModal] = React.useState(false);
  const [paxInfo, setPaxInfo] = React.useState(defaultPaxInfo);

  const [currentMonth, setCurrentMonth] = React.useState(
    queryParams.checkin_date ?? moment().format('YYYY-MM-DD'),
  );

  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');

  const updateQuantity = item => {
    setPaxInfo({
      adult: item.adult,
      child_a_count: item.upperGrades,
      child_b_count: item.withMealsAndBedding,
      child_c_count: item.withSameMealsAndBedding,
      child_d_count: item.withoutMealsAndBedding,
      child_e_count: item.withoutSameMealsAndBedding,
    });
    setChangeRooms(true);
    dispatch(
      getPlansWithStockRequest({
        hotel_id: active_plan?.hotel_id,
        checkin_date:
          queryParams.checkin_date ??
          moment(currentMonth).startOf('month').format('YYYY-MM-DD'),
        checkout_date:
          queryParams.checkout_date ??
          moment(currentMonth).endOf('month').format('YYYY-MM-DD'),
        plan_id: active_plan?.plan_id,
        id: active_plan?.plan_id,
        guest_count: item.adult,
        fromModal: true,
        child_a_count: item.upperGrades,
        child_b_count: item.withMealsAndBedding,
        child_c_count: item.withSameMealsAndBedding,
        child_d_count: item.withoutMealsAndBedding,
        child_e_count: item.withoutSameMealsAndBedding,
      }),
    );
  };

  const changeMonth = month => {
    setCurrentMonth(month);
    setChangeRooms(true);
    dispatch(
      getPlansWithStockRequest({
        hotel_id: active_plan?.hotel_id,
        checkin_date: moment(month).startOf('month').format('YYYY-MM-DD'),
        checkout_date: moment(month).endOf('month').format('YYYY-MM-DD'),
        plan_id: active_plan?.plan_id,
        id: active_plan?.plan_id,
        guest_count: paxInfo.adult,
        fromModal: true,
        ..._.pick(paxInfo, [
          'child_a_count',
          'child_b_count',
          'child_c_count',
          'child_d_count',
          'child_e_count',
        ]),
      }),
    );
  };

  const [isCheckinCheckout, setCheckinCheckout] = React.useState(false);
  const getRoomsLoading = checkIsRequesting([getPlansWithStockInModalStatus]);

  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    setPaxInfo({
      adult: params.guest_count ?? 2,
      child_a_count: params.child_a_count ?? 0,
      child_b_count: params.child_b_count ?? 0,
      child_c_count: params.child_c_count ?? 0,
      child_d_count: params.child_d_count ?? 0,
      child_e_count: params.child_e_count ?? 0,
    });
  }, [location.search]);

  return (
    <Box>
      <Modal
        paddingTopTitle={'9px'}
        title={'プラン詳細'}
        className="room_list_modal"
        open={!!active_plan}
        onClose={() => {
          setActiveRoom(
            _.get(
              _.first(
                _.get(_.find(plans, { id: active_plan?.plan_id }), 'rooms'),
              ),
              'id',
            ),
          );
          setPaxInfo(defaultPaxInfo);
          dispatch(setActivePlan(null));
          setCurrentPlan(null);
          setChangeRooms(false);
          dispatch(getStockDataSuccess(null));
          if (props.isGoBackWhenClose) {
            let params = queryString.parse(window.location.search);
            let qs = '';
            if (queryString.stringify(params).length > 0) {
              qs = `?${queryString.stringify(params)}`;
            }
            const newUrl = `${Path.RESERVATION_PREFIX}/${slug}${Path.PLANS}/${qs}`;
            window.history.replaceState(window.history.state, '', newUrl);
          }
        }}
      >
        {/* <Loader open={isLoading} /> */}
        <WrapDetailPlan
          spacing={{ xs: 2, sm: 5 }}
          sx={{
            padding: {
              xs: '0px 0 40px 0',
              sm: '0px 0 80px 0',
            },
          }}
        >
          <Stack
            spacing={{ xs: 2, sm: 5 }}
            sx={{
              width: pageWidth <= 768 ? '100%' : '400px',
              margin: { xs: '0 auto', sm: '0 auto' },
            }}
          >
            <Box ml={pageWidth <= 768 ? '-16px' : '0px'}>
              <ImageSlider
                images={getImages(
                  detail_plan?.images,
                  LIMIT_ITEMS.PLAN_ROOM_IMAGE,
                )}
                sliderWidth={pageWidth <= 768 ? 'calc(100vw)' : '400px'}
                paginatePosition={IPaginatePosition.OUTSIDE}
                normalPaginateColor={colors.grey5}
                activePaginateColor={colors.grey1}
                iconPrevSize={40}
              />
            </Box>
            <Stack spacing={2}>
              <NameRoomModal mt={{ xs: 2 }}>{detail_plan?.name}</NameRoomModal>
              {detail_plan?.tags.length ? (
                <TagList data={getTagsData(detail_plan?.tags)} type={2} />
              ) : (
                ''
              )}
            </Stack>
            {detail_plan?.content ? (
              <DescRoomModal
                dangerouslySetInnerHTML={{
                  __html: detail_plan?.content,
                }}
              >
                {/* {detail_plan?.content} */}
              </DescRoomModal>
            ) : (
              ''
            )}
            <Box display={'flex'} justifyContent={'center'}>
              <PlanDetail />
            </Box>
          </Stack>
          <BoxHeader fontSize={18}>カレンダー</BoxHeader>

          <WrapCalendar
            direction="row"
            spacing={2}
            sx={{
              marginTop: { md: '3rem !important' },
              flexDirection: {
                md: 'row',
                xs: 'column',
              },
              padding: {
                md: '0 24px',
                xs: '16px',
              },
            }}
            alignItems="flex-start"
            id="wrap_calendar"
          >
            <Box
              sx={{
                minWidth: '288px',
                marginRight: { md: '0', xs: '16px' },
                width: { md: '100%', xs: 'calc(100% - 20px)' },
              }}
            >
              <RoomFilter isInModal updateQuantity={updateQuantity} />
              <Box
                sx={{
                  minHeight: '100px',
                  maxHeight: '450px',
                  overflow: 'auto',
                  position: 'relative',
                }}
              >
                <PartialLoader open={getRoomsLoading} />
                {!getRoomsLoading &&
                  (!_.isEmpty(_.get(currentPlan, 'rooms')) ? (
                    _.get(currentPlan, 'rooms').map((it, idx) => {
                      return (
                        <RoomBox
                          key={idx}
                          className={it.id === activeRoom ? 'active' : ''}
                          sx={{
                            marginTop: '1rem',
                            borderRadius: '8px',
                            padding: '1rem 0.5rem',
                            position: 'relative',
                            cursor: 'pointer',
                            '&:first-of-type': {
                              marginTop: '2rem',
                            },
                          }}
                          onClick={() => setActiveRoom(it.id)}
                        >
                          <Typography
                            fontSize="14px"
                            fontWeight={700}
                            color={`#333333`}
                          >
                            {it.name}
                          </Typography>

                          {getTagsData(it?.tags).join('  ') ? (
                            <Typography
                              component="span"
                              className="btn"
                              sx={{
                                display: 'inline-block',
                                borderRadius: '37px',
                                background: '#F2F2F2',
                                fontSize: '10px',
                                lineHeight: '14px',
                                color: '#4F4F4F',
                                padding: '4px 8px',
                                margin: '1rem 0 0.5rem',
                              }}
                            >
                              {getTagsData(it?.tags).join('   ')}
                            </Typography>
                          ) : (
                            ''
                          )}
                          {it.id === activeRoom && (
                            <Typography
                              component="span"
                              fontSize={`8px`}
                              color={`#333333`}
                              sx={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                              }}
                            >
                              選択 <CheckIcon />
                            </Typography>
                          )}
                        </RoomBox>
                      );
                    })
                  ) : (
                    <Typography
                      color={`#EB5757`}
                      textAlign={`center`}
                      mt={2}
                      mb={2}
                    >
                      ご予約可能な部屋がございません。
                    </Typography>
                  ))}
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: '1rem !important',
                marginLeft: { md: '30px!important', xs: '0!important' },
              }}
            >
              <Calendar
                changeMonth={changeMonth}
                setCheckinAndCheckout={isCheckinCheckout}
                hotel={active_plan?.hotel_id}
                plan_id={_.toInteger(active_plan?.plan_id)}
                room_id={activeRoom ?? 1}
                selectable={true}
                month={
                  queryParams.checkin_date
                    ? moment(queryParams.checkin_date).format('YYYY-MM')
                    : moment().format('YYYY-MM')
                }
                onSelectedDateRangeChange={checkInOutDate => {
                  if (checkInOutDate.length > 1) {
                    const nights = moment.duration(
                      moment(checkInOutDate[1]).diff(
                        moment(checkInOutDate[0]),
                        'days',
                      ),
                    );

                    if (
                      detail_plan?.min_nights &&
                      detail_plan?.max_nights &&
                      (nights < detail_plan?.min_nights ||
                        nights > detail_plan?.max_nights)
                    ) {
                      setShowAlert(true);
                      setAlertMessage(
                        `${detail_plan?.min_nights}泊〜${detail_plan?.max_nights}泊でご予約ください。`,
                      );
                      setSelectedDates([]);
                      return;
                    } else {
                      setShowAlert(false);
                      setAlertMessage('');
                    }
                  } else {
                    setShowAlert(false);
                    setAlertMessage('');
                  }

                  dispatch(changeCheckInOutDate(checkInOutDate));
                  let params = queryString.parse(window.location.search);
                  if (checkInOutDate && checkInOutDate.length === 2) {
                    const qs = queryString.stringify({
                      ...params,
                      checkin_date: checkInOutDate[0],
                      checkout_date: checkInOutDate[1],
                    });
                    history.push(`${location.pathname}?${qs}`);
                    setCheckinCheckout(true);
                    // setOpenOrderModal(true);
                    setDateInfo({
                      checkin_date: checkInOutDate[0],
                      checkout_date: checkInOutDate[1],
                    });
                    dispatch(
                      getStockDataRequest({
                        hotel_id: active_plan?.hotel_id,
                        plan_id: _.toInteger(active_plan?.plan_id),
                        room_id: activeRoom,
                        guest_count: paxInfo.adult,
                        checkin_date: checkInOutDate[0],
                        checkout_date: checkInOutDate[1],
                        ..._.pick(paxInfo, [
                          'child_a_count',
                          'child_b_count',
                          'child_c_count',
                          'child_d_count',
                          'child_e_count',
                        ]),
                      }),
                    );
                    setCheckCalendar(true);
                  } else if (checkInOutDate && checkInOutDate.length === 0) {
                    const qs = queryString.stringify(
                      _.omit(params, ['checkin_date', 'checkout_date']),
                    );

                    history.push(`${location.pathname}?${qs}`);
                  }
                  setSelectedDates(checkInOutDate);
                }}
                selectedDateRange={selectedDates[0] ? selectedDates : []}
                guest_count={paxInfo.adult}
                isPlanModal
                pax={paxInfo}
              />
            </Box>
          </WrapCalendar>
          <BoxHeader fontSize={18}>プランを共有する</BoxHeader>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 100px',
              gap: '10px',
            }}
          >
            <InputItem value={window.location.href} fullWidth disabled />
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: '100%',
                  height: '100%',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setDisplayCopiedMessage(true);
                  setTimeout(() => {
                    setDisplayCopiedMessage(false);
                  }, 1000);
                }}
              >
                コピー
              </Button>
              <Alert
                color="info"
                sx={{
                  position: 'absolute',
                  width: 'max-content',
                  right: 0,
                  bottom: '-55px',
                  animation: 'all 0.5s',
                  opacity: displayCopiedMessage ? 1 : 0,
                }}
              >
                コピーしました。
              </Alert>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px',
              color: '#000',
            }}
          >
            予約は当サイトが最もお得！　ベストレート保証 + HP限定特典
          </Typography>
          <Button
            sx={{
              maxWidth: 'max-content',
              color: '#000',
              marginTop: '10px !important',
              fontSize: '14px',
              lineHeight: '16px',
              height: '40px',
            }}
            onClick={() => {
              if (best_rate) {
                window.open(best_rate, '_blank');
              }
            }}
          >
            ＞詳細を見る
          </Button>
        </WrapDetailPlan>
      </Modal>
      {active_plan && (
        <PlanOrderModal
          data={{
            dateInfo,
            ...detail_plan,
            checkin_times,
            checkout_times,
            addition_info: {
              hotel_id: _.toInteger(hotelId),
              hotel_name,
              local_hotel_id: slug,
              plan_id: active_plan.plan_id,
              room_id: activeRoom,
              plan_name: name,
              room_name: _.get(
                _.find(_.get(currentPlan, 'rooms'), { id: activeRoom }),
                'name',
              ),
              cancellation_fee_policies,
              max_guests: _.get(
                _.find(_.get(currentPlan, 'rooms'), { id: activeRoom }),
                'max_guests',
              ),
              meals,
              payment_method,
            },
          }}
          totalAmount={stockData?.price}
          open={openOrderModal}
          onClose={() => {
            setOpenOrderModal(false);
          }}
        />
      )}

      <ESSnackbar isOpen={showAlert} mess={alertMessage} />
    </Box>
  );
};

export default PlanDetailModal;

const WrapDetailPlan = styled(Stack)``;

const NameRoomModal = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    color: ${colors.grey1};
  }
`;
const DescRoomModal = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.grey1};
  }
`;

const WrapCalendar = styled(Stack)`
  width: 100%;
  padding: 0 2rem;
  & > div {
    align-items: center;
    width: 100%;
    max-width: 351px;
    &:first-child {
      max-width: 288px;
      > div > div::after {
        width: 100%;
      }
    }
  }
  ${dMedia.tablet`
    margin-left: -16px !important;
    width: calc(100vw);
    padding: 0 6px;
    & > div {
      width: 100%;
      max-width: none;
    }
  `}
`;
const RoomBox = styled(Box)`
  border: 1px solid #f2f2f2;

  &.active {
    border: 1px solid #2f80ed;
    background: rgba(47, 128, 237, 0.1);
    .btn {
      color: #fff;
      background: #2f80ed;
    }
  }
`;
