import 'moment/locale/ja';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/calendar/ArrowLeft.svg';
import { ReactComponent as MaruIcon } from 'assets/icons/calendar/type-1.svg';
import { ReactComponent as TriangleIcon } from 'assets/icons/calendar/type-2.svg';
import { ReactComponent as EmptyIcon } from 'assets/icons/calendar/type-3.svg';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IParamWithSlug } from 'types/common';
import {
  checkDisabledMonth,
  checkIsRequesting,
  getChildParams,
  getGuestCount,
} from 'utils/helper';

import SelectItem from '../SelectItem';
import { Calendar as CalendarComponent } from './calendar';
import { useCalendarSlice } from './slice';
import { selectMetaData } from './slice/selectors';

// redux
moment.locale('ja');

interface CalendarProps {
  /**
   * Date range selectable
   */
  selectable?: boolean;
  /**
   * View mode in single or double calendar
   */
  viewMode?: 'single' | 'double';

  /**
   * Display label in top of calendar
   */
  label?: string;

  /**
   * Display month in format YYYY-MM
   */
  month?: string;

  /**
   * Price display in each day
   * type:
   * - 1: チェックイン可
   * - 2: 残りわずか
   * - 3: チェックイン不可
   * - 4: 休館
   * Ex: { '2021-12-01': {type: 1, disable: true, price: 5000}, '2021-12-12': {type: 2, disable: false, price: 6000} }
   */
  data?: {
    [key: string]: {
      type: 1 | 2 | 3 | 4;
      disable?: boolean;
      price?: number;
    };
  };

  /**
   * Selected Date List in format YYYY-MM-DD
   * Ex: ['2021-12-01', '2021-12-10']
   */
  selectedDateRange?: string[];

  /**
   * selected date range change event callback
   * param of callback function is array: from date, to date
   * Ex: ['2021-12-01', '2021-12-10']
   */
  onSelectedDateRangeChange?: Function;

  fromSearch?: boolean;
  guest_count?: number;
  hotel?: string | number;
  plan_id?: number;
  room_id?: number;
  isPlanModal?: boolean;
  pax?: any;
  setCheckinAndCheckout?: boolean;
  isPlanAndRoomPage?: boolean;
  changeMonth?: (month: string) => void;
}

export function Calendar({
  selectable = false,
  viewMode = 'single',
  label = '料金・空室カレンダー',
  fromSearch = false,
  data = {},
  guest_count,
  plan_id,
  room_id,
  isPlanModal = false,
  pax,
  setCheckinAndCheckout = false,
  isPlanAndRoomPage = false,
  changeMonth,
  ...props
}: CalendarProps) {
  const getRanges = () => {
    let monthYearRange: any[] = [];
    for (let i = 0; i <= 11; i++) {
      monthYearRange.push({
        value: moment().add(i, 'months').format('YYYY-MM'),
        label: moment().add(i, 'months').format('YYYY年MM月'),
      });
    }

    return monthYearRange;
  };

  const {
    metaData,
    metaDataStatus,
    stockDataStatus,
    calendarData,
    calendarDataStatus,
  } = useSelector(selectMetaData);
  const dispatch = useDispatch();
  const params = queryString.parse(window.location.search);
  const guestCount = guest_count ? guest_count : getGuestCount();
  const {
    actions: { getMetaDataRequest, getCalendarDataRequest },
  } = useCalendarSlice();

  const { isLoggedIn } = useSelector(selectGlobal);

  const [month, setMonth] = React.useState<string>(
    isPlanAndRoomPage && params.checkin_date
      ? moment(params.checkin_date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
  );
  const [selectedDateRange, setSelectedDateRange] = React.useState<string[]>(
    props.selectedDateRange || [],
  );
  const isFirstOfMonth = moment().format('DD') === '01';
  const [convertedToMonth, setConvertedToMonth] = React.useState<boolean>(
    !params.checkin_date && isFirstOfMonth ? true : false,
  );

  const [hoverDate, setHoverDate] = React.useState<string>();
  const [possibleCheckinDate, setPossibleCheckinDate] =
    React.useState<string>();
  const [hiddenTag, setHiddenTag] = React.useState<boolean>(false);
  /** handle get api */
  const { slug } = useParams<IParamWithSlug>();

  React.useEffect(() => {
    setConvertedToMonth(true);
    setMonth(moment(`${props.month}-01`).format('YYYY-MM-DD'));
  }, [props.month]);

  React.useEffect(() => {
    setSelectedDateRange(props.selectedDateRange || []);
  }, [props.selectedDateRange]);

  React.useEffect(
    () => {
      if (!fromSearch && !isPlanModal && convertedToMonth) {
        dispatch(
          getMetaDataRequest({
            hotel_slug: slug ?? props.hotel,
            checkin_date: moment(month).startOf('month').format('YYYY-MM-DD'),
            checkout_date: moment(month).endOf('month').format('YYYY-MM-DD'),
            guest_count: guestCount,
            ...getChildParams(),
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [month, guestCount, params.checkin_date, params.checkout_date],
  );

  React.useEffect(() => {
    if (
      !fromSearch &&
      props.hotel &&
      plan_id &&
      room_id &&
      isPlanModal &&
      convertedToMonth
    ) {
      dispatch(
        getCalendarDataRequest({
          hotel_id: props.hotel,
          room_id,
          plan_id,
          checkin_date: moment(month).startOf('month').format('YYYY-MM-DD'),
          checkout_date: moment(month).endOf('month').format('YYYY-MM-DD'),
          guest_count: pax?.adult ?? params.guest_count ?? 2,
          ..._.pick(pax ?? params, [
            'child_a_count',
            'child_b_count',
            'child_c_count',
            'child_d_count',
            'child_e_count',
          ]),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.checkin_date,
    params.checkout_date,
    params.guest_count,
    room_id,
    plan_id,
    pax,
    month,
    setCheckinAndCheckout,
  ]);

  const isLoading = checkIsRequesting([
    metaDataStatus,
    stockDataStatus,
    calendarDataStatus,
  ]);

  const monthObject = moment(month);

  const enumerateDays = () => {
    const dates: string[] = [];

    if (
      selectedDateRange.length > 0 ||
      (isPlanModal && params.checkin_date && params.checkout_date)
    ) {
      const startDate = moment(
        selectedDateRange[0]
          ? selectedDateRange[0]
          : isPlanModal
          ? params.checkin_date
          : '',
      );
      var now = startDate.clone();
      let endDate: any =
        selectedDateRange.length > 1
          ? selectedDateRange[1]
          : selectedDateRange[0];

      if (
        isPlanModal &&
        params.checkout_date &&
        selectedDateRange.length < 1 &&
        now.isSameOrBefore(moment(params.checkout_date))
      ) {
        endDate = params.checkout_date;
      }

      endDate = moment(endDate);
      endDate = moment(
        selectedDateRange.length > 1 ||
          (isPlanModal && params.checkout_date && selectedDateRange.length < 1)
          ? endDate
          : hoverDate && now.isSameOrBefore(hoverDate)
          ? hoverDate
          : now,
      );

      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
      }
    }
    return dates;
  };

  const renderTags = () => {
    if (selectedDateRange.length < 2) {
      // setSelectedDateRange([]);
      // _.invoke(props, 'onSelectedDateRangeChange', []);
      // setPossibleCheckinDate('');
      setHiddenTag(true);
    }
  };

  const renderCalendar = (m: moment.Moment, position: string | null) => {
    return (
      <Box maxWidth="340px" width="100%">
        <Stack
          direction="row"
          justifyContent={`space-between`}
          alignItems="center"
          marginLeft={position ? '-10px' : '0'}
          marginRight={position ? '-10px' : '0'}
          spacing={1}
          sx={{
            '& > .MuiBox-root': {
              width: '110px!important',
              // marginRight: position === 'right' ? '15px' : 'auto',
            },
            '& > .MuiBox-root .MuiFormControl-root .MuiSelect-select': {
              padding: '14px 32px 10px 10px',
            },
          }}
        >
          {position === 'right' && (
            <SelectItem
              onChange={value => {
                setMonth(moment(`${value}-01`).format('YYYY-MM-DD'));
              }}
              selected={
                m
                  ? m.format('YYYY-MM')
                  : month
                  ? `${month.split('-')[0]}-${month.split('-')[1]}`
                  : moment().format('YYYY-MM')
              }
              options={getRanges()}
            />
          )}
          <ButtonCustom
            disabled={checkDisabledMonth(m)}
            variant="text"
            size="small"
            sx={{
              whiteSpace: 'nowrap',
              backgroundColor: '#F2F2F2',
              color: '#333333',
              visibility:
                position === null || position === 'left' ? 'visible' : 'hidden',
              display:
                position === 'right' ? 'none!important' : 'flex !important',
            }}
            onClick={() => {
              if (checkDisabledMonth(m)) return;
              const prevMonth = m.subtract(1, 'months').format('YYYY-MM-DD');
              setMonth(prevMonth);
              if (changeMonth) {
                changeMonth(prevMonth);
              }
            }}
            startIcon={<ArrowLeftIcon />}
          >
            <span className="text_btn">前の月</span>
          </ButtonCustom>

          {(!position || position === 'left') && (
            <SelectItem
              onChange={value => {
                const newMonth = moment(`${value}-01`).format('YYYY-MM-DD');
                setMonth(newMonth);
                if (changeMonth) {
                  changeMonth(newMonth);
                }
              }}
              selected={
                m
                  ? m.format('YYYY-MM')
                  : month
                  ? `${month.split('-')[0]}-${month.split('-')[1]}`
                  : moment().format('YYYY-MM')
              }
              options={getRanges()}
            />
          )}

          <ButtonCustom
            variant="text"
            size="small"
            disabled={checkDisabledMonth(m, false)}
            sx={{
              whiteSpace: 'nowrap',
              backgroundColor: '#F2F2F2',
              color: '#333333',
              visibility:
                position === null || position === 'right'
                  ? 'visible'
                  : 'hidden',
              display:
                position === 'left' ? 'none!important' : 'flex !important',
            }}
            onClick={() => {
              if (checkDisabledMonth(m, false)) return;
              const nextMonth = m.add(1, 'months').format('YYYY-MM-DD');
              setMonth(nextMonth);
              if (changeMonth) {
                changeMonth(nextMonth);
              }
            }}
            endIcon={<ArrowLeftIcon />}
          >
            <span className="text_btn">次の月</span>
          </ButtonCustom>
        </Stack>
        <Box position="relative" onMouseLeave={renderTags}>
          <CalendarComponent
            isLoggedIn={isLoggedIn}
            hiddenTag={hiddenTag}
            month={m.format('YYYY-MM')}
            isPlanModal={isPlanModal}
            data={isPlanModal ? calendarData : metaData}
            selectable={selectable}
            selectedDateRange={enumerateDays()}
            onClick={selectedDate => {
              // const newSelectedDateRange = [
              //   selectedDate,
              //   moment(selectedDate).add(1, 'day').format('YYYY-MM-DD'),
              // ];
              // setSelectedDateRange(newSelectedDateRange);
              // _.invoke(props, 'onSelectedDateRangeChange', newSelectedDateRange);
              if (selectedDateRange.length < 2) {
                const newSelectedDateRange = [
                  ...selectedDateRange,
                  selectedDate,
                ];
                setSelectedDateRange(newSelectedDateRange);
                _.invoke(
                  props,
                  'onSelectedDateRangeChange',
                  newSelectedDateRange,
                );
              } else if (selectedDateRange.length === 2) {
                setSelectedDateRange([selectedDate]);
                _.invoke(props, 'onSelectedDateRangeChange', [selectedDate]);
              } else {
                const newSelectedDateRange = [selectedDate];
                setSelectedDateRange(newSelectedDateRange);
                _.invoke(
                  props,
                  'onSelectedDateRangeChange',
                  newSelectedDateRange,
                );
              }
              setPossibleCheckinDate('');
            }}
            onHover={hoveredDate => {
              setHoverDate(hoveredDate);
            }}
            onDrag={day => {
              setSelectedDateRange(day);
              _.invoke(props, 'onSelectedDateRangeChange', day);
            }}
            onHoverCheckinDay={day => {
              setHiddenTag(false);
              setPossibleCheckinDate(day);
            }}
            possibleCheckinDate={possibleCheckinDate}
            fromSearch={fromSearch}
            isLoading={isLoading}
            guestCount={guestCount}
          />
        </Box>
      </Box>
    );
  };

  return (
    // <DndProvider backend={HTML5Backend}>
    <Stack>
      {label ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingBottom={3}
        >
          <Label>{label}</Label>
        </Box>
      ) : null}
      {viewMode === 'single' ? (
        renderCalendar(monthObject, null)
      ) : (
        <Grid container spacing={5}>
          <Grid item xs>
            {renderCalendar(monthObject, 'left')}
          </Grid>
          <Grid item xs>
            {renderCalendar(monthObject.clone().add(1, 'months'), 'right')}
          </Grid>
        </Grid>
      )}
      {!fromSearch ? (
        <Box
          mt={1}
          sx={{
            '& svg ': {
              marginRight: 1,
              width: '12px!important',
              height: '12px!important',
            },
          }}
        >
          <Typography
            fontSize={`14px`}
            color={`#4F4F4F`}
            lineHeight={`20px`}
            mb={1}
          >
            {isPlanModal
              ? '※1室合計料金を表示しています'
              : '※1泊1名あたりの最低宿泊料金を表示しています'}
          </Typography>
          <Typography fontSize={`14px`} color={`#000`} lineHeight={`20px`}>
            <MaruIcon />
            チェックイン可
          </Typography>
          <Typography fontSize={`14px`} color={`#000`} lineHeight={`20px`}>
            <TriangleIcon />
            残りわずか
          </Typography>
          <Typography fontSize={`14px`} color={`#000`} lineHeight={`20px`}>
            <EmptyIcon />
            チェックイン不可
          </Typography>
        </Box>
      ) : (
        ''
      )}
    </Stack>
    // </DndProvider>
  );
}

export default Calendar;

const Label = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    line-height: 16px;
  }
`;

const ButtonCustom = styled(Button)`
  &.MuiButtonBase-root {
    align-items: center;
    padding: 10px 8px;
    .MuiButton-iconSizeSmall {
      margin-top: 2px;
    }
    .MuiButton-endIcon {
      svg {
        transform: rotate(180deg);
      }
    }
    span.text_btn {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }
  }
`;
