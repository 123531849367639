// UI
import { Box } from '@mui/material';
import { Loader } from 'app/components/Loading';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import LoginAfterRegister from 'app/pages/LoginPage/components/LoginAfterRegister';
import { useLoginSlice } from 'app/pages/LoginPage/slice';
import { selectLogin } from 'app/pages/LoginPage/slice/selectors';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { setLoginTime, setToken } from 'utils/localStorage';

import Modal from '.';

interface ILoginAfterRegisterModal {}

const LoginAfterRegisterModal = (props: ILoginAfterRegisterModal) => {
  const history = useHistory();

  const {
    changeShowLoginAfterRegisterModalStatus,
    login,
    changeIsRedirectAfterLogin,
  } = useGlobalSlice().actions;
  const { showLoginAfterRegisterModal, isReservation, isRedirectAfterLogin } =
    useSelector(selectGlobal);
  const { setErrorMessages, setSuccessMessages } = useGlobalSlice().actions;
  const dispatch = useDispatch();
  const { loginStatus, accessToken, errorMess } = useSelector(selectLogin);
  const { loginRequest } = useLoginSlice().actions;

  const submitLogin = formData => {
    dispatch(
      loginRequest({ ...formData, email: showLoginAfterRegisterModal.email }),
    );
  };

  React.useEffect(() => {
    if (loginStatus === RequestStatus.SUCCESS) {
      dispatch(changeShowLoginAfterRegisterModalStatus({ open: false }));
      dispatch(login());
      setToken(accessToken);
      setLoginTime(new Date().getTime());
      // no redirect page
      if (isRedirectAfterLogin) {
        // if user reserve room and has not login => login and redirect to order info page
        if (isReservation) {
          history.push(Path.ORDER_INFO);
        } else {
          history.push(Path.SEARCH);
        }
      }
      dispatch(changeIsRedirectAfterLogin(true));
    }
    if (loginStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const isLoading = loginStatus === RequestStatus.REQUESTING;

  return (
    <>
      <Loader open={isLoading} />
      <Container>
        <CustomModal
          title={''}
          className="login_modal"
          open={showLoginAfterRegisterModal.open}
          // open={true}
          onClose={() => {
            dispatch(changeShowLoginAfterRegisterModalStatus({ open: false }));
          }}
        >
          <LoginAfterRegister
            email={showLoginAfterRegisterModal.email}
            submitLoginForm={submitLogin}
          />
        </CustomModal>
      </Container>
    </>
  );
};

LoginAfterRegisterModal.defaultProps = {};
export default LoginAfterRegisterModal;

const CustomModal = styled(Modal)`
  .login_form {
    .MuiCardContent-root {
      padding-top: 0px;
    }
  }
`;

const Container = styled(Box)``;
