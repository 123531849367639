import {
  AccountBox,
  Add,
  ArrowDropDown,
  CalendarToday,
  Remove,
} from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import Title from './Title';
import { useClickOutside } from 'app/hooks/useClickOutSide';
import { colors, fontsizes } from 'constants/styles';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { dMedia } from 'styles/media';
import { color, fontSize } from '@mui/system';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { getUrlWithNewParams } from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { DEFAULT_GUEST_COUNT } from 'constants/common';

interface IQuantity {
  adult: number;
  upperGrades: number;
  withMealsAndBedding: number;
  withSameMealsAndBedding: number;
  withoutMealsAndBedding: number;
  withoutSameMealsAndBedding: number;
}

export type Props = {
  isInModal?: Boolean;
  isFromSearch?: Boolean;
  updateQuantity?: (any) => void;
  quantity?: IQuantity;
  disableClick?: boolean;
};

export default function RoomFilter({
  isInModal = false,
  isFromSearch = false,
  disableClick = false,
  ...props
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    plans,
    active_plan,
    getPlansStatus,
    additionSearchInfo: {
      meal,
      adult,
      child,
      nights,
      child_A,
      child_B,
      child_C,
      child_D,
      child_E,
    },
    additionSearchInfo,
  } = useSelector(selectPlanAndRoom);

  const {
    actions: { changeAdditionSearchInfo },
  } = usePlanAndRoomSlice();

  const getAdult = () => {
    if (isFromSearch) {
      return 1;
    } else if (isInModal) {
      return adult ? adult : DEFAULT_GUEST_COUNT;
    }
    return 0;
  };

  const getDefaultValue = () => {
    if (isInModal) {
      return {
        upperGrades: 0,
        withMealsAndBedding: 0,
        withSameMealsAndBedding: 0,
        withoutMealsAndBedding: 0,
        withoutSameMealsAndBedding: 0,
      };
    }
  };

  const [quantity, setQuantity] = React.useState<IQuantity>({
    adult: getAdult(),
    upperGrades: isInModal ? child_A || 0 : 0,
    withMealsAndBedding: isInModal ? child_B || 0 : 0,
    withSameMealsAndBedding: isInModal ? child_C || 0 : 0,
    withoutMealsAndBedding: isInModal ? child_D || 0 : 0,
    withoutSameMealsAndBedding: isInModal ? child_E || 0 : 0,
  });
  const [roomDisplay] = React.useState<number>(7);
  // const [nightsNumber, setNightsNumber] = React.useState<number>(0);
  const [isShowClientDetail, showClientDetail] = React.useState<boolean>(false);
  const [isShowNightsSelect, showNightsSelect] = React.useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (isInModal) {
      setQuantity({
        ...quantity,
        adult: adult || 2,
      });
    } else {
      let params = queryString.parse(window.location.search);
      // only calculate guest count in url for adult, child is free
      if (isFromSearch) {
        setQuantity({
          adult: +params?.guest_count || 2,
          upperGrades: +params?.child || 0,
          withMealsAndBedding: props.quantity?.withMealsAndBedding ?? 0,
          withSameMealsAndBedding: props.quantity?.withSameMealsAndBedding ?? 0,
          withoutMealsAndBedding: props.quantity?.withoutMealsAndBedding ?? 0,
          withoutSameMealsAndBedding:
            props.quantity?.withoutSameMealsAndBedding ?? 0,
        });
      } else {
        setQuantity({
          adult: +params?.guest_count || 2,
          upperGrades: +params?.child_a_count || 0,
          withMealsAndBedding: +params?.child_b_count || 0,
          withSameMealsAndBedding: +params?.child_c_count || 0,
          withoutMealsAndBedding: +params?.child_d_count || 0,
          withoutSameMealsAndBedding: +params?.child_e_count || 0,
        });
      }
    }
    return () => {
      if (!isFromSearch) {
        dispatch(
          changeAdditionSearchInfo({
            ...additionSearchInfo,
          }),
        );
      }
    };
    // dispatch(changeAdditionSearchInfo({ nights: params?.nights ?? 0 }));
    // setNightsNumber(params?.nights ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!isFromSearch && !isInModal) {
      dispatch(
        changeAdditionSearchInfo({
          adult: quantity.adult,
          // child: quantity.upperGrades,
          ...getChildrens(),
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const guestCount = () => {
    return (
      quantity?.adult + quantity?.upperGrades + quantity?.withMealsAndBedding
    );
  };
  const getChildrens = () => {
    return {
      child_A: quantity.upperGrades,
      child_B: quantity.withMealsAndBedding,
      child_C: quantity.withSameMealsAndBedding,
      child_D: quantity.withoutMealsAndBedding,
      child_E: quantity.withoutSameMealsAndBedding,
    };
  };
  React.useEffect(() => {
    setIsFirstRender(false);
    if (!isShowClientDetail && !isFirstRender) {
      // if (guestCount())
      if (!isFromSearch && !isInModal) {
        history.push(
          getUrlWithNewParams({
            guest_count: quantity?.adult,
            child_a_count: quantity?.upperGrades,
            child_b_count: quantity?.withMealsAndBedding,
            child_c_count: quantity?.withSameMealsAndBedding,
            child_d_count: quantity?.withoutMealsAndBedding,
            child_e_count: quantity?.withoutSameMealsAndBedding,
          }),
        );
      }
      // change addition info to trigger get price for calendar
      if (isInModal) {
        dispatch(
          changeAdditionSearchInfo({
            adult: quantity.adult,
            // child: quantity.upperGrades,
            ...getChildrens(),
          }),
        );
        if (props.updateQuantity) {
          props.updateQuantity(quantity);
        }
      }
      if (isFromSearch && props.updateQuantity) {
        props.updateQuantity(quantity);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowClientDetail]);

  const handleShowClientDetail = () => {
    showClientDetail(!isShowClientDetail);
  };

  const handleShowNightsSelect = () => {
    showNightsSelect(!isShowNightsSelect);
  };

  const handleNightsNumber = (item: number) => {
    dispatch(changeAdditionSearchInfo({ nights: item }));
    // setNightsNumber(item);
    showNightsSelect(false);
    history.push(getUrlWithNewParams({ nights: item }));
  };

  const handleIncrement = (fieldName: string) => {
    const newData = { ...quantity };
    newData[fieldName] = newData[fieldName] + 1;
    setQuantity(newData);
  };

  const handleDecrement = (fieldName: string) => {
    const newData = { ...quantity };
    if (fieldName === 'adult' && newData[fieldName] === 1) {
      return;
    }
    if (newData[fieldName] === 0) {
      return;
    }
    newData[fieldName] = newData[fieldName] - 1;
    setQuantity(newData);
  };

  const renderDropDownList = () => {
    let arr: number[] = [];
    for (let i = 0; i < roomDisplay; i++) {
      arr.push(i + 1);
    }
    return arr.map(item => (
      <li key={item} onClick={() => handleNightsNumber(item)}>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: '28px',
            color: colors.grey1,
          }}
        >
          {item}泊
        </Typography>
      </li>
    ));
  };

  const renderQuantityGroup = (fieldName: string) => {
    return (
      <QuantityGroup>
        <QuantityControl
          style={{ borderRadius: '4px 0px 0px 4px' }}
          onClick={() => handleDecrement(fieldName)}
          className={
            fieldName === 'adult' && quantity[fieldName] === 1
              ? 'disable-quantity'
              : quantity[fieldName] === 0
              ? 'disable-quantity'
              : ''
          }
        >
          <Remove />
        </QuantityControl>
        <Quantity>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: fontsizes.main,
              lineHeight: '16px',
              color: colors.mainContent,
            }}
          >
            {quantity[fieldName]}名
          </Typography>
        </Quantity>
        <QuantityControl
          style={{ borderRadius: '0 4px 4px 0' }}
          onClick={() => handleIncrement(fieldName)}
        >
          <Add />
        </QuantityControl>
      </QuantityGroup>
    );
  };

  const [clientRef] = useClickOutside(() => {
    showClientDetail(false);
  });
  const [nightsRef] = useClickOutside(() => showNightsSelect(false));

  const infantsInfo = [
    { label: '食事あり・寝具あり', field: 'withMealsAndBedding' },
    { label: '食事あり・添い寝', field: 'withSameMealsAndBedding' },
    { label: '食事なし・寝具あり', field: 'withoutMealsAndBedding' },
    { label: '食事なし・添い寝', field: 'withoutSameMealsAndBedding' },
  ];

  const renderQuantityArea = item => {
    return (
      <QuantityField key={item?.field}>
        <Title text={item?.label} fontWeight={400} />
        {renderQuantityGroup(item.field)}
      </QuantityField>
    );
  };

  return (
    <Wrapper>
      <ClientInfo
        className={isFromSearch ? 'auto' : ''}
        ref={clientRef}
        isShowClientDetail={isShowClientDetail}
      >
        <ClientMainContent
          onClick={
            disableClick
              ? () => {
                  return;
                }
              : handleShowClientDetail
          }
        >
          {isFromSearch ? (
            <ClientNumber className="input">
              <Box>
                <Typography
                  component="span"
                  sx={{
                    margin: 0,
                    fontSize: '1rem',
                    fontWeight: '500',
                    lineHeight: '23px',
                    color: colors.grey1,
                  }}
                >
                  大人{quantity.adult}名、
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    margin: 0,
                    fontSize: '1rem',
                    fontWeight: '500',
                    lineHeight: '23px',
                    color: colors.grey1,
                  }}
                >
                  子供
                  {quantity.upperGrades +
                    quantity.withMealsAndBedding +
                    quantity.withSameMealsAndBedding +
                    quantity.withoutMealsAndBedding +
                    quantity.withoutSameMealsAndBedding}
                  名
                </Typography>
              </Box>
              {!disableClick && (
                <ArrowDown
                  className={isShowClientDetail ? 'rotate-icon' : ''}
                />
              )}
            </ClientNumber>
          ) : (
            <>
              <AccountBox />
              <ClientNumber>
                <Typography
                  sx={{
                    margin: 0,
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '23px',
                    color: colors.grey1,
                  }}
                >
                  {`大人 ${quantity.adult}名`}
                </Typography>
                <Typography
                  sx={{
                    margin: 0,
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '23px',
                    color: colors.grey1,
                  }}
                >
                  {`子供 ${
                    quantity.upperGrades +
                    quantity.withMealsAndBedding +
                    quantity.withSameMealsAndBedding +
                    quantity.withoutMealsAndBedding +
                    quantity.withoutSameMealsAndBedding
                  }名`}
                </Typography>
              </ClientNumber>
              {!disableClick && (
                <ArrowDropDown
                  className={isShowClientDetail ? 'rotate-icon' : ''}
                />
              )}
            </>
          )}
        </ClientMainContent>
        {isShowClientDetail && (
          <ClientDetail className={isFromSearch ? 'auto' : ''}>
            <QuantityField style={{ paddingBottom: '8px' }}>
              <Title text="大人" />
              {renderQuantityGroup('adult')}
            </QuantityField>
            <ChildBox>
              <Title text="子供" />
              <GrayBox>
                <Title fontWeight={700} marginBottom="8px" text="小学生" />
                <QuantityField>
                  <Title text="人数" fontWeight={400} />
                  {renderQuantityGroup('upperGrades')}
                </QuantityField>
              </GrayBox>
              <GrayBox>
                <Title text="乳幼児" fontWeight={700} marginBottom="8px" />
                {infantsInfo.map(item => renderQuantityArea(item))}
              </GrayBox>
            </ChildBox>
          </ClientDetail>
        )}
      </ClientInfo>
      {/* <RoomInfo ref={nightsRef}>
        <ClientMainContent onClick={handleShowNightsSelect}>
          <CalendarToday style={{ fontSize: '24px' }} />
          <Box
            sx={{
              margin: '0 16px',
              marginRight: '10px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '28px',
              }}
            >
              {nights}泊
            </Typography>
          </Box>
          <ArrowDropDown
            style={{ fontSize: '32px' }}
            className={isShowNightsSelect ? 'rotate-icon' : ''}
          />
        </ClientMainContent>
        {isShowNightsSelect && (
          <RoomDropDown>{renderDropDownList()}</RoomDropDown>
        )}
      </RoomInfo> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  .MuiPopover-paper {
    left: 0 !important;
  }

  /* @media screen and (max-width: 768px) {
    width: auto;
  } */
`;

const ClientInfo = styled.div<{
  isShowClientDetail: Boolean;
}>`
  height: 78px;
  ${props =>
    props.isShowClientDetail &&
    css`
      border-bottom: 1px solid ${colors.grey};
    `}
  position: relative;
  cursor: pointer;
  justify-content: center;
  display: flex;
  width: 100%;
  .MuiSvgIcon-root {
    font-size: 32px;
  }
  transition: 0.5s all;
  ${dMedia.tablet`
    height: 54px;
    padding-bottom: 0;
  `}

  &:after {
    content: '';
    height: 1px;
    width: 240px;
    position: absolute;
    left: 0px;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    border-bottom: 1px dashed ${colors.grey};
    ${props =>
      props.isShowClientDetail &&
      css`
        display: none;
      `}
    ${dMedia.tablet`
      border-bottom: unset;
    `}
  }
  &.auto {
    height: auto;
    border-bottom: none;
    &:after {
      border: none;
    }
    > div {
      justify-content: flex-start;
    }
  }
`;

const ClientDetail = styled.div`
  z-index: 100;
  position: absolute;
  box-shadow: 0px 9px 17px -2px rgb(0 0 0 / 11%);
  top: 79px;
  height: auto;
  width: 100%;
  background-color: ${colors.white};
  padding: 16px 30px;
  box-sizing: border-box;
  ${dMedia.tablet`
    top: 54px;
  `}
  &.auto {
    top: 40px;
    right: 0;
    height: auto;
    width: 100%;
    min-width: 320px;
    padding: 16px;
    ${dMedia.tablet`
      top: 54px;
      right: auto;
      left: 0;
    `}
  }
`;

const ClientNumber = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  &.input {
    margin: 0;
    padding: 6.5px 0 6.5px 10px;
    width: 170px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${colors.grey6};
    svg {
      width: 24px;
    }
    ${dMedia.tablet`
      width: 200px;
    `}
  }
  ${dMedia.tablet`
    flex-direction: row;
    gap: 15px;
  `}
`;

const QuantityControl = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .MuiSvgIcon-root {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    color: ${colors.white};
    margin: 0;
  }
  background-color: ${colors.blue};
  transition: 0.5s all;
`;

const Quantity = styled.div`
  margin: 0;
  width: 56px;
  height: 40px;
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuantityGroup = styled.div`
  display: flex;
  .disable-quantity {
    background-color: ${colors.grey5};
  }
`;

const QuantityField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ChildBox = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${colors.grey};
`;

const GrayBox = styled.div`
  margin-top: 16px;
  padding: 8px;
  background-color: ${colors.grey6};
  border-radius: 8px;
`;

const ClientMainContent = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  .rotate-icon {
    transform: rotate(180deg);
  }
`;

const RoomInfo = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${dMedia.tablet`
    height: 29px;
    padding-top: 9.5px;
  `}
`;

const RoomDropDown = styled.ul`
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  margin: 0;
  padding-left: 0;
  position: absolute;
  top: 64px;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0px 9px 17px -2px rgb(0 0 0 / 11%);
  max-height: 434px;
  overflow: auto;
  z-index: 100;
  li {
    list-style-type: none;
    border-top: 1px solid ${colors.grey};
    height: 61px;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 28px;
    cursor: pointer;
  }
  ${dMedia.tablet`
    top: 41px;
  `}
`;
