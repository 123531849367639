// UI
import CheckBoxItem from 'app/components/CheckBoxItem';
import { colors } from 'constants/styles';
import styled from 'styled-components';
import { dMedia } from 'styles/media';

interface IUnknownDate {
  onCheckUnknownDate?: (value: any) => void;
  isResetValue?: boolean;
}

const UnknownDate = (props: IUnknownDate) => {
  return (
    <CheckBoxCustom
      className="check_box_item"
      label={'宿泊日未定'}
      isChecked={false}
      onChange={props.onCheckUnknownDate}
      isResetValue={props.isResetValue}
    />
  );
};

export default UnknownDate;

const CheckBoxCustom = styled(CheckBoxItem)`
  &.check_box_item {
    .MuiFormControlLabel-root {
      align-items: stretch;
    }
    .MuiCheckbox-root {
      height: 20px;
    }
    .MuiTypography-root {
      margin-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${colors.grey1};
    }
    ${dMedia.tablet`
      align-self: flex-start;
    `}
  }
`;
