// UI
import { Box, Button, Stack, Typography } from '@mui/material';
import { colors } from 'constants/styles';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { CommonButton } from '../CommonButton';
import { MoneyItem } from '../MoneyItem';
import SingleImage from '../SingleImage';
import _ from 'lodash';
import { getCheckoutDate, getStringCheckinOutDate } from 'utils/helper';
import moment from 'moment';

import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Path from 'config/clientPath';
import { useTranslation } from 'react-i18next';
import { useGetInfoOrder } from 'utils/useGetInfoOrder';
interface IOrderHistoryItem {
  data: any;
  isCancelOrderPage?: boolean;
  onClick?: (data: any) => void;
}

const OrderHistoryItem = ({
  data: {
    plan: { images },
    // room_name,
    // plan_name,
    plan_id,
    arrival_date,
    checkout_date,
    number_of_stay,
    total_amount,
    meals,
    hotel_id,
    room_types,
    plan,
    hotel_slug,
    cancelable,
    reservation_status,
  },
  data,
  isCancelOrderPage = false,
  onClick,
  ...props
}: IOrderHistoryItem) => {
  //
  const history = useHistory();
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const { getPlanAndRoomName } = useGetInfoOrder();
  const { room_name, plan_name } = getPlanAndRoomName(data);

  const renderMoney = (title, price) => {
    return (
      <WrapTotal
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <TitleTotal>{title}</TitleTotal>
        <MoneyItem amount={price} isBold={true} fontSize={24} />
      </WrapTotal>
    );
  };

  const orderStatues = {
    cancelled: {
      label: 'キャンセル済',
      bgColor: '#EB5757',
      color: '#EB5757',
      borderColor: '#EB5757',
    },
    confirmed_success: {
      label: '予約中',
      bgColor: '#2F80ED',
      color: '#2F80ED',
      borderColor: '#2F80ED',
    },
    confirmed_info: {
      label: '宿泊済',
      bgColor: '#333333',
      color: '#333333',
      borderColor: '#333333',
    },
  };

  const renderStatusBtn = () => {
    let statusProperty = reservation_status;
    if (reservation_status === 'confirmed') {
      statusProperty = moment().isBefore(moment(arrival_date), 'day')
        ? 'confirmed_success'
        : 'confirmed_info';
    }
    const itemStatus = orderStatues?.[statusProperty];
    return (
      <StatusButton
        disableRipple
        style={{
          // backgroundColor: itemStatus?.bgColor,
          color: itemStatus?.color,
          border: `1px solid ${itemStatus?.borderColor}`,
        }}
      >
        {itemStatus?.label}
      </StatusButton>
    );
  };

  return (
    <Container spacing={2}>
      {!isCancelOrderPage && (
        <WrapButton flexDirection={'row'} gap={2}>
          {renderStatusBtn()}
          <a
            href={
              window.location.origin +
              `${Path.RESERVATION_PREFIX}/${
                hotel_slug ? hotel_slug : hotel_id
              }/`
            }
          >
            <CommonButton
              className="white-btn"
              variant="contained"
              sx={{
                background: colors.white,
                color: colors.blue,
                border: `1px solid ${colors.blue}`,
                fontSize: '15px',
                boxShadow: 'none',
                lineHeight: '20px',
                padding: '8px 62.5px',
                width: 'fit-content',
              }}
            >
              ホテル詳細を見る
            </CommonButton>
          </a>
          {cancelable && (
            <CancelButton
              onClick={() => {
                onClick && onClick(data);
              }}
            >
              <span>キャンセルする</span>
            </CancelButton>
          )}
        </WrapButton>
      )}

      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
        <Stack spacing={2} sx={{ flex: 1 }}>
          <SingleImage
            width={240}
            height={180}
            backgroundImage={_.first(images)}
          ></SingleImage>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <TitleRoom>部屋名</TitleRoom>
            <RoomName>{room_name}</RoomName>
            {/* <RoomName>VMG グランド（定員4名）</RoomName> */}
          </Stack>
        </Stack>
        <Stack spacing={2} sx={{ flex: 1 }}>
          <Stack spacing={1}>
            <TitleRoom>宿泊日</TitleRoom>
            <ContentRoom>{`${number_of_stay}泊 \n${getStringCheckinOutDate(
              arrival_date,
              // check out date is arrival date + number of stay
              getCheckoutDate(arrival_date, number_of_stay),
            )}`}</ContentRoom>
          </Stack>
          <Stack spacing={1}>
            <TitleRoom>プラン名</TitleRoom>
            <ContentRoom>{plan_name}</ContentRoom>
          </Stack>
          <GrayBoxMeal spacing={1}>
            <TitleRoom>食事内容</TitleRoom>
            <ContentRoom>
              {meals ? meals.join('、') : '-'}
              <br />
            </ContentRoom>
          </GrayBoxMeal>
        </Stack>
      </Stack>
      <Stack>
        <Box
          style={{
            borderTop: `0.5px solid ${colors.grey}`,
          }}
        >
          {renderMoney('合計', total_amount)}
        </Box>
        {/* {isCancelOrderPage && (
          <>
            {renderMoney('キャンセル料金', 20000)}
            {renderMoney('決済変更情報', 10000)}
          </>
        )} */}
      </Stack>
    </Container>
  );
};

OrderHistoryItem.defaultProps = {};
export default OrderHistoryItem;

const WrapButton = styled(Stack)`
  /* margin-left: auto; */
`;

const Container = styled(Stack)`
  padding: 24px 33px;
  outline: 1px solid ${colors.grey4};
  box-sizing: border-box;
  border-radius: 8px;
  ${dMedia.tablet`
    padding: 24px;
  `}
`;
const TitleRoom = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
`;
const RoomName = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.default};
  }
`;
const ContentRoom = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;
const GrayBoxMeal = styled(Stack)`
  background: ${colors.grey6};
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px 40px 16px 16px;
`;
const WrapTotal = styled(Stack)`
  padding-top: 16px;
  .money-item {
    span {
      font-weight: bold;
      line-height: 20px;
      color: ${colors.default};
    }
  }
`;
const TitleTotal = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.default};
  }
`;
const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }
    padding: 9px 12px;
    background-color: ${colors.grey6};
    border-radius: 8px;
    color: ${colors.blue};
    width: max-content;
    line-height: 20px;
    height: fit-content;
  }
`;

const StatusButton = styled(Button)`
  &.MuiButtonBase-root {
    color: ${colors.blue};
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }

    text-transform: none;
    padding: 9px 12px;
    border-radius: 8px;
    width: max-content;
    line-height: 20px;
    height: fit-content;
    cursor: default;
    &.MuiButton-root:hover {
      background-color: unset !important;
    }
  }
`;
