import { Button } from '@mui/material';
import styled from 'styled-components/macro';
import { colors } from 'constants/styles';

export const CommonButton = styled(Button)`
  padding: 9px 22px;
  width: 100%;
  box-shadow: none;
  &.white-btn {
    &:hover {
      color: ${colors.white};
      a {
        color: ${colors.white};
      }
    }
  }
`;
