import { createSelector } from '@reduxjs/toolkit';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.checkout || initialState;

export const selectCheckout = createSelector(
  [selectSlice, selectGlobal],
  (checkoutState, globalState) => {
    return {
      ...checkoutState,
      ...globalState,
    };
  },
);
