/* eslint-disable jsx-a11y/anchor-has-content */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';
import { TagList } from 'app/components/TagList';
import HotelImg from 'assets/imgs/hotel_1.png';
import { colors, fontsizes } from 'constants/styles';
import _ from 'lodash';
import queryString from 'query-string';
import { SyntheticEvent } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { IHotel } from '../slice/types';
export interface ISearchItem extends IHotel {
  onHover: () => void;
  onMouseLeave: () => void;
  isLoading?: boolean;
}

export interface IAvailability {
  date: string;
  lowest_price?: number;
  remain_room_count?: number;
}

export const getQueryString = () => {
  let params = queryString.parse(window.location.search);
  params = _.omit(params, ['order', 'tag_id', 'area_id']);
  params = _.omitBy(params, v => !v || v === -1);
  // remove query param when has not value or value is -1 (all value)
  return queryString.stringify(params);
};

export const SearchItem = ({ ...props }) => {
  const { image, prefecture, name, title, tags, availability, id, slug } =
    props;
  const history = useHistory();
  const ALERT_TEXT = '残り一室';
  const handleFallback = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = HotelImg;
  };

  const qs = getQueryString();
  return (
    <Card
      onMouseEnter={props.onHover}
      onMouseLeave={props.onMouseLeave}
      sx={{
        padding: '16px 8px',
        boxShadow: '0px 0px 1px 0px #00000040',
        borderRadius: 1,
      }}
      ref={props.parentRef}
    >
      <Stack
        sx={{ display: 'flex', flexDirection: { md: 'row', sm: 'column' } }}
      >
        <Box
          sx={{
            display: 'flex',
            width: { md: '180px', xs: '100%' },
            height: { md: '120px', xs: 'auto' },
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: {
                md: 180,
                xs: '100%',
              },
            }}
            image={image}
            alt={name}
            onError={handleFallback}
          />
        </Box>

        <CardWrapper>
          <CardContent
            sx={{
              flex: '1 0 auto',
              padding: {
                md: '0 24px 0 16px',
                xs: '0',
              },
            }}
          >
            <Typography
              mb={1}
              component="h5"
              sx={{
                fontSize: '12px',
                color: colors.grey3,
                mt: {
                  md: '0',
                  xs: '2rem',
                },
              }}
            >
              {prefecture}
            </Typography>
            <Typography
              variant="h4"
              mb={1}
              component="h4"
              sx={{ fontSize: '16px', fontWeight: 700 }}
            >
              {name}
            </Typography>
            <Description mb={1} sx={{ fontSize: '12px' }}>
              {title}
            </Description>

            <TagList data={_.map(_.filter(tags, { is_main: 1 }), 'tag_name')} />
          </CardContent>
        </CardWrapper>
      </Stack>

      {/* <Stack
        mt={1}
        mb={1}
        direction="row"
        minHeight="70px"
        spacing={0.5}
        sx={{
          overflow: 'auto',
          cursor: 'pointer',
          '& > div': {
            marginTop: 0,
            paddingTop: 0,
          },
        }}
        position="relative"
        onClick={() => {
          window.location.href = `/reservation/${slug ? slug : id}/`;
        }}
      >
        <PartialLoader open={props.isLoading} />

        {_.isArray(availability) && availability.length
          ? availability?.map((it, idx) =>
              it.remain_room_count && it.lowest_price ? (
                <Box
                  key={idx}
                  sx={{ flexBasis: { xs: '20%' }, fontWeight: 500 }}
                >
                  <PriceBox>
                    <Box component="span">{`${commonFormat(
                      moment(it.date),
                      'M/D (ddd)',
                    )}`}</Box>
                    <MoneyItem amount={+it.lowest_price} hasMin={true} />
                  </PriceBox>
                  {it.remain_room_count === 1 ? (
                    <OrangeTag component="span" mt="2px">
                      {ALERT_TEXT}
                    </OrangeTag>
                  ) : (
                    <Box height="16px"></Box>
                  )}
                </Box>
              ) : (
                <Box
                  key={idx}
                  sx={{ flexBasis: { xs: '20%' }, width: { xs: '32px' } }}
                >
                  <PriceBox className="grey-box">満室</PriceBox>
                  <Box
                    component="span"
                    height="16px"
                    sx={{ display: { md: 'flex', xs: 'none' } }}
                  ></Box>
                </Box>
              ),
            )
          : !props.isLoading && (
              <Typography
                color={`#EB5757`}
                textAlign={`center`}
                mt={2}
                mb={2}
                sx={{ width: '100%' }}
              >
                条件に合致する空室は見つかりませんでした
              </Typography>
            )}
      </Stack> */}

      <a
        href={
          window.location.origin +
          `/reservation/${!!slug ? slug : id}${qs ? '?' + qs : '/'}`
        }
      >
        <Button
          variant="contained"
          size="large"
          sx={{
            fontSize: fontsizes.main,
            margin: 0,
            padding: 1,
            background: colors.blue,
            width: '100%',
            boxShadow: 'none',
          }}
        >
          ホテルの詳細を見る
        </Button>
      </a>
    </Card>
  );
};

const PriceBox = styled(Box)`
  border: 1px solid ${colors.grey};
  padding: 0.4rem 5px;
  font-size: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .money-item {
    font-weight: 500;
  }
  ${dMedia.tablet`     
      font-size: 12px;
      .money-item {
        font-size: 12px;
      }
    `}

  &.grey-box {
    background: ${colors.grey};
    height: 56px;
    box-shadow: 0px 1px 0px 0px #0000000d;
    color: ${colors.grey3};
    @media only screen and (max-width: 1018px) {
      min-width: auto;
      text-align: center;
    }
    ${dMedia.tablet`
      height: 52px;
      font-size: 12px;     
      min-width: auto;  
    `}
  }
`;

const OrangeTag = styled(Box)`
  display: flex;
  font-weight: 500;
  /* max-width: 105px; */
  padding: 2px;
  justify-content: center;
  align-items: center;
  background: ${colors.orange};
  border-radius: 2px;
  font-size: ${fontsizes.xsText};
`;

const CardWrapper = styled(Box)`
  .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
`;

const Description = styled(Typography)`
  width: 100%;
  height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
