import { Box, TextField, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CommonButton } from 'app/components/CommonButton';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { colors, fontsizes } from 'constants/styles';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Path from 'config/clientPath';
import { useDispatch, useSelector } from 'react-redux';

import { useResetPasswordSlice } from './slice';
import { selectResetPassword } from './slice/selectors';
import { useGlobalSlice } from '../GlobalContainer/slice';
import { getToken } from 'utils/localStorage';
import { RequestStatus } from 'constants/API';
import ResetPasswordForm from './components/ResetPasswordForm';
import { Loader } from 'app/components/Loading';
import { checkTokenNotExpired } from 'utils/helper';

export interface IForgotPasswordPageProps {}
interface IForgetPassword {
  email: string;
}
export default function ForgotPasswordPage(props: IForgotPasswordPageProps) {
  const history = useHistory();
  const { setErrorMessages, setSuccessMessages } = useGlobalSlice().actions;
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState<string>('');
  const {
    getResetPasswordStatus,
    postResetPasswordStatus,
    errorMess,
    successMess,
  } = useSelector(selectResetPassword);

  const { getResetPasswordRequest, postResetPasswordRequest, resetStatus } =
    useResetPasswordSlice().actions;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleForgotPasswordSubmit = formData => {
    setEmail(formData.email);
    dispatch(getResetPasswordRequest(formData));
  };

  const handleResetPasswordSubmit = formData => {
    formData.email = email;
    dispatch(postResetPasswordRequest(formData));
  };

  React.useEffect(() => {
    // if (getToken() && checkTokenNotExpired()) {
    //   history.push(Path.SEARCH);
    // }
    return () => {
      dispatch(resetStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (postResetPasswordStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Successfully reset password']));
      history.push(Path.LOGIN);
    }
    if (getResetPasswordStatus === RequestStatus.SUCCESS && successMess) {
      dispatch(setSuccessMessages([successMess]));
    }
    if (
      getResetPasswordStatus === RequestStatus.ERROR ||
      postResetPasswordStatus === RequestStatus.ERROR
    ) {
      dispatch(setErrorMessages(errorMess));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResetPasswordStatus, postResetPasswordStatus]);

  const isLoading =
    getResetPasswordStatus === RequestStatus.REQUESTING ||
    postResetPasswordStatus === RequestStatus.REQUESTING;

  return (
    <>
      <Helmet>
        <title>パスワードを忘れた場合 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="パスワードを忘れた場合  | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Box>
        <Header />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{
            maxWidth: {
              md: '375px',
              xs: '100%',
            },
            minHeight: 'calc(100vh - 80px)',
            margin: {
              md: '0 auto',
              xs: '4rem 1rem',
            },
          }}
        >
          <Loader open={isLoading} />

          {getResetPasswordStatus === RequestStatus.SUCCESS ? (
            <Box
              sx={{
                paddingTop: { md: 4, xs: 0 },
                paddingBottom: { md: 4, xs: 0 },
              }}
            >
              <BoxHeader>パスワード変更</BoxHeader>
              <Typography
                sx={{
                  margin: { md: '36px 0', xs: '24px 0' },
                  whiteSpace: 'break-spaces',
                }}
                component="p"
                fontSize={fontsizes.main}
                color={colors.default}
                textAlign="center"
                lineHeight="24px"
              >
                パスワードは当サイトを利用する際に必要となります
                以下のフォームより新しいパスワードを入力してください
              </Typography>
              <ResetPasswordForm
                submitResetPassword={handleResetPasswordSubmit}
              />
            </Box>
          ) : (
            <Box
              sx={{
                paddingTop: { md: 4, xs: 0 },
                paddingBottom: { md: 4, xs: 0 },
              }}
            >
              <BoxHeader>パスワードを忘れた場合</BoxHeader>
              <Typography
                sx={{
                  margin: { md: '36px 0', xs: '24px 0' },
                  whiteSpace: 'break-spaces',
                }}
                component="p"
                fontSize={fontsizes.main}
                color={colors.default}
                textAlign="center"
                lineHeight="24px"
              >
                以下のフォームに登録しているメールアドレスを入力し、
                パスワード再設定メールを送信してください
              </Typography>
              <Box
                sx={{}}
                component="form"
                id="forget_password_form"
                onSubmit={handleSubmit(handleForgotPasswordSubmit)}
              >
                <Typography
                  gutterBottom
                  color={colors.mainContent}
                  fontWeight={500}
                >
                  メールアドレス
                </Typography>
                <TextField
                  {...register<keyof IForgetPassword>('email', {
                    required: 'こちらは必須項目です。',
                  })}
                  type="email"
                  placeholder="例）abcd@vmg.co.jp"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                />

                <CommonButton
                  className="white-btn"
                  variant="contained"
                  size="large"
                  type="submit"
                  form="forget_password_form"
                  sx={{
                    background: colors.blue,
                    boxShadow: 'none',
                    fontSize: '15px',
                    marginBottom: 1,
                    lineHeight: '20px',
                    padding: '10px',
                    marginTop: 5,
                  }}
                >
                  送信する
                </CommonButton>
              </Box>
            </Box>
          )}
        </Box>
        <Footer />
      </Box>
    </>
  );
}
