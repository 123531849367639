import styled from 'styled-components/macro';
import { Phone } from '@mui/icons-material';

import { BoxHeader } from 'app/components/BoxHeader';
import { dMedia } from 'styles/media';
import { Typography } from '@mui/material';

import { fontsizes, colors } from 'constants/styles';

interface IPhoneDetails {
  phoneNumber: string;
  name: string;
}

const normalize = phone => {
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export default function PhoneDetails(props: IPhoneDetails) {
  return (
    <>
      <BoxHeader fontSize={18}>電話番号</BoxHeader>
      <Content>
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: {
              xs: fontsizes.main,
              md: fontsizes.heading3,
            },
            lineHeight: {
              xs: '18px',
              md: '26px',
            },
            color: colors.heading,
            marginBottom: '16px',
            marginTop: '0',
          }}
        >
          VMG総合窓口（11:00〜20:00）
        </Typography>
        <PhoneWrapper href={`tel:${props.phoneNumber}`}>
          <Phone />
          <PhoneNumber className="phone-number-pc">
            {normalize(props.phoneNumber)}
          </PhoneNumber>
          <PhoneNumberSP>
            <PhoneNumber>電話で問い合わせる</PhoneNumber>
            <PhoneNumber>{normalize(props.phoneNumber)}</PhoneNumber>
          </PhoneNumberSP>
        </PhoneWrapper>
        <Notification>
          弊社が運営しております宿泊施設の、総合窓口にて承ります。
          <br className="notification-sp" />
          お電話の際は「{props.name}」のお問い合わせとお伝え下さい。
        </Notification>
      </Content>
    </>
  );
}

const Content = styled.div`
  padding: 40px 15px 0;
  margin-bottom: 50px;
  ${dMedia.tablet`
    padding: 0;
    padding-top: 40px
    `};
`;

const PhoneWrapper = styled.a`
  text-decoration: unset;
  .MuiSvgIcon-root {
    font-size: 32px;
    margin-right: 16px;
    color: ${colors.heading};
    ${dMedia.tablet`
    font-size: ${fontsizes.heading2};
    margin-right: 10px;
  `}
  }
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  ${dMedia.tablet`
    height: 52px;
    border-radius: 4px;
    border: 1px solid #BABFC3;
    width: 100%;
    justify-content: center;
    .phone-number-pc {
      display: none
    }
  `}
`;

const PhoneNumber = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: ${fontsizes.heading3};
  line-height: 26px;
  color: ${colors.grey1};
  ${dMedia.tablet`
    font-size: ${fontsizes.main};
    line-height: 18px;
  `}
`;

const Notification = styled.p`
  font-weight: 400;
  font-size: ${fontsizes.main};
  line-height: 20px;
  color: ${colors.grey3};
  ${dMedia.tablet`
  font-size: ${fontsizes.smallText};
  line-height: 18px;
  .notification-sp {
    display: none
  }
`}
`;

const PhoneNumberSP = styled.div`
  display: flex;
  flex-direction: column;
  display: none;
  text-align: center;
  ${dMedia.tablet`
    display: block
  `}
`;
