import moment from 'moment';
import 'moment/locale/ja';

moment.locale('ja');

export const convertDate = (d: moment.Moment | null) => {
  try {
    return d?.format('YYYY-MM-DD');
  } catch (e) {}
  return d;
};

export const commonFormat = (
  d: moment.Moment | null,
  format = 'YYYY/M/D(ddd)',
) => {
  try {
    return d?.format(format);
  } catch (e) {}
};

export const convertDateFormatSlash = (d: moment.Moment | null) => {
  try {
    return d?.format('YYYY/MM/DD');
  } catch (e) {}
  return d;
};
