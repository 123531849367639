// UI
import { fontsizes, colors } from 'constants/styles';
import uniq from 'lodash/uniq';
import { Box, CardMedia } from '@mui/material';
import Button from '@mui/material/Button';

import { BoxHeader } from 'app/components/BoxHeader';
import Title from './Title';
import Description from './Description';
import ItemName from './ItemName';
import styled, { css } from 'styled-components';

interface IEquipmentListData {
  room_count: number;
  checkin_time?: string;
  checkout_time?: string;
  tags: any;
  checkin_time_end: string;
  checkin_time_start: string;
  parking?: string;
  stay_image_url?: string;
  with_pets_url?: string;
}

interface IEquipmentListProps {
  isInHotelPage?: boolean;
  className?: string;
  equipmentListData?: IEquipmentListData;
  slug?: string;
}

export default function EquipmentList({
  isInHotelPage = true,
  className,
  equipmentListData,
  slug,
  ...props
}: IEquipmentListProps) {
  const renderCheckinTime = () => {
    if (!!equipmentListData) {
      const { checkin_time_end, checkin_time_start } = equipmentListData;
      if (!!checkin_time_end && !checkin_time_start) {
        return checkin_time_end.slice(0, 5);
      }
      if (!checkin_time_end && !!checkin_time_start) {
        return checkin_time_start.slice(0, 5);
      }
      if (!!checkin_time_start && !!checkin_time_end) {
        return `${checkin_time_start.slice(0, 5)}~${checkin_time_end.slice(
          0,
          5,
        )}`;
      }
    }
  };

  const categoryList = uniq(
    equipmentListData &&
      equipmentListData.tags
        .filter(item => item.is_main === 0)
        .sort((a, b) =>
          a.category_order > b.category_order
            ? 1
            : b.category_order > a.category_order
            ? -1
            : 0,
        )
        .map(item => item.category_name),
  );

  return (
    <Box className={className}>
      {isInHotelPage && <BoxHeader fontSize={18}>施設情報</BoxHeader>}
      <Box
        sx={
          isInHotelPage
            ? {
                padding: {
                  xs: '40px 0 0',
                  md: '40px 15px 0',
                },
                marginBottom: '50px',
              }
            : {}
        }
        className="equipment_list_content"
      >
        {isInHotelPage && (
          <>
            <BoxItem isInHotelPage={isInHotelPage}>
              <Title text="総客室数" />
              <Description>{equipmentListData?.room_count}室</Description>
            </BoxItem>
            <BoxItem isInHotelPage={isInHotelPage}>
              <Title text="チェックイン時間" />
              <Description>{renderCheckinTime()}</Description>
            </BoxItem>
            <BoxItem isInHotelPage={isInHotelPage}>
              <Title text="チェックアウト時間" />
              <Description>
                {equipmentListData?.checkout_time &&
                  equipmentListData?.checkout_time.slice(0, 5)}
              </Description>
            </BoxItem>
          </>
        )}
        {categoryList.map((item, index) => {
          return (
            <BoxItem key={index} isInHotelPage={isInHotelPage}>
              {isInHotelPage && <Title text={item} />}
              <ListWrapper isInHotelPage={isInHotelPage}>
                {equipmentListData?.tags
                  .filter(i => i.category_name === item)
                  .sort((a, b) =>
                    a.tag_order > b.tag_order
                      ? 1
                      : b.tag_order > a.tag_order
                      ? -1
                      : 0,
                  )
                  .map((i, index) => (
                    <MainItem key={index} disabledItem={!i.active}>
                      {i.tag_icon && (
                        <CardMedia
                          sx={{
                            width: '24px',
                            height: '24px',
                          }}
                          image={i.tag_icon}
                        />
                      )}
                      <ItemName size={11}>{i.tag_name}</ItemName>
                    </MainItem>
                  ))}
              </ListWrapper>
            </BoxItem>
          );
        })}
        {isInHotelPage && (
          <>
            {equipmentListData?.parking && (
              <BoxItem isInHotelPage={isInHotelPage}>
                <Title text="駐車場" />
                <Description
                  sx={{
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {equipmentListData?.parking}
                </Description>
              </BoxItem>
            )}
            <BtnGroup className="btn_equipment_group">
              {equipmentListData?.with_pets_url && (
                <Button
                  className="btn-equipment"
                  target="_blank"
                  href={equipmentListData?.with_pets_url}
                  variant="outlined"
                >
                  ペットとのご宿泊について
                </Button>
              )}
              {equipmentListData?.stay_image_url && (
                <Button
                  className="btn-equipment"
                  target="_blank"
                  href={equipmentListData?.stay_image_url}
                  variant="outlined"
                >
                  歴史建築での滞在について
                </Button>
              )}
            </BtnGroup>
          </>
        )}
      </Box>
    </Box>
  );
}

const BoxItem = styled(Box)<{ isInHotelPage?: Boolean }>`
  ${props =>
    props.isInHotelPage &&
    css`
      margin-bottom: 32px;
    `}
`;

const ListWrapper = styled(Box)<{ isInHotelPage?: Boolean }>`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  ${props =>
    props.isInHotelPage &&
    css`
      margin-top: 16px;
    `}
`;

const MainItem = styled(Box)<{
  disabledItem?: Boolean;
}>`
  .MuiSvgIcon-root {
    font-size: ${fontsizes.heading2};
    margin-bottom: 4px;
  }
  padding: 8px 0;
  opacity: ${props => (props.disabledItem ? 0.3 : 1)};
  width: 90px;
  background-color: ${colors.grey6};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const BtnGroup = styled(Box)`
  margin-top: 55px;
  .btn-equipment {
    padding: 12px 36px;
    height: 40px;
    margin-right: 8px;
    font-size: ${fontsizes.main};
    line-height: 16px;
    font-weight: 500;
    color: ${colors.grey1};
    border-radius: 4px;
    border-color: #bbbfc3;
  }
  @media screen and (max-width: 900px) {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-equipment {
      margin-right: 0px;
      margin-bottom: 8px;
      width: max-content;
    }
  }
`;
