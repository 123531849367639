import {
  Box,
  CardContent,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { BoxHeader } from 'app/components/BoxHeader';
import CheckBoxItem from 'app/components/CheckBoxItem';
import { CommonButton } from 'app/components/CommonButton';
import { CommonLink } from 'app/components/Link';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import Path from 'config/clientPath';
import { colors } from 'constants/styles';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';

interface ILoginForm {
  email: string;
  password: string;
}

interface IProps {
  className?: string;
  submitLoginForm: (formData: any) => void;
  id?: string;
}

const LoginForm = ({ className, ...props }: IProps) => {
  const dispatch = useDispatch();

  const { changeShowLoginModalStatus } = useGlobalSlice().actions;
  const history = useHistory();

  const handleLoginSubmit = formData => {
    // dispatch(login());
    // history.push(Path.SEARCH);
    props.submitLoginForm(formData);
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: codeResponse => {
      fetch(
        `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${codeResponse.access_token}`,
      )
        .then(res => res.json())
        .then(response => {
          props.submitLoginForm({
            email: response.email,
            googleAuth: { ...response, ...codeResponse },
          });
          dispatch(changeShowLoginModalStatus(false));
        });
    },
    onError: err => console.log('error with google', err),
    flow: 'implicit',
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Container className={className}>
      <FormContainer>
        <CardContent
          className="body"
          sx={{
            maxWidth: {
              md: '375px',
              xs: '100%',
            },
            margin: {
              md: '0 auto',
            },
            padding: {
              md: '80px 0',
              xs: '0',
            },
          }}
        >
          <BoxHeader fontSize={18}>
            アカウントをお持ちの方（ログイン）
          </BoxHeader>
          <Box
            sx={{
              marginTop: {
                md: 5,
                xs: 3,
              },
            }}
            component="form"
            id={props.id || 'login_form'}
            onSubmit={handleSubmit(handleLoginSubmit)}
          >
            <Stack spacing={4}>
              <Stack spacing={1}>
                <Typography gutterBottom color={colors.mainContent}>
                  メールアドレス
                </Typography>
                <TextField
                  {...register<keyof ILoginForm>('email', {
                    required: 'こちらは必須項目です。',
                  })}
                  type="email"
                  placeholder="例）abcd@vmg.co.jp"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography gutterBottom color={colors.mainContent}>
                  パスワード
                </Typography>
                <TextField
                  {...register<keyof ILoginForm>('password', {
                    required: 'こちらは必須項目です。',
                  })}
                  type="password"
                  placeholder="例）abcd@vmg.co.jp"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.password ? true : false}
                  helperText={errors.password?.message}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </Stack>
              <Stack direction="column" justifyContent="space-between">
                <CommonButton
                  className="white-btn"
                  variant="contained"
                  size="large"
                  type="submit"
                  form={props.id || 'login_form'}
                  sx={{
                    background: colors.blue,
                    boxShadow: 'none',
                    fontSize: '15px',
                    marginBottom: 1,
                    lineHeight: '20px',
                    padding: '10px',
                  }}
                >
                  ログインする
                </CommonButton>
                <FormControl>
                  <Controller
                    control={control}
                    name="remember"
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <CheckBoxItem
                          onChange={onChange}
                          label="ログインしたままにする"
                          isChecked={value || false}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Stack>

              <CommonLink
                to={Path.RESET_PASSWORD}
                onClick={() => dispatch(changeShowLoginModalStatus(false))}
              >
                パスワードを忘れた方
              </CommonLink>
            </Stack>

            <Box mt={5}>
              <BoxHeader>アカウントをお持ちでない方</BoxHeader>
              <CommonButton
                className="white-btn"
                variant="contained"
                sx={{
                  background: colors.white,
                  color: colors.blue,
                  border: `1px solid ${colors.blue}`,
                  margin: {
                    xs: '40px 0 20px',
                  },
                  fontSize: '15px',
                  boxShadow: 'none',
                  lineHeight: '20px',
                  padding: '10px',
                }}
                onClick={() => {
                  dispatch(changeShowLoginModalStatus(false));
                  // dispatch(changeShowRegisterModalStatus(true));
                  history.push(Path.REGISTRATION_INFO);
                }}
              >
                アカウントを作成して予約する
              </CommonButton>
              <CommonButton
                className="white-btn"
                variant="contained"
                sx={{
                  width: {
                    xs: '100%',
                  },
                  background: colors.white,
                  color: colors.blue,
                  border: `1px solid ${colors.blue}`,
                  margin: {
                    xs: '32px 0',
                  },
                  fontSize: '15px',
                  boxShadow: 'none',
                  lineHeight: '20px',
                  padding: '10px',
                }}
                onClick={() => {
                  dispatch(changeShowLoginModalStatus(false));
                }}
              >
                <a href={window.location.origin + Path.ORDER_INFO}>
                  アカウント登録せず予約する
                </a>
              </CommonButton>

              <BoxHeader>SNSでログイン</BoxHeader>
              {/* <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Googleでログインする"
                onSuccess={handleGoogleLoginSuccess}
                onFailure={handleGoogleLoginFailure}
                cookiePolicy="single_host_origin"
                render={props => ( */}
              <CommonButton
                className="white-btn"
                onClick={() => loginWithGoogle()}
                variant="contained"
                sx={{
                  background: colors.white,
                  color: colors.blue,
                  border: `1px solid ${colors.blue}`,
                  margin: {
                    xs: '40px 0 20px',
                  },
                  fontSize: '15px',
                  boxShadow: 'none',
                  lineHeight: '20px',
                  padding: '10px',
                }}
              >
                Googleでログインする
              </CommonButton>
              {/* )}
              /> */}
            </Box>
          </Box>
        </CardContent>
      </FormContainer>
    </Container>
  );
};

LoginForm.defaultProps = {};
export default LoginForm;

const Container = styled(Box)``;

const FormContainer = styled(Box)`
  max-width: 800px;
  margin: 0 auto;
  background: ${colors.white};
  .MuiCardContent-root {
    ${dMedia.tablet`
        padding: 3rem 1rem 2rem;
      `}
  }

  .MuiTypography-gutterBottom {
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
  }
  .MuiInputBase-input {
    font-size: 14px;
  }
  .MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: inherit;
    border-width: 1px;
  }
  .MuiButton-root {
    font-weight: 500;
    display: block;
    a {
      color: inherit;
      display: block;
      text-decoration: none;
    }
  }

  input:-internal-autofill-selected {
    background: none;
  }

  a {
    text-align: center;
  }
`;
