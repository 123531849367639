import React, { PropsWithChildren } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CACHED_URL } from 'constants/localStorage';
import path from 'config/clientPath';
import { isHavingToken } from 'utils/localStorage';

type IRouteProps = {
  component: React.ComponentType;
  location: {
    pathname: string;
  };
  path: string;
  exact?: boolean;
};

const ProtectedRoute = ({
  component: Component,
  location,
  ...rest
}: IRouteProps) => {
  const isLoggedIn = isHavingToken();
  /**
   *  Navigate to desired path after logging in
   */
  if (!isLoggedIn) {
    // const { location } = rest;
    localStorage.setItem(CACHED_URL, location.pathname);
  }

  // const cachedUrl = localStorage.getItem(CACHED_URL);

  return (
    <Route
      {...rest}
      render={(props: PropsWithChildren<any>) => {
        /**
         *  For common navigation in app
         */
        if (isLoggedIn) {
          return <Component {...props} />;
        }
        /**
         *  For navigation to cached path RIGHT after logging in
         */
        // if (isLoggedIn && cachedUrl) {
        //   // remove the cached path (if it exists) after achieve it for navigating purpose
        //   localStorage.removeItem(CACHED_URL);
        //   return <Redirect to={{ pathname: cachedUrl }} />;
        // }
        /**
         *  If not logined yet
         */
        return (
          <Redirect
            to={{
              pathname: path.LOGIN,
            }}
          />
        );
        // return <Component {...props} />;
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  location: {
    pathname: '/',
  },
};

export default ProtectedRoute;
