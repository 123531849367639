import { Box, CardContent, Stack, TextField, Typography } from '@mui/material';
import { CommonButton } from 'app/components/CommonButton';
import { colors } from 'constants/styles';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { BoxHeader } from '../BoxHeader';
import LabelItem from '../LabelItem';

interface Props {
  // has two page call this component: register page (=> show modal) and registration info page (=> show page)
  isRegisterPage?: boolean;
  submitRegisterForm: (formData: IAccountInfoRegister) => void;
}

interface IAccountInfoRegister {
  email: string;
  name: string;
  password: string;
  password_confirm: string;
}

export const AccountInfoRegister = ({
  isRegisterPage = false,
  ...props
}: Props) => {
  const handleFormSubmit = formData => {
    props.submitRegisterForm(formData);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  return (
    <FormContainer className="register_form">
      <CardContent
        className="body"
        sx={{
          maxWidth: {
            md: '375px',
            xs: '100%',
          },
          margin: {
            md: '0 auto',
          },
        }}
      >
        <Box
          component="form"
          id="account_info_register_form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Stack spacing={4}>
            <WrapBoxHeader>
              <BoxHeader fontSize={18}>アカウント仮登録</BoxHeader>
            </WrapBoxHeader>
            <Note>
              アカウント作成をするにはメールアドレスの{'\n'}
              登録が必要となります。{'\n'}
              以下のフォームよりメールアドレスを入力ください。
            </Note>
            <Stack spacing={2}>
              <FormField spacing={1}>
                <LabelItem label={'メールアドレス'} required></LabelItem>
                <TextField
                  {...register<keyof IAccountInfoRegister>('email', {
                    required: 'こちらは必須項目です。',
                  })}
                  placeholder="例）abcd@vmg.co.jp"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                />
              </FormField>

              <FormField spacing={1}>
                <LabelItem label={'氏名（漢字)'} required></LabelItem>
                <TextField
                  {...register<keyof IAccountInfoRegister>('name', {
                    required: 'こちらは必須項目です。',
                  })}
                  placeholder="氏名（漢字)"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.name ? true : false}
                  helperText={errors.name?.message}
                />
              </FormField>
            </Stack>
          </Stack>
          <Stack direction="column" justifyContent="space-between" mt={5}>
            <CommonButton
              variant="contained"
              size="large"
              type="submit"
              form="account_info_register_form"
              sx={{
                background: colors.blue,
                boxShadow: 'none',
              }}
            >
              確認メールを送付する
            </CommonButton>
          </Stack>
        </Box>
      </CardContent>
    </FormContainer>
  );
};

const FormContainer = styled(Box)`
  margin: 0 auto;
  .MuiOutlinedInput-root {
    height: 36px;
  }
  .MuiCardContent-root {
    padding: 0;
  }

  .MuiTypography-gutterBottom {
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
  .MuiInputBase-input {
    font-size: 14px;
    padding: 0 16px;
    line-height: 20px;
    color: ${colors.grey7};
  }
  .MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: inherit;
    border-width: 1px;
  }
  .MuiButton-root {
    height: 40px;
    font-weight: 500;
    display: block;
    font-size: 15px;
    line-height: 20px;
    padding: 10px 20px;

    ${dMedia.tablet`
      font-size: 15px;
      padding: 6px 22px;
      `}
    a {
      color: inherit;
      display: block;
      text-decoration: none;
    }
  }

  input:-internal-autofill-selected {
    background: none;
  }
`;

const FormField = styled(Stack)``;
const WrapBoxHeader = styled(Box)`
  .MuiTypography-root {
    font-size: 18px;
  }
`;

const Note = styled(Typography)`
  && {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    white-space: pre-line;
  }
`;
