import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'app/components/Loading';
import { Helmet } from 'react-helmet-async';
import { useCheckoutSlice } from 'app/pages/CheckoutPage/slice';
import { selectCheckout } from 'app/pages/CheckoutPage/slice/selectors';
import SelectItem from 'app/components/SelectItem';
import { useOrderInfoContext } from 'app/OrderInfoContext';

export interface ICreditCardFormProps {}

interface IPaymentForm {
  card_holder: string;
  card_number: string;
  expired_year: number;
  expired_month: string;
  security_code: string;
}
export default function CreditCardForm(props: ICreditCardFormProps) {
  const history = useHistory();
  const { changeConfirmOrderModalStatus, setErrorMessages } =
    useGlobalSlice().actions;
  const { showConfirmOrderModal } = useSelector(selectGlobal);
  const { customerInfo } = useSelector(selectCheckout);
  const dispatch = useDispatch();
  const [isGettingToken, setIsGettingToken] = React.useState<boolean>(false);
  const { changeCustomerInfo } = useCheckoutSlice().actions;
  const { activeTab, setActiveTab, activeCartItem, allPlans, setAllPlans } =
    useOrderInfoContext();
  const { cacheFormData } = customerInfo?.guest;

  const afterGenerateToken = response => {
    if (response.result === 'OK') {
      // setCacheFormData({
      //   ...cacheFormData,
      // paymentInfo: {
      //   ...cacheFormData?.paymentInfo,
      //   payment_method: 'softbank_payment',
      //   softbank_payment_token: response.tokenResponse.token,
      //   softbank_payment_token_key: response.tokenResponse.tokenKey,
      // },
      // });
      const newData = {
        ...customerInfo?.guest,
        cacheFormData: {
          ...cacheFormData,
          paymentInfo: {
            ...cacheFormData?.paymentInfo,
            payment_method: 'softbank_payment',
            softbank_payment_token: response.tokenResponse.token,
            softbank_payment_token_key: response.tokenResponse.tokenKey,
          },
        },
      };

      dispatch(changeCustomerInfo(newData));
      // dispatch(
      //   changeCustomerInfo({
      //     ...customerInfo.guest,
      //     payment: {
      //       ...customerInfo?.guest?.payment,
      //       payment_method: 'softbank_payment',
      //       softbank_payment_token: response.tokenResponse.token,
      //       softbank_payment_token_key: response.tokenResponse.tokenKey,
      //     },
      //   }),
      // );
      setIsGettingToken(false);
      // handle 2 task at once
      dispatch(changeConfirmOrderModalStatus(true));
      history.push(Path.CONFIRM);
    } else {
      dispatch(setErrorMessages(['Payment Info is wrong']));
      setIsGettingToken(false);
    }
  };

  const handleFormSubmit = formData => {
    const { payment } = customerInfo?.guest;
    const { card_number, security_code, expired_year, expired_month } =
      formData;
    setIsGettingToken(true);
    // history.push(`${Path.CONFIRM}`)

    const { paymentInfo } = cacheFormData;
    const merchantId = paymentInfo?.softbank_payment_merchant_id;
    const serviceId = paymentInfo?.softbank_payment_service_id;
    // const merchantId = '30132';
    // const serviceId = '104';

    const month = ('0' + expired_month).slice(-2);
    const data = {
      merchantId,
      serviceId,
      // ccNumber: '4126450131000620',
      // ccExpiration: '202412',
      // securityCode: '123',
      ccNumber: card_number,
      ccExpiration: `${expired_year}${month}`,
      securityCode: security_code,
    };

    // const { com_sbps_system } = window;
    window.com_sbps_system.generateToken(
      {
        ...data,
      },
      afterGenerateToken,
    );
  };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      card_holder: '',
      card_number: '',
      expired_year: '',
      expired_month: '',
      security_code: '',
    },
  });

  return (
    <Box>
      <Loader open={isGettingToken} />
      <CardContent
        className="body"
        sx={{
          maxWidth: {
            md: '375px',
            xs: '100%',
          },
          margin: {
            md: '0 auto',
          },
          border: {
            xs: 'none',
          },
          padding: {
            md: 2,
            xs: '16px 0',
          },
        }}
      >
        <BoxHeader>決済情報</BoxHeader>
        <Box
          mt={4}
          component="form"
          id="payment_form"
          onSubmit={handleSubmit(data => handleFormSubmit(data))}
        >
          <FormField spacing={0.5}>
            <Typography
              gutterBottom
              fontWeight={500}
              color={colors.mainContent}
              fontSize={fontsizes.main}
              marginBottom="0"
            >
              カード名義人
            </Typography>
            <TextField
              {...register<keyof IPaymentForm>('card_holder', {
                // required: 'こちらは必須項目です。',
                required: 'こちらは必須項目です。',
              })}
              placeholder="TARO VMG"
              fullWidth
              variant="outlined"
              size="small"
              error={errors.card_holder ? true : false}
              helperText={errors.card_holder?.message}
            />
          </FormField>
          <FormField spacing={0.5}>
            <Typography
              gutterBottom
              fontWeight={500}
              color={colors.mainContent}
              fontSize={fontsizes.main}
              marginBottom="0"
            >
              クレジットカード番号
            </Typography>
            <TextField
              {...register<keyof IPaymentForm>('card_number', {
                required: 'こちらは必須項目です。',
              })}
              type="number"
              placeholder="0000000000000000 ※ハイフン不要"
              fullWidth
              variant="outlined"
              size="small"
              error={errors.card_number ? true : false}
              helperText={errors.card_number?.message}
            />
          </FormField>

          <FormField spacing={0.5}>
            <Typography
              gutterBottom
              fontWeight={500}
              color={colors.mainContent}
              fontSize={fontsizes.main}
              marginBottom="0"
            >
              有効期限
            </Typography>
            <Stack spacing={1} direction="row">
              <FormControl sx={{ width: '100px' }}>
                <Controller
                  control={control}
                  name="expired_month"
                  rules={{
                    validate: () => {
                      return (
                        !!getValues('expired_month') || 'こちらは必須項目です。'
                      );
                    },
                  }}
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <SelectItem
                        error={errors.expired_month ? true : false}
                        onChange={onChange}
                        selected={value}
                        selectPlaceholder={'月'}
                        helperText={errors.expired_month?.message}
                        options={Array(12)
                          .fill('')
                          .map((i, k) => {
                            return {
                              label: k > 8 ? `${k + 1}` : `0${k + 1}`,
                              value: k + 1,
                            };
                          })}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: '100px' }}>
                <Controller
                  control={control}
                  name="expired_year"
                  rules={{
                    validate: () => {
                      return (
                        !!getValues('expired_year') || 'こちらは必須項目です。'
                      );
                    },
                  }}
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <SelectItem
                        error={errors.expired_year ? true : false}
                        onChange={onChange}
                        selected={value}
                        selectPlaceholder={'年'}
                        helperText={errors.expired_year?.message}
                        options={Array(20)
                          .fill('')
                          .map((i, k) => {
                            return {
                              label: (moment().year() + k).toString(),
                              value: moment().year() + k,
                            };
                          })}
                      />
                    );
                  }}
                />
              </FormControl>
            </Stack>
          </FormField>
          <FormField spacing={0.5}>
            <Typography
              gutterBottom
              fontWeight={500}
              color={colors.mainContent}
              fontSize={fontsizes.main}
              marginBottom="0"
            >
              セキュリティーコード
            </Typography>
            <TextField
              {...register<keyof IPaymentForm>('security_code', {
                required: 'こちらは必須項目です。',
              })}
              type="number"
              placeholder="0000"
              fullWidth
              variant="outlined"
              size="small"
              error={errors.security_code ? true : false}
              helperText={errors.security_code?.message}
              sx={{ maxWidth: { xs: '208px' } }}
            />
          </FormField>
          <Typography
            component="p"
            sx={{
              whiteSpace: 'break-spaces',
              color: '#4F4F4F',
              fontSize: fontsizes.main,
              lineHeight: '18px',
              marginBottom: 4,
              marginTop: 4,
            }}
          >
            ※VISA、MasterCard、AMERICAN EXPRESS、JCB、Diners
            をご利用いただけます。{'\n'}
            ※デビットカード・プリペイドカードはご利用いただけません。{'\n'}
            ※ご宿泊料金はチェックイン日に決済されます。{'\n'}
            ※カード決済が出来ない場合又は事前決済で予約完了確定後に通知が届く場合がございますが、カードの利用期限がご宿泊日前になっている、カードの限度額を越えている場合など多数の事象があり、個人情報の観点からホテル側には理由が開示されませんので、お客様ご自身でカード会社に確認をお願いいたします。
            {'\n'}
            チェックイン当日にカード決済が完了していない場合、チェックイン時にお伝えさせていただきますので、予めご了承ください。
            {'\n'}
            ※引落しの時期につきましては、カード会社によって異なる場合がごさいます。
          </Typography>

          <Button
            variant="contained"
            size="large"
            type="submit"
            form="payment_form"
            sx={{
              background: colors.blue,
              boxShadow: 'none',
              color: colors.white,
              fontSize: '15px',
              padding: '7px 22px',
              width: '100%',
            }}
          >
            予約する
          </Button>
        </Box>
      </CardContent>
    </Box>
  );
}

const FormField = styled(Stack)`
  margin-bottom: 24px !important;
  .css-mnuau6-MuiInputBase-root-MuiInput-root {
    &:after,
    &:before {
      border: none;
    }
    .MuiSelect-select {
      padding-left: 10px;
      background: ${colors.grey6};
      height: 26px;
      line-height: 26px;
      border-radius: 4px;
      font-weight: 500;
    }
  }
  .css-mnuau6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
