import { Box } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { AccountCircle } from '@mui/icons-material';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import { fontsizes } from 'constants/styles';
import Button from '@mui/material/Button';
import styled from 'styled-components';

interface Props {
  children: any;
}

export default function ProfileWrapper({ children }: Props) {
  const { handleLogout } = useGlobalFunc();
  return (
    <Box
      sx={{
        maxWidth: '562px',
        margin: '0 auto',
        padding: {
          xs: '25px 16px',
          md: '50px 0 150px',
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <BoxHeader
          icon={
            <AccountCircle
              sx={{
                boxSizing: 'content-box',
                fontSize: {
                  xs: fontsizes.heading2,
                  md: fontsizes.heading1,
                },
              }}
            />
          }
          fontSize={24}
        >
          会員情報
        </BoxHeader>
        <LogoutBtn
          sx={{
            position: 'absolute',
            top: {
              md: '5px',
              xs: 0,
            },
            right: 0,
          }}
          variant="outlined"
          onClick={handleLogout}
        >
          ログアウト
        </LogoutBtn>
      </Box>
      <Box
        sx={{
          paddingTop: '40px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

const LogoutBtn = styled(Button)`
  color: #333 !important;
  border-color: #bdbdbd !important;
  &:hover {
    background: unset !important;
  }
`;
