import { Box } from '@mui/material';

import Calendar from 'app/components/Calendar';
import { BoxHeader } from 'app/components/BoxHeader';
import moment from 'moment';
import { useHistory } from 'react-router';
import { getUrlPlansPage } from 'utils/helper';
import { IParamWithSlug } from 'types/common';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import omitBy from 'lodash/omitBy';
import { dMedia } from 'styles/media';
import styled from 'styled-components/macro';

interface IHotelCalendar {
  paramsList: any;
}

export default function HotelCalendar(props: IHotelCalendar) {
  const history = useHistory();
  let { slug } = useParams<IParamWithSlug>();

  const selectDate = data => {
    if (data.length > 1) {
      let dataParams = {
        ...props.paramsList,
        checkin_date: data[0],
        checkout_date: data[1],
      };
      const qs = queryString.stringify({ ...omitBy(dataParams, v => !v) });
      history.push(`${getUrlPlansPage(slug)}?${qs}`);
    }
  };

  const renderSelectedDate = () => {
    if (props.paramsList.checkin_date && props.paramsList.checkout_date) {
      return [props.paramsList.checkin_date, props.paramsList.checkout_date];
    }
    return [];
  };

  return (
    <Box
      sx={{
        marginTop: '50px',
      }}
    >
      <BoxHeader fontSize={18}>カレンダー</BoxHeader>
      <Wrapper
        sx={{
          width: {
            xs: '100%',
            sm: '351px',
          },
          margin: '0 auto',
          marginBottom: '50px',
          paddingTop: '40px',
          display: {
            xs: 'grid',
            md: 'block',
          },
          justifyContent: {
            xs: 'center',
            md: 'unset',
          },
        }}
      >
        <Calendar
          selectable={true}
          selectedDateRange={renderSelectedDate()}
          viewMode="single"
          label="料金・空室カレンダー"
          month={moment().format('YYYY-MM')}
          onSelectedDateRangeChange={selectDate}
        ></Calendar>
      </Wrapper>
    </Box>
  );
}

const Wrapper = styled(Box)`
  ${dMedia.sm`
    display: block!important;
  `}
`;
