import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { colors } from 'constants/styles';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as CheckedIcon } from 'assets/icons/form/checked_icon.svg';

export interface IInputItem {
  helperText?: string;
  nowrapHelperText?: boolean;
  labelPrimary?: string | ReactElement;
  labelSecondary?: ReactElement;
  textRequire?: string;
  required?: boolean;
  noValue?: boolean;
  maxWidth?: number | string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  fieldName?: string;
  // 1: default item, 2: item with large border
  formItemType?: 1 | 2;
  inputPadding?: string;
  borderRadiusItem?: number;
  field?: any;
}

const InputItem = ({
  helperText,
  labelPrimary,
  labelSecondary,
  textRequire = '必須',
  required = false,
  nowrapHelperText = false,
  noValue,
  maxWidth,
  formItemType = 1,
  inputPadding,
  borderRadiusItem,
  field,
  ...rest
}: IInputItem & OutlinedInputProps) => {
  return (
    <Container
      sx={{ maxWidth: maxWidth ? maxWidth : 'unset' }}
      className={`item_type_${formItemType}`}
      input_padding={inputPadding}
      border_radius_item={borderRadiusItem}
    >
      <FormControl fullWidth={rest.fullWidth}>
        {labelPrimary && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={1}
          >
            {typeof labelPrimary === 'string' && (
              <Box
                className={'labelPrimaryContainer'}
                display="flex"
                alignItems="center"
              >
                <LabelPrimary>{labelPrimary}</LabelPrimary>
                {required && <TextRequire pl={1}>{textRequire}</TextRequire>}
              </Box>
            )}
          </Box>
        )}
        <OutlinedInput margin="dense" inputRef={field?.ref} {...rest} />
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </FormControl>
    </Container>
  );
};

export default InputItem;

const Container = styled(Box)<{
  input_padding?: string;
  border_radius_item?: number;
}>`
  .MuiOutlinedInput-root {
    background: #eeeeee;
    border-radius: 5px;
    &.Mui-disabled {
      background: #cdcdcd;
      color: #222;
    }
  }
  .MuiOutlinedInput-input {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.05em;
    color: #222222;
    padding: 10px 19px;
    &.Mui-disabled {
      -webkit-text-fill-color: unset;
      color: #888;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  /* css for form item type */
  &.item_type_2 {
    .MuiOutlinedInput-root {
      border-radius: 60px;
    }
    .MuiOutlinedInput-input {
      padding: 18px 43px;
    }
  }

  /* css for props */
  ${props =>
    props.input_padding &&
    css`
      .MuiOutlinedInput-input {
        padding: ${props.input_padding};
      }
    `}

  ${props =>
    props.border_radius_item &&
    css`
      .MuiOutlinedInput-root {
        border-radius: ${props.border_radius_item}px;
      }
    `}
`;

const LabelPrimary = styled(Typography)`
  && {
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
    padding-right: 0;
  }
`;

const TextRequire = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #f2994a;
  }
`;
