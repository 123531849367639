// UI
import { Box, Stack } from '@mui/material';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { IAdditionRoomInfo, IRoomItem } from 'types/common';
import { getTagsData } from 'utils/helper';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { AddToCartButton } from '../Buttons/AddToCartButton';
import { ReserveButton } from '../Buttons/ReserveButton';
import { MoneyItem } from '../MoneyItem';
import { TagList } from '../TagList';

interface IRecommendedPlanItem {
  data: IRoomItem;
  className?: string;
}

const RecommendedPlanItem = ({
  data: { tags, name, price, addition_info, price_for_1_person, hasStock },
  className,
  data,
}: IRecommendedPlanItem) => {
  const { plan_id, local_hotel_id } = addition_info || {};
  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;
  const { addItemToCart, setSuccessMessages } = useGlobalSlice().actions;
  const dispatch = useDispatch();

  const {
    actions: { setActivePlan },
  } = usePlanAndRoomSlice();

  return (
    <Container className={className}>
      <Header
        mb={1}
        onClick={() => {
          dispatch(
            setActivePlan({ hotel_id: local_hotel_id, plan_id: plan_id }),
          );
        }}
      >
        {name}
      </Header>
      <TagList data={getTagsData(tags)}></TagList>
      <Prices direction="row" spacing={isMobile ? 1 : 0.5} mb={2} mt={1}>
        {isMobile ? (
          <>
            <PriceInfo style={{ justifyContent: 'center' }}>
              <RoomNumber>1名あたり</RoomNumber>
              <MoneyItem
                amount={price_for_1_person}
                isBold
                fontSize={18}
              ></MoneyItem>
            </PriceInfo>
            <PriceInfo style={{ justifyContent: 'center' }}>
              <RoomNumber>1室合計</RoomNumber>
              {/* twoNightPrice */}
              <MoneyItem amount={price} isBold fontSize={18}></MoneyItem>
            </PriceInfo>
          </>
        ) : (
          <PriceInfo direction="row">
            <RoomNumber> 1室合計</RoomNumber>
            <MoneyItem amount={price} isBold fontSize={18}></MoneyItem>
            {!isMobile && (
              <Total>{`(1名あたり ￥${new Intl.NumberFormat('ja-JP').format(
                price_for_1_person,
              )})`}</Total>
            )}
          </PriceInfo>
        )}
      </Prices>
      <WrapButton
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 1 : 1}
      >
        <ReserveButton item={data}></ReserveButton>
        <AddToCartButton item={data}></AddToCartButton>
      </WrapButton>
    </Container>
  );
};

RecommendedPlanItem.defaultProps = {};
export default RecommendedPlanItem;

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;
  background: #fcfcfc;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  @media (max-width: 768px) {
    padding-right: 27px;
  }
`;

const Header = styled(Box)`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #2f80ed;
  cursor: pointer;
`;

const RoomNumber = styled(Box)`
  color: #828282;
`;
const Total = styled(Box)`
  color: #828282;
`;

const WrapButton = styled(Stack)``;

const Prices = styled(Stack)`
  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;

const PriceInfo = styled(Stack)`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  .money-item {
    line-height: 24px;
    span {
      font-weight: 700;
    }
  }
  @media (max-width: 768px) {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 16px;
    flex-direction: column;
    flex: 1;
  }
`;
