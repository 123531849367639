import { StatusCode } from 'constants/API';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import actions from 'app/pages/GlobalContainer/slice/';
import {
  getCountDetailGuest,
  getReservationId,
  getReservationStatusesInfo,
  lodash,
} from 'utils/helper';
import { IReservationStatus } from 'types/common';
import { getCartItemsFromStorage } from 'utils/localStorage';
import _ from 'lodash';

function* createOrderFlow({ payload }) {
  const abc = {
    guest: {
      birthday: '1970-01-01',
      address: '南一条西',
      gender: 'male',
      prefecture_code: '01',
      city: '札幌市中央区',
      name: 'son',
      telephone: '00000000000',
      kana: 'dang',
      postal_code: '0600061',
      email: 'duyhungp+8@gmail.com',
    },
    reservations: [
      {
        reservation: {
          hotel_id: 99,
          reservation_datetime: '2022-01-22 10:00:00',
          arrival_date: '2022-01-29',
          check_in_time: '12:00',
          number_of_stay: 1,
          number_of_room: 2,
          total_amount: 16500,
          plan_id: 27,
        },
        room_types: [
          {
            date_of_stay: '2022-01-29',
            room_type_id: 1,
            guests: [
              {
                guest_type: 'male',
                count: 2,
                rate: 0,
              },
            ],
          },
        ],
        add_on_services: [],
      },
    ],
  };
  try {
    // const response = yield call(APIs.createOrderTest, abc);
    const address = payload?.data?.guest?.address;
    // const newData = {
    //   ...payload?.data,
    //   guest: {
    //     ...payload?.data?.guest,
    //     address: address || ' ',
    //     city: ' ',
    //     prefecture_code: '01',
    //   },
    // };
    const newData = [...payload?.data];

    const response = yield call(APIs.createOrder, {
      data: newData,
      account_id: payload?.account_id,
    });

    let reservation_status = 'confirmed';
    // reservation_status =
    //   response?.data?.reservations[0]?.reservation?.reservation_status;

    if (response.status === StatusCode.SUCCESS_COMMON) {
      const allReservations = response?.data?.reservations || [];

      const allReservationStatues = allReservations.map(
        v => v.reservation.reservation_status,
      );
      let selectedOrders: number[] = [];
      let reservation_statuses_info: IReservationStatus[] = [];
      if (allReservationStatues.includes('out_of_stock')) {
        const cart_items = getCartItemsFromStorage() ?? [];
        const cartItemsObj = lodash.keyBy(cart_items, 'reservationId');
        reservation_status = 'out_of_stock';
        const identifyCartItems = allReservations.map(v => {
          const { reservation, room_types } = v;
          const { arrival_date, check_in_time, hotel_id, plan_id } =
            reservation;
          const roomType = lodash.first(room_types);
          const { maleCount, femaleCount, childrenCount } = getCountDetailGuest(
            roomType?.guests,
          );
          const reservationId = getReservationId({
            arrival_date,
            check_in_time,
            hotel_id,
            plan_id,
            room_type_id: roomType?.room_type_id,
            maleCount,
            femaleCount,
            childrenCount,
          });

          // handle cart items has same order
          const repeatItems = _.filter(cart_items, v1 => {
            return v1.reservationId === reservationId;
          });
          const countRepeatItems = _.size(repeatItems);

          let standardOrder = 0;
          // has many item same that order
          if (countRepeatItems > 1) {
            const sameItems = _.filter(
              repeatItems,
              v1 => !_.includes(selectedOrders, v1.reservationName),
            );
            if (_.size(sameItems)) {
              const foundItem = sameItems[0];
              standardOrder = foundItem?.reservationName;
              selectedOrders = [...selectedOrders, standardOrder];
            }
            // only one item has that order
          } else if (countRepeatItems === 1) {
            const foundItem = repeatItems[0];
            standardOrder = foundItem?.reservationName;
          }

          const foundCartItem = cartItemsObj?.[reservationId];
          reservation_statuses_info = [
            ...reservation_statuses_info,
            {
              name: '予約' + standardOrder,
              numberOrder: standardOrder,
              status: reservation.reservation_status,
            },
          ];
          return {
            ...v,
            reservationId,
            standardOrder,
            reservationNameDuplicated: foundCartItem?.reservationName,
          };
        });

        reservation_statuses_info = getReservationStatusesInfo(
          reservation_statuses_info,
        );
      } else {
        allReservations.every(v => {
          if (v?.reservation?.reservation_status !== 'confirmed') {
            reservation_status = v?.reservation?.reservation_status;
            return false;
          }
          return true;
        });
      }

      // check if all reservation is confirmed => user can create order
      if (reservation_status === 'confirmed') {
        yield put({
          type: Slice.createOrderSuccess,
          payload: response.data,
        });
      } else {
        yield put({
          type: Slice.createOrderFail.type,
          payload: {
            reservation_statuses_info,
            reservation_status,
          },
        });
      }
    } else {
      yield put({
        type: Slice.createOrderFail.type,
        payload: {
          reservation_statuses_info: [],
          reservation_status: '',
        },
      });
    }
  } catch (error) {
    yield put({
      type: Slice.createOrderFail.type,
      payload: {
        reservation_statuses_info: [],
        reservation_status: '',
      },
    });
  }
}

function* getOrderHistoryFlow({ payload }) {
  try {
    const response = yield call(APIs.getOrderHistory, {
      account_id: payload?.account_id,
    });
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getOrderHistorySuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getOrderHistoryFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getOrderHistoryFail.type });
  }
}

function* getOrderDetailFlow({ payload }) {
  try {
    const { needLoginPage } = payload;

    const response = yield call(
      needLoginPage ? APIs.getOrderDetail : APIs.getOrderDetailNologin,
      payload,
    );
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getOrderDetailSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getOrderDetailFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getOrderDetailFail.type });
  }
}

function* cancelOrderFlow({ payload }) {
  try {
    const { hotel_id, userEmail } = payload;
    let params: any = {
      data: payload?.data,
      account_id: payload?.account_id,
      reservation_id: payload?.reservation_id,
    };
    if (hotel_id) {
      params = {
        ...params,
        hotel_id,
        data: { ...params.data, email: userEmail },
      };
    }
    const response = yield call(
      hotel_id ? APIs.cancelOrderNoLogin : APIs.cancelOrder,
      params,
    );
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.cancelOrderSuccess,
        payload: response.data,
      });
      // const reservation_status =
      //   response?.data?.reservations[0]?.reservation?.reservation_status;

      // if (reservation_status === 'cancelled') {
      //   yield put({
      //     type: Slice.cancelOrderSuccess,
      //     payload: response.data,
      //   });
      // } else {
      //   yield put({
      //     type: Slice.cancelOrderFail.type,
      //     // payload: 'plan_rate_changes',
      //     payload: reservation_status,
      //   });
      // }
    } else {
      yield put({ type: Slice.cancelOrderFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.cancelOrderFail.type });
  }
}

function* checkInventoryFlow({ payload }) {
  try {
    const response = yield call(APIs.checkInventory, payload);

    const data = response?.data;
    const reservation_status = 'out_of_stock';
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.checkInventorySuccess,
        payload: data,
      });
    } else {
      const errorLocalIds = data?.local_id || [];
      const cart_items = getCartItemsFromStorage() ?? [];
      let reservation_statuses_info: IReservationStatus[] = [];
      _.map(cart_items, (v, k) => {
        const cartItemStatus = _.includes(errorLocalIds, v.local_id)
          ? 'out_of_stock'
          : 'confirmed';
        reservation_statuses_info = [
          ...reservation_statuses_info,
          {
            name: '予約' + (k + 1),
            numberOrder: k + 1,
            status: cartItemStatus,
          },
        ];
      });
      reservation_statuses_info = getReservationStatusesInfo(
        reservation_statuses_info,
      );

      yield put({
        type: Slice.checkInventoryFail.type,
        payload: {
          reservation_statuses_info,
          reservation_status,
        },
      });
    }
  } catch (error) {
    yield put({
      type: Slice.checkInventoryFail,
      payload: {
        reservation_statuses_info: [],
        reservation_status: '',
      },
    });
  }
}

function* checkoutWatcher() {
  yield takeLatest(Slice.createOrderRequest, createOrderFlow);
  yield takeLatest(Slice.getOrderHistoryRequest, getOrderHistoryFlow);
  yield takeLatest(Slice.cancelOrderRequest, cancelOrderFlow);
  yield takeLatest(Slice.getOrderDetailRequest, getOrderDetailFlow);
  yield takeLatest(Slice.checkInventoryRequest, checkInventoryFlow);
}

export default checkoutWatcher;
