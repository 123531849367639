import { Box, Collapse, Divider, Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import RoomFilter from 'app/components/RoomFilter';
import SelectItem from 'app/components/SelectItem';
import Path from 'config/clientPath';
import {
  DEFAULT_MEAL,
  MEALS,
  TYPE_TAB_PLAN,
  TYPE_TAB_ROOM,
} from 'constants/common';
import { colors, fontsizes } from 'constants/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import AccommodationInfo from 'app/pages/PlanAndRoomPage/components/AccommodationInfo';
import CheckInOutDate from 'app/pages/PlanAndRoomPage/components/CheckInOutDate';
import PlanListTab from 'app/pages/PlanAndRoomPage/components/Tabs/PlanListTab';
import RoomListTab from 'app/pages/PlanAndRoomPage/components/Tabs/RoomListTab';
import SuggestTitle from 'app/pages/PlanAndRoomPage/components/SuggestTitle';
import UnknownDate from 'app/pages/PlanAndRoomPage/components/UnknownDate';
import {
  checkIsRequesting,
  getUrlPlansPage,
  getUrlRoomsPage,
  getUrlWithNewParams,
  getFullUrlforTab,
} from 'utils/helper';
import { useParams } from 'react-router-dom';
import { IParamWithSlug } from 'types/common';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import RightPanel from '../RightPanel';
import { PartialLoader } from 'app/components/PartialLoader';
import queryString from 'query-string';
import Modal from 'app/components/Modal';
import Calendar from 'app/components/Calendar';
import moment from 'moment';
import debounce from 'lodash/debounce';
import InputItem from 'app/components/FormItems/InputItem';
import {
  AccountBox,
  Add,
  ArrowDropDown,
  CalendarToday,
  Remove,
} from '@mui/icons-material';

import _ from 'lodash';
// import _ from 'lodash';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

function TabPanel(props: TabPanelProps) {
  const {
    tab_type,
    sort_type,
    plans,
    additionSearchInfo: { meal },
    active_plan,
    active_room,
    getPlansWithStockStatus,
    getRoomsWithStockStatus,
  } = useSelector(selectPlanAndRoom);
  let params = queryString.parse(window.location.search);

  const [codeValue, setCodeValue] = React.useState<string | null>(
    queryString.parse(window.location.search).secret_plan_code,
  );
  const history = useHistory();
  const location = useLocation();
  const { children, value, index, handleChange, ...other } = props;
  const dispatch = useDispatch();
  const { width: pageWidth } = useWindowDimensions(0);
  const isSmallDesktop = pageWidth > 768 && pageWidth <= 1024;

  const [isOpenModalCalendar, setOpenModalCalendar] =
    React.useState<boolean>(false);
  const [month, setMonth] = React.useState<any>(moment().format('YYYY-MM'));
  const [selectedDateRange, setSelectedDateRange] = React.useState<string[]>(
    [],
  );

  const [checkinCheckout, setCheckinCheckout] = React.useState<string[]>([
    queryString.parse(window.location.search).checkin_date,
    queryString.parse(window.location.search).checkout_date,
  ]);

  const [isResetValue, setResetValueFlag] = React.useState(false);
  const [openCoupon, setOpenCoupon] = React.useState(false);

  const changeCheckInOutInfo = () => {
    let params = queryString.parse(window.location.search);
    const selectedDates =
      params?.checkin_date && params?.checkout_date
        ? [params?.checkin_date, params?.checkout_date]
        : [];
    dispatch(changeCheckInOutDate(selectedDates));

    setSelectedDateRange(selectedDates);
    const _checkInDate = params?.checkin_date
      ? moment(params?.checkin_date)
      : moment();
    const newMonth = _checkInDate.format('YYYY-MM');
    setMonth(newMonth);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFillCode = React.useCallback(
    debounce(nextValue => {
      let params = queryString.parse(window.location.search);
      const qs = queryString.stringify({
        ...params,
        secret_plan_code: nextValue,
      });
      history.push(`${location.pathname}?${qs}`);
    }, 500),
    [],
  );

  const handleChangeCode = e => {
    const { value } = e.target;
    setCodeValue(value);
    debounceFillCode(value);
  };
  const {
    actions: {
      filterPlans,
      getPlansRequest,
      changeAdditionSearchInfo,
      changeCheckInOutDate,
    },
  } = usePlanAndRoomSlice();

  React.useEffect(() => {
    changeCheckInOutInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    changeCheckInOutInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_plan, active_room]);

  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    const meal = +params?.tags || DEFAULT_MEAL;

    dispatch(changeAdditionSearchInfo({ meal }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckUnknownDate = value => {
    let params = queryString.parse(window.location.search);
    let qs;
    if (value) {
      setResetValueFlag(false);
      dispatch(changeCheckInOutDate(['', '']));

      qs = queryString.stringify(
        _.omit(params, ['checkin_date', 'checkout_date']),
      );
      setSelectedDateRange([]);
    } else {
      setSelectedDateRange(checkinCheckout);
      dispatch(changeCheckInOutDate(checkinCheckout));
      params.checkin_date = checkinCheckout[0];
      params.checkout_date = checkinCheckout[1];
      qs = queryString.stringify(params);
    }
    history.push(`${location.pathname}?${qs}`);
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }} className="single_tab">
          <Stack
            direction={{ xs: 'column', sm: 'column' }}
            alignItems={{ xs: 'center', sm: 'center' }}
            className="common_list_tab"
            style={{ position: 'relative' }}
          >
            <UpperDivider
              alignItems={'center'}
              direction={'column'}
              sx={{
                display: { xs: 'flex', sm: 'none' },
                px: { xs: 2, sm: '0px' },
              }}
            >
              <AccommodationInfo />
              <WrapRoomFilter
                sx={{
                  width: {
                    xs: 'calc(100vw - 32px)',
                    sm: '351',
                  },
                  maxWidth: {
                    xs: 'unset',
                    sm: '351px',
                  },
                  border: `1px solid ${colors.grey}`,
                  padding: '16px 0',
                  margin: '16px 0 0 0',
                  borderRadius: '8px',
                }}
                direction="column"
                alignItems={'center'}
              >
                <RoomFilter />
                <Stack
                  sx={{
                    pt: 1,
                    px: {
                      sm: 2,
                      xs: 0,
                    },
                    width: '100%',
                    pb: { xs: 1, sm: 3 },
                  }}
                  alignItems={'center'}
                >
                  <CheckInOutDate
                    onCheckUnknownDate={value => handleCheckUnknownDate(value)}
                    isResetValue={isResetValue}
                    onClick={() => {
                      if (pageWidth < 769) {
                        changeCheckInOutInfo();
                        setOpenModalCalendar(true);
                      }
                    }}
                  />
                </Stack>
                <Line />
                <WrapCoupon>
                  <Coupon>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: '#000',
                        textAlign: 'center',
                        marginBottom: '24px',
                      }}
                    >
                      プロモーションコードで検索
                    </Typography>
                    <ArrowDropDown
                      className={openCoupon ? 'rotate-icon' : ''}
                      onClick={() => setOpenCoupon(!openCoupon)}
                    />
                  </Coupon>

                  <Collapse in={openCoupon} timeout="auto" unmountOnExit>
                    <InputItem
                      value={codeValue}
                      onChange={handleChangeCode}
                      fullWidth
                    />
                  </Collapse>
                </WrapCoupon>

                <Modal
                  children={
                    <VacancyCalendar
                      sx={{ display: 'flex', alignItems: 'center' }}
                      spacing={1}
                      className="calendar-mobile"
                    >
                      <Calendar
                        selectable={true}
                        month={month}
                        selectedDateRange={selectedDateRange}
                        onSelectedDateRangeChange={checkInOutDate => {
                          dispatch(changeCheckInOutDate(checkInOutDate));
                          setResetValueFlag(true);
                          let params = queryString.parse(
                            window.location.search,
                          );
                          if (checkInOutDate && checkInOutDate.length === 2) {
                            setCheckinCheckout(checkInOutDate);
                            const qs = queryString.stringify({
                              ...params,
                              checkin_date: checkInOutDate[0],
                              checkout_date: checkInOutDate[1],
                            });
                            history.push(`${location.pathname}?${qs}`);
                            setOpenModalCalendar(false);
                          } else if (
                            checkInOutDate &&
                            checkInOutDate.length === 0
                          ) {
                            const qs = queryString.stringify(
                              _.omit(params, ['checkin_date', 'checkout_date']),
                            );
                            history.push(`${location.pathname}?${qs}`);
                            setOpenModalCalendar(false);
                          }
                        }}
                      />
                    </VacancyCalendar>
                  }
                  open={isOpenModalCalendar}
                  onClose={() => {
                    setOpenModalCalendar(false);
                  }}
                />
              </WrapRoomFilter>

              <Box
                mt={3}
                sx={{
                  display: { xs: 'block', sm: `none` },
                  width: '100%',
                }}
              >
                {renderTabs(tab_type, handleChange)}
              </Box>
            </UpperDivider>
            <CustomDivider sx={{ display: { xs: 'flex', sm: 'none' } }} />
            <WrapOrderTitle
              direction={{
                xs: 'row',
                sm: 'row',
              }}
              alignItems={'flex-end'}
              justifyContent={'center'}
              spacing={{
                xs: 3,
                sm: 5,
              }}
              sx={{
                width: '100%',
                px: { xs: 2 },
              }}
            >
              <SuggestTitle></SuggestTitle>
              {/* only show select unknown date in plan list tab */}
              {value === 0 && (
                <Box sx={{ maxWidth: 180, flex: 1 }}>
                  <Typography
                    component="h4"
                    sx={{
                      lineHeight: '16px',
                      fontSize: fontsizes.main,
                      fontWeight: '500',
                      color: colors.heading,
                      mb: 1,
                    }}
                  >
                    食事
                  </Typography>
                  <SelectItem
                    key={meal}
                    onChange={value => {
                      dispatch(changeAdditionSearchInfo({ meal: +value }));
                      history.push(
                        getUrlWithNewParams({ tags: value ? +value : '' }),
                      );
                      // dispatch(filterPlans({ meal: value }));
                    }}
                    selected={meal || DEFAULT_MEAL}
                    options={MEALS}
                  />
                </Box>
              )}
            </WrapOrderTitle>

            {isSmallDesktop ? (
              <Box sx={{ margin: '16px auto 0 auto' }}>
                <RightPanel></RightPanel>
              </Box>
            ) : (
              ''
            )}
            {children}
          </Stack>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const renderTabs = (tab_type, handleChange) => {
  return (
    <Tabs
      value={tab_type}
      onChange={handleChange}
      aria-label="basic tabs example"
    >
      <Tab label="プラン" {...a11yProps(0)} />
      <Tab label="お部屋" {...a11yProps(1)} />
    </Tabs>
  );
};

interface IAllTabs {
  url?: string | null;
}

const AllTabs = (props: IAllTabs) => {
  const { tab_type, sort_type, plans, getPlansStatus, getRoomsStatus } =
    useSelector(selectPlanAndRoom);
  const { slug } = useParams<IParamWithSlug>();

  const [value, setValue] = React.useState(tab_type);

  const dispatch = useDispatch();
  const {
    actions: { changeTabType },
  } = usePlanAndRoomSlice();
  const history = useHistory();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // dispatch(changeTabType(newValue));
    if (newValue === TYPE_TAB_PLAN) {
      history.push(getFullUrlforTab(true, slug));
    } else {
      history.push(getFullUrlforTab(false, slug));
    }
  };
  const location = useLocation();

  const regexCheckPlansPage = RegExp(
    `${Path.RESERVATION_PREFIX}\\/.+${Path.PLANS}/`,
  );
  const regexCheckRoomsPage = RegExp(
    `${Path.RESERVATION_PREFIX}\\/.+${Path.ROOMS}/`,
  );

  useEffect(() => {
    const newTabType = regexCheckPlansPage.test(location.pathname)
      ? TYPE_TAB_PLAN
      : TYPE_TAB_ROOM;
    dispatch(changeTabType(newTabType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const renderTabContent = () => {
    return location.pathname === Path.PLANS ? <PlanListTab /> : <RoomListTab />;
  };

  const handleBannerClick = () => {
    if (props.url) {
      window.open(props.url, '_blank');
    }
  };

  return (
    <Container
      sx={{
        flex: 1,
        position: 'relative',
      }}
      className="container_all_tabs"
    >
      <Box
        sx={{
          borderBottom: { xs: 'none', sm: `0.5px solid ${colors.grey4}` },
          pb: { xs: 0, sm: 3 },
          px: { xs: 2, sm: '0px' },
        }}
      >
        <DiscountInfo onClick={() => handleBannerClick()}>
          <Typography>
            {`予約は当サイトが最もお得！\n ベストレート保証 + HP限定特典`}
          </Typography>
        </DiscountInfo>
        <Box
          sx={{
            display: { xs: 'none', sm: `block` },
          }}
        >
          {renderTabs(tab_type, handleChange)}
        </Box>
      </Box>
      <TabPanel
        value={tab_type}
        index={TYPE_TAB_PLAN}
        handleChange={handleChange}
      >
        {regexCheckPlansPage.test(location.pathname) && <PlanListTab />}
        {/* {renderTabContent()} */}
      </TabPanel>
      <TabPanel
        value={tab_type}
        index={TYPE_TAB_ROOM}
        handleChange={handleChange}
      >
        {/* {renderTabContent()} */}
        {regexCheckRoomsPage.test(location.pathname) && <RoomListTab />}
      </TabPanel>
    </Container>
  );
};

export default AllTabs;

const DiscountInfo = styled(Box)`
  cursor: pointer;
  white-space: pre-line;
  margin-bottom: 24px;
  height: 48px;
  background: #27ae60;
  /* border: 1px solid #219653; */
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  .MuiTypography-root {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #fff;
  }
  ${dMedia.tablet`
    margin-bottom: 0px;
  `}
`;
const Container = styled(Box)`
  .MuiTabs-root {
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${colors.grey1};
    min-height: 40px;
    align-items: center;
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTab-root {
      padding: 8px 24px;
      min-height: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${colors.grey1};
      flex: 1;
      &.Mui-selected {
        background: ${colors.grey1};
        color: ${colors.white};
      }
    }
  }
  max-width: 640px;
  /* ${dMedia.xmedium`
      width: calc(100vw - 440px);
      max-width: none;
    `} */
  ${dMedia.tablet`
      max-width: none;
      width: 100%;
    `}
`;

const CustomDivider = styled(Divider)`
  padding-top: 24px;
  width: 100%;
`;

const UpperDivider = styled(Stack)`
  ${dMedia.sm`
    width: 100%;
  `}
`;

const WrapRoomFilter = styled(Stack)`
  ${dMedia.sm`
    width: 100%;
  `}
`;

const WrapOrderTitle = styled(Stack)`
  ${dMedia.tablet`
    margin: 24px auto 0 auto;
  `}
`;

const VacancyCalendar = styled(Stack)`
  width: 100%;
  padding: 0 7.5px;
  & > div {
    width: 100%;
  }
  &.calendar-mobile > div {
    align-items: center;
    padding: 0 0 20px;
  }
`;

const Line = styled(Box)`
  height: 1px;
  width: 274px;
  border-bottom: 1px dashed ${colors.grey};
  margin-top: 16px;
`;
const Coupon = styled(Box)`
  display: flex;
`;

const WrapCoupon = styled(Box)`
  margin-top: 16px;
  .MuiSvgIcon-root {
    font-size: 32px;
    margin-top: -7px;
  }
  .rotate-icon {
    transform: rotate(180deg);
  }
`;
