import { Stack, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useClickOutside } from 'app/hooks/useClickOutSide';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import Path from 'config/clientPath';
import { colors } from 'constants/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IParamTypes } from 'types/common';
import { getUrlPlansPage } from 'utils/helper';
import { getRecentlyHotel } from 'utils/localStorage';
import { useWindowDimensions } from 'utils/useWindowDimensions';

// import { LanguageSwitch } from 'app/components/LanguageSwitch';

interface IFooter {}

const Footer = (props: IFooter) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openHotelList = Boolean(anchorEl);

  const [clientRef] = useClickOutside(() => setAnchorEl(null));
  const handleCloseList = () => {
    setAnchorEl(null);
    setShowMenuSP(false);
  };

  const { isLoggedIn, hotelList } = useSelector(selectGlobal);
  const {
    login,
    logout,
    changeShowRegisterModalStatus,
    changeShowLoginModalStatus,
    getHotelListRequest,
  } = useGlobalSlice().actions;
  const dispatch = useDispatch();
  const { handleLogout } = useGlobalFunc();

  const [showMenuSP, setShowMenuSP] = useState(false);
  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;

  const hideOnPCProps = { display: { xs: 'flex', sm: 'none' } };

  useEffect(() => {
    dispatch(getHotelListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { slug } = useParams<IParamTypes>();
  const HOME_URL = 'https://www.vmg.co.jp';
  const MENU = [
    {
      id: 1,
      text: 'VMG HOTELS 一覧',
      link: Path.RESERVATION,
    },
    {
      id: 2,
      text: 'ホテル詳細',
      link: Path.RESERVATION,
    },
    {
      id: 3,
      text: 'プラン一覧',
      link: Path.RESERVATION,
    },
    {
      id: 4,
      text: '会員情報',
      link: Path.RESERVATION,
    },
    {
      id: 5,
      text: 'ログアウト',
      link: Path.RESERVATION,
    },
    {
      id: 6,
      text: 'ブランドサイト',
      link: `${HOME_URL}/hotels/`,
    },
    {
      id: 7,
      text: '特定商取引法に基づく表記',
      link: `${HOME_URL}/transaction/`,
    },
    {
      id: 8,
      text: 'プライバシーポリシー',
      link: `${HOME_URL}/privacy/`,
    },
    {
      id: 9,
      text: 'サイトの使い方・よくあるご質問',
      link: `${HOME_URL}/hotels/reservation/faq/`,
    },
  ];

  const renderItem = item => {
    switch (item.id) {
      case 1:
        return (
          <LinkText
            key={item.id}
            component="span"
            onClick={() => {
              history.push(`${Path.RESERVATION}`);
            }}
          >
            {item.text}
          </LinkText>
        );
      case 2:
        return (
          <LinkText
            key={item.id}
            component="span"
            onClick={() => {
              history.push(
                `${Path.RESERVATION_PREFIX}/${
                  slug ? slug : getRecentlyHotel()
                }/`,
              );
            }}
          >
            {item.text}
          </LinkText>
        );
      case 3:
        return (
          <LinkText
            key={item.id}
            component="span"
            onClick={() => {
              history.push(
                `${getUrlPlansPage(slug ? slug : getRecentlyHotel())}`,
              );
            }}
          >
            {item.text}
          </LinkText>
        );
      case 4:
        return isLoggedIn ? (
          <LinkText
            key={item.id}
            component="span"
            onClick={() => {
              history.push(`/reservation/account/`);
            }}
          >
            会員情報
          </LinkText>
        ) : (
          <LinkText
            key={item.id}
            component="span"
            onClick={() => {
              dispatch(changeShowRegisterModalStatus(true));
            }}
          >
            会員情報
          </LinkText>
        );
      case 5:
        return isLoggedIn ? (
          <LinkText
            key={item.id}
            component="span"
            onClick={() => {
              handleLogout();
            }}
          >
            ログアウト
          </LinkText>
        ) : (
          <LinkText
            key={item.id}
            component="span"
            onClick={() => {
              dispatch(changeShowLoginModalStatus(true));
            }}
          >
            ログイン
          </LinkText>
        );
      default:
        return (
          <a href={item.link} key={item.link}>
            <LinkText component="span">{item.text}</LinkText>
          </a>
        );
    }
  };
  return (
    <FooterWrapper
      sx={{ padding: '2rem', borderTop: '1px solid #1e1e1e' }}
      className="page__footer"
    >
      <Stack
        direction="row"
        spacing={4}
        sx={{
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        }}
      >
        <Link to={Path.SEARCH}>
          <Logo></Logo>
        </Link>

        <Box>
          <Stack
            direction="row"
            spacing={5}
            sx={{
              flexWrap: 'wrap',
              maxWidth: '650px',
              justifyContent: {
                md: 'flex-start',
                xs: 'space-evenly',
              },
              '& a': {
                textDecoration: 'none',
                color: '#333333',
              },
            }}
          >
            {MENU.map((it, idx) => {
              if (idx <= 4) {
                return renderItem(it);
              }
              return null;
            })}
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            mt={2}
            sx={{
              flexWrap: 'wrap',
              maxWidth: '650px',
              justifyContent: {
                md: 'flex-start',
                xs: 'space-evenly',
              },
              '& a': {
                textDecoration: 'none',
                color: '#333333',
              },
            }}
          >
            {MENU.map((it, idx) => {
              if (idx > 4) {
                return renderItem(it);
              }
              return null;
            })}
          </Stack>
        </Box>

        <Box>
          <Box
            sx={{
              padding: '10px 20px',
              border: '1px solid rgb(189, 189, 189)',
              '& .MuiTypography-root': {
                whiteSpace: 'nowrap',
              },
            }}
          >
            <Typography fontSize="12px">TEL 0120-210-289</Typography>
            <Typography fontSize="12px">
              VMG総合窓口 （11:00 ~ 20:00）
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Typography fontSize="12px" textAlign={`center`} mt={3}>
        © VMG HOTELS & UNIQUE VENUES
      </Typography>
    </FooterWrapper>
  );
};

Footer.defaultProps = {
  isLoggedIn: true,
};

export default Footer;

const RightFooter = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    height: 80px;
    flex: auto;
    a {
      width: 50%;
      svg {
        width: 100%;
      }
    }
  }
`;

const LinkText = styled(Typography)<{ component: 'span' }>`
  &.MuiTypography-root {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    cursor: pointer;
  }
`;

const FooterWrapper = styled(Box)`
  @media (max-width: 768px) {
    display: none;
  }
`;
