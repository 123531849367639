import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.hotelDetails || initialState;

export const selectHotelDetails = createSelector(
  [selectSlice],
  state => state.data,
);

export const selectGetDetailStatus = createSelector(
  [selectSlice],
  state => state.getStatus,
);

export const selectGetSuggestPlanStatus = createSelector(
  [selectSlice],
  state => state.getSuggestPlanStatus,
);

export const selectSuggestPlanByHotel = createSelector(
  [selectSlice],
  state => state.suggestPlans,
);
