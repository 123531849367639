import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Parser from 'html-react-parser';
import moment from 'moment';
import { Box, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { usePolicySlice } from './slice';
import { IPolicyState } from './slice/types';
import { selectPolicyData } from './slice/selectors';

import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { fontsizes, colors } from 'constants/styles';

export default function PrivacyPolicyPage() {
  const dispatch = useDispatch();

  const { getPolicy } = usePolicySlice().actions;

  const data: IPolicyState = useSelector(selectPolicyData);

  useEffect(() => {
    dispatch(getPolicy());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { title, content, updated_at } = data;

  return (
    <>
      <Helmet>
        <title>プライバシーポリシー | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="プライバシーポリシー | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Box
        sx={{
          maxWidth: '640px',
          margin: '0 auto',
          padding: '80px 16px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: {
              xs: '20px',
              md: '24px',
            },
            lineHeight: {
              xs: '28px',
              md: fontsizes.heading1,
            },
            color: colors.mainContent,
          }}
          variant="h3"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: {
              xs: fontsizes.main,
              md: '16px',
            },
            lineHeight: {
              xs: '20px',
              md: '24px',
            },
            color: colors.grey3,
            marginBottom: '40px',
          }}
        >
          {updated_at && moment(updated_at).format('YYYY.MM.DD')}
        </Typography>
        <Box>
          <Typography
            component={'span'}
            sx={{
              fontWeight: 400,
              fontSize: {
                xs: fontsizes.main,
                md: '18px',
              },
              lineHeight: {
                xs: '20px',
                md: '32px',
              },
            }}
          >
            {!!content ? Parser(content) : ''}
          </Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
