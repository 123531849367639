import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import hotelHistorySaga from './saga';
import { HotelHistoryState } from './types';

export const initialState: HotelHistoryState = {
  getStatus: RequestStatus.IDLE,
  data: [],
  errorMess: [],
};

const slice = createSlice({
  name: 'hotelHistory',
  initialState,
  reducers: {
    getHotelHistory: (state, action: PayloadAction<any>) => {
      state.getStatus = RequestStatus.REQUESTING;
    },
    getHotelHistorySuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getStatus = RequestStatus.SUCCESS;
        state.data = action.payload;
      }
    },
    getHotelHistoryFail: (state, action: PayloadAction<any>) => {
      if (action) state.getStatus = RequestStatus.ERROR;
    },
    resetGetRequestStatus: state => {
      state.getStatus = RequestStatus.IDLE;
      state.errorMess = [];
    },
  },
});

export default slice.actions;

export const useHotelHistorySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: hotelHistorySaga });
  return { actions: slice.actions };
};
