import { Box, Stack, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { Loader } from 'app/components/Loading';
import OrderHistoryItem from 'app/components/OrderHistoryItem';
import { ReactComponent as Calendar24Icon } from 'assets/icons/calendar-icon-24.svg';
import { ReactComponent as Calendar40Icon } from 'assets/icons/calendar-icon-40.svg';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { checkIsRequesting, checkTokenNotExpired } from 'utils/helper';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { useCheckoutSlice } from '../CheckoutPage/slice';
import { selectCheckout } from '../CheckoutPage/slice/selectors';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import Path from 'config/clientPath';
import { useHistory, useParams } from 'react-router';
import { RequestStatus } from 'constants/API';
import { CancelOrder } from './components/CancelOrder';
import EmailForm from './components/EmailForm';
import { getToken } from 'utils/localStorage';

export interface IParamTypes {
  order_id?: string;
  hotel_id?: string;
}

export function CancelOrderPage() {
  const history = useHistory();
  let { hotel_id, order_id } = useParams<IParamTypes>();

  const [needLoginPage, setNeedLoginPage] = React.useState(!!!hotel_id);
  const [userEmail, setUserEmail] = React.useState('');

  const { width: pageWidth } = useWindowDimensions(0);
  const {
    order_history,
    getOrderHistoryStatus,
    cancelOrderStatus,
    order_detail,
    getOrderDetailStatus,
  } = useSelector(selectCheckout);

  const { cart_items, userSessionData } = useSelector(selectGlobal);
  const dispatch = useDispatch();

  const {
    actions: {
      getOrderHistoryRequest,
      getOrderDetailRequest,
      resetGetOrderDetailStatus,
    },
  } = useCheckoutSlice();

  const isLoading = checkIsRequesting([
    // getOrderHistoryStatus,
    cancelOrderStatus,
    getOrderDetailStatus,
  ]);

  React.useEffect(() => {
    if (getOrderDetailStatus === RequestStatus.SUCCESS && order_detail) {
      if (!order_detail?.cancelable) {
        history.push(Path.NOT_FOUND_PAGE);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderDetailStatus]);

  React.useEffect(() => {
    // if need login => get order detail page
    if (needLoginPage) {
      dispatch(
        getOrderDetailRequest({
          account_id: userSessionData?.account?.account_id,
          reservation_id: order_id,
          needLoginPage,
        }),
      );
    } else {
      // no need login but is logged in => redirect to cancel order detail page which need login
      getToken() &&
        checkTokenNotExpired() &&
        history.push(Path.CANCEL_ORDER_DETAIL_PREFIX + order_id);
    }

    return () => {
      dispatch(resetGetOrderDetailStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>予約情報 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="予約情報 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Loader open={isLoading} />

      {/* render email form to fill to get order detail if no need login page */}
      {!needLoginPage && getOrderDetailStatus !== RequestStatus.SUCCESS && (
        <Box style={{ minHeight: 'calc(100vh - 240px)' }}>
          <EmailForm
            submitForm={data => {
              setNeedLoginPage(true);

              const { email } = data;
              setUserEmail(email);
              dispatch(
                getOrderDetailRequest({
                  hotel_id,
                  reservation_id: order_id,
                  needLoginPage,
                  data: {
                    email,
                  },
                }),
              );
            }}
          ></EmailForm>
        </Box>
      )}
      {/* render cancel order detail */}
      {needLoginPage && (
        <CancelOrder
          activeOrder={order_detail || {}}
          onBack={() => {
            // setActiveOrder(null);
          }}
          hotel_id={hotel_id}
          userEmail={userEmail}
        ></CancelOrder>
      )}
      <Footer />
    </>
  );
}

const WrapHeader = styled(Box)``;

const Container = styled(Stack)`
  max-width: 562px;
  width: 100%;
`;
