import {
  Button,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import CancelPolicy from 'app/components/CancelPolicy';
import { CardPreview } from 'app/components/CardPreview';
import CheckBoxItem from 'app/components/CheckBoxItem';
import { useCheckoutSlice } from 'app/pages/CheckoutPage/slice';
import { selectCheckout } from 'app/pages/CheckoutPage/slice/selectors';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { ReactComponent as Calendar24Icon } from 'assets/icons/calendar-icon-24.svg';
import { ReactComponent as Calendar40Icon } from 'assets/icons/calendar-icon-40.svg';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { colors } from 'constants/styles';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { getToken } from 'utils/localStorage';
import { useGetInfoOrder } from 'utils/useGetInfoOrder';
import { useWindowDimensions } from 'utils/useWindowDimensions';

import { CancelOrderSuccess } from './CancelOrderSuccess';
import SummaryMoney from './SummaryMoney';

interface ICancelOrder {
  travel: boolean;
  change_destination: boolean;
  change_schedule: boolean;
  change_accommodation: boolean;
  other: boolean;
  opinion: string;
}
interface IProps {
  activeOrder: any;
  hotel_id: any;
  userEmail: any;
  onBack: () => void;
}

const reasonsCancelOrder = {
  travel: '旅行の中止',
  change_destination: '行き先（エリア）の変更',
  change_schedule: '日程の変更',
  change_accommodation: '宿泊施設の変更',
  other: 'その他',
};

export function CancelOrder({
  activeOrder,
  onBack,
  hotel_id,
  userEmail,
}: IProps) {
  const {
    plan,
    arrival_date,
    number_of_stay,
    total_amount,
    room_types,
    payment_method,
    hotel_name,
    cancellation_fee,
  } = activeOrder;
  const guests = room_types ? room_types[0]?.guests || [] : [];

  const history = useHistory();
  const [showModalCancelSuccess, setShowModalCancelSuccess] =
    React.useState(false);
  const dispatch = useDispatch();
  const {
    setSuccessMessages,
    setErrorMessages,
    resetCart,
    changeShowOrderStatusModal,
  } = useGlobalSlice().actions;
  const {
    cancelOrderRequest,
    createOrderRequest,
    resetCreateAndCancelOrderStatus,
  } = useCheckoutSlice().actions;
  const { cancelOrderStatus, getOrderHistoryStatus, errorCode } =
    useSelector(selectCheckout);

  const { getPlanAndRoomName } = useGetInfoOrder();
  const { room_name, plan_name } = getPlanAndRoomName(activeOrder);

  const getPriceDetail = () => {
    let priceDetail: any = {};
    room_types?.map((v, k) => {
      v?.guests?.map(v1 => {
        const { guest_type, amount, count, rate } = v1;
        if (guest_type === 'male' || guest_type === 'female') {
          const oldCount = priceDetail?.adult?.count || 0;
          const oldPrice = priceDetail?.adult?.price || 0;
          // debugger;
          priceDetail = {
            ...priceDetail,
            adult: {
              ...priceDetail?.adult,
              // only calculate count for first item of room type
              count: k === 0 ? oldCount + count : oldCount,
              price: guest_type === 'male' ? oldPrice + rate : oldPrice,
            },
          };
        } else {
          const guest_name = guest_type.toLowerCase();
          const oldCount = priceDetail?.[guest_name]?.count || 0;
          const oldPrice = priceDetail?.[guest_name]?.price || 0;
          priceDetail = {
            ...priceDetail,
            [guest_name]: {
              count: k === 0 ? count : oldCount,
              price: oldPrice + rate,
            },
          };
        }
      });
    });
    return priceDetail;
  };

  const fakedOrderData = {
    images: plan?.images,
    reservation: {
      arrival_date,
      checkout_date: moment(arrival_date).add(number_of_stay, 'days'),
    },
    plan_name,
    room_name,
    totalPrice: total_amount,
    guests,
    cancellation_fee_policies: plan?.cancellation_fee_policies,
    meals: [],
    payment_method: {
      [payment_method]: true,
    },
    hotel_name,
    pricesDetail: getPriceDetail(),
  };

  const { width: pageWidth } = useWindowDimensions(0);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      travel: false,
      change_destination: false,
      change_schedule: false,
      change_accommodation: false,
      other: false,
      opinion: '',
    },
  });

  const { showConfirmOrderModal, cart_items, userSessionData } =
    useSelector(selectGlobal);

  React.useEffect(() => {
    if (cancelOrderStatus === RequestStatus.SUCCESS) {
      // setShowModalCancelSuccess(true);
      dispatch(setSuccessMessages(['キャンセルが完了しました']));
    } else if (cancelOrderStatus === RequestStatus.ERROR) {
      if (errorCode) {
        dispatch(changeShowOrderStatusModal(true));
      } else {
        dispatch(setErrorMessages(['Cancel order error']));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelOrderStatus]);

  const handleFormSubmit = formData => {
    const checkBoxSelected = _.omitBy(formData, v => !v);
    let reasons = '';
    _.forEach(checkBoxSelected, function (value, key) {
      reasons =
        reasons +
        (reasonsCancelOrder[key] ? reasonsCancelOrder[key] + '\n' : '');
    });
    if (formData?.opinion) {
      reasons = reasons + formData.opinion;
    }

    dispatch(
      cancelOrderRequest({
        data: {
          cancel: {
            cancel_datetime: moment().toISOString(),
            cancel_reason: reasons,
          },
        },
        account_id: getToken() ? userSessionData?.account?.account_id : '',
        reservation_id: activeOrder?.reservation_id,
        hotel_id,
        userEmail,
      }),
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(resetCreateAndCancelOrderStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const item = {
    image: '/img/hotels/room.png',
    room_name: 'VMG グランド（定員4名）',
    stay_date: '2泊',
    stay_period: '2021/08/17(火) ~ 2021/08/19(木)',
    plan_name: '通常ご宿泊プラン',
    meals: ['朝食（朝食）', '夕食（夕食）'],
    price: 203000,
  };

  return (
    <>
      {/* <Modal
        className="cancel_order_modal"
        // open={true}
        open={showModalCancelSuccess}
        onClose={() => {
          setShowModalCancelSuccess(false);
        }}
      >
        <CancelOrderSuccess activeOrder={activeOrder}></CancelOrderSuccess>
      </Modal> */}
      <Wrapper alignItems={'center'}>
        {cancelOrderStatus === RequestStatus.SUCCESS ? (
          // {cancelOrderStatus === RequestStatus.IDLE ? (
          <CancelOrderSuccess activeOrder={activeOrder}></CancelOrderSuccess>
        ) : (
          <Container spacing={5}>
            <BoxHeader
              fontSize={24}
              className="box_header"
              icon={pageWidth < 900 ? <Calendar24Icon /> : <Calendar40Icon />}
            >
              キャンセル
            </BoxHeader>
            <AllPanel direction={{ xs: 'column', sm: 'row' }} spacing={5}>
              <LeftPanel spacing={5}>
                <UpperContainer>
                  <WrapPolicy spacing={2}>
                    <CancelPolicy
                      data={plan?.cancellation_fee_policies}
                    ></CancelPolicy>
                    <Divider></Divider>

                    <SummaryMoney
                      // total_amount={total_amount}
                      activeOrder={activeOrder}
                    ></SummaryMoney>
                  </WrapPolicy>
                </UpperContainer>
                {/* <OrderHistoryItem
              data={activeOrder}
              isCancelOrderPage
            ></OrderHistoryItem> */}
                <Stack spacing={2}>
                  <WhyCancel>
                    キャンセルする理由をお聞かせください。(複数選択可)
                  </WhyCancel>
                  <Stack
                    component="form"
                    id="cancel_order_form"
                    onSubmit={handleSubmit(handleFormSubmit)}
                    spacing={2}
                    pl={1}
                  >
                    {/* travel */}
                    <FormControl>
                      <Controller
                        control={control}
                        name="travel"
                        render={({ field }) => {
                          const { onChange, value } = field;
                          return (
                            <CheckBoxItem
                              onChange={onChange}
                              label="旅行の中止"
                              isChecked={value}
                            />
                          );
                        }}
                      />
                    </FormControl>

                    {/* change_destination */}
                    <FormControl>
                      <Controller
                        control={control}
                        name="change_destination"
                        render={({ field }) => {
                          const { onChange, value } = field;
                          return (
                            <CheckBoxItem
                              onChange={onChange}
                              label="行き先（エリア）の変更"
                              isChecked={value}
                            />
                          );
                        }}
                      />
                    </FormControl>

                    {/* change_schedule */}
                    <FormControl>
                      <Controller
                        control={control}
                        name="change_schedule"
                        render={({ field }) => {
                          const { onChange, value } = field;
                          return (
                            <CheckBoxItem
                              onChange={onChange}
                              label="日程の変更"
                              isChecked={value}
                            />
                          );
                        }}
                      />
                    </FormControl>

                    {/* change_accommodation */}
                    <FormControl>
                      <Controller
                        control={control}
                        name="change_accommodation"
                        render={({ field }) => {
                          const { onChange, value } = field;
                          return (
                            <CheckBoxItem
                              onChange={onChange}
                              label="宿泊施設の変更"
                              isChecked={value}
                            />
                          );
                        }}
                      />
                    </FormControl>

                    {/* other */}
                    <FormControl>
                      <Controller
                        control={control}
                        name="other"
                        render={({ field }) => {
                          const { onChange, value } = field;
                          return (
                            <CheckBoxItem
                              onChange={onChange}
                              label="その他"
                              isChecked={value}
                            />
                          );
                        }}
                      />
                    </FormControl>

                    <FormField spacing={1} className={'text_area_field'}>
                      <TextField
                        {...register<keyof ICancelOrder>('opinion')}
                        multiline
                        rows={5}
                        placeholder="ご意見がございましたらお聞かせください。(100文字以内)"
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        error={errors.opinion ? true : false}
                        helperText={errors.opinion?.message}
                      />
                    </FormField>
                  </Stack>
                </Stack>

                <WrapButton
                  spacing={2}
                  direction="row"
                  justifyContent={'center'}
                  // sx={{
                  //   maxWidth: {
                  //     xs: 'none',
                  //     sm: '320px',
                  //   },
                  // }}
                >
                  <BackButton
                    onClick={() => {
                      history.push(Path.ORDER_HISTORY);
                    }}
                  >
                    <span>戻る</span>
                  </BackButton>
                  <CancelButton type="submit" form="cancel_order_form">
                    <span>キャンセルする</span>
                  </CancelButton>
                  {/* <FindPlanOtherButton
                onClick={() => {
                  history.push(Path.RESERVATION_PREFIX + '/' + activeOrder?.hotel_id);
                }}
              >
                <span>別のプランを探す</span>
              </FindPlanOtherButton>
              <FindPlanOtherButton
                onClick={() => {
                  history.push(getUrlPlansPage('', {}, activeOrder?.hotel_id));
                }}
              >
                <span>他のエリアのホテルを探す</span>
              </FindPlanOtherButton> */}
                </WrapButton>
              </LeftPanel>

              <RightPanel>
                <CardPreview
                  isCancelOrderPage
                  orderedCart={[fakedOrderData]}
                ></CardPreview>
              </RightPanel>
            </AllPanel>
          </Container>
        )}
      </Wrapper>
    </>
  );
}
const FindPlanOtherButton = styled(Button)`
  &.MuiButtonBase-root {
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }
    padding: 10px 12px;
    background-color: ${colors.blue};
    border-radius: 4px;
    color: ${colors.white};
    height: fit-content;
    width: 100%;
    &:hover {
      background-color: ${colors.blue};
      color: ${colors.white};
    }
  }
`;

const BackButton = styled(Button)`
  &.MuiButtonBase-root {
    max-width: 207px;
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }
    padding: 10px 12px;
    background-color: ${colors.white};
    border-radius: 4px;
    color: ${colors.blue};
    height: fit-content;
    width: 100%;
    border: 1px solid ${colors.blue};
    &:hover {
      background-color: ${colors.white};
      color: ${colors.blue};
    }
  }
`;
const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    max-width: 248px;
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }
    padding: 10px 12px;
    background-color: #eb5757;
    border-radius: 4px;
    color: ${colors.white};
    height: fit-content;
    width: 100%;
    &:hover {
      background-color: #eb5757;
      color: ${colors.white};
    }
  }
`;

const FormField = styled(Stack)`
  &.text_area_field {
    .MuiOutlinedInput-root {
      height: 120px;
      padding: 15px 15px 15px 12px;
      .MuiInputBase-input {
        padding: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #222;
      }
    }
  }
`;
const WrapButton = styled(Stack)`
  text-align: center;
  align-items: center;
  width: 100%;
`;

const Thanks = styled(Typography)`
  &.MuiTypography-root {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4f4f;
  }
`;

const TitleField = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;

const WrapHeader = styled(Typography)``;
// const WrapForm = styled(Stack)``;

const WhyCancel = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.grey1};
  }
`;

const Container = styled(Stack)`
  max-width: 1032px;
  width: 100%;
`;

const LeftPanel = styled(Stack)`
  max-width: 562px;
  width: 100%;
`;

const WrapPolicy = styled(Stack)`
  max-width: 340px;
  margin: 0 auto;
`;

const UpperContainer = styled(Stack)`
  padding: 24px 59px;
  outline: 1px solid ${colors.grey4};
  box-sizing: border-box;
  border-radius: 8px;
  ${dMedia.tablet`
    padding: 24px;
  `}
`;

const Wrapper = styled(Stack)`
  padding: 40px 0;
  margin: 0 auto;
  .box_header {
    padding-left: 10px;
    svg {
      padding-left: 0px;
    }
  }
  ${dMedia.tablet`
    padding: 16px 16px 80px 16px;
  `}
`;

const RightPanel = styled(Stack)``;
const AllPanel = styled(Stack)``;
