import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CommonButton } from 'app/components/CommonButton';
import {
  defaultCustomerInfo,
  useCheckoutSlice,
} from 'app/pages/CheckoutPage/slice';
import { selectCheckout } from 'app/pages/CheckoutPage/slice/selectors';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { CompanionInfoForm } from 'app/pages/OrderInfoPage/components/CompanionInfoForm';
import {
  ENQUETE_IDS_HAS_PLACEHOLDER,
  ENQUETE_PLACEHOLDERS,
  GENDERS_ENG,
} from 'constants/common';
import { colors, fontsizes } from 'constants/styles';
import Parser from 'html-react-parser';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { BoxHeader } from '../BoxHeader';
import CheckBoxItem from '../CheckBoxItem';
import Modal from '../Modal';
import SelectItem from '../SelectItem';

//form
import { useOrderInfoContext } from 'app/OrderInfoContext';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import usePlanAndRoom from 'app/pages/PlanAndRoomPage/usePlanAndRoom';
import { RequestStatus } from 'constants/API';
import { Controller, useFormContext } from 'react-hook-form';
import {
  checkTokenNotExpired,
  cloneFormData,
  getAdultCount,
  getReservationId,
  lodash,
  validatePaymentMethod,
} from 'utils/helper';
import { getToken } from 'utils/localStorage';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { SyncOrderInfo } from '../Checkout/components/SyncOrderInfo';
import ConfirmModal from '../ConfirmModal';
import InputItem from '../FormItems/InputItem';
import LabelItem from '../LabelItem';
// import { IOrderInfo } from 'types/common';
interface Props {
  setShowPaymentMethod: (isDisplayed) => void;
  tabId: any;
  cartItemValue: any;
  planIdValue: any;
  hasMultiple?: boolean;
}

interface IOrderInfo {
  male_adult_count?: string | number;
  // customer info
  name?: string;
  kana?: string;
  gender?: string;
  telephone?: string;
  postal_code?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  visit_by_car?: boolean;
  email?: string;
}

interface FormProps {
  [key: number]: {
    male_adult_count: number;
    female_adult_count: number;
    guest: any;
  };
}

export const OrderInfo = ({
  setShowPaymentMethod,
  tabId,
  cartItemValue,
  planIdValue,
  hasMultiple = false,
  ...props
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;
  const { activeTab, setActiveTab, activeCartItem, allPlans, setAllPlans } =
    useOrderInfoContext();

  const orderOfFields = [
    'eh3',
    's1',
    'check_in_time',
    'ep2',
    'eh2',
    // 'others',
    's3',
  ];
  const orderCheckBoxFields = ['c3', 'c2'];
  const fakeFields = {
    check_in_time: {
      enquete_id: 'check_in_time',
      enquete_type: 'select',
      confirm_label: '',
      select_answers: [],
      // required: false,
      required: true,
      enquete_text: 'チェックイン時間',
    },
    // others: {
    //   enquete_id: 'others',
    //   enquete_type: 'entry',
    //   confirm_label: '',
    //   select_answers: [],
    //   required: false,
    //   enquete_text: 'その他',
    // },
  };

  const { login, changeShowLoginModalStatus, removeItemToCart } =
    useGlobalSlice().actions;
  const {
    actions: { changeCustomerInfo },
  } = useCheckoutSlice();
  const { createOrderStatus, customerInfo } = useSelector(selectCheckout);

  const [showCustomerInfoModal, setShowCustomerInfoModal] = useState(false);
  const [showCompanionInfoModal, setShowCompanionInfoModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [addDynamicFieldSuccess, setAddDynamicFieldSuccess] = useState(false);
  // const [mode, setMode] = useState('edit');
  const [hasOldData, setHasOldData] = useState(false);
  // const [customerInfoData, setCustomerInfoData] = useState({
  //   ...customerInfo.guest,
  // });
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true);
  const {
    getPlanDetailRequest,
    getPlanDetailStatus,
    detail_plan,
    getPlanForOrderStatus,
    planForOrder,
  } = usePlanAndRoom();

  const {
    cart_items,
    changeCartItemInfo,
    handleRedirectPaymentPage,
    alertConflictPaymentMethod,
  } = useGlobalFunc();
  // const cartItemValue = cart_items[tabId];

  const getInitialAdultCount = () => {
    if (cart_items && cart_items.length) {
      const male_item = lodash.find(cartItemValue?.guests, [
        'guest_type',
        'male',
      ]);
      const female_item = lodash.find(cartItemValue?.guests, [
        'guest_type',
        'female',
      ]);
      return {
        male_adult_count: male_item ? male_item?.count : 0,
        female_adult_count: female_item ? female_item?.count : 0,
      };
    }
    return {
      male_adult_count: customerInfo?.guest?.male_adult_count,
      female_adult_count: customerInfo?.guest?.female_adult_count,
    };
  };

  const infoForm = useFormContext();

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    trigger,
    setValue,
    formState: { errors },
    formState,
  } = infoForm;

  const transformSelectOptions = v => {
    let newValue = { ...v };
    if (v?.enquete_type === 'select') {
      const select_answers = v?.select_answers.map(v1 => {
        return {
          label: v1?.select_answer_text,
          value: v1?.select_answer_id,
        };
      });
      newValue = { ...newValue, select_answers };
    }
    return { ...newValue };
  };

  const activePlan = planForOrder?.[planIdValue]
    ? planForOrder?.[planIdValue]
    : {};

  const { cacheFormData } = customerInfo?.guest;
  React.useEffect(() => {
    if (getPlanForOrderStatus?.[planIdValue] !== RequestStatus.SUCCESS) {
      return;
    }

    // setAllPlans({ ...allPlans, [cartItemValue?.local_id]: detail_plan });

    const initialAdultCount = getInitialAdultCount();

    let newFormData = {
      ...watch(),
      [tabId]: {
        male_adult_count: initialAdultCount.male_adult_count,
        female_adult_count: initialAdultCount.female_adult_count,
        guest: { ...customerInfo.guest },
      },
    };
    if (!_.isEmpty(cacheFormData) && cacheFormData?.[tabId]) {
      newFormData = {
        ...newFormData,
        [tabId]: { ...cacheFormData?.[tabId] },
      };
    }
    reset({
      ...newFormData,
    });
    if (activePlan?.enquetes && activePlan?.enquetes.length !== 0) {
      const newQuestions = lodash.filter(
        activePlan.enquetes,
        v => v.enquete_type === 'entry' || v.enquete_type === 'select',
      );
      const newCheckBoxes = lodash.filter(
        activePlan.enquetes,
        v => v.enquete_type === 'confirm',
      );

      // all questions
      let infoOfQuestions: any = [];
      // transform question with key
      let newQuestionsByKey = lodash.keyBy(newQuestions, 'enquete_id');

      orderOfFields.map(v => {
        if (newQuestionsByKey?.[v]) {
          infoOfQuestions = [
            ...infoOfQuestions,
            { ...transformSelectOptions(newQuestionsByKey?.[v]) },
          ];
          // remove item is selected
          newQuestionsByKey = lodash.omit(newQuestionsByKey, [v]);
        } else if (fakeFields?.[v]) {
          const { checkin_times } = activePlan;

          let newData = { ...transformSelectOptions(fakeFields?.[v]) };
          // render options of check in times
          if (v === 'check_in_time') {
            if (checkin_times) {
              // render options of check in time from plan detail data
              const options = checkin_times?.map(v => {
                return {
                  label: v,
                  value: v,
                };
              });
              newData = { ...newData, select_answers: options || [] };
            }
          }

          // only add this fake field if has this condition
          if (
            (v === 'check_in_time' && checkin_times) ||
            v !== 'check_in_time'
          ) {
            infoOfQuestions = [...infoOfQuestions, { ...newData }];
          }
        }
      });

      // add item is not selected into form
      lodash.forEach(newQuestionsByKey, v => {
        infoOfQuestions = [
          ...infoOfQuestions,
          { ...transformSelectOptions(v) },
        ];
      });
      // important render input
      setQuestions(infoOfQuestions);

      // all questions
      let infoOfCheckBoxes: any = [];
      // transform question with key
      let newCheckBoxesByKey = lodash.keyBy(newCheckBoxes, 'enquete_id');

      orderCheckBoxFields.map(v => {
        if (newCheckBoxesByKey?.[v]) {
          infoOfCheckBoxes = [
            ...infoOfCheckBoxes,
            { ...newCheckBoxesByKey?.[v] },
          ];
          // remove item is selected
          newCheckBoxesByKey = lodash.omit(newCheckBoxesByKey, [v]);
        }
      });

      // add item is not selected into form
      lodash.forEach(newCheckBoxesByKey, v => {
        infoOfCheckBoxes = [...infoOfCheckBoxes, { ...v }];
      });
      setAddDynamicFieldSuccess(true);
      // important render input
      setCheckBoxes(infoOfCheckBoxes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlanForOrderStatus]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const { postal_code, address, gender } = customerInfo.guest;
    // if user created customer info => view customer info (default info is different current customer info)
    // const newMode = customerInfoData?.email ? 'view' : 'edit';
    // setMode(newMode);
    if (
      postal_code ||
      address ||
      gender !== defaultCustomerInfo?.guest?.gender
    ) {
      setHasOldData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const {
  //   email,
  //   name,
  //   kana,
  //   gender,
  //   age,
  //   telephone,
  //   postal_code,
  //   address,
  //   zipcode,
  //   city,
  //   visit_by_car,
  // } = customerInfoData;

  // const clearCustomerInfo = () => {
  //   dispatch(changeCustomerInfo({ ...defaultCustomerInfo.guest }));
  //   setMode('edit');
  //   setCustomerInfoData(defaultCustomerInfo.guest);
  // };

  const handleFormSubmit = () => {
    const formData = { ...cloneFormData(getValues()) };

    // transform enquetes from form to send it with order info to server when create order
    let enquetes: any = {};

    lodash.forEach(cart_items, v1 => {
      const localId = v1?.local_id;
      const idOfPlan = v1?.plan_id;

      const enquetesOfPlan = planForOrder?.[idOfPlan]?.enquetes;
      if (!enquetesOfPlan || enquetesOfPlan?.length === 0) {
        return;
      }
      let allQuestions = lodash.keyBy(enquetesOfPlan, 'enquete_id');
      lodash.forEach(allQuestions, (v, k) => {
        let answer = formData?.[localId]?.[k];
        if (v?.enquete_type === 'confirm') {
          answer = answer ? 'yes' : 'no';
        }
        const oldEnquete = enquetes?.[localId] || [];

        enquetes = {
          ...enquetes,
          [localId]: [
            ...(enquetes?.[localId] || []),
            {
              enquete: v?.enquete_text,
              enquete_id: v?.enquete_id,
              answer: answer,
            },
          ],
        };
        // enquetes = [
        //   ...enquetes,
        //   {
        //     enquete: v?.enquete_text,
        //     enquete_id: v?.enquete_id,
        //     answer: answer,
        //   },
        // ];
      });
    });

    // let check_in_time = {};
    // let allGuests = {};
    // _.forEach(cart_items, v => {
    //   console.log('🚀 ~ _.forEach ~ v', v);
    //   const tabId = v?.local_id;
    //   if (!formData?.[tabId]) {
    //     return;
    //   }
    //   check_in_time = {
    //     ...check_in_time,
    //     [tabId]: formData?.[tabId]?.check_in_time,
    //   };
    //   allGuests = { ...allGuests, [tabId]: { ...formData?.[tabId]?.guest } };
    //   console.log(
    //     '🚀 ~ handleFormSubmit ~ formData?.[tabId]',
    //     formData?.[tabId],
    //   );
    //   // delete allGuests[tabId][tabId];
    // });
    // console.log('🚀 ~ handleFormSubmit ~ allGuests', allGuests);

    // const firstFormData = { ...formData?.[_.first(cart_items)?.local_id] };
    // // const firstFormData = _.clone(_.first(allGuests));
    // const submitData = {
    //   male_adult_count: firstFormData?.male_adult_count,
    //   female_adult_count: firstFormData?.female_adult_count,
    //   address: firstFormData?.address,
    //   gender: firstFormData?.gender,
    //   city: firstFormData?.city,
    //   kana: firstFormData?.kana,
    //   postal_code: firstFormData?.postal_code,
    //   // postal_code: '0600061',
    //   email: firstFormData?.email,
    //   telephone: firstFormData?.telephone,
    //   name: firstFormData?.name,
    //   // zipcode: firstFormData?.zipcode,
    //   prefecture_code: firstFormData?.prefecture_code,
    //   // birthday: firstFormData?.birthday,
    //   birthday: '',
    //   payment: {
    //     softbank_payment_merchant_id: detail_plan?.softbank_payment_merchant_id,
    //     softbank_payment_service_id: detail_plan?.softbank_payment_service_id,
    //   },
    //   // data for all tabs of order info
    //   enquetes,
    //   allGuests,
    //   check_in_time,
    // };
    // console.log('🚀 ~ handleFormSubmit ~ submitData', submitData);
    // let oldFormData = {};
    // const reservationOrders = '①②③④⑤⑥⑦⑧⑨⓾'.split('');
    const newCartItems = cart_items.map((v, k) => {
      const { hotel_id, plan_id, reservation, room_type_id } = v;
      const { arrival_date } = reservation;
      const tabId = v?.local_id;
      const cacheCartItem = formData?.[tabId];

      const { check_in_time } = cacheCartItem;

      const childrenInfo = v.guests?.filter(
        it => it.guest_type !== 'male' && it.guest_type !== 'female',
      );

      const childrenCount = _.sumBy(childrenInfo, 'count') || 0;
      const maleCount = formData?.[tabId]?.male_adult_count || 0;
      const femaleCount = formData?.[tabId]?.female_adult_count || 0;
      // no transform data if has not form data of that tab
      if (!formData?.[tabId]) {
        return v;
      }
      // // get old formData
      // oldFormData = {
      //   ...oldFormData,
      //   [tabId]: {
      //     ...formData?.[tabId],
      //   },
      // };
      return {
        ...v,
        guests: [
          {
            guest_type: 'male',
            count: maleCount,
            rate: 0,
          },
          {
            guest_type: 'female',
            count: femaleCount,
            rate: 0,
          },
          ...childrenInfo,
        ],
        // addition info for check unique cart from local with server
        check_in_time,
        reservationId: getReservationId({
          arrival_date,
          check_in_time,
          hotel_id,
          plan_id,
          room_type_id,
          maleCount,
          femaleCount,
          childrenCount,
        }),
        reservationName: k + 1,
        // reservationName: reservationOrders?.[k] || k + 1,
      };
    });
    changeCartItemInfo(newCartItems);

    // handle payment
    const validateResult = validatePaymentMethod(planForOrder);
    const { isError, allowPayTwoMethod, paymentType } = validateResult;

    // do not create order if has conflict about payment method
    if (isError) {
      alertConflictPaymentMethod();
      return;
    }

    // get payment info for order
    let paymentInfo = {};
    _.forEach(planForOrder, v => {
      const { front_desk, softbank_payment } = v.payment_method;
      // save softbank info to use for pay using credit card
      if (softbank_payment) {
        paymentInfo = {
          softbank_payment_merchant_id: v.softbank_payment_merchant_id,
          softbank_payment_service_id: v.softbank_payment_service_id,
        };
      }
      if (!front_desk && softbank_payment) {
        // if has one plan can only pay before, can not pay after => all order must pay before
        paymentInfo = {
          ...paymentInfo,
          payment_method: 'softbank_payment',
        };
        return;
      }
    });

    // setCacheFormData({ ...watch(), enquetes, paymentInfo });
    // const oldFormData = { ...watch() };
    const newData = {
      ...customerInfo?.guest,
      cacheFormData: {
        // ...customerInfo?.guest?.cacheFormData,
        ...formData,
        enquetes: { ...enquetes },
        paymentInfo,
      },
    };

    dispatch(changeCustomerInfo(newData));

    if (allowPayTwoMethod) {
      setShowPaymentMethod(true);
    } else {
      handleRedirectPaymentPage(paymentType);
    }

    // comment this block
    // handleRedirectPaymentPage(BEFORE_PAID_PAYMENT_TYPE);
  };

  const handleAgree = formData => {
    const { postal_code, gender, address } = formData;
    // setCustomerInfoData(formData);
    // dispatch(changeCustomerInfo(formData));
    if (
      postal_code ||
      // address ||
      gender !== defaultCustomerInfo?.guest?.gender
    ) {
      setHasOldData(true);
    } else {
      setHasOldData(false);
    }
    setValue('postal_code', postal_code);
    setValue('address', address);
    setValue('gender', gender);
    setShowCustomerInfoModal(false);
    // setMode('view');
  };

  const tabGuestField = `${tabId}.guest`;
  const tabGuestErrors = errors?.[tabId]?.guest;
  const tabErrors = errors?.[tabId];
  const messErrorNumberPerson = (() => {
    return (
      tabErrors?.male_adult_count?.message ||
      tabErrors?.female_adult_count?.message
    );
  })();
  const isLoggedIn = !!getToken();

  const renderQuestions = (v, k) => {
    const nameField = `${tabId}.${v?.enquete_id}`;
    const tabQuestionError = errors?.[`${tabId}`]?.[`${v?.enquete_id}`];
    if (v.enquete_type === 'entry') {
      const placeholder = _.includes(ENQUETE_IDS_HAS_PLACEHOLDER, v.enquete_id)
        ? ENQUETE_PLACEHOLDERS[v.enquete_id].placeholder
        : v?.enquete_text;
      return (
        <FormField spacing={1} className={'text_area_field'} key={k}>
          <Controller
            key={tabId}
            control={control}
            name={nameField}
            rules={{
              validate: () => {
                if (!v?.required) {
                  return true;
                }
                return !!getValues(nameField) || 'こちらは必須項目です。';
              },
            }}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <InputItem
                  key={tabId}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  size="small"
                  placeholder={placeholder}
                  error={tabQuestionError ? true : false}
                  helperText={tabQuestionError?.message}
                  labelPrimary={v?.enquete_text}
                  required={!!v?.required}
                  // required
                  multiline
                  rows={5}
                  field={field}
                />
              );
            }}
          />
        </FormField>
      );
    }
    if (v.enquete_type === 'select') {
      return (
        <FormField spacing={1} key={k}>
          <FormControl>
            <Controller
              control={control}
              name={nameField}
              rules={{
                validate: () => {
                  if (!v?.required) {
                    return true;
                  }
                  return !!getValues(nameField) || 'こちらは必須項目です。';
                },
              }}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <SelectItem
                    onChange={onChange}
                    selected={value}
                    options={v?.select_answers || []}
                    maxWidth={'100%'}
                    labelPrimary={v?.enquete_text}
                    required={v?.required}
                    error={!!tabQuestionError}
                    helperText={tabQuestionError?.message}
                    field={field}
                  />
                );
              }}
            />
          </FormControl>
        </FormField>
      );
    }
    if (v.enquete_type === 'confirm') {
      return (
        <FormControl error={!!tabQuestionError?.message} key={k}>
          <Controller
            control={control}
            rules={{
              validate: () => {
                if (!v?.required) {
                  return true;
                }
                return !!getValues(nameField) || 'こちらは必須項目です。';
              },
            }}
            name={nameField}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <CheckBoxItem
                  onChange={newVal => {
                    onChange(newVal);
                    // handle async data for all tab
                    let newData = { ...watch() };
                    const oldData = watch();

                    _.forEach(Object.keys(oldData), v1 => {
                      const singleDataKeys = Object.keys(oldData?.[v1]);
                      if (
                        // only apply for tab has enquet id same as current tab
                        _.includes(singleDataKeys, v?.enquete_id) &&
                        tabId !== v1 &&
                        // only apply for checkbox has enquet id in ['c2', 'c4', 'c5']
                        _.includes(['c2', 'c4', 'c5'], v?.enquete_id)
                      ) {
                        newData = {
                          ...newData,
                          [v1]: { ...oldData?.[v1], [v?.enquete_id]: newVal },
                        };
                      }
                    });
                    reset({
                      ...newData,
                    });
                  }}
                  label={Parser(v?.enquete_text)}
                  // label={v?.enquete_text}
                  isChecked={!!value}
                  field={field}
                />
              );
            }}
          />
          <FormHelperText>{tabQuestionError?.message}</FormHelperText>
        </FormControl>
      );
    }
    return '';
  };

  const requiredOneOfTwoField = () => {
    return (
      !!getValues(`${tabId}.male_adult_count`) ||
      !!getValues(`${tabId}.female_adult_count`) ||
      'One of two fields is required'
    );
  };

  return (
    <>
      <ConfirmModal
        title={'ご予約情報を削除'}
        content={'この予約を削除してもよろしいですか？'}
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onAgree={() => {
          setShowConfirmModal(false);
          dispatch(
            removeItemToCart({
              key: cartItemValue?.cart_key,
              local_id: cartItemValue?.local_id,
            }),
          );
        }}
      />
      <FormContainer
        sx={{
          maxWidth: {
            sm: '375px',
            xs: '100%',
          },
        }}
      >
        {/* <CustomerInfoModal
        title={'お客様情報'}
        open={showCustomerInfoModal}
        onClose={() => {
          setShowCustomerInfoModal(false);
        }}
      >
        <CustomerInfoForm
          initialData={{ ...getValues() }}
          onAgree={formData => handleAgree(formData)}
          onCancel={() => {
            setShowCustomerInfoModal(false);
          }}
        />
      </CustomerInfoModal> */}

        <CompanionInfoModal
          title={'同行者の情報'}
          open={showCompanionInfoModal}
          onClose={() => {
            setShowCompanionInfoModal(false);
          }}
        >
          <CompanionInfoForm
            onAgree={() => {
              setShowCompanionInfoModal(false);
            }}
            onCancel={() => {
              setShowCompanionInfoModal(false);
            }}
          />
        </CompanionInfoModal>
        <Box
          component="form"
          id="order_info_form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Stack spacing={5}>
            <WrapBoxHeader>
              <BoxHeader fontSize={18}>
                ご予約情報{' '}
                {hasMultiple && (
                  <DeleteBtn
                    onClick={() => {
                      setShowConfirmModal(true);
                    }}
                  >
                    削除する
                  </DeleteBtn>
                )}
              </BoxHeader>
              {isMobile && (
                <WrapPrice mt={2}>
                  <SyncOrderInfo />
                </WrapPrice>
              )}
            </WrapBoxHeader>

            {/* number of adult */}
            <FormField spacing={1}>
              <LabelItem label={'男性・女性の人数'} required></LabelItem>
              <DescField>大人の男性・女性の人数をお聞かせください</DescField>
              <Stack direction="row" spacing={2}>
                <Stack direction="row" spacing={1} alignItems={'center'}>
                  <TitleField>男性</TitleField>
                  <FormControl
                    margin="dense"
                    sx={{
                      width: '80px',
                    }}
                  >
                    <Controller
                      control={control}
                      name={`${tabId}.male_adult_count`}
                      rules={{
                        validate: {
                          requiredOneOfTwoField,
                          equalSelectedAdultNumber: () => {
                            const selectedAdultCount = cartItemValue
                              ? getAdultCount(cartItemValue?.guests || [])
                              : 0;
                            const totalAdult =
                              _.toInteger(
                                getValues(`${tabId}.male_adult_count`),
                              ) +
                              _.toInteger(
                                getValues(`${tabId}.female_adult_count`),
                              );
                            return (
                              +totalAdult === +selectedAdultCount ||
                              '大人の人数は' +
                                selectedAdultCount +
                                '名をご選択ください'
                            );
                          },
                        },
                      }}
                      render={({ field }) => {
                        const { onChange, value } = field;

                        return (
                          <SelectItem
                            error={
                              tabErrors?.male_adult_count ||
                              tabErrors?.female_adult_count
                                ? true
                                : false
                            }
                            onChange={e => {
                              onChange(e);
                              // changeNumberAdultAndPrice();
                              trigger(`${tabId}.female_adult_count`);
                              trigger(`${tabId}.male_adult_count`);
                            }}
                            selected={value}
                            options={Array(7)
                              .fill('')
                              .map((i, k) => {
                                return {
                                  label: `${k} 人`,
                                  value: k,
                                };
                              })}
                            field={field}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={1} alignItems={'center'}>
                  <TitleField>女性</TitleField>
                  <FormControl
                    margin="dense"
                    sx={{
                      width: '80px',
                    }}
                  >
                    <Controller
                      control={control}
                      name={`${tabId}.female_adult_count`}
                      rules={{
                        validate: {
                          requiredOneOfTwoField,
                        },
                      }}
                      render={({ field }) => {
                        const { onChange, value } = field;
                        return (
                          <SelectItem
                            error={
                              tabErrors?.male_adult_count ||
                              tabErrors?.female_adult_count
                                ? true
                                : false
                            }
                            onChange={e => {
                              onChange(e);
                              // changeNumberAdultAndPrice();
                              trigger(`${tabId}.male_adult_count`);
                              trigger(`${tabId}.female_adult_count`);
                            }}
                            selected={value}
                            options={Array(7)
                              .fill('')
                              .map((i, k) => {
                                return {
                                  label: `${k} 人`,
                                  value: k,
                                };
                              })}
                            field={field}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <FormControl error={!!messErrorNumberPerson}>
                <FormHelperText>{messErrorNumberPerson}</FormHelperText>
              </FormControl>
            </FormField>

            {/* <FormField spacing={1} className={'text_area_field'}>
            <TitleField>お子様同伴の場合</TitleField>
            <TextField
              {...register<keyof IOrderInfo>('age_and_gender')}
              multiline
              rows={5}
              placeholder="性別、ご年齢をお聞かせください"
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              error={errors.age_and_gender ? true : false}
              helperText={errors.age_and_gender?.message}
            />
          </FormField> */}

            {/* check_in_time */}
            {/* <FormField spacing={1}>
            <FormControl>
              <Controller
                control={control}
                name="check_in_time"
                rules={{
                  validate: () => {
                    return (
                      !!(
                        getValues('check_in_time') ||
                        getValues('check_in_time') === '0'
                      ) || 'こちらは必須項目です。'
                    );
                  },
                }}
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <SelectItem
                      onChange={onChange}
                      selected={value}
                      options={means}
                      maxWidth={'100%'}
                      labelPrimary={'チェックイン時間'}
                      required
                      error={!!errors.check_in_time}
                      helperText={tabErrors?.check_in_time?.message}
                    />
                  );
                }}
              />
            </FormControl>
          </FormField> */}

            {/* <FormField spacing={1} className={'text_area_field'}>
            <TitleField>その他</TitleField>
            <TextField
              {...register<keyof IOrderInfo>('others')}
              multiline
              rows={5}
              placeholder="ご要望がございましたらご記入ください"
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              error={errors.others ? true : false}
              helperText={errors.others?.message}
            />
          </FormField> */}

            {questions.map((v, k) => {
              return renderQuestions(v, k);
            })}
            {/* customer info */}
            {addDynamicFieldSuccess && (
              <Stack spacing={3}>
                <WrapBoxHeader>
                  <BoxHeader fontSize={18}>お客様情報</BoxHeader>
                </WrapBoxHeader>

                <FormField spacing={1}>
                  <Controller
                    control={control}
                    name={`${tabGuestField}.name`}
                    rules={{
                      validate: () => {
                        return (
                          !!getValues(`${tabGuestField}.name`) ||
                          'こちらは必須項目です。'
                        );
                      },
                    }}
                    render={({ field }) => {
                      const { onChange, value } = field;

                      return (
                        <InputItem
                          value={value}
                          onChange={onChange}
                          fullWidth
                          size="small"
                          placeholder="例）田中 太郎"
                          error={!!tabGuestErrors?.name}
                          helperText={tabGuestErrors?.name?.message}
                          labelPrimary={'氏名（漢字）'}
                          required
                          field={field}
                        />
                      );
                    }}
                  />
                  <Typography component="p" mt={2}>
                    ※氏名の間にスペースをいれてください
                  </Typography>
                </FormField>
                <FormField spacing={1}>
                  <Controller
                    control={control}
                    name={`${tabGuestField}.kana`}
                    rules={{
                      required: 'こちらは必須項目です。',
                      pattern: {
                        value: /^([ァ-ン]|ー|[ｧ-ﾝﾞﾟ]| |　)+$/,
                        message: '半角全角（カナのみ）で入力してください',
                      },
                    }}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <InputItem
                          value={value}
                          onChange={onChange}
                          fullWidth
                          size="small"
                          placeholder="例）タナカ タロウ"
                          error={!!tabGuestErrors?.kana}
                          helperText={tabGuestErrors?.kana?.message}
                          labelPrimary={'氏名（カナ）'}
                          required
                        />
                      );
                    }}
                  />
                  <Typography component="p" mt={2}>
                    ※氏名の間にスペースをいれてください
                  </Typography>
                </FormField>

                <FormField spacing={1}>
                  <Typography gutterBottom>性別</Typography>
                  <FormControl fullWidth margin="dense">
                    <Controller
                      control={control}
                      name={`${tabGuestField}.gender`}
                      render={({ field }) => {
                        const { onBlur, onChange, value } = field;
                        return (
                          <RadioGroup
                            row
                            value={value ? value.toString() : null}
                            onBlur={onBlur}
                            onChange={onChange}
                          >
                            {GENDERS_ENG.map(v => (
                              <FormControlLabel
                                key={v.id}
                                value={v.value}
                                control={
                                  <Radio
                                    sx={{
                                      color: '#333',
                                      '&.Mui-checked': {
                                        color: '#333',
                                      },
                                    }}
                                  />
                                }
                                label={v.label}
                              />
                            ))}
                          </RadioGroup>
                        );
                      }}
                    />
                  </FormControl>
                </FormField>

                <FormField spacing={1}>
                  <Controller
                    control={control}
                    name={`${tabGuestField}.telephone`}
                    rules={{
                      validate: () => {
                        return (
                          !!getValues(`${tabGuestField}.telephone`) ||
                          'こちらは必須項目です。'
                        );
                      },
                      pattern: {
                        value: /^\d+$/,
                        message: '※ハイフンなしで入力ください',
                      },
                    }}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <InputItem
                          value={value}
                          onChange={onChange}
                          fullWidth
                          size="small"
                          type="tel"
                          placeholder="例）0312345678"
                          error={!!tabGuestErrors?.telephone}
                          helperText={tabGuestErrors?.telephone?.message}
                          labelPrimary={'当日ご連絡のつく電話番号'}
                          required
                        />
                      );
                    }}
                  />
                  <Typography
                    component="p"
                    mt={2}
                    style={{
                      color: !tabGuestErrors?.telephone ? '#ff0000' : '#000000',
                    }}
                  >
                    ※ハイフンなしで入力ください
                  </Typography>
                </FormField>

                <FormField spacing={1}>
                  <Controller
                    control={control}
                    name={`${tabGuestField}.email`}
                    rules={{
                      validate: () => {
                        return (
                          !!getValues(`${tabGuestField}.email`) ||
                          'こちらは必須項目です。'
                        );
                      },
                    }}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <InputItem
                          value={value}
                          onChange={onChange}
                          fullWidth
                          type="email"
                          size="small"
                          placeholder="例）abcd@vmg.co.jp"
                          error={!!tabGuestErrors?.email}
                          helperText={tabGuestErrors?.email?.message}
                          labelPrimary={'メールアドレス'}
                          required
                          disabled={!!customerInfo?.guest?.email}
                        />
                      );
                    }}
                  />
                </FormField>

                <FormField spacing={1}>
                  <Typography gutterBottom>郵便番号</Typography>
                  <TextField
                    {...register<any>(`${tabGuestField}.postal_code`, {
                      //required: 'こちらは必須項目です。',
                    })}
                    placeholder="お住まいの郵便番号を入力してください"
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={!!tabGuestErrors?.postal_code}
                    helperText={tabGuestErrors?.postal_code?.message}
                    // sx={{ maxWidth: { md: '240px', xs: '100%' } }}
                  />
                </FormField>

                <FormField spacing={1}>
                  <Controller
                    control={control}
                    name={`${tabGuestField}.address`}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <InputItem
                          value={value}
                          onChange={onChange}
                          fullWidth
                          size="small"
                          placeholder="お住まいの住所を入力してください"
                          error={!!tabGuestErrors?.address}
                          helperText={tabGuestErrors?.address?.message}
                          labelPrimary={'住所'}
                        />
                      );
                    }}
                  />
                </FormField>
                {/* <FormField spacing={1}>
              <Typography gutterBottom>住所（都道府県・市区町村）</Typography>

              <TextField
                {...register<keyof IOrderInfo>('city', {
                  //required: 'こちらは必須項目です。',
                })}
                placeholder="郵便番号を入力すると自動で選択されます"
                fullWidth
                variant="outlined"
                size="small"
                error={errors.city ? true : false}
                helperText={errors.city?.message}
              />
            </FormField> */}

                {/* <FormField spacing={1}>
              <FormControlLabel
                control={
                  <Controller
                    name="visit_by_car"
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <Checkbox
                          disableRipple
                          sx={{
                            color: colors.grey,
                            '&.Mui-checked': {
                              color: colors.grey1,
                            },
                          }}
                          {...field}
                          checked={!!value}
                          onChange={onChange}
                        />
                      );
                    }}
                    control={control}
                  />
                }
                label="車での来訪を希望される方はチェックしてください"
              />
            </FormField> */}
              </Stack>
            )}

            {/* <Stack spacing={2}> */}
            {/* {!isLoggedIn && (
            <CustomButton
              onClick={() => {
                setShowCustomerInfoModal(true);
              }}
              variant="outlined"
              color="primary"
            >
              {hasOldData ? '+ 詳細を追加する' : '+ 詳細を追加する'}
            </CustomButton>
          )} */}

            {/* {!customerInfoData?.email && (
              <Stack sx={{ marginTop: '8px !important' }} alignItems={'center'}>
                <FormHelperText error>
                  {'お客様情報をご追加ください'}
                </FormHelperText>
              </Stack>
            )} */}

            {/* <Box>
              <CustomButton
                onClick={() => {
                  setShowCompanionInfoModal(true);
                }}
                variant="outlined"
                color="primary"
              >
                + 同行者の情報を追加する
              </CustomButton>
              <Note>
                ※ご入力いただくと、チェックイン時に宿帳記載が不要となります。
              </Note>
            </Box>
          </Stack> */}

            {!!checkBoxes.length && (
              <Stack spacing={3}>
                {/* <Alert component={'span'}>
              ご予約中の内容、
              <ContentAlert component={'a'} href={'#'}>
                キャンセル規定
              </ContentAlert>
              、
              <ContentAlert component={'a'} href={'#'}>
                プライバシーポリシー{' '}
              </ContentAlert>
              <br />
              をご確認ください。
            </Alert> */}

                {/* <FormControl error={!!tabErrors?.agree_policy?.message}>
              <Controller
                control={control}
                rules={{
                  validate: () => {
                    return (
                      getValues('agree_policy') || 'こちらは必須項目です。'
                    );
                  },
                }}
                name="agree_policy"
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <CheckBoxItem
                      onChange={onChange}
                      label="キャンセル規定、プライバシーポリシー、利用規約、宿泊約款に同意する"
                      isChecked={!!value}
                    />
                  );
                }}
              />
              <FormHelperText>{tabErrors?.agree_policy?.message}</FormHelperText>
            </FormControl> */}

                {checkBoxes.map((v, k) => {
                  return renderQuestions(v, k);
                })}
              </Stack>
            )}

            <Stack direction="column" justifyContent="space-between">
              <CommonButton
                variant="contained"
                size="large"
                type="submit"
                form="order_info_form"
                sx={{
                  background: colors.blue,
                  boxShadow: 'none',
                }}
                onClick={async () => {
                  // await trigger('');
                  await trigger();

                  if (_.isEmpty(errors)) {
                    // handleSubmit(handleFormSubmit)();
                  } else {
                    const errorTabValue = Object.keys(errors)[0];

                    if (errorTabValue !== activeTab) {
                      setActiveTab(errorTabValue);
                    }
                  }
                }}
              >
                決済方法を選ぶ
              </CommonButton>
            </Stack>
          </Stack>
        </Box>
      </FormContainer>
    </>
  );
};

const CustomButton = styled(Button)`
  width: 100%;
  border: 1px solid #2f80ed;
  box-shadow: 0px 1px 0px rgb(0 122 255 / 5%);
  border-radius: 4px;
  height: 40px;
  padding: 8px 16px;
  &:hover {
    border: 1px solid #2f80ed;
    background: none;
  }
  span {
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #2f80ed;
  }
`;

const CustomerInfoModal = styled(Modal)`
  &.common_modal {
  }
`;

const CompanionInfoModal = styled(Modal)``;

const FormContainer = styled(Box)`
  margin: 0 auto;
  .MuiOutlinedInput-root {
    height: 36px;
  }
  .MuiCardContent-root {
    padding: 0;

    ${dMedia.tablet`
        padding: 0 16px 40px 16px;
    `}
  }

  .MuiTypography-gutterBottom {
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
  .MuiInputBase-input {
    font-size: 14px;
    padding: 0 16px;
    line-height: 20px;
    /* color: ${colors.grey7}; */
  }
  .MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: inherit;
    border-width: 1px;
  }
  .MuiButton-root {
    height: 40px;
    font-weight: 500;
    display: block;
    font-size: 15px;
    line-height: 20px;
    padding: 10px 20px;

    ${dMedia.tablet`
      font-size: 15px;
      padding: 6px 22px;
      `}
    a {
      color: inherit;
      display: block;
      text-decoration: none;
    }
  }

  input:-internal-autofill-selected {
    background: none;
  }

  .text_area_field {
    .MuiOutlinedInput-root {
      height: 120px;
      padding: 15px 15px 15px 12px;
      .MuiInputBase-input {
        padding: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        /* color: ${colors.grey3}; */
      }
    }
  }
`;

const FormField = styled(Stack)``;

const TitleHeightField = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
    display: flex;
  }
`;

const DescField = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;

const TitleField = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;
const WrapPrice = styled(Box)``;
const WrapBoxHeader = styled(Box)`
  position: relative;
  .MuiTypography-root {
    font-size: 18px;
  }
`;

const Alert = styled(Typography)<{
  component?: String;
}>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.heading};
`;

const ContentAlert = styled(Typography)<{
  component?: String;
  href?: String;
}>`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: ${colors.blue};
`;

const Note = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    color: ${colors.grey7};
  }
`;

const TitleWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  margin: 0 !important;
  justify-content: space-between;
`;

const DeleteInfoBtn = styled.a`
  padding: 4px 8px;
  background-color: ${colors.grey6};
  border-radius: 16px;
  color: #2f80ed;
  font-size: ${fontsizes.main};
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const FieldData = styled(Box)`
  margin-top: 24px !important;
  margin-bottom: 0 !important;
`;

const TextRequire = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #f2994a;
    margin-left: 8px;
  }
`;

const DeleteBtn = styled.div`
  padding: 8px 12px;
  background-color: ${colors.grey6};
  border-radius: 8px;
  color: ${colors.blue};
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  width: max-content;
  cursor: pointer;
  /* margin-left: auto; */
  margin-bottom: 16px;
  position: absolute;
  right: 0;
  top: -12px;
`;
