import { Box, FormHelperText, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { lodash } from 'utils/helper';

interface ISelectItem {
  maxWidth?: number | string;
  selected?: string | number;
  options: { label: string; value: string | number }[];
  onChange?: (value: any) => void;
  labelPrimary?: string | React.ReactElement;
  textRequire?: string;
  required?: boolean;
  error?: boolean;
  selectPlaceholder?: string;
  helperText?: string;
  field?: any;
}

const SelectItem = ({
  maxWidth = 180,
  selected = '',
  options,
  onChange,
  labelPrimary,
  textRequire = '必須',
  required = false,
  error,
  selectPlaceholder = '未選択',
  helperText,
  field,
  ...props
}: ISelectItem) => {
  const [value, setValue] = React.useState(selected.toString());

  React.useEffect(() => {
    setValue(selected.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChange && onChange(newValue);
  };
  const optionsInfo = options ? lodash.keyBy(options, 'value') : {};

  return (
    <Container sx={{ maxWidth }}>
      <FormControl fullWidth error={error}>
        {labelPrimary && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={1}
          >
            {typeof labelPrimary === 'string' && (
              <Box
                className={'labelPrimaryContainer'}
                display="flex"
                alignItems="center"
              >
                <LabelPrimary pr={1}>{labelPrimary}</LabelPrimary>
                {required && <TextRequire>{textRequire}</TextRequire>}
              </Box>
            )}
          </Box>
        )}
        <Select
          inputRef={field?.ref}
          value={value}
          onChange={handleChange}
          IconComponent={props => <ArrowDown {...props} />}
          displayEmpty
          renderValue={(value: any) => {
            if ((value || !isNaN(value)) && optionsInfo?.[value]?.label) {
              return <OptionText>{optionsInfo?.[value]?.label}</OptionText>;
            }
            return <OptionText>{selectPlaceholder}</OptionText>;
          }}
        >
          {options.map((v, k) => {
            return (
              <MenuItem value={v.value} key={k}>
                <OptionText
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {v.label}
                </OptionText>
              </MenuItem>
            );
          })}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Container>
  );
};

export default SelectItem;

const OptionText = styled(Box)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  display: flex;
  align-items: center;
`;

const Container = styled(Box)`
  width: 100%;
  .MuiFormControl-root {
    .MuiSelect-select {
      padding: 10px 32px 10px 10px;
      min-height: 16px;
    }
    .MuiInputBase-root {
      &:before {
        content: '';
        border: none;
      }
      &:after {
        border: none;
      }
    }
    .MuiInputBase-input {
      display: flex;
    }
    .MuiSelect-icon {
      top: calc(50% - 8px);
    }
  }
`;

const LabelPrimary = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }
`;

const TextRequire = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #f2994a;
  }
`;

const Placeholder = styled(Typography)`
  && {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    display: flex;
    align-items: center;
  }
`;
