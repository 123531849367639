import { Grid } from '@mui/material';
import * as React from 'react';
import styled, { css } from 'styled-components';

export interface IDayItemProps {
  xs: any;
  item: any;
  selectable?: boolean;
  isBlank: boolean;
  isToday: boolean;
  isSelected: boolean;
  key: number;
  children: any;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  className: string;
  accept: any;
  // onDrop: (item: any) => void;
}

export default function DayItem(props: IDayItemProps) {
  const {
    selectable,
    isBlank,
    isToday,
    isSelected,
    onClick,
    onMouseEnter,
    onMouseLeave,
    className,
    // onDrop,
    accept,
  } = props;
  // const [{ isOver, canDrop }, drop] = useDrop({
  //   accept,
  //   drop: onDrop,
  //   collect: monitor => ({
  //     isOver: monitor.isOver(),
  //     canDrop: monitor.canDrop(),
  //   }),
  // });

  // const isActiveBackdrop = canDrop && isOver;

  return (
    <Day
      // ref={drop}
      item
      xs
      selectable={selectable}
      isBlank={isBlank}
      isToday={isToday}
      isSelected={isSelected}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      // isActiveBackdrop={isActiveBackdrop}
    >
      {props.children}
    </Day>
  );
}
const Day = styled(Grid)<{
  selectable?: Boolean;
  isBlank?: Boolean;
  isToday?: Boolean;
  isSelected?: Boolean;
  // isActiveBackdrop: Boolean;
}>`
  height: 48px;
  flex-grow: 1 !important;
  flex-basis: 0 !important;
  min-width: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfdfdf;
  margin-top: -1px;
  margin-left: -1px;
  position: relative;
  z-index: unset !important;
  ${props =>
    props.selectable &&
    css`
      cursor: pointer;
    `}
  &.unselected {
    ${props =>
      props.isSelected &&
      css`
        background-color: #fff;
      `}
  }
  ${props =>
    props.isSelected &&
    css`
      border-color: #2f80ed;
      background-color: #dfedff;
      z-index: 1;
    `}

  ${props =>
    props.isToday &&
    css`
      background-color: #f3f8ff;
    `}
  
    ${props =>
    props.isBlank &&
    css`
      background-color: #f2f2f2;
    `}
`;
