import APIs from 'APIs';
import Path from 'config/clientPath';
import { StatusCode } from 'constants/API';
import { CART_HANDLE_TYPE } from 'constants/common';
import moment from 'moment';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getReservationInfo, checkTokenNotExpired } from 'utils/helper';
import {
  getToken,
  setUserCachedDataFromLocalStorage,
} from 'utils/localStorage';
import { convertDate } from 'utils/moment';
import Slice from '.';

function* editProfileFlow(action) {
  try {
    const response = yield call(APIs.updateAccount, {
      ...action.payload,
      id: action.payload.account_id,
    });
    if (response.status === StatusCode.SUCCESS_COMMON) {
      const currentData = localStorage.getItem('USER_CACHED_DATA');
      if (!!currentData) {
        setUserCachedDataFromLocalStorage({
          ...JSON.parse(currentData),
          account: {
            ...JSON.parse(currentData).account,
            ...response.data.account,
          },
        });
      }
      yield put({
        type: Slice.editProfileSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.editProfileFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.editProfileFail });
  }
}

function* getHotelStockFlow({ payload }) {
  let errorMess;
  const { cart_local_id } = payload;
  try {
    const response = yield call(APIs.getHotelStock, { ...payload });

    const stockInfo = response?.data;
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getHotelStockSuccess,
        payload: {
          stockInfo,
          cart_local_id,
        },
      });
      // payload.onSuccess(response.data);
    } else {
      // onError(response.data);
      yield put({
        type: Slice.getHotelStockFail.type,
        payload: { errorMess, cart_local_id, stockInfo },
      });
    }
  } catch (error) {
    // onError();
    yield put({
      type: Slice.getHotelStockFail.type,
      payload: { errorMess, cart_local_id },
    });
  }
}

function* getHotelListFlow({ payload }) {
  try {
    const response = yield call(APIs.search, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getHotelListSuccess,
        payload: response.data.data,
      });
    } else {
      yield put({ type: Slice.getHotelListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getHotelListFail });
  }
}

function* getAccountInfoFlow({ payload }) {
  try {
    const response = yield call(APIs.getAccountInfo, payload);
    const currentData = localStorage.getItem('USER_CACHED_DATA');
    if (!!currentData) {
      setUserCachedDataFromLocalStorage({
        ...JSON.parse(currentData),
        account: {
          ...JSON.parse(currentData).account,
          ...response.data,
        },
      });
    }
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getAccountInfoSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getAccountInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getAccountInfoFail });
  }
}

function* addItemToCartFlow({ payload }) {
  const defaultErrorMess = 'Hotel is out of stock';
  try {
    const { item, handleType, history } = payload;

    const { hotel_id, plan_id, room_id } = payload.addition_info;
    const {
      checkin_date,
      checkout_date,
      adult,
      child_A,
      child_B,
      child_C,
      child_D,
      child_E,
    } = payload.reservation;
    const moment_checkin_date = moment(checkin_date || null);
    const moment_checkout_date = moment(checkout_date || null);

    const checkStockParams = {
      hotel_id,
      plan_id,
      room_id,
      checkin_date: convertDate(moment_checkin_date),
      checkout_date: convertDate(moment_checkout_date),
      guest_count: adult,
      child_a_count: child_A,
      child_b_count: child_B,
      child_c_count: child_C,
      child_d_count: child_D,
      child_e_count: child_E,
      // add cart_local_id to check status get stock of cart item by cart_local_id
      cart_local_id: item?.local_id,
    };
    const resCheckStock = yield call(APIs.getHotelStock, checkStockParams);
    let serverErrorMess = '';

    if (resCheckStock.data.ok) {
      // const resGetPlanDetail = yield call(APIs.getPlanDetail, {
      //   hotel_id,
      //   plan_id,
      // });
      // if (resGetPlanDetail.status === StatusCode.SUCCESS_COMMON) {
      // const detail_plan = resGetPlanDetail.data;
      const detail_plan = {};

      const reservationInfo = getReservationInfo(
        item,
        {
          ...item?.addition_info,
          hotelStockStatus: resCheckStock.data.calendars,
          detail_plan,
        },
        {
          ...payload.reservation,
        },
        resCheckStock.data,
      );
      yield put({
        type: Slice.addItemToCart,
        payload: { ...reservationInfo, checkStockParams },
      });
      yield put({
        type: Slice.addItemToCartSuccess,
        // payload: response.data,
      });
      yield put({
        type: Slice.setSuccessMessages,
        payload: ['カートに追加しました'],
      });

      let isBuyNow = false;
      if (handleType === CART_HANDLE_TYPE.RESERVED) {
        isBuyNow = true;
      }

      if (handleType === CART_HANDLE_TYPE.ADDITION_MODAL) {
        yield put({
          type: Slice.changeShowAdditionInfoModalStatus,
          payload: false,
        });
        if (item?.isReserved) {
          isBuyNow = true;
        }
      }

      if (
        isBuyNow &&
        (handleType === CART_HANDLE_TYPE.ADDITION_MODAL ||
          handleType === CART_HANDLE_TYPE.RESERVED)
      ) {
        // if login
        if (getToken() && checkTokenNotExpired()) {
          history && history.push(`${Path.ORDER_INFO}`);
        } else {
          yield put({
            type: Slice.changeShowLoginModalStatus,
            payload: true,
          });
          yield put({
            type: Slice.changeReservationStatus,
            payload: true,
          });
        }
      }
      // } else {
      //   serverErrorMess = defaultErrorMess;
      // }
    } else {
      serverErrorMess = resCheckStock?.data?.message || defaultErrorMess;
    }

    if (serverErrorMess) {
      yield put({
        type: Slice.setErrorMessages,
        payload: [serverErrorMess],
      });
      yield put({ type: Slice.addItemToCartFail.type });
    }
  } catch (error) {
    yield put({
      type: Slice.setErrorMessages,
      payload: [defaultErrorMess],
    });
    yield put({ type: Slice.addItemToCartFail.type });
  }
}

function* commonWatcher() {
  yield takeLatest(Slice.editProfile, editProfileFlow);
  yield takeEvery(Slice.getHotelStockRequest, getHotelStockFlow);
  yield takeLatest(Slice.getHotelListRequest, getHotelListFlow);
  yield takeLatest(Slice.getAccountInfoRequest, getAccountInfoFlow);
  yield takeLatest(Slice.addItemToCartRequest, addItemToCartFlow);
}

export default commonWatcher;
