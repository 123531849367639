// import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import Button from '@mui/material/Button';
import { colors, fontsizes } from 'constants/styles';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import Path from 'config/clientPath';

export default function NotFoundPage() {
  const history = useHistory();
  return (
    <>
      <Wrapper>
        <Typography
          sx={{
            fontSize: { xs: '36px', md: '72px' },
            fontWeight: '700',
            color: colors.heading,
            lineHeight: { xs: '52px', md: '104px' },
            marginBottom: { xs: '16px', md: '0' },
          }}
          variant="h4"
          align="center"
        >
          404
        </Typography>
        <Typography
          sx={{
            marginBottom: '40px',
            fontSize: fontsizes.main,
            fontWeight: '400',
            lineHeight: '20px',
            color: colors.grey1,
            textAlign: 'center',
          }}
        >
          お探しのページは見つかりませんでした <br />
          下記のボタンよりホームへ戻ってください
        </Typography>
        <Button
          sx={{
            width: {
              xs: '240px',
              md: '320px',
            },
            height: '40px',
            backgroundColor: colors.blue,
            fontSize: fontsizes.main,
            lineHeight: '20px',
            fontWeight: '500',
            color: colors.white,
          }}
          variant="contained"
          onClick={() => {
            history.push(Path.HOME);
          }}
        >
          TOPへ戻る
        </Button>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  height: calc(100vh - 82px);
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
`;
