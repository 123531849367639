// import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import Button from '@mui/material/Button';
import { colors, fontsizes } from 'constants/styles';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router';
import Path from 'config/clientPath';

export default function SystemError() {
  const history = useHistory();
  return (
    <>
      <Wrapper>
        <Typography
          sx={{
            fontWeight: '700',
            color: colors.heading,
            fontSize: { xs: '24px', md: '36px' },
            lineHeight: { xs: '34px', md: '52px' },
            marginBottom: '16px',
            maxWidth: { xs: '186px', md: 'unset' },
          }}
          variant="h4"
          align="center"
        >
          システムエラーが発生しました
        </Typography>
        <Typography
          align="center"
          sx={{
            marginBottom: '40px',
            fontSize: fontsizes.main,
            fontWeight: '400',
            marginTop: '0',
            lineHeight: '20px',
            color: colors.grey1,
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          大変お手数ですが、サポートデスクまでご連絡ください
        </Typography>
        <Typography
          align="center"
          sx={{
            marginBottom: '40px',
            fontSize: fontsizes.main,
            fontWeight: '400',
            marginTop: '0',
            lineHeight: '20px',
            color: colors.grey1,
            display: {
              xs: 'block',
              md: 'none',
            },
          }}
        >
          大変お手数ですが、
          <br />
          サポートデスクまでご連絡ください
        </Typography>
        <Button
          sx={{
            width: {
              xs: '240px',
              md: '320px',
            },
            height: '40px',
            backgroundColor: colors.blue,
            fontSize: fontsizes.main,
            lineHeight: '20px',
            fontWeight: '500',
            color: colors.white,
          }}
          variant="contained"
          onClick={() => {
            history.push(Path.HOME);
          }}
        >
          TOPへ戻る
        </Button>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  height: calc(100vh - 82px);
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
`;
