// UI
import { Box } from '@mui/material';

interface IBanner {
  bannerUrl?: string;
}

const Banner = (props: IBanner) => {
  return (
    <Box
      sx={{
        backgroundImage: {
          xs: `url(${props.bannerUrl})`,
          sm: `url(${props.bannerUrl})`,
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: { xs: 56, sm: 172 },
      }}
    ></Box>
  );
};

export default Banner;
