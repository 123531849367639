// UI
import { Button } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as ReserveIcon } from 'assets/icons/reserve.svg';
import { useHistory } from 'react-router';
import Path from 'config/clientPath';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { IAdditionRoomInfo, IRoomItem } from 'types/common';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { getReservationInfo } from 'utils/helper';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import usePlanAndRoom from 'app/pages/PlanAndRoomPage/usePlanAndRoom';
import { useEffect, useState } from 'react';
import { RequestStatus } from 'constants/API';
import { CART_HANDLE_TYPE } from 'constants/common';

interface IReserveButton {
  onClick?: () => void;
  item?: IRoomItem;
  isBuyNow?: boolean;
}

export function ReserveButton({
  item,
  isBuyNow = false,
  onClick,
  ...props
}: IReserveButton) {
  const [isSuccessGetStock, setIsSuccessGetStock] = useState(false);

  const {
    addItemToCart,
    setSuccessMessages,
    changeShowLoginModalStatus,
    changeReservationStatus,
    changeShowAdditionInfoModalStatus,
    changeSavedCartItem,
  } = useGlobalSlice().actions;
  const dispatch = useDispatch();
  const { isLoggedIn, cart_items } = useSelector(selectGlobal);
  const history = useHistory();

  const { getPlanDetailStatus, detail_plan, getPlanDetailRequest } =
    usePlanAndRoom();
  const { checkHotelStock, hotelStockStatus, handleAddItemToCart } =
    useGlobalFunc();

  const {
    plans,
    active_plan,
    getPlansStatus,
    additionSearchInfo: {
      meal,
      adult,
      child,
      nights,
      child_A,
      child_B,
      child_C,
      child_D,
      child_E,
    },
    checkin_date,
    checkout_date,
  } = useSelector(selectPlanAndRoom);
  const {
    actions: { changeAdditionSearchInfo },
  } = usePlanAndRoomSlice();

  // useEffect(() => {
  //   if (isSuccessGetStock && getPlanDetailStatus === RequestStatus.SUCCESS) {
  //     // transform cart item with reservation info
  //     dispatch(
  //       addItemToCart(
  //         getReservationInfo(
  //           item,
  //           { ...item?.addition_info, hotelStockStatus, detail_plan },
  //           {
  //             meal,
  //             adult,
  //             // child,
  //             nights,
  //             checkin_date,
  //             checkout_date,
  //             child_A,
  //             child_B,
  //             child_C,
  //             child_D,
  //             child_E,
  //           },
  //         ),
  //       ),
  //     );
  //     dispatch(setSuccessMessages(['カートに追加しました']));

  //     history.push(`${Path.ORDER_INFO}`);
  //     setIsSuccessGetStock(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSuccessGetStock, getPlanDetailStatus]);

  const onSuccess = () => {
    setIsSuccessGetStock(true);
  };

  return (
    <ReserveBtn
      variant="contained"
      color="primary"
      onClick={() => {
        handleAddItemToCart({
          handleType: CART_HANDLE_TYPE.RESERVED,
          item: item || {},
          addition_info: item?.addition_info || {},
          reservation: {
            meal,
            adult,
            // child,
            nights,
            checkin_date,
            checkout_date,
            child_A,
            child_B,
            child_C,
            child_D,
            child_E,
          },
        });

        // if (cart_items.length >= 1) {
        //   return;
        // }
        // // add to cart if has item
        // if (
        //   item &&
        //   item?.addition_info &&
        //   Object.keys(item?.addition_info).length
        // ) {
        //   // if has adult,... => add to cart
        //   // else open modal addition info
        //   if (adult && checkin_date && checkout_date) {
        //     // if signed in => add item to cart
        //     // else show modal log in
        //     if (isLoggedIn) {
        //       const { hotel_id, plan_id, room_id } = item.addition_info;
        //       getPlanDetailRequest(hotel_id, plan_id);

        //       checkHotelStock(
        //         {
        //           hotel_id,
        //           plan_id,
        //           room_id,
        //           checkin_date,
        //           checkout_date,
        //         },
        //         onSuccess,
        //       );
        //     } else {
        //       dispatch(changeShowLoginModalStatus(true));
        //       dispatch(changeReservationStatus(true));
        //     }
        //   } else {
        //     // save cart item to redux with item is reserved
        //     dispatch(changeSavedCartItem({ ...item, isReserved: true }));
        //     dispatch(changeShowAdditionInfoModalStatus(true));
        //   }
        // }
        onClick && onClick();
      }}
    >
      <ReserveIcon></ReserveIcon>
      <span>{isBuyNow ? '今すぐ購入' : '予約に進む'}</span>
    </ReserveBtn>
  );
}

const ReserveBtn = styled(Button)`
  background: #2f80ed;
  box-shadow: 0px 1px 0px rgb(0 122 255 / 5%);
  border-radius: 4px;
  height: 40px;
  padding: 8px 40px 8px 16px;
  &:hover {
    background: #2f80ed;
  }
  span {
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
  }
`;
