import { PayloadAction } from '@reduxjs/toolkit';
import { retrieveUserCachedDataFromStorage } from 'app/pages/GlobalContainer/slice';
import { RequestStatus } from 'constants/API';
import {
  GENDERS_ENG,
  initCustomerInfo,
  TYPE_SORT_SUGGESTION,
  TYPE_TAB_PLAN,
  TYPE_TAB_ROOM,
} from 'constants/common';
import _ from 'lodash';
import moment from 'moment';
import { ICustomerInfo, IReservationStatus } from 'types/common';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { getExpiredTime } from 'utils/helper';
import {
  getCustomerInfoFromLocalStorage,
  setCustomerInfoFromLocalStorage,
} from 'utils/localStorage';
import { commonFormat } from 'utils/moment';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import checkoutSaga from './saga';
import { CheckoutState } from './types';

const userData: any = retrieveUserCachedDataFromStorage()
  ? retrieveUserCachedDataFromStorage().account
  : {};

export const defaultCustomerInfo = {
  ...initCustomerInfo,
  guest: {
    ...initCustomerInfo.guest,
    // email: retrieveUserCachedDataFromStorage()?.account?.email || '',
    ...userData,
    //TODO: comment
    // render default value if server has not data
    // address: userData?.address ? userData?.address : ' ',
    // city: userData?.city ? userData?.city : ' ',
    // prefecture_code: userData?.prefecture_code
    //   ? userData?.prefecture_code
    //   : '01',
  },
};

export const customerInfoWithoutUseData = {
  ...initCustomerInfo,
  guest: {
    ...initCustomerInfo.guest,
  },
};

export const retrieveCustomerInfoFromStorage = () => {
  let customerInfo = getCustomerInfoFromLocalStorage();

  let isExpiredCustomerInfo = true;
  if (customerInfo?.expired_time) {
    isExpiredCustomerInfo = moment().isAfter(customerInfo?.expired_time);
  }

  // reset customer info if it is expired or has not customer info
  if (!customerInfo || isExpiredCustomerInfo) {
    customerInfo = {
      ...defaultCustomerInfo,
      expired_time: getExpiredTime(),
    };
    setCustomerInfoFromLocalStorage({ ...customerInfo });
  }

  return customerInfo;
};

export const initialState: CheckoutState = {
  createOrderStatus: RequestStatus.IDLE,
  cancelOrderStatus: RequestStatus.IDLE,
  customerInfo: retrieveCustomerInfoFromStorage(),
  getOrderHistoryStatus: RequestStatus.IDLE,
  getOrderDetailStatus: RequestStatus.IDLE,
  order_history: {
    guest: {
      birthday: '',
      address: '',
      gender: '',
      prefecture_code: '',
      city: '',
      name: '',
      telephone: '',
      kana: '',
      postal_code: '',
      email: '',
    },
    reservations: [],
  },
  errorCode: '',
  order_detail: null,
  reservation_statuses_info: [],
  checkInventoryStatus: RequestStatus.IDLE,
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    // setCustomerInfoState: (
    //   state,
    //   action: PayloadAction<{
    //     guest: ICustomerInfo;
    //   }>,
    // ) => {
    //   state.customerInfo = action.payload;
    //   setCustomerInfoFromLocalStorage(action.payload);
    // },

    getOrderDetailRequest: (state, action: PayloadAction<any>) => {
      state.getOrderDetailStatus = RequestStatus.REQUESTING;
    },
    getOrderDetailSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getOrderDetailStatus = RequestStatus.SUCCESS;
        const reservation = action.payload?.reservation;
        const order_detail = {
          ...reservation?.reservation,
          room_types: reservation?.room_types,
        };
        state.order_detail = order_detail;
      }
    },
    getOrderDetailFail: (state, action: PayloadAction<string>) => {
      state.getOrderDetailStatus = RequestStatus.ERROR;
    },

    cancelOrderRequest: (state, action: PayloadAction<any>) => {
      state.cancelOrderStatus = RequestStatus.REQUESTING;
      state.errorCode = '';
    },
    cancelOrderSuccess: (state, action: PayloadAction<any>) => {
      state.cancelOrderStatus = RequestStatus.SUCCESS;
    },
    cancelOrderFail: (state, action: PayloadAction<string>) => {
      state.cancelOrderStatus = RequestStatus.ERROR;
      state.errorCode = action.payload;
    },

    getOrderHistoryRequest: (state, action: PayloadAction<any>) => {
      state.getOrderHistoryStatus = RequestStatus.REQUESTING;
    },
    getOrderHistorySuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getOrderHistoryStatus = RequestStatus.SUCCESS;
        state.order_history = action.payload;
      }
    },
    getOrderHistoryFail: (state, action: PayloadAction<any>) => {
      state.getOrderHistoryStatus = RequestStatus.ERROR;
    },

    checkInventoryRequest: (state, action: PayloadAction<any>) => {
      state.checkInventoryStatus = RequestStatus.REQUESTING;
      state.errorCode = '';
      state.reservation_statuses_info = [];
    },
    checkInventorySuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.checkInventoryStatus = RequestStatus.SUCCESS;
        state.reservation_statuses_info = [];
      }
    },
    checkInventoryFail: (
      state,
      action: PayloadAction<{
        reservation_statuses_info: IReservationStatus[];
        reservation_status: string;
      }>,
    ) => {
      if (action) state.checkInventoryStatus = RequestStatus.ERROR;
      const { reservation_status, reservation_statuses_info } = action.payload;
      state.errorCode = reservation_status;
      state.reservation_statuses_info = reservation_statuses_info;
    },

    createOrderRequest: (state, action: PayloadAction<any>) => {
      state.createOrderStatus = RequestStatus.REQUESTING;
      state.errorCode = '';
      state.reservation_statuses_info = [];
    },
    createOrderSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.createOrderStatus = RequestStatus.SUCCESS;
        state.reservation_statuses_info = [];
        // state.detail_room = action.payload;
      }
    },
    createOrderFail: (
      state,
      action: PayloadAction<{
        reservation_statuses_info: IReservationStatus[];
        reservation_status: string;
      }>,
    ) => {
      state.createOrderStatus = RequestStatus.ERROR;
      const { reservation_status, reservation_statuses_info } = action.payload;
      state.errorCode = reservation_status;
      state.reservation_statuses_info = reservation_statuses_info;
    },

    changeCustomerInfo: (state, action: PayloadAction<ICustomerInfo>) => {
      // const email = retrieveUserCachedDataFromStorage()?.account?.email || '';
      //  const email = 'duyhungp+8@gmail.com';

      const newInfo = {
        guest: {
          ...action.payload,
          // email,
        },
        expired_time: getExpiredTime(),
      };

      state.customerInfo = {
        ...newInfo,
      };

      setCustomerInfoFromLocalStorage({
        ...newInfo,
      });
    },

    resetCancelOrderStatus: state => {
      state.cancelOrderStatus = RequestStatus.IDLE;
    },

    resetGetOrderDetailStatus: state => {
      state.getOrderDetailStatus = RequestStatus.IDLE;
    },

    resetCreateAndCancelOrderStatus: state => {
      state.createOrderStatus = RequestStatus.IDLE;
      // state.errorCode = '';
      // state.reservation_statuses_info = [];
      state.cancelOrderStatus = RequestStatus.IDLE;
    },

    resetCheckInventoryStatus: state => {
      state.errorCode = '';
      state.reservation_statuses_info = [];
      state.checkInventoryStatus = RequestStatus.IDLE;
    },

    resetCheckoutStatus: state => {
      state.createOrderStatus = RequestStatus.IDLE;
      state.cancelOrderStatus = RequestStatus.IDLE;
      // reset expired_time to now
      const newCustomerInfo = {
        ...customerInfoWithoutUseData,
        expired_time: getExpiredTime(),
      };
      state.customerInfo = { ...newCustomerInfo };

      setCustomerInfoFromLocalStorage({ ...newCustomerInfo });
    },
  },
});

export default slice.actions;

export const useCheckoutSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  try {
    useInjectSaga({ key: slice.name, saga: checkoutSaga });
  } catch (e) {
    console.log(e);
  }
  return { actions: slice.actions };
};

export const checkoutSlice = () => {
  return slice;
};
