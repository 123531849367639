import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import registerSaga from './saga';
import { RegistrationState } from './types';
import { RequestStatus } from 'constants/API';
import { ISuccessPayload } from './types';

export const initialState: RegistrationState = {
  registrationStatus: RequestStatus.IDLE,
  errorMess: [],
};

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    registerRequest: (state, action: PayloadAction<any>) => {
      if (action) state.registrationStatus = RequestStatus.REQUESTING;
    },
    registerSuccess: (state, action: PayloadAction<ISuccessPayload>) => {
      state.registrationStatus = RequestStatus.SUCCESS;
    },
    registerFail: (state, action: PayloadAction<any>) => {
      if (action) state.registrationStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    resetRegistrationStatus: state => {
      state.errorMess = [];
      state.registrationStatus = RequestStatus.IDLE;
    },
  },
});

export default slice.actions;

export const useRegisterSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: registerSaga });
  return { actions: slice.actions };
};
