import { IUserSessionData } from 'app/pages/GlobalContainer/slice/types';
import { ICustomerInfo, IOrder } from 'types/common';
import {
  ACCESS_TOKEN_KEY,
  CACHED_URL,
  SHOW_SIDEBAR,
} from '../constants/localStorage';
import { checkTokenNotExpired } from './helper';

const I18NextLng = 'i18nextLng';
const USER_CACHED_DATA = 'USER_CACHED_DATA';
const CUSTOMER_INFO = 'CUSTOMER_INFO';
export const ORDER_LIST = 'ORDER_LIST';
export const CART_ITEMS = 'CART_ITEMS';
export const CURRENT_CHECKOUT_STEP = 'CURRENT_CHECKOUT_STEP';
export const SEARCH_INFO = 'SEARCH_INFO';
export const STAY_LOGGEDIN = 'STAY_LOGGEDIN';
export const LOGIN_TIME = 'atusjzjdshfdsjfhd';
export const RECENTLY_HOTEL = 'recently_hotel';
export const DEFAULT_HOTEL = 'hakodate';
// <Access Token>
export const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const getLang = () => localStorage.getItem(I18NextLng);

export const setRecentlyHotel = (hotelName: string) =>
  localStorage.setItem(RECENTLY_HOTEL, hotelName);

export const getRecentlyHotel = () =>
  !!localStorage.getItem(RECENTLY_HOTEL)
    ? localStorage.getItem(RECENTLY_HOTEL)
    : DEFAULT_HOTEL;

export const setToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const removeToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};
export const setLoginTime = (time: number) =>
  localStorage.setItem(LOGIN_TIME, `${time}`);
export const getLoginTime = () => localStorage.getItem(LOGIN_TIME);

export const removeLoginTime = (): void => {
  localStorage.removeItem(LOGIN_TIME);
};

export const isHavingToken = () => !!(getToken() && checkTokenNotExpired());
// </Access Token>

// <User Profile>
export const getUserCachedDataFromLocalStorage =
  (): IUserSessionData | null => {
    const data = localStorage.getItem(USER_CACHED_DATA);
    try {
      return data ? JSON.parse(data) : null;
    } catch (e) {
      return null;
    }
  };
export const setUserCachedDataFromLocalStorage = (
  userSessionData: IUserSessionData,
) => localStorage.setItem(USER_CACHED_DATA, JSON.stringify(userSessionData));

export const removeUserCachedDataFromLocalStorage = () => {
  localStorage.removeItem(USER_CACHED_DATA);
};

// <CustomerInfo>
export const getCustomerInfoFromLocalStorage = (): IOrder | null => {
  const data = localStorage.getItem(CUSTOMER_INFO);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};
export const setCustomerInfoFromLocalStorage = (customerInfo: IOrder) =>
  localStorage.setItem(CUSTOMER_INFO, JSON.stringify(customerInfo));

export const removeCustomerInfoFromLocalStorage = () => {
  localStorage.removeItem(CUSTOMER_INFO);
};

// cart items
export const getCartItemsFromStorage = () => {
  const data = localStorage.getItem(CART_ITEMS);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};

export const setCartItemsToStorage = cartItems =>
  localStorage.setItem(CART_ITEMS, JSON.stringify(cartItems));

export const removeCartItemsFromStorage = () => {
  localStorage.removeItem(CART_ITEMS);
};

// order list (no use)
export const getOrderListFromStorage = () => {
  const data = localStorage.getItem(ORDER_LIST);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};

export const setOrderListToStorage = orderList =>
  localStorage.setItem(ORDER_LIST, JSON.stringify(orderList));

export const removeOrderListFromStorage = () => {
  localStorage.removeItem(ORDER_LIST);
};

// checkout data
export const getCurrentCheckoutStep = () => {
  const data = localStorage.getItem(CURRENT_CHECKOUT_STEP);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};

export const setCurrentCheckoutStep = step =>
  localStorage.setItem(CURRENT_CHECKOUT_STEP, JSON.stringify(step));

export const removeCurrentCheckoutStep = () => {
  localStorage.removeItem(CURRENT_CHECKOUT_STEP);
};

export const setIsStayLoggedIn = val =>
  localStorage.setItem(STAY_LOGGEDIN, JSON.stringify(val));

export const removeIsStayLoggedIn = () => {
  localStorage.removeItem(STAY_LOGGEDIN);
};

export const removeStorageData = () => {
  removeUserCachedDataFromLocalStorage();
  removeToken();
};
// </ Profile>

// cart items
export const getSearchInfoFromStorage = () => {
  const data = localStorage.getItem(SEARCH_INFO);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};

export const setSearchInfoToStorage = searchInfo =>
  localStorage.setItem(SEARCH_INFO, JSON.stringify(searchInfo));

export const getCachedUrl = () => localStorage.getItem(CACHED_URL);

export const removeCachedUrl = () => localStorage.removeItem(CACHED_URL);

export const setIsShowSidebar = (isShowSidebar: boolean) =>
  localStorage.setItem(SHOW_SIDEBAR, isShowSidebar ? 'true' : 'false');
export const isShowSidebar = (): boolean =>
  (localStorage.getItem(SHOW_SIDEBAR) || 'false') === 'true';
