// UI
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from 'constants/styles';
import { dMedia } from 'styles/media';
import { renderPaymentMethods, renderPeriodCancel } from 'utils/helper';
import { ICancelInfo } from 'types/common';
import { MoneyItem } from 'app/components/MoneyItem';
import _ from 'lodash';
import { PAYMENT_METHODS } from 'constants/common';
interface ICancelPolicy {
  // total_amount: number;
  activeOrder: any;
}

const SummaryMoney = ({
  activeOrder: { total_amount, cancelable, cancellation_fee, payment_method },
  ...props
}: ICancelPolicy) => {
  const cancel_amount = cancelable ? cancellation_fee : 0;

  const renderMoney = (title, price, other_title = '') => {
    return (
      <WrapTotal
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <TitleTotal>{title}</TitleTotal>
        {price || price === 0 ? (
          <MoneyItem amount={price} isBold={true} fontSize={18} />
        ) : (
          ''
        )}
        {other_title && <TitleTotal>{other_title}</TitleTotal>}
      </WrapTotal>
    );
  };

  const refundMoney =
    payment_method === 'front_desk'
      ? 0
      : +total_amount - _.toInteger(cancel_amount);

  return (
    <>
      {renderMoney(
        '決済方法',
        '',
        _.find(PAYMENT_METHODS, ['value', payment_method])?.label,
      )}
      {renderMoney('合計', total_amount)}
      {renderMoney('キャンセル料金', cancel_amount)}
      {renderMoney('払い戻し金額', refundMoney)}
    </>
  );
};

export default SummaryMoney;

const WrapTotal = styled(Stack)`
  .money-item {
    span {
      font-weight: bold;
      line-height: 20px;
      color: ${colors.default};
    }
  }
`;

const TitleTotal = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.default};
  }
`;
