// UI
import { Box, Stack } from '@mui/material';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down-icon.svg';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from '@mui/material';
import { colors, fontsizes } from 'constants/styles';
import {
  AccountBox,
  Add,
  ArrowDropDown,
  CalendarToday,
  Remove,
} from '@mui/icons-material';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Path from 'config/clientPath';
import _ from 'lodash';

import { useWindowDimensions } from 'utils/useWindowDimensions';
import {
  getAdultCount,
  getChildrenCount,
  getCountGuest,
  getStringCheckinOutDate,
  getTotalPriceOfRoom,
  getUrlPlansPage,
  lodash,
  renderPaymentMethods,
  renderPeriodCancel,
} from 'utils/helper';
import { ICartItem } from 'types/common';
import CollapsePanel from 'app/components/CollapsePanel';
import SingleImage from 'app/components/SingleImage';
import moment from 'moment';
import { hideOnPCProps } from 'constants/common';
import BoxTotalMoney from 'app/components/CardPreview/BoxTotalMoney';

interface ISingleCartItemProps {
  data: ICartItem;
  cart_item_key: number;
}

export enum IPaginatePosition {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
}

export function SingleCartItem({
  data: {
    images,
    name,
    reservation: { arrival_date, checkout_date, number_of_stay },
    reservation,
    plan_name,
    room_name,
    meal,
    totalPrice,
    id,
    guests,
    local_hotel_id,
    checkin_times,
    cancellation_fee_policies,
    max_guests,
    meals,
    selected_payment_method,
    hotel_name,
    pricesDetail,
  },
  cart_item_key,
  ...props
}: ISingleCartItemProps) {
  const { width: pageWidth } = useWindowDimensions(0);
  const isMediumMobile = pageWidth <= 475;
  const { cart_items } = useSelector(selectGlobal);
  const history = useHistory();

  const { changeShowCardPreviewStatus } = useGlobalSlice().actions;
  const dispatch = useDispatch();

  return (
    <Container spacing={3}>
      <Stack
        spacing={2}
        alignItems="center"
        sx={{
          padding: { sm: '40px 80px 0 80px', xs: '16px 40px 0 40px' },
          // xs: { padding: '16px 40px 0 40px' },
        }}
      >
        <WrapImage
          sx={{
            width: '100%',
            alignItems: {
              sm: 'center',
              xs: 'unset',
            },
          }}
        >
          <ContentReserve mb={2}>予約内容</ContentReserve>
          <Stack
            alignSelf={'flex-start'}
            alignItems={'center'}
            spacing={2}
            className="image-box"
          >
            <SingleImage
              backgroundImage={_.first(images)?.path}
              width={240}
              height={180}
            />
            <RoomName>{hotel_name}</RoomName>
          </Stack>
        </WrapImage>
      </Stack>
      {/* for PC */}
      <Stack
        spacing={{ xs: 5, sm: 2 }}
        sx={{
          padding: { sm: '0 80px 0 80px', xs: '0px 40px 0 40px' },
        }}
      >
        <Stack spacing={4}>
          <StayInfo>宿泊情報</StayInfo>
          <Stack spacing={1}>
            <Title>宿泊日</Title>
            <Desc>{`${number_of_stay}泊 \n${getStringCheckinOutDate(
              arrival_date,
              // check out date is arrival date + number of stay
              moment(arrival_date)
                .add(number_of_stay || 1, 'days')
                .format('YYYY-MM-DD'),
            )}`}</Desc>
          </Stack>
          <Stack spacing={1}>
            <Title>人数</Title>
            <Desc>
              大人{getAdultCount(guests)}
              名、子供{getChildrenCount(guests)}名
            </Desc>
          </Stack>
          <Stack spacing={1}>
            <Title>プラン名</Title>
            <Desc>{plan_name}</Desc>
          </Stack>
          {/* <GrayBox spacing={1}>
            <Title>食事内容</Title>
            {!lodash.isEmpty(meal) && meal[0] ? (
              <Desc>
                {meal?.map(item => {
                  return (
                    <>
                      {item}
                      <br />
                    </>
                  );
                })}
              </Desc>
            ) : (
              ''
            )}
          </GrayBox> */}
          <Stack spacing={1}>
            <Title>部屋名</Title>
            <Desc>{room_name}</Desc>
          </Stack>
        </Stack>

        <Box
          sx={{
            width: '100vw',
            marginLeft: '-40px !important',
            display: { xs: 'block', sm: 'none' },
          }}
        >
          <Divider></Divider>
        </Box>

        <Stack spacing={4}>
          <StayInfo>ご宿泊料</StayInfo>
          <Stack spacing={1}>
            <Title>決済方法</Title>
            <Box display="flex" alignItems={'center'}>
              {/* <PaymentIcon /> */}
              <CreditCardPay>
                {renderPaymentMethods(selected_payment_method)}
              </CreditCardPay>
            </Box>
          </Stack>
          <BoxTotalMoney
            totalPrice={totalPrice}
            title={'価格'}
            pricesDetail={pricesDetail}
            isInSuccessPage
          ></BoxTotalMoney>

          {/* <CollapsePanel isOpen={true} viewMoreText={'料金内訳を表示する'}>
            
          </CollapsePanel> */}
          <GrayBoxBig>
            <CancelPolicy>キャンセル規定</CancelPolicy>
            <WrapPolicy display="flex">
              <Box>
                <CancelTitle>取消日</CancelTitle>
                {cancellation_fee_policies?.map(item => (
                  <CancelInfo>{`${renderPeriodCancel(
                    item.period_start,
                    item.period_end,
                  )}日前`}</CancelInfo>
                ))}
              </Box>
              <WrapCancelFee>
                <CancelTitle>取消料率</CancelTitle>
                {cancellation_fee_policies?.map(item => (
                  <CancelInfo>{`ご宿泊料金の${item.cancellation_fee}%`}</CancelInfo>
                ))}
              </WrapCancelFee>
            </WrapPolicy>
          </GrayBoxBig>
        </Stack>
      </Stack>
    </Container>
  );
}

const CreditCardPay = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;

const StayInfo = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #333333;
  }
`;
const Title = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }
`;
const Desc = styled(Typography)`
  && {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    white-space: pre-line;
  }
`;
const GrayBox = styled(Stack)`
  background: #f2f2f2;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px;
`;

const WrapImage = styled(Stack)`
  .image-box {
    ${dMedia.tablet`
    width: 240px !important;
    margin: 0 auto
  `}
  }
  .single-img {
    ${dMedia.tablet`
        width: 240px !important;
        height: 180px !important
      `}
  }
`;
const ContentReserve = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #333333;
  }
`;
const RoomName = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
  }
`;
const Container = styled(Stack)``;

const GrayBoxBig = styled(Box)`
  background: ${colors.grey6};
  padding: 16px 16.5px;
  border-radius: 8px;
  ${dMedia.tablet`
    padding: 16px 16.5px;
  `}
`;

const CancelPolicy = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.default};
    margin-bottom: 8px;
    text-align: center;
  }
`;

const WrapPolicy = styled(Box)`
  width: 207px;
  justify-content: space-between;
  margin: 0 auto;
`;

const CancelTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.grey3};
  }
`;
const CancelInfo = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;

const WrapCancelFee = styled(Box)`
  width: 121px;
  flex-shrink: 0;
  margin-left: 10px;
`;
