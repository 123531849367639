import 'moment/locale/ja';

import { Box, Grid, Stack, Typography } from '@mui/material';
import { PartialLoader } from 'app/components/PartialLoader';
import { ReactComponent as MaruIcon } from 'assets/icons/calendar/type-1.svg';
import { ReactComponent as TriangleIcon } from 'assets/icons/calendar/type-2.svg';
import { ReactComponent as EmptyIcon } from 'assets/icons/calendar/type-3.svg';
import { IMetaData } from 'constants/commonTypes';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getCalendar } from 'utils/calendar';

import { MoneyItem } from '../MoneyItem';
import DayItem from './day';
import Tag from './tag';
import { DEFAULT_GUEST_COUNT } from 'constants/common';

moment.locale('ja');
export const ItemTypes = {
  BOX: 'box',
};
export interface DropResult {
  isCheckin: boolean;
}

export interface IStock {
  date?: string;
  status?: string;
  quantity?: number;
  guest_types?: {
    guests_count?: number;
    available?: boolean;
    rate?: number;
    member_rate?: number;
  };
}
interface CalendarProps {
  /**
   * Date range selectable
   */
  selectable?: boolean;
  /*
   * Display month in format YYYY-MM
   */
  month: string;

  /**
   * Price display in each day
   * type:
   * - 1: チェックイン可
   * - 2: 残りわずか
   * - 3: チェックイン不可
   * - 4: 休館
   * Ex: { '2021-12-01': {type: 1, price: 5000}, '2021-12-12': {type: 2, price: 6000} }
   */
  data?: any;

  /**
   * Callback when click to a date
   */
  onClick?: Function;

  /**
   * Callback when mouse over to a date
   */
  onHover?: Function;

  /**
   * Selected Date List in format YYYY-MM-DD
   * Ex: ['2021-12-01', '2021-12-02']
   */
  selectedDateRange?: string[];

  /**
   * Callback for drag and drop event
   */
  onDrag?: Function;

  /** hover checkin date */
  onHoverCheckinDay?: Function;

  possibleCheckinDate?: string;

  fromSearch?: boolean;
  isLoading?: boolean;
  hiddenTag?: boolean;
  isLoggedIn?: boolean;
  guestCount?: number;
  isPlanModal?: boolean;
}
const DEFAULT_SELECTED_VALUE = -2;
const INVALID_DATE = 'Invalid date';

export const Calendar = (props: CalendarProps) => {
  const DAYS_OF_WEEK = ['日', '月', '火', '水', '木', '金', '土'];

  const [month, setMonth] = useState<string>();

  const [selectedLastRow, setSelectedLastRow] = useState<number>(
    DEFAULT_SELECTED_VALUE,
  );
  const [selectedLastColumn, setSelectedLastColumn] = useState<number>(
    DEFAULT_SELECTED_VALUE,
  );
  const [selectedIndexArray, setSelectedIndexArray] = useState<number[]>([]);

  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

  const isCheckinDate = d => {
    return (
      props.selectedDateRange &&
      props.selectedDateRange.length > 0 &&
      d === _.first(props.selectedDateRange) &&
      d !== INVALID_DATE
    );
  };
  const isCheckoutDate = d => {
    return (
      props.selectedDateRange &&
      props.selectedDateRange.length > 1 &&
      d === _.last(props.selectedDateRange) &&
      d !== INVALID_DATE
    );
  };
  const checkCheckinOrCheckOutDate = d => {
    return isCheckoutDate(d) || isCheckinDate(d);
  };

  const checkCheckinUnset = () => {
    return (
      props.selectable &&
      props.selectedDateRange &&
      props.selectedDateRange?.length === 0
    );
  };
  const checkHoveredDate = d => {
    return (
      checkCheckinUnset() &&
      props.possibleCheckinDate === d &&
      d !== INVALID_DATE
    );
  };

  useEffect(() => {
    setMonth(moment(`${props.month}-01`).format('YYYY-MM-DD'));
  }, [props.month]);

  const monthObject = month ? moment(month) : moment();

  const checkDaySelected = dateFormated => {
    return (
      props.selectable &&
      _.isArray(props.selectedDateRange) &&
      _.includes(props.selectedDateRange, dateFormated)
    );
  };

  const calendarDays = getCalendar(monthObject, DAYS_OF_WEEK);
  const getIndexDaySelected = () => {
    let _selectedLastRow = DEFAULT_SELECTED_VALUE;
    let _selectedLastColumn = DEFAULT_SELECTED_VALUE;

    let _selectedIndexArray: number[] = [];
    calendarDays.map((v1, k1) => {
      v1.map((v2, k2) => {
        const date = moment(v2);
        const formattedDate = date.format('YYYY-MM-DD');
        if (
          checkDaySelected(formattedDate) ||
          (props.possibleCheckinDate &&
            checkCheckinUnset() &&
            props.possibleCheckinDate === formattedDate &&
            props.possibleCheckinDate !== INVALID_DATE)
        ) {
          _selectedLastRow = k1;
          _selectedIndexArray = [..._selectedIndexArray, k1];
          _selectedLastColumn = k2;
        }
        return '';
      });
      return '';
    });

    setSelectedIndexArray(_.uniq(_selectedIndexArray));
    setSelectedLastRow(_selectedLastRow);
    setSelectedLastColumn(_selectedLastColumn);
  };

  useEffect(() => {
    getIndexDaySelected();
    setShowBackdrop(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedDateRange]);

  const handleDrop = (it, day) => {
    let checkInDate =
      props.selectedDateRange && props.selectedDateRange.length
        ? _.first(props.selectedDateRange)
        : '';
    let checkOutDate =
      props.selectedDateRange && props.selectedDateRange.length > 1
        ? _.last(props.selectedDateRange)
        : '';
    if (it.isCheckin) {
      checkInDate = day;
    } else {
      checkOutDate = day;
    }

    if (props.onDrag) {
      props.onDrag([checkInDate, checkOutDate]);
    }
  };

  const renderIcon = count => {
    if (!count) return <EmptyIcon />;
    switch (count) {
      case 0:
        return <EmptyIcon />;
      case 1 || 2:
        return <TriangleIcon />;
      default:
        return <MaruIcon />;
    }
  };

  function handleClickOnDateCell(date, data, formattedDate) {
    if (isDisabledDate(data) && _.isEmpty(props.selectedDateRange)) {
      return;
    }
    if (props.selectable && props.onHoverCheckinDay && date) {
      props.onHoverCheckinDay('');
    }

    if (props.selectable && props.onClick && date) {
      props.onClick(formattedDate);
    }
  }

  const isDisabledDate = data => {
    return (
      !props.fromSearch && (data?.remain_room_count === 0 || _.isEmpty(data))
    );
  };

  const hasTag = (data, formattedDate) => {
    return (
      (checkCheckinOrCheckOutDate(formattedDate) ||
        (checkCheckinUnset() && props.possibleCheckinDate === formattedDate)) &&
      !props.hiddenTag &&
      ((isDisabledDate(data) &&
        !_.isEmpty(props.selectedDateRange) &&
        _.size(props.selectedDateRange) > 1) ||
        !isDisabledDate(data))
    );
  };

  return (
    <CalendarPanel maxWidth="340px">
      <Grid container paddingY={2}>
        {DAYS_OF_WEEK.map(d => {
          const isHoliday = d === '日';
          return (
            <Grid
              item
              xs
              key={d}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <DayLabel fontSize={12} color={isHoliday ? '#EB5757' : '#333'}>
                {d}
              </DayLabel>
            </Grid>
          );
        })}
      </Grid>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {!props.fromSearch && <PartialLoader open={!!props.isLoading} />}
        <Box
          position={`relative`}
          minWidth={props.fromSearch ? ' auto' : `310px`}
        >
          {calendarDays.map((it, idx1) => {
            const metadata =
              props.data && month
                ? props.isPlanModal
                  ? props.data
                  : props.data[month?.substring(0, 7)]
                : [];

            return (
              <Row
                container
                wrap="nowrap"
                key={idx1}
                // set class when selected row indexes  is > 1 item
                className={`${
                  selectedLastRow === idx1 && selectedIndexArray.length > 1
                    ? 'isSelectedLastRow'
                    : ''
                } ${
                  selectedLastRow === idx1 && selectedIndexArray.length === 1
                    ? 'isSelectedOnlyRow'
                    : ''
                } ${idx1 === _.size(calendarDays) - 1 ? 'last-row' : ''}`}
              >
                {it.map((d, idx2) => {
                  const date = moment(d);
                  const dateFormated = date.format('YYYY-MM-DD');
                  const isHoliday = date.weekday() === 0;
                  const dt = _.find(
                    metadata,
                    item => item.date === dateFormated,
                  );

                  const isSelected = checkDaySelected(dateFormated);
                  return (
                    <DayItem
                      item
                      xs
                      selectable={props.selectable}
                      isBlank={d === ''}
                      isToday={dateFormated === moment().format('YYYY-MM-DD')}
                      isSelected={isSelected || checkHoveredDate(dateFormated)}
                      key={idx2}
                      onClick={() => handleClickOnDateCell(d, dt, dateFormated)}
                      onMouseEnter={() => {
                        if (props.selectable && props.onHover && d) {
                          props.onHover(dateFormated);
                        }
                        if (props.onHoverCheckinDay && props.selectable && d) {
                          props.onHoverCheckinDay(dateFormated);
                        }
                      }}
                      onMouseLeave={() => {
                        // if (props.onHoverCheckinDay) {
                        //   props.onHoverCheckinDay('');
                        // }
                      }}
                      className={`${
                        (isSelected && selectedLastRow === idx1) ||
                        checkHoveredDate(dateFormated)
                          ? 'isSelectedDayRow'
                          : ''
                      } ${
                        selectedLastColumn + 1 === idx2 ||
                        checkHoveredDate(dateFormated)
                          ? 'isColumnLastBesideColumn'
                          : ''
                      }
                      ${props.hiddenTag ? 'unselected' : ''}
                    ${showBackdrop ? 'opacity' : ''}
                    ${isDisabledDate(dt) ? 'disabled' : ''}
                    ${isSelected ? 'selected-date' : ''}
                    `}
                      accept={d ? ItemTypes.BOX : ''}
                      // onDrop={it => handleDrop(it, dateFormated)}
                    >
                      {d ? (
                        <>
                          {hasTag(dt, dateFormated) && (
                            <Tag
                              isCheckin={
                                dateFormated !== INVALID_DATE &&
                                (isCheckinDate(dateFormated) ||
                                  (checkCheckinUnset() &&
                                    props.possibleCheckinDate === dateFormated))
                              }
                              // setDragging={val => setShowBackdrop(val)}
                            />
                          )}
                          <Stack padding="0 5px" width={'100%'}>
                            {props.fromSearch ? (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                paddingBottom={1}
                              >
                                <DateLabel
                                  color={isHoliday ? '#EB5757' : '#333'}
                                >
                                  {date.format('D')}
                                </DateLabel>
                              </Box>
                            ) : (
                              <Box paddingBottom={1}>
                                <Stack
                                  direction="row"
                                  justifyContent={`space-between`}
                                  alignItems={`center`}
                                  pb={0.8}
                                  pt={0.8}
                                  sx={{
                                    '& svg': {
                                      height: '8px !important',
                                    },
                                  }}
                                >
                                  {renderIcon(dt?.remain_room_count)}

                                  <DateLabel
                                    color={isHoliday ? '#EB5757' : '#333'}
                                  >
                                    {date.format('D')}
                                  </DateLabel>
                                </Stack>
                                {dt?.remain_room_count ? (
                                  <MoneyItem
                                    fontSize={10}
                                    amount={dt?.lowest_price ?? 0}
                                  />
                                ) : (
                                  <Typography component="span" fontSize="10px">
                                    ￥<EmptyIcon />
                                  </Typography>
                                )}
                              </Box>
                            )}
                          </Stack>
                        </>
                      ) : (
                        ''
                      )}
                    </DayItem>
                  );
                })}
              </Row>
            );
          })}
        </Box>
      </Box>
    </CalendarPanel>
  );
};

const CalendarPanel = styled(Stack)``;

const DateLabel = styled(Typography)`
  &.MuiTypography-root {
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
  }
`;

const DayLabel = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
  }
`;

const Row = styled(Grid)`
  .MuiGrid-item {
    &:nth-of-type(7n + 1),
    &:nth-of-type(7n + 2),
    &:nth-of-type(7n + 3),
    &:nth-of-type(7n + 4),
    &:nth-of-type(7n + 5),
    &:nth-of-type(7n + 6) {
      border-right: none;
    }
  }
  &:nth-of-type(5n + 1),
  &:nth-of-type(5n + 2),
  &:nth-of-type(5n + 3),
  &:nth-of-type(5n + 4),
  &:nth-of-type(5n + 5) {
    .MuiGrid-item {
      border-bottom: none;
    }
  }
  &:last-child {
    .MuiGrid-item {
      border-bottom: 1px solid #dfdfdf;

      &.unselected,
      &.disabled:not(.selected-date) {
        border-bottom: 1px solid #dfdfdf;
      }
    }
  }
  &.isSelectedLastRow .MuiGrid-item {
    border-top: 1px solid #2f80ed;
    &.unselected,
    &.disabled:not(.selected-date) {
      /* border-color: #dfdfdf; */
    }
    &.isColumnLastBesideColumn {
      &:not(.unselected) {
        border-left: 1px solid #2f80ed;
      }

      &.disabled:not(.selected-date) {
        border-color: #dfdfdf;
      }
    }
    &.disabled:not(.selected-date) {
      border-top: 1px solid #2f80ed !important;
    }
  }
  &.isSelectedOnlyRow .MuiGrid-item {
    &.isColumnLastBesideColumn {
      border-left: 1px solid #2f80ed;
      &.unselected,
      &.disabled:not(.selected-date) {
        border-left: 1px solid #dfdfdf;
      }
    }

    &.isSelectedDayRow.disabled:not(.selected-date)
      ~ .isColumnLastBesideColumn {
      border-left: 1px solid #dfdfdf;
    }
    .isColumnLastBesideColumn.isSelectedDayRow {
      border-left: 1px solid #2f80ed;
    }
  }
  & .MuiGrid-root.selected-date:not(.unselected) + div,
  & .MuiGrid-root.isSelectedDayRow:not(.unselected) + div {
    border-left: 1px solid #2f80ed !important;
  }

  & .MuiGrid-root.selected-date.disabled + div.disabled,
  & .MuiGrid-root.disabled.isSelectedDayRow:not(.unselected) + div.disabled {
    border-left: 1px solid #dfdfdf !important;
  }

  .MuiGrid-item {
    &.isSelectedDayRow {
      border-bottom: 1px solid #2f80ed;
      &.unselected,
      &.disabled:not(.selected-date) {
        border-bottom: 1px solid #dfdfdf;
      }
      &.disabled:not(.selected-date) ~ .isColumnLastBesideColumn {
        border-left: 1px solid #dfdfdf;
      }
    }
    &.last-row .MuiGrid-item {
      &.isColumnLastBesideColumn {
        &.unselected,
        &.disabled:not(.selected-date) {
          border-bottom: none;
        }
      }
    }

    &.unselected,
    &.disabled:not(.selected-date) {
      border-color: #dfdfdf;
    }

    &.disabled {
      background: rgba(223, 223, 223, 0.6);
    }
  }
  &:not(.last-row) .MuiGrid-item {
    &.isColumnLastBesideColumn {
      &.unselected,
      &.disabled:not(.selected-date) {
        border-bottom: none;
      }
    }
  }
`;
