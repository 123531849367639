import { Box, Button, CardContent, Stack, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CommonButton } from 'app/components/CommonButton';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Modal from 'app/components/Modal';
import { getUrlPlansPage } from 'utils/helper';
import { useCheckoutSlice } from 'app/pages/CheckoutPage/slice';
import SummaryMoney from './SummaryMoney';

interface Props {
  activeOrder: any;
}

export const CancelOrderSuccess = ({ activeOrder }: Props) => {
  const { total_amount } = activeOrder;
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentCheckoutStep } = useSelector(selectGlobal);
  const { login, changeConfirmOrderModalStatus, changeCurrentCheckoutStep } =
    useGlobalSlice().actions;

  const { cancelOrderRequest, resetCancelOrderStatus } =
    useCheckoutSlice().actions;

  React.useEffect(() => {
    return () => {
      dispatch(resetCancelOrderStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container spacing={5}>
      <TitleHeader>キャンセル完了</TitleHeader>
      <Desc>
        下記の内容で、キャンセルを承りました。ご登録いただいているメールにも内容を送りいたしますので、ご確認くださいませ。
      </Desc>

      <WrapMoney spacing={2}>
        <SummaryMoney
          // total_amount={total_amount}
          activeOrder={activeOrder}
        ></SummaryMoney>
      </WrapMoney>

      <Desc>
        同じホテルの別プランや別日程の検索、また、近いエリアにあるVMG
        HOTELSの空き状況もご覧いただけます。
      </Desc>

      <WrapButton
        spacing={6}
        direction={'row'}
        // sx={{
        //   maxWidth: {
        //     xs: 'none',
        //     sm: '320px',
        //   },
        // }}
      >
        <FindPlanOtherButton
          onClick={() => {
            history.push(getUrlPlansPage('', {}, activeOrder?.hotel_id));
          }}
        >
          <span>別のプランを探す</span>
        </FindPlanOtherButton>
        <FindPlanOtherButton
          onClick={() => {
            // history.push(Path.ORDER_HISTORY);
            history.push(Path.SEARCH);
          }}
        >
          <span>他のホテルを探す</span>
        </FindPlanOtherButton>
        {/* <BackButton
          onClick={() => {
            history.push(Path.SEARCH);
          }}
        >
          <span>TOPへ</span>
        </BackButton>{' '} */}
      </WrapButton>

      <Box>
        <Desc>またのご利用をお待ち申し上げております。</Desc>
        <br />
        <Desc>VMG総合窓口</Desc>
        <Desc>0120-210-289（11:00-20:00）</Desc>
      </Box>
    </Container>
  );
};

const WrapButton = styled(Stack)`
  /* text-align: center;
  align-items: center;
  margin: 40px auto 0 auto !important; */
  width: 100%;
`;

const BackButton = styled(Button)`
  &.MuiButtonBase-root {
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }
    padding: 8px 12px;
    background-color: ${colors.white};
    border-radius: 4px;
    color: ${colors.blue};
    height: fit-content;
    width: 100%;
    border: 1px solid ${colors.blue};
    &:hover {
      background-color: ${colors.white};
      color: ${colors.blue};
    }
  }
`;

const FindPlanOtherButton = styled(Button)`
  &.MuiButtonBase-root {
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }
    padding: 10px 12px;
    background-color: ${colors.blue};
    border-radius: 4px;
    color: ${colors.white};
    height: fit-content;
    width: 100%;
    &:hover {
      background-color: ${colors.blue};
      color: ${colors.white};
    }
  }
`;

const Container = styled(Stack)`
  margin: 0 auto;
  max-width: 458px;
  width: 100%;
  ${dMedia.tablet`
    
  `}
`;

const TitleHeader = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    text-align: center;
    padding-bottom: 10px;
  }
`;

const WrapMoney = styled(Stack)`
  padding: 24px 7px;
  outline: 1px solid ${colors.grey4};
  box-sizing: border-box;
  border-radius: 8px;
`;

const Desc = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
`;
