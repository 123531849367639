import { convertDate, convertDateFormatSlash } from './moment';
import { retrieveUserCachedDataFromStorage } from '../app/pages/GlobalContainer/slice/index';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment';
import {
  AFTER_PAID_PAYMENT_TYPE,
  BEFORE_PAID_PAYMENT_TYPE,
  CHILD_PRICE,
  DEFAULT_GUEST_COUNT,
  DEFAULT_MEAL,
  DEFAULT_STORE_HOTEL_ID,
  GTM_CLASS_NAME,
  MEALS,
  PAYMENT_METHODS,
} from 'constants/common';
import { IAdditionRoomInfo, ICartItem, IReservationStatus } from 'types/common';
import { IAdditionInfoFormData } from 'app/components/Modal/AdditionInfoModal';
import { ReactComponent as PremiumIcon } from 'assets/icons/room/premium.svg';
import { ReactComponent as BathIcon } from 'assets/icons/room/bath.svg';
import { v4 as uuidv4 } from 'uuid';
import { getLoginTime, getToken, setLoginTime } from './localStorage';

export const FORMAT_HOUR_MINUTE = 'HH:mm';

export const checkIsRequesting = (statuses: string[]) => {
  let isRequesting = false;
  _.forEach(statuses, status => {
    if (status === RequestStatus.REQUESTING) {
      isRequesting = true;
      return;
    }
  });
  return isRequesting;
};

export const getImages = (images: any, limitImages = 5) => {
  const newImages = _.isEmpty(images)
    ? []
    : images.map(item => {
        return { src: item.path };
      });

  // return [];
  return _.slice(newImages, 0, limitImages);
};

export const getTagsData = (tags: any) => {
  let newTags: string[] = [];
  tags &&
    _.map(tags, item => {
      newTags = [...newTags, item.name];
    });
  return newTags;
  // return tags;
};

export const getUrlPlansPage = (slug: any, queryParams = {}, id = 0) => {
  const urlString = slug || id;
  let url = Path.RESERVATION_PREFIX + '/' + urlString + Path.PLANS + '/';
  const params = { ..._.omitBy(queryParams, v => !v) };
  if (_.isEmpty(params)) {
    return url;
  }
  const qs = queryString.stringify({ ...params });
  return url + `?${qs}`;
};

export const getUrlRoomsPage = (slug: any) => {
  return Path.RESERVATION_PREFIX + '/' + slug + Path.ROOMS + '/';
};

export const getUrlWithNewParams = newParams => {
  let params = { ...queryString.parse(window.location.search), ...newParams };

  const qs = queryString.stringify({ ..._.omitBy(params, v => !v) });

  return `${window.location.pathname}?${qs}`;
};

export const getFullUrlforTab = (isPlan: boolean, slug: any) => {
  let params = queryString.parse(window.location.search);
  let url =
    Path.RESERVATION_PREFIX +
    '/' +
    slug +
    (isPlan ? Path.PLANS : Path.ROOMS) +
    '/';
  const qs = queryString.stringify({ ..._.omitBy(params, v => !v) });
  return `${url}${!_.isEmpty(qs) ? '?' + qs : ''}`;
};

export const bath_option = '檜風呂';
export const premium_room_option = 'プレミアム';
export const private_open_air_bath_option = '専用露天風呂';

export const getRoomOptions = ({
  options: {
    bath = false,
    premium_room = false,
    private_open_air_bath = false,
  },
}) => {
  return [
    {
      text: premium_room_option,
      isDisplayed: premium_room,
      icon: <PremiumIcon />,
    },
    { text: bath_option, isDisplayed: bath, icon: <BathIcon /> },
    { text: private_open_air_bath_option, isDisplayed: private_open_air_bath },
  ];
};

export const getRoomOptionsTag = ({
  options: {
    bath = false,
    premium_room = false,
    private_open_air_bath = false,
  },
  options,
}) => {
  return _.map(
    _.filter(getRoomOptions({ options }), ['isDisplayed', true]),
    'text',
  );
};

export const calPriceForGuests = (
  newCartItem,
  hotelStockStatus,
  roomForCalPrice,
) => {
  const adultCount = getAdultCount(newCartItem.guests);
  const adultPrice = _.reduce(
    hotelStockStatus,
    (result, value, key) => {
      const foundRateObj = _.find(
        value.guest_types,
        v => +v.guests_count === +adultCount,
      );
      const priceForOnePerson = foundRateObj
        ? foundRateObj.rate
        : +newCartItem.price_for_1_person;
      return result + priceForOnePerson * +adultCount;
    },
    0,
  );

  const children_guests = _.filter(newCartItem.guests, v => {
    return v.guest_type !== 'male' && v.guest_type !== 'female';
  });
  const childPrice = _.reduce(
    children_guests,
    (result, value, key) => {
      const priceForOneChild =
        roomForCalPrice?.[CHILD_PRICE[value.guest_type]] || 0;

      const newPrice = value.count ? +value.count * priceForOneChild : 0;
      return result + newPrice;
    },
    0,
  );

  return {
    adultPrice,
    childPrice,
    totalAllPrice: adultPrice + childPrice,
  };
};

export const getReservationInfo = (
  cartItem,
  addition_info: IAdditionRoomInfo,
  reservation: IAdditionInfoFormData,
  pricesDetail: any,
) => {
  const {
    hotel_id,
    local_hotel_id,
    plan_id,
    room_id,
    plan_name,
    room_name,
    cancellation_fee_policies,
    max_guests,
    // meals,
    payment_method,
    hotel_name,
    hotelStockStatus,
    detail_plan,
  } = addition_info;
  const roomForCalPrice = _.find(detail_plan?.rooms, v => v.id === room_id);

  const {
    // meal,
    adult,
    // child,
    child_A,
    child_B,
    child_C,
    child_D,
    child_E,
    nights,
    checkin_date,
    checkout_date,
    // checkin_time,
  } = reservation;
  const moment_checkin_date = moment(checkin_date || null);

  const moment_checkout_date = moment(checkout_date || null);
  const string_arrival_date = convertDate(moment_checkin_date);
  const string_checkout_date = convertDate(moment(checkout_date));

  const number_of_stay = Math.abs(
    moment_checkout_date.diff(moment_checkin_date, 'days'),
  );
  // if user select 1 time to check in => use that time, else render array of check in times
  // const check_in_time = checkin_time
  //   ? checkin_time
  //   : getStringCheckoutTimeFromArray(cartItem.checkin_times);
  const meals = addition_info?.meals || [];

  const newCartItem = {
    ...cartItem,
    pricesDetail,
    checkin_times: cartItem?.checkin_times,
    checkout_times: getCheckInOutTime(cartItem.checkout_times),
    reservation: {
      plan_id,
      hotel_id,
      // hotel_id:
      //   retrieveUserCachedDataFromStorage()?.account?.hotel_id ||
      //   DEFAULT_STORE_HOTEL_ID,
      reservation_datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
      check_in_time: '',
      number_of_stay,
      number_of_room: 1,
      total_amount: 0,
      arrival_date: string_arrival_date,
      checkout_date: string_checkout_date,
    },
    room_type_id: room_id,
    date_of_stay: string_arrival_date,
    plan_id,
    local_hotel_id,
    plan_name,
    room_name,
    guests: [
      {
        guest_type: 'male',
        count: adult,
        rate: 0,
      },
      {
        guest_type: 'child_A',
        count: child_A,
        rate: 0,
      },
      {
        guest_type: 'child_B',
        count: child_B,
        rate: 0,
      },
      {
        guest_type: 'child_C',
        count: child_C,
        rate: 0,
      },
      {
        guest_type: 'child_D',
        count: child_D,
        rate: 0,
      },
      {
        guest_type: 'child_E',
        count: child_E,
        rate: 0,
      },
    ],
    // no use
    meal: meals,
    // use
    meals,
    cancellation_fee_policies,
    max_guests: max_guests || 0,
    payment_method,
    hotel_name: hotel_name || '',
  };

  // const { adultPrice, childPrice, totalAllPrice } = calPriceForGuests(
  //   newCartItem,
  //   hotelStockStatus,
  //   roomForCalPrice,
  // );
  // const countPerson = foundGuest?.count || 1;
  // const adultCount = getAdultCount(newCartItem.guests);
  // const adultPrice = _.reduce(
  //   hotelStockStatus,
  //   (result, value, key) => {
  //     const foundRateObj = _.find(
  //       value.guest_types,
  //       v => +v.guests_count === +adultCount,
  //     );
  //     const priceForOnePerson = foundRateObj
  //       ? foundRateObj.rate
  //       : +newCartItem.price;
  //     return result + priceForOnePerson * +adultCount;
  //   },
  //   0,
  // );
  //  total price = price * number of night * count person adult (male)
  // const totalPrice =
  //   +newCartItem.price *
  //   (newCartItem?.reservation?.number_of_stay || 1) *
  //   +adultCount;

  const { price } = pricesDetail;
  return {
    local_id: uuidv4(),
    ...newCartItem,
    // price
    totalPrice: price || 0,
    // adultPrice,
    // childPrice,
    roomForCalPrice,
    hotelStockStatus,
    reservation: {
      ...newCartItem.reservation,
      total_amount: price || 0,
    },
  };
};

export const cloneFormData = formData => {
  let newFormData = {};
  Object.keys(formData).map(v => {
    let newData = {
      [v]: { ...formData?.[v] },
    };
    if (formData?.[v]?.guest) {
      newData = {
        [v]: { ...formData?.[v], guest: { ...formData?.[v]?.guest } },
      };
    }
    newFormData = { ...newFormData, ...newData };
    return '';
  });
  return newFormData;
};

export const getReservationsList = (cartItems, cacheFormData) => {
  // const newItems = _.reduce(
  //   cartItems,
  //   function (result, value, key) {
  //     (result[value.plan_id] || (result[value.plan_id] = [])).push(value);
  //     return result;
  //   },
  //   [],
  // );
  // const { allGuests } = guestInfo;
  const { enquetes, paymentInfo } = cacheFormData;
  const reservations = cartItems.map((v1, k) => {
    // const allTotalPrice = _.reduce(
    //   v1,
    //   function (sum, v2) {
    //     return sum + +v2.totalPrice;
    //   },
    //   0,
    // );
    const tabId = v1?.local_id;

    const cacheCartItem = cacheFormData?.[tabId];
    let reservation = {
      ...v1?.reservation,
      total_amount: v1?.totalPrice,
      number_of_room: 1,
      // number_of_room: v1?.length,
      enquetes: enquetes?.[tabId] || [],
      check_in_time: cacheCartItem?.check_in_time || '',
    };
    // uncomment this block
    if (paymentInfo?.softbank_payment_token) {
      reservation = { ...reservation, ...paymentInfo };
    }
    // if has c5 field => render text from form data, else get employmentName form account info
    const employmentName = _.includes(Object.keys(cacheCartItem), 'c5')
      ? getEmploymentNameText(cacheCartItem?.c5)
      : cacheCartItem?.guest?.employment_name || '拒否';

    // const {
    //   address,
    //   city,
    //   email,
    //   kana,
    //   name,
    //   prefecture_code,
    //   telephone,
    //   postal_code,
    //   gender,
    //   employment_kana,
    // } = cacheCartItem?.guest;
    const newGuest = {
      ...cacheCartItem?.guest,
      employment_name: employmentName,
      // address,
      // city,
      // email,
      // kana,
      // name,
      // prefecture_code,
      // telephone,
      // postal_code,
      // gender,
      // employment_kana: 'サンプルカブシキガイシャ',
      // employment_name: 'サンプル株式会社',
    };
    let room_types = [v1];
    for (let i = 1; i < reservation.number_of_stay; i++) {
      room_types.push({
        ...v1,
        date_of_stay: moment(reservation.arrival_date)
          .add(i, 'days')
          .format('YYYY-MM-DD'),
      });
    }
    return {
      guest: _.omit(newGuest, ['account_id', 'hotel_id', 'cacheFormData']),
      reservations: [
        {
          reservation,
          room_types,
          add_on_services: [],
        },
      ],
    };
  });

  return reservations.sort().filter(v => v);
};

export const getTotalPriceOfRoom = cart_items => {
  return _.reduce(
    cart_items,
    function (sum, v2) {
      return sum + +v2.totalPrice;
    },
    0,
  );
};

export const getPlanPageQueryParams = (newParams = null) => {
  let params = queryString.parse(window.location.search);
  const tags = params?.tags || '';
  // const tags = params?.tags || DEFAULT_MEAL;
  if (newParams) {
    params = { ...params, newParams, tags };
  }
  params = { ...params, guest_count: params?.guest_count || 2 };
  params = { ..._.omitBy(params, v => !v) };
  return { ...params };
};

export const getStringCheckDate = check_date => {
  return check_date ? moment(check_date).format('YYYY/MM/DD(ddd)') : '';
};

export const getStringCheckinOutDate = (
  checkin_date: string,
  checkout_date: string,
) => {
  return (
    getStringCheckDate(checkin_date) + ' ~ ' + getStringCheckDate(checkout_date)
  );
};

export const getCountGuest = (guest_type: string, guests) => {
  return _.find(guests, ['guest_type', guest_type])?.count || 0;
};

export const getCheckInOutTime = time => {
  return time
    ? moment(time, FORMAT_HOUR_MINUTE).format(FORMAT_HOUR_MINUTE)
    : '';
};

export const getStringCheckoutTimeFromArray = (time: string[]) => {
  if (_.isEmpty(time)) return '';
  return time
    .map((v, k) => {
      return getCheckInOutTime(v);
    })
    .join(', ');
};

export const lodash = _;

// expire time for customer info is 1 days
export const getExpiredTime = () => {
  return moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
};

export function deleteAllCookies() {
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let hostName = window.location.hostname;
    let domain = hostName.slice(hostName.indexOf('.'), hostName.length);
    // console.log(domain, 'domain');
    let cookie = cookies[i];
    let eqPos = cookie.indexOf('=');
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=; path=/; expires=` + new Date(0).toUTCString();
    document.cookie =
      `${name}=; path=/; domain=${domain}; expires=` +
      new Date(0).toUTCString();
  }
}

export const isDevelopEnv = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return true;
  } else {
    return false;
  }
};

export const getSingleImage = (images: [{ path: string }]) => {
  return _.isEmpty(images) ? '' : images[0]?.path;
};

export const renderPeriodCancel = (period_start, period_end) => {
  if (period_start === period_end) {
    return period_start;
  }
  return `${period_start}~${period_end}`;
};

export const getGuestCount = () => {
  let params = queryString.parse(window.location.search);
  return params?.guest_count || DEFAULT_GUEST_COUNT;
};

export const renderPaymentMethods = (payment_method: {
  softbank_payment?: boolean;
  front_desk?: boolean;
}) => {
  if (!payment_method) {
    return '';
  }
  let allMethods: any = [];
  PAYMENT_METHODS.map(v => {
    if (payment_method?.[v.value]) {
      allMethods = [...allMethods, v.label];
    }
    return '';
  });

  return allMethods?.join('・');
};

export const getChildParams = () => {
  let params = queryString.parse(window.location.search);
  return _.pick(params, [
    'child_a_count',
    'child_b_count',
    'child_c_count',
    'child_d_count',
    'child_e_count',
  ]);
};

export const convertStockDataToCalendar = (
  stockData,
  calendarData,
  isLoggedIn,
  guests = 0,
) => {
  const data = _.cloneDeep(calendarData);
  let params = queryString.parse(window.location.search);
  const guest = guests ?? params.guest_count ?? DEFAULT_GUEST_COUNT;
  if (!_.isEmpty(stockData)) {
    stockData.forEach((it, idx) => {
      const month = it.date.substring(0, 7);
      const matchGuestType = _.find(it?.guest_types, {
        guests_count: _.toInteger(guest),
      });
      const index = _.findIndex(data[month], { date: it.date });

      if (matchGuestType) {
        if (index >= 0) {
          if (!matchGuestType.available)
            data[month][index].remain_room_count = 0;
          else data[month][index].remain_room_count = it.quantity;
          data[month][index].lowest_price = isLoggedIn
            ? matchGuestType.member_rate
            : matchGuestType.rate;
        } else {
          if (_.isEmpty(data[month])) {
            data[month] = [];
          }
          data[month].push({
            remain_room_count: matchGuestType.available ? it.quantity : 0,
            lowest_price: isLoggedIn
              ? matchGuestType.member_rate
              : matchGuestType.rate,
            date: it.date,
          });
        }
      }
    });
  }
  return data;
};

export const getChildrenCount = guests => {
  const guests_data = _.filter(guests, v => {
    return v.guest_type !== 'male' && v.guest_type !== 'female';
  });
  return _.reduce(
    guests_data,
    (result, value, key) => {
      return result + +value.count;
    },
    0,
  );
};

export const getAdultCount = guests => {
  const guests_data = _.filter(guests, v => {
    return v.guest_type === 'male' || v.guest_type === 'female';
  });
  return _.reduce(
    guests_data,
    (result, value, key) => {
      return result + +value.count;
    },
    0,
  );
};

export const getDateWithStartZero = (date): any => {
  return ('0' + date).slice(-2);
};

export const getRangeYear = (_startYear = 1980, _endYear = 2000): any => {
  let startYear = _startYear;
  let endYear = _endYear || +moment().format('YYYY');
  let years: any = [];
  while (startYear <= endYear) {
    years = [
      ...years,
      {
        label: startYear,
        value: startYear,
      },
    ];
    startYear++;
  }
  return years;
};

export const getRangeMonth = (): any => {
  let startMonth = 1;
  let months: any = [];
  while (startMonth <= 12) {
    const stringMonth = getDateWithStartZero(startMonth);
    months = [
      ...months,
      {
        label: stringMonth,
        value: stringMonth,
      },
    ];
    startMonth++;
  }
  return months;
};

export const getRangeDate = (): any => {
  let startDate = 1;
  let dates: any = [];
  while (startDate <= 31) {
    const stringDate = getDateWithStartZero(startDate);
    dates = [
      ...dates,
      {
        label: stringDate,
        value: stringDate,
      },
    ];
    startDate++;
  }
  return dates;
};

export const getCheckoutDate = (
  arrival_date: string,
  number_of_stay,
): string => {
  return moment(arrival_date)
    .add(number_of_stay || 1, 'days')
    .format('YYYY-MM-DD');
};

export const getProductsHasPrice = (products: any[]): string => {
  return _.filter(products, v => v.price);
};

// check allow access checkout pages or not
export const checkIsAllowInCheckoutPage = (
  usedCartItems: Array<ICartItem>,
): boolean => {
  // allow access checkout page when has cart items and it has price > 0
  const cartItems = usedCartItems ? usedCartItems.filter(v => v.price) : [];
  if (cartItems.length !== 0) {
    return true;
  }
  return false;
};

export const checkTokenNotExpired = () => {
  const loginTime = getLoginTime() ? _.toInteger(getLoginTime()) : 0;
  const currentTimeStamp = new Date().getTime();
  if (loginTime === 0) {
    if (getToken()) {
      setLoginTime(currentTimeStamp);
    }
    return true;
  }
  const invertal = currentTimeStamp - loginTime;
  return invertal > 0 && invertal < 6 * 60 * 60 * 1000;
};

export const getEmploymentNameBool = name => {
  return name === '取得';
};

export const getEmploymentNameText = name => {
  return name ? '取得' : '拒否';
};

export const checkDisabledMonth = (month, isBefore = true) => {
  if (
    month.format('YYYY-MM') ===
    moment()
      .add(isBefore ? 0 : 11, 'months')
      .format('YYYY-MM')
  ) {
    return true;
  }
  return false;
};

export const removeOldGtm = () => {
  const gtmElements = document.querySelectorAll(`.${GTM_CLASS_NAME}`);

  gtmElements.forEach(el => {
    el.remove();
  });
};

export const getGtmParams = (gtmId, gtmClassName = '') => {
  return {
    gtmId: gtmId,
    events: {},
    dataLayer: {
      event: 'Pageview',
    },
    gtmClassName,
  };
};

export const validatePaymentMethod = plans => {
  let isError = false;
  let hasBeforePaidPaymentProduct = false;
  let hasAfterPaidPaymentProduct = false;
  let countProductAllowTwoMethod = 0;
  let paymentType = '';
  _.forEach(plans, v => {
    const { front_desk, softbank_payment } = v.payment_method;
    // count number of product allow two payment method
    if (front_desk && softbank_payment) {
      countProductAllowTwoMethod++;
      // only one payment method
    } else if (front_desk && !softbank_payment) {
      paymentType = AFTER_PAID_PAYMENT_TYPE;
      // set flag hasBeforePaidPaymentProduct to then other product has rest payment method => set isError is true
      if (hasBeforePaidPaymentProduct) {
        isError = true;
      }
      hasAfterPaidPaymentProduct = true;
    } else if (softbank_payment && !front_desk) {
      paymentType = BEFORE_PAID_PAYMENT_TYPE;
      if (hasAfterPaidPaymentProduct) {
        isError = true;
      }
      hasBeforePaidPaymentProduct = true;
    }
  });
  return {
    isError,
    countProductAllowTwoMethod,
    paymentType,
    // allowPayTwoMethod if all product allow two method
    allowPayTwoMethod: countProductAllowTwoMethod === _.size(plans),
  };
};

// export const getStockId = allIds => {
//   const { hotel_id, plan_id, room_id } = allIds;
//   return `${hotel_id}_${plan_id}_${room_id}`;
// };

export const getReservationId = ({
  arrival_date,
  check_in_time,
  hotel_id,
  plan_id,
  room_type_id,
  maleCount,
  femaleCount,
  childrenCount,
}) => {
  return [
    arrival_date,
    check_in_time,
    hotel_id,
    plan_id,
    room_type_id,
    maleCount,
    femaleCount,
    childrenCount,
  ].join('_');
};

export const getCountBySpecifyGuest = (guests, guestName = 'male') => {
  const guest = _.find(guests, ['guest_type', guestName]);
  return guest?.count || 0;
};

export const getCountDetailGuest = guests => {
  const childrenInfo = guests?.filter(
    it => it.guest_type !== 'male' && it.guest_type !== 'female',
  );
  return {
    maleCount: getCountBySpecifyGuest(guests, 'male'),
    femaleCount: getCountBySpecifyGuest(guests, 'female'),
    childrenCount: _.sumBy(childrenInfo, 'count') || 0,
  };
};

export const getCountByGuestType = (guests, guestType) => {
  return lodash.find(guests, v => v.guest_type === guestType)?.count || 0;
};

export const getCheckStockParams = v => {
  let checkStockParams = v?.checkStockParams;

  // handle transform info from cart to checkStockParams with old cart (without checkStockParams)
  if (!checkStockParams) {
    const { hotel_id, plan_id, room_id } = v?.addition_info || {};
    const { date_of_stay, guests } = v;
    const { checkout_date } = v?.reservation || {};
    const guest_count =
      getCountByGuestType(guests, 'male') +
      getCountByGuestType(guests, 'female');
    // let allChild = {}
    const allChild = Object.keys(CHILD_PRICE).map(v => {
      return getCountByGuestType(guests, v);
    });

    const [child_A, child_B, child_C, child_D, child_E, child_F] = allChild;

    checkStockParams = {
      hotel_id,
      plan_id,
      room_id,
      checkin_date: date_of_stay,
      checkout_date: checkout_date,
      guest_count,
      child_a_count: child_A,
      child_b_count: child_B,
      child_c_count: child_C,
      child_d_count: child_D,
      child_e_count: child_E,
    };
  }
  return { ...checkStockParams, local_id: v?.local_id };
};

export const getReservationStatusesInfo = reservation_statuses_info => {
  let statusesInfo: IReservationStatus[] = [];
  const reservationOrders = '①②③④⑤⑥⑦⑧⑨⓾'.split('');
  statusesInfo = _.orderBy(reservation_statuses_info, ['numberOrder']);
  statusesInfo = statusesInfo.map((v1, k1) => {
    const orderKey = v1.numberOrder ? +v1.numberOrder - 1 : 0;
    const order = reservationOrders[orderKey] || k1 + 1;
    return {
      ...v1,
      name: '予約' + order,
    };
  });
  return statusesInfo;
};
