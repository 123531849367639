import { Box, CardContent, Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CommonButton } from 'app/components/CommonButton';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import {
  AFTER_PAID_PAYMENT_TYPE,
  BEFORE_PAID_PAYMENT_TYPE,
} from 'constants/common';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';

interface Props {}

export const PaymentMethod = (props: Props) => {
  React.useEffect(() => {
    window?.dataLayer?.push({
      pagePath: Path.PAYMENT,
      pageUrl: Path.PAYMENT,
      pageTitle: '決済選択',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const { currentCheckoutStep, cart_items } = useSelector(selectGlobal);
  const {
    login,
    changeConfirmOrderModalStatus,
    changeCurrentCheckoutStep,
    changeCartItemInfo,
  } = useGlobalSlice().actions;
  const { handleRedirectPaymentPage } = useGlobalFunc();

  const changePaymentMethodOfCart = newPaymentMethod => {
    const newCartItems = _.map(cart_items, v => {
      return { ...v, ...newPaymentMethod };
    });
    dispatch(changeCartItemInfo({ newCartItems }));
  };

  return (
    <>
      <Container>
        <CardContent
          className="body"
          sx={{
            maxWidth: {
              md: '375px',
              xs: '100%',
            },
            margin: {
              md: '0 auto',
            },
          }}
        >
          <BoxHeader>決済方法</BoxHeader>
          <Typography
            component="p"
            fontSize={fontsizes.main}
            color={colors.mainContent}
            mt={4}
            mb={4}
          >
            下記より決済方法を選択してください
          </Typography>
          <CommonButton
            variant="contained"
            size="large"
            sx={{
              background: colors.blue,
              boxShadow: 'none',
              marginBottom: 2,
            }}
            onClick={() => {
              // changePaymentMethodOfCart({
              //   selected_payment_method: { softbank_payment: true },
              // });

              // history.push(`${Path.PAYMENT}`);
              // dispatch(changeCurrentCheckoutStep(3));
              handleRedirectPaymentPage(BEFORE_PAID_PAYMENT_TYPE);
            }}
          >
            <span style={{ color: colors.white, textDecoration: 'none' }}>
              事前決済する
            </span>
          </CommonButton>
          <CommonButton
            variant="contained"
            size="large"
            sx={{
              margin: '1rem 0',
              background: 'transparent',
              boxShadow: 'none',
              color: colors.default,
              border: '1px solid ' + colors.blue,
              '&:hover': {
                background: 'rgba(25, 118, 210, 0.04)',
              },
            }}
            onClick={() => {
              handleRedirectPaymentPage(AFTER_PAID_PAYMENT_TYPE);
              // changePaymentMethodOfCart({
              //   selected_payment_method: { front_desk: true },
              // });

              // dispatch(changeConfirmOrderModalStatus(true));
              // dispatch(changeCurrentCheckoutStep(3));
            }}
          >
            <span style={{ color: colors.blue, textDecoration: 'none' }}>
              現地で決済する
            </span>
          </CommonButton>
        </CardContent>
      </Container>
    </>
  );
};

const Container = styled(Box)`
  margin: 0 auto;
  ${dMedia.tablet`
    width: 100%;
    margin: 35px 0 0 0;
    max-width: 375px;
  `}
  .MuiCardContent-root {
    padding-bottom: 8rem;
    ${dMedia.tablet`
        padding: 0px;
    `}
  }
`;
