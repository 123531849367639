// UI
import * as React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { IImage } from 'constants/commonTypes';
import styled, { css } from 'styled-components';
import SingleImage from '../SingleImage';
import Viewer from 'react-viewer';
import { colors } from 'constants/styles';
import { MoneyItem } from '../MoneyItem';
import { DETAIL_PRICES } from 'constants/common';

interface IBoxTotalMoney {
  totalPrice: number;
  title?: string;
  pricesDetail?: any;
  isInSuccessPage?: boolean;
}

const BoxTotalMoney = ({
  totalPrice,
  title = '合計',
  isInSuccessPage = false,
  pricesDetail,
}: IBoxTotalMoney) => {
  return (
    <Container>
      <WrapMoney direction="row" justifyContent={'space-between'}>
        <Total>{title}</Total>
        <MoneyItem amount={totalPrice} fontSize={24} isBold />
      </WrapMoney>
      <DetailArea gap={2} isInSuccessPage={isInSuccessPage}>
        <Title>内訳（税・サービス料込）</Title>
        <Content gap={1}>
          {DETAIL_PRICES.map((v, k) => {
            const data = pricesDetail?.[v.key];
            return (
              <Row direction="row" gap={2} key={k}>
                <Name>{v.label}</Name>
                <WrapPrice>
                  {data?.count ? (
                    <MoneyItem
                      amount={data?.price}
                      fontSize={isInSuccessPage ? 12 : 14}
                      isBold
                    />
                  ) : (
                    <Text>{'ー'}</Text>
                  )}
                </WrapPrice>
                <Count>{data?.count ? data.count + '名' : 'ー'}</Count>
              </Row>
            );
          })}
        </Content>
      </DetailArea>
    </Container>
  );
};

BoxTotalMoney.defaultProps = {};
export default BoxTotalMoney;

const Total = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.default};
  }
`;

const Container = styled(Stack)`
  background: ${colors.grey6};
  border-radius: 8px;
  padding: 8px;
`;

const Title = styled(Typography)`
  && {
    font-weight: 500;
    font-size: inherit;
    line-height: 1.2;
    color: #333333;
    flex-shrink: 0;
  }
`;
const Content = styled(Stack)`
  width: 100%;
  padding-left: 10px;
`;
const Row = styled(Stack)``;
const WrapPrice = styled(Box)`
  width: 70px;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  .money-item {
    padding-right: 0px !important;
    span {
      line-height: 1.2;
    }
  }
`;
const Count = styled(Typography)`
  && {
    font-weight: 500;
    font-size: inherit;
    line-height: 1.2;
    color: #333333;
    width: 23px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
  }
`;
const Name = styled(Typography)`
  && {
    font-weight: 500;
    font-size: inherit;
    line-height: 1.2;
    color: #333333;
    flex: 1;
  }
`;
const Text = styled(Typography)`
  && {
    font-weight: 500;
    font-size: inherit;
    line-height: 1.2;
    color: #333333;
  }
`;

const WrapMoney = styled(Stack)`
  align-items: center;
  .money-item {
    padding-right: 0px !important;
    span {
      font-weight: bold;
      line-height: 20px;
    }
  }
`;

const DetailArea = styled(Stack)<{
  isInSuccessPage?: boolean;
}>`
  padding: 15px 0 0 0px;
  font-size: 14px;
  ${props =>
    props.isInSuccessPage &&
    css`
      font-size: 12px;
    `};
`;
