export const colors = {
  blue: '#2F80ED',
  grey: '#BDBDBD',
  grey1: '#333333',
  grey2: '#4F4F4F',
  grey3: '#828282',
  grey4: '#BDBDBD',
  grey5: '#E0E0E0',
  grey6: '#F2F2F2',
  grey7: '#757575',
  default: '#202223',
  white: '#fff',
  heading: '#000',
  orange: '#F2994A',
  lightGreen: '#E9FFE3',
  greenText: '#219653',
  inputBackgroud: '#EEEEEE',
  mainContent: '#000',
};

export const fontsizes = {
  main: '14px',
  heading1: '40px',
  heading2: '24px',
  heading3: '18px',
  smallText: '12px',
  xsText: '10px',
};
