import { Box, TextField, Typography, Button } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { CommonButton } from 'app/components/CommonButton';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { colors, fontsizes } from 'constants/styles';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { Loader } from 'app/components/Loading';
import { RequestStatus } from 'constants/API';

import { useSelector, useDispatch } from 'react-redux';
import Path from 'config/clientPath';
import { useAccountSlice } from './slice';
import { selectAccount } from './slice/selectors';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getToken } from 'utils/localStorage';
import useGlobalFunc from '../GlobalContainer/useGlobalFunc';
import { checkTokenNotExpired } from 'utils/helper';
import { FORM_ERROR_TEXT } from 'constants/common';

export interface IChangePasswordPageProps {}
interface IChangePassword {
  email: string;
  new_password: string;
  old_password: string;
  confirm_password: string;
}

export default function ChangePasswordPage(props: IChangePasswordPageProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userSessionData } = useSelector(selectGlobal);
  const { setErrorMessages, setSuccessMessages, logout } =
    useGlobalSlice().actions;
  const { updateAccountStatus, errorMess } = useSelector(selectAccount);
  const { updateAccountRequest } = useAccountSlice().actions;
  const [successUpdatePassword, setSuccessUpdatePassword] =
    React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { handleLogout } = useGlobalFunc();

  const handleChangePasswordSubmit = formData => {
    const { account_id, email, hotel_id } = userSessionData.account;
    const data = {
      new_password: formData.new_password,
      old_password: formData.old_password,
      id: account_id,
      email,
      hotel_id,
    };
    dispatch(updateAccountRequest(data));
  };

  React.useEffect(() => {
    if (!getToken() || !checkTokenNotExpired()) {
      history.push(Path.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (updateAccountStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Successfully updated password']));
      setSuccessUpdatePassword(true);
      handleLogout();
    }
    if (updateAccountStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAccountStatus]);

  const isLoading = updateAccountStatus === RequestStatus.REQUESTING;

  return !successUpdatePassword ? (
    <>
      <Helmet>
        <title>パスワード変更 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="パスワード変更 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Box>
        <Header />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{
            margin: {
              md: '0 auto',
              xs: '60px auto',
            },
            padding: {
              md: '50px 0',
              xs: '0 20px',
            },
            maxWidth: {
              md: '375px',
              xs: '100%',
            },
            // height: {
            //   md: 'calc(100vh - 80px)',
            //   xs: 'auto',
            // },
          }}
        >
          <Loader open={isLoading} />
          <BoxHeader>パスワード変更</BoxHeader>
          <Typography
            sx={{
              margin: { md: '36px 0', xs: '24px 0' },
              whiteSpace: 'break-spaces',
            }}
            component="p"
            fontSize={fontsizes.main}
            color={colors.default}
            textAlign="center"
            lineHeight="24px"
          >
            パスワードは当サイトを利用する際に必要となります
            以下のフォームより新しいパスワードを入力してください
          </Typography>
          <FormContainer
            component="form"
            id="change_password_form"
            onSubmit={handleSubmit(handleChangePasswordSubmit)}
          >
            <Typography
              gutterBottom
              color={colors.mainContent}
              fontWeight={500}
              fontSize="14px"
            >
              古いパスワード
            </Typography>
            <TextField
              {...register<keyof IChangePassword>('old_password', {
                required: 'こちらは必須項目です。',
              })}
              type="password"
              placeholder="例）abcd@vmg.co.jp"
              fullWidth
              variant="outlined"
              size="small"
              error={errors.old_password ? true : false}
              helperText={errors.old_password?.message}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
            <Typography
              gutterBottom
              color={colors.mainContent}
              fontWeight={500}
              fontSize="14px"
              mt={5}
            >
              新しいパスワード
            </Typography>
            <TextField
              {...register<keyof IChangePassword>('new_password', {
                required: 'こちらは必須項目です。',
                validate: value =>
                  value !== watch('old_password') ||
                  'New password must be different',
              })}
              type="password"
              placeholder="********"
              fullWidth
              variant="outlined"
              size="small"
              error={errors.new_password ? true : false}
              helperText={errors.new_password?.message}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />

            <Typography
              gutterBottom
              color={colors.mainContent}
              fontWeight={500}
              fontSize="14px"
              mt={5}
            >
              新しいパスワード（確認）
            </Typography>
            <TextField
              {...register<keyof IChangePassword>('confirm_password', {
                required: 'こちらは必須項目です。',
                validate: value =>
                  value === watch('new_password') ||
                  FORM_ERROR_TEXT.PASS_NOT_MATCH,
              })}
              type="password"
              placeholder="********"
              fullWidth
              variant="outlined"
              size="small"
              error={errors.confirm_password ? true : false}
              helperText={errors.confirm_password?.message}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />

            <CommonButton
              className="white-btn"
              variant="contained"
              size="large"
              type="submit"
              form="change_password_form"
              sx={{
                background: colors.blue,
                boxShadow: 'none',
                fontSize: '15px',
                marginBottom: 1,
                lineHeight: '20px',
                padding: '10px',
                marginTop: 5,
              }}
            >
              送信する
            </CommonButton>
          </FormContainer>
        </Box>
        <Footer />
      </Box>
    </>
  ) : (
    <>
      <Helmet>
        <title>
          パスワードの再設定完了しました | VMG HOTELS & UNIQUE VENUES
        </title>
        <meta
          name="パスワードの再設定完了しました  | VMG HOTELS & UNIQUE VENUES"
          content="パスワードの再設定完了しました
"
        />
      </Helmet>
      <Box height="100vh">
        <Header />
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{
            margin: {
              md: '0 auto',
              xs: '60px auto',
            },
            padding: {
              md: '0',
              xs: '0 20px',
            },
            maxWidth: {
              md: '375px',
              xs: '100%',
            },
            height: {
              md: 'calc(100vh - 80px)',
              xs: 'auto',
            },
          }}
        >
          <BoxHeader>パスワードの再設定完了しました</BoxHeader>

          <Typography
            sx={{
              margin: { md: '36px 0', xs: '24px 0' },
              whiteSpace: { md: 'nowrap', xs: 'normal' },
            }}
            component="p"
            fontSize={fontsizes.main}
            color={colors.default}
            textAlign="center"
            lineHeight="24px"
          >
            以下の「ログイン」ボタンより、改めてログインしてください
          </Typography>

          <Button
            component={Link}
            to={Path.LOGIN}
            className="white-btn"
            variant="contained"
            size="large"
            sx={{
              background: colors.blue,
              boxShadow: 'none',
              fontSize: '15px',
              marginBottom: 1,
              lineHeight: '20px',
              padding: '10px',
              width: '100%',
            }}
          >
            ログインする
          </Button>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

const FormContainer = styled(Box)`
  .MuiInputBase-input {
    font-size: 14px;
  }
  .MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: inherit;
    border-width: 1px;
  }
  .MuiButton-root {
    font-weight: 500;
    display: block;
    a {
      color: inherit;
      display: block;
      text-decoration: none;
    }
  }
`;
