import { Box } from '@mui/material';
import Checkout from 'app/components/Checkout';
import { Loader } from 'app/components/Loading';
import Modal from 'app/components/Modal';
import { OrderInfo } from 'app/components/OrderInfo';
import { useOrderInfoContext } from 'app/OrderInfoContext';
import { RequestStatus } from 'constants/API';
import { hideOnPCProps, hideOnSPProps } from 'constants/common';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import {
  checkIsRequesting,
  cloneFormData,
  getCheckStockParams,
  lodash,
} from 'utils/helper';
import _ from 'lodash';

import { useCheckoutSlice } from '../CheckoutPage/slice';
import { selectCheckout } from '../CheckoutPage/slice/selectors';
import { useGlobalSlice } from '../GlobalContainer/slice';
import useGlobalFunc from '../GlobalContainer/useGlobalFunc';
import { PaymentMethod } from '../PaymentPage/components/PaymentMethod';
import usePlanAndRoom from '../PlanAndRoomPage/usePlanAndRoom';
import Path from 'config/clientPath';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  planId: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { checkInventoryStatus } = useGlobalFunc();

  const {
    getPlanDetailRequest,
    getPlanDetailStatus,
    detail_plan,
    getPlanForOrderStatus,
    planForOrder,
  } = usePlanAndRoom();

  const { children, value, index, planId, ...other } = props;
  const isLoading =
    value === index &&
    checkIsRequesting([getPlanForOrderStatus?.[planId], checkInventoryStatus]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Loader open={isLoading} />
      <Box>{children}</Box>
    </div>
  );
};

export function OrderInfoPage() {
  const infoForm = useForm<any>({
    shouldFocusError: true,
    defaultValues: {
      // [activeTab]: {
      //   male_adult_count: getInitialAdultCount().male_adult_count,
      //   female_adult_count: getInitialAdultCount().female_adult_count,
      //   guest: { ...customerInfo.guest },
      // },
      // ...customerInfo.guest,
      // male_adult_count: getInitialAdultCount().male_adult_count,
      // female_adult_count: getInitialAdultCount().female_adult_count,
    },
    // defaultValues: {
    //   use_purpose: '',
    //   male_adult_count: 0,
    //   female_adult_count: 0,
    //   allergies: '',
    //   field_not_good: '',
    //   others: '',
    //   receive_email: true,
    //   agree_policy: false,
    //   architect_note: false,
    //   mean: '',
    //   age_and_gender: '',
    //   check_in_time: '',
    // },
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    trigger,
    setValue,
    formState: { errors },
    formState,
  } = infoForm;

  const [showPaymentMethod, setShowPaymentMethod] =
    React.useState<boolean>(false);
  const {
    activeTab,
    setActiveTab,
    activeCartItem,
    allPlans,
    setAllPlans,
    isSynced,
    setIsSynced,
  } = useOrderInfoContext();

  const { createOrderStatus, customerInfo, reservation_statuses_info } =
    useSelector(selectCheckout);
  const { cacheFormData } = customerInfo?.guest;
  const history = useHistory();

  const { resetCreateAndCancelOrderStatus, changeCustomerInfo } =
    useCheckoutSlice().actions;

  const refUnmount = React.useRef<any>(null);
  const handleUnmount = () => {
    const formData = { ...cloneFormData(watch()) };

    // setCacheFormData({ ...cacheFormData, ...watch() });
    const newData = {
      ...customerInfo?.guest,
      cacheFormData: {
        ...cacheFormData,
        ...formData,
      },
    };

    dispatch(changeCustomerInfo(newData));
    dispatch(resetCreateAndCancelOrderStatus());
  };
  refUnmount.current = handleUnmount;

  const dispatch = useDispatch();
  const { changeCurrentCheckoutStep } = useGlobalSlice().actions;
  const {
    getPlanDetailRequest,
    getPlanDetailStatus,
    detail_plan,
    planForOrder,
  } = usePlanAndRoom();
  const {
    cart_items,
    checkInventory,
    checkInventoryStatus,
    errorCode,
    changeShowOrderStatusModal,
  } = useGlobalFunc();
  React.useEffect(() => {
    dispatch(changeCurrentCheckoutStep(2));
    if (cart_items) {
      let gotPlanIds: any = [];
      cart_items.map(v => {
        const cart_item = { ...v?.addition_info };
        if (lodash.includes(gotPlanIds, cart_item?.plan_id)) {
          return '';
        }
        gotPlanIds.push(cart_item?.plan_id);
        gotPlanIds = lodash.uniq(gotPlanIds);

        getPlanDetailRequest(
          cart_item?.local_hotel_id,
          cart_item?.plan_id,
          cart_item?.plan_id,
        );
      });

      // new spec 07/12/2022: call 1 api check inventory when access create order page
      const checkInventoryParams = _.map(cart_items, v => {
        let checkStockParams = getCheckStockParams(v);
        return { ...checkStockParams };
      });
      // handle check inventory of all items in cart after check stock of each item
      checkInventory(checkInventoryParams);
    }

    return () => {
      refUnmount.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (checkInventoryStatus === RequestStatus.ERROR && errorCode) {
      history.push(Path.CART);
      changeShowOrderStatusModal(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInventoryStatus]);

  React.useEffect(() => {
    if (isSynced) {
      let formData = watch();
      cart_items.map(v => {
        if (v?.local_id !== activeTab) {
          // handle copy all data from active tab to another tab
          const syncedProps = Object.keys(formData?.[activeTab]).filter(
            v => v !== 'guest',
          );

          const comparedProps = Object.keys(formData[v?.local_id]);

          let newData = {
            ...formData[v?.local_id],
            guest: { ...formData?.[activeTab]?.guest },
          };

          lodash.forEach(syncedProps, v1 => {
            if (lodash.includes(comparedProps, v1)) {
              let newValue = formData?.[activeTab]?.[v1];
              // handle options check_in_time when sync data
              if (v1 === 'check_in_time') {
                const activePlan = planForOrder?.[v?.plan_id];

                if (!lodash.includes(activePlan?.checkin_times, newValue)) {
                  newValue = formData?.[v?.local_id]?.[v1];
                }
              }
              newData = {
                ...newData,
                [v1]: newValue,
              };
            }
          });
          formData = { ...formData, [v?.local_id]: newData };
        }
      });

      reset({ ...formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSynced]);

  // const isLoading = checkIsRequesting([getPlanDetailStatus]);

  React.useEffect(() => {
    if (createOrderStatus === RequestStatus.ERROR) {
      if (errorCode) {
        setShowPaymentMethod(false);
        // dispatch(resetCreateAndCancelOrderStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderStatus]);

  return (
    <>
      <Helmet>
        <title>予約情報登録 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="予約情報登録 | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      {/* <button
        onClick={() => {
          history.push(`${Path.CART}`);
        }}
      >
        dfs
      </button> */}
      <Checkout>
        {showPaymentMethod && (
          <>
            {/* show payment method component on SP */}
            <Box sx={{ ...hideOnPCProps }}>
              <PaymentMethod />
            </Box>
            {/* show payment method modal on PC */}
            <Box sx={{ ...hideOnSPProps }}>
              <PaymentMethodModal
                open={showPaymentMethod}
                onClose={() => {
                  setShowPaymentMethod(false);
                }}
              >
                <PaymentMethod />
              </PaymentMethodModal>
            </Box>
          </>
        )}

        {/* on PC show payment method modal and order info component */}
        <Box
          sx={showPaymentMethod ? { ...hideOnSPProps } : {}}
          className="wrap_tabs"
        >
          <FormProvider {...infoForm}>
            {cart_items?.map((v, k) => (
              <TabPanel
                value={activeTab}
                index={v?.local_id}
                key={k}
                planId={v?.plan_id}
              >
                <OrderInfo
                  setShowPaymentMethod={setShowPaymentMethod}
                  tabId={v?.local_id}
                  planIdValue={v?.plan_id}
                  cartItemValue={v}
                  hasMultiple={!!(cart_items?.length > 1)}
                />
              </TabPanel>
            ))}
          </FormProvider>
        </Box>
      </Checkout>
    </>
  );
}

const PaymentMethodModal = styled(Modal)`
  ${dMedia.tablet`
    &.common_modal {
      display: none;
    }
  `}
`;
