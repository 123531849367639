// UI
import { Box, Stack } from '@mui/material';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { OrangeTag } from '../atoms/OrangeTag';
import SingleImage from '../SingleImage';
import { MoneyItem } from '../MoneyItem';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down-icon.svg';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from '@mui/material';
import { colors, fontsizes } from 'constants/styles';
import {
  AccountBox,
  Add,
  ArrowDropDown,
  CalendarToday,
  Remove,
} from '@mui/icons-material';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useDispatch } from 'react-redux';
import CollapsePanel, { ButtonPosition } from '../CollapsePanel';
import { Link } from 'react-router-dom';
import Path from 'config/clientPath';
// swiper
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/slider/arrow_left.svg';
import { ReactComponent as ArrowLeft40 } from 'assets/icons/slider/arrow_left_40.svg';
import { ReactComponent as ArrowLeftSPIcon } from 'assets/icons/slider/arrow_left_sp.svg';
import { CartItem } from './CartItem';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import BoxTotalMoney from './BoxTotalMoney';
import { getTotalPriceOfRoom, lodash } from 'utils/helper';
import SummaryBox from './SummaryBox';
import { ICartItem } from 'types/common';
import { useOrderInfoContext } from 'app/OrderInfoContext';

interface ICardPreview {
  isCancelOrderPage?: boolean;
  orderedCart?: Array<any>;
  // orderedCart?: Array<ICartItem>;
}

export function CardPreview({
  isCancelOrderPage = false,
  orderedCart,
  ...props
}: ICardPreview) {
  const { activeTab, setActiveTab, activeCartItem, allPlans, setAllPlans } =
    useOrderInfoContext();

  const location = useLocation();
  const { cart_items, cart_items_deleted } = useSelector(selectGlobal);
  const cart_data = isCancelOrderPage
    ? orderedCart
    : location.pathname === Path.SUCCESS
    ? cart_items_deleted
    : cart_items;
  // const found_cart_data = lodash.find(allCartItems, ['local_id', activeTab]);
  // const cart_data = found_cart_data ? [found_cart_data] : [];
  // console.log('🚀 ~ cart_data', cart_data);

  // const { width: pageWidth } = useWindowDimensions(0);

  // const { changeShowCardPreviewStatus } = useGlobalSlice().actions;
  // const dispatch = useDispatch();

  return (
    <Container
      // spacing={{ xs: 5, sm: 3 }}
      mb={5}
      className={`${isCancelOrderPage ? 'cancel_page_preview' : ''}`}
    >
      {/* {!isCancelOrderPage && (
        <SummaryBox
          totalPrice={getTotalPriceOfRoom(cart_data)}
          cart_data={cart_data}
        ></SummaryBox>
      )} */}

      {cart_data.map((data, k) => {
        return (
          <WrapCartData
            key={k}
            sx={{
              display:
                data?.local_id === activeTab || isCancelOrderPage
                  ? 'block'
                  : 'none',
              marginTop: { xs: 0, sm: isCancelOrderPage ? 0 : 3 },
            }}
          >
            <CartItem data={data} cart_item_key={k}></CartItem>
          </WrapCartData>
        );
      })}
    </Container>
  );
}

const WrapCartData = styled(Box)`
  /* padding: 40px 40px 40px 40px; */
  padding-bottom: 40px;
  border: 1px solid ${colors.grey};
  border-radius: 8px;
  ${dMedia.tablet`
    padding: 0 0 40px 0;
  `}
`;

const Container = styled(Stack)`
  height: fit-content;
  width: 431px;
  /* border: 1px solid ${colors.grey}; */
  /* border-radius: 8px; */
  /* padding: 40px 40px 40px 40px; */
  &.cancel_page_preview {
    /* max-width: 431px;
    width: 100%; */
  }

  ${dMedia.tablet`
    border: none;
    width: 100%;
  `}
`;
