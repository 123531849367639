import React from 'react';

// UI
import { Box, CircularProgress } from '@mui/material';

// Library
import styled from 'styled-components/macro';

interface IPartialLoader {
  open: boolean;
  sx?: any;
}

export function PartialLoader(props: IPartialLoader) {
  return props.open ? (
    <WrapLoader {...props}>
      <CircularProgress color="inherit" />
    </WrapLoader>
  ) : (
    <></>
  );
}

const WrapLoader = styled(Box)`
  padding-top: 36px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  /* background: rgb(0 0 0 / 5%); */
  z-index: 2;
`;
