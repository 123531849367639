// UI
import { Typography } from '@mui/material';
import { colors, fontsizes } from 'constants/styles';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';

interface IBoxHeader {
  children: any;
  icon?: React.ReactNode;
  fontSize?: number;
  maxWidth?: string;
  className?: string;
}

export function BoxHeader({
  icon = null,
  fontSize = 18,
  maxWidth = '100%',
  className,
  ...props
}: IBoxHeader) {
  const { children } = props;
  return (
    <Heading
      variant="h3"
      sx={{
        maxWidth: {
          xs: 'unset',
          md: maxWidth,
        },
        borderBottom: 1,
        borderColor: colors.grey4,
        fontSize: {
          md: fontSize + 'px',
          xs: mappingFontSizeToScreens(fontSize + 'px'),
        },
        paddingBottom: {
          md: icon ? '14px' : '10px',
          xs: '10px',
        },
      }}
      display="flex"
      alignItems="center"
      className={`box_header ${className}`}
    >
      {icon}
      <Typography
        component="span"
        color={colors.mainContent}
        sx={{
          fontSize: {
            md: fontSize + 'px',
            xs: mappingFontSizeToScreens(fontSize + 'px'),
          },
          marginLeft: icon ? '12px' : '6px',
          fontWeight: 700,
          lineHeight: 1,
        }}
      >
        {children}
      </Typography>
    </Heading>
  );
}

const Heading = styled(Typography)`
  svg {
    padding-left: 10px;
  }

  ${dMedia.tablet`
    svg {
      width: 30px;
      height: auto;
    }
  `}
`;

const mappingFontSizeToScreens = (fontSize: string): string => {
  switch (fontSize) {
    case fontsizes.heading2:
      return fontsizes.heading3;
    case fontsizes.heading3:
      return fontsizes.main;
    default:
      return fontsizes.heading3;
  }
};
