// UI
import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { IImage } from 'constants/commonTypes';
import styled from 'styled-components';
import SingleImage from '../SingleImage';
import Viewer from 'react-viewer';

interface IImageGallery {
  images: Array<IImage>;
  galleryWidth?: number | string;
  width?: number | string;
  height?: number | string;
  backgroundSize?: string;
  backgroundPosition?: string;
  borderRadius?: number;
  showOnFullScreen?: boolean;
  showFullGallery?: boolean;
}

const ImageGallery = (props: IImageGallery) => {
  const {
    borderRadius,
    width,
    height,
    backgroundSize,
    backgroundPosition,
    showOnFullScreen,
    images,
    galleryWidth,
    showFullGallery,
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [activeImg, setActiveImg] = React.useState(0);

  return (
    <Container style={{ width: galleryWidth }}>
      <Grid container spacing={'40px'}>
        {images.map((image, k) => {
          return (
            <Grid
              onClick={() => {
                setVisible(true);
                setActiveImg(k);
              }}
              item
              xs={6}
              md={6}
              key={k}
            >
              <SingleImage
                width={width}
                height={height}
                backgroundImage={image.src}
                borderRadius={borderRadius}
                backgroundSize={backgroundSize}
                backgroundPosition={backgroundPosition}
                showOnFullScreen={showOnFullScreen}
                showFullGallery={showFullGallery}
              ></SingleImage>
            </Grid>
          );
        })}
      </Grid>
      <ViewerContainer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        rotatable={false}
        zoomable={false}
        scalable={false}
        noImgDetails={true}
        images={images.map(item => {
          return { src: item.imgFull ? item.imgFull : item.src };
        })}
        activeIndex={activeImg}
      />
    </Container>
  );
};

ImageGallery.defaultProps = {
  images: Array(5)
    .fill('')
    .map(() => {
      return { src: '/img/hotels/room.png' };
    }),
  galleryWidth: 520,
  width: 240,
  height: 150,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: 'unset',
  showOnFullScreen: false,
};
export default ImageGallery;

const Container = styled(Box)`
  margin: 0 auto;
  .MuiGrid-root {
    justify-content: center;
  }
`;

const ViewerContainer = styled(Viewer)`
  .react-viewer-toolbar {
    display: flex;
    justify-content: space-between;
    margin: 0 40px;
    height: calc(100vh - 50px);
    align-items: center;
    li[data-key='reset'] {
      display: none;
    }
    .react-viewer-btn {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .react-viewer-icon {
        font-size: 22px;
      }
    }
  }
  .react-viewer-attribute {
    position: absolute;
    bottom: 60px;
    margin: 0 40px;
    width: calc(100% - 80px);
  }
`;
