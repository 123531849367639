import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import resetPasswordSaga from './saga';
import { ResetPasswordState } from './types';
import { RequestStatus } from 'constants/API';
import { ISuccessPayload } from './types';

export const initialState: ResetPasswordState = {
  getResetPasswordStatus: RequestStatus.IDLE,
  postResetPasswordStatus: RequestStatus.IDLE,
  errorMess: [],
  successMess: null,
};

const slice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    postResetPasswordRequest: (state, action: PayloadAction<any>) => {
      if (action) state.postResetPasswordStatus = RequestStatus.REQUESTING;
      state.successMess = null;
    },
    postResetPasswordSuccess: (
      state,
      action: PayloadAction<ISuccessPayload>,
    ) => {
      state.postResetPasswordStatus = RequestStatus.SUCCESS;
    },
    postResetPasswordFail: (state, action: PayloadAction<any>) => {
      if (action) state.postResetPasswordStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    getResetPasswordRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getResetPasswordStatus = RequestStatus.REQUESTING;
    },
    getResetPasswordSuccess: (
      state,
      action: PayloadAction<ISuccessPayload>,
    ) => {
      state.getResetPasswordStatus = RequestStatus.SUCCESS;
      state.successMess = action.payload.message;
    },
    getResetPasswordFail: (state, action: PayloadAction<any>) => {
      if (action) state.getResetPasswordStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    resetStatus: state => {
      state.errorMess = [];
      state.successMess = null;
      state.postResetPasswordStatus = RequestStatus.IDLE;
      state.getResetPasswordStatus = RequestStatus.IDLE;
    },
  },
});

export default slice.actions;

export const useResetPasswordSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: resetPasswordSaga });
  return { actions: slice.actions };
};
