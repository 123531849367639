import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import {
  getQueryString,
  IAvailability,
} from 'app/pages/SearchPage/components/SearchItem';
import { IHotel } from 'app/pages/SearchPage/slice/types';
import ZoomInIcon from 'assets/icons/map/zoom_in.svg';
import ZoomOutIcon from 'assets/icons/map/zoom_out.svg';
import HotelImg from 'assets/imgs/hotel_1.png';
import { colors, fontsizes } from 'constants/styles';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import queryString from 'query-string';

import { MoneyItem } from '../MoneyItem';

const LATITUDE = parseFloat('37.88637');
const LONGITUDE = parseFloat('135.00589');
export interface IHotelWithPrice extends IHotel {
  availability: boolean;
}
interface Props {
  data: IHotel[];
  height?: string;
  width?: string;
  handleScroll?: (index) => void;
  activeMarker?: number | null;
  isSetCenter?: Boolean;
  key: string;
  isLoading?: any;
  center?: {
    lat: number | string;
    lng: number | string;
  };
}

interface IMarker {
  lat: number | string;
  lng: number | string;
  active: boolean;
  color: string;
}

export const GoogleMap = ({
  height = '100vh',
  width = '100%',
  isSetCenter = false,
  isLoading = {},
  center = { lat: LATITUDE, lng: LONGITUDE },
  ...props
}: Props) => {
  const mapRef = React.useRef<GoogleMapReact>(null);
  const [googleMap, setGoogleMap] = React.useState<GoogleMapReact>();
  const [googleMaps, setGoogleMaps] = React.useState<GoogleMapReact>();

  let params = queryString.parse(window.location.search);
  const CURRENT_DATE = params.checkin_date
    ? moment(params.checkin_date).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;
  const UNAVAILABILITY = '満室';
  const LOADING = '読込中';
  const ZOOM = 5;
  const BUFFER_SLIDE = 2;

  const [activeMarker, setActiveMarker] = React.useState<number | null>();

  const swiper = React.useRef() as any;
  const handleClick = idx => {
    if (props.handleScroll) props.handleScroll(idx);
    setActiveMarker(idx);

    swiper.current?.swiper.slideTo(idx + BUFFER_SLIDE);
  };

  // React.useEffect(() => {
  //   if (props.data?.length) {
  //     fitBounds();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.data, googleMap, googleMaps]);

  React.useEffect(() => {
    if (props.activeMarker !== undefined) {
      setActiveMarker(props.activeMarker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeMarker]);

  const handleFallback = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = HotelImg;
  };
  const qs = getQueryString();

  return (
    <MapContainer
      style={{
        height,
        width,
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        defaultZoom={ZOOM}
        center={center}
        onGoogleApiLoaded={({ map, maps }) => {
          setGoogleMaps(maps);
          setGoogleMap(map);
        }}
        ref={mapRef}
        key={props.key}
      >
        {props.data?.map((it, idx) => {
          return (
            <Marker
              key={idx}
              lat={parseFloat(it.latitude)}
              lng={parseFloat(it.longitude)}
              onMouseOver={() => setActiveMarker(idx)}
              onMouseLeave={() => setActiveMarker(null)}
              color={it.availability ? colors.blue : colors.grey3}
              active={idx === activeMarker}
              onClick={() => handleClick(idx)}
            >
              {/* {isLoading[it.id] && (
                <CircularProgress
                  sx={{
                    width: '12px !important',
                    height: '12px !important',
                    marginRight: '3px!important',
                  }}
                  color="inherit"
                />
              )}
              {isLoading[it.id] ? (
                LOADING
              ) : it.availability &&
                _.get(currentDateItem, 'remain_room_count') &&
                _.get(currentDateItem, 'lowest_price') ? (
                <MoneyItem
                  amount={_.toInteger(_.get(currentDateItem, 'lowest_price'))}
                  hasMin={activeMarker === idx && isMobile}
                />
              ) : (
                UNAVAILABILITY
              )} */}
              <Typography
                component="span"
                fontSize="10px"
                sx={{
                  width: '60px',
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {it.name}
              </Typography>
            </Marker>
          );
        })}
      </GoogleMapReact>
      <Wrapper>
        {isMobile && (
          <Swiper
            onInit={(core: SwiperCore) => {
              swiper.current = core.el;
            }}
            onSlideChange={swiperCore => {
              const { realIndex } = swiperCore;
              setActiveMarker(realIndex);
            }}
            centeredSlides={true}
            loop={true}
            navigation={false}
            slidesPerView={1.1}
            spaceBetween={12}
            pagination={false}
          >
            {props.data?.map((it, idx) => {
              const currentDateItem = _.find(it.availability, {
                date: CURRENT_DATE,
              });
              return (
                <SwiperSlide key={idx}>
                  <Card
                    sx={{
                      padding: '1rem',
                      boxShadow: '0px 0px 1px 0px #00000040',
                      borderRadius: '8px',
                    }}
                    onMouseOverCapture={() => {
                      setActiveMarker(idx);
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <ImgBox>
                        <CardMedia
                          component="img"
                          sx={{
                            width: {
                              xs: '110',
                            },
                          }}
                          image={it.image}
                          alt={it.name}
                          onError={handleFallback}
                        />
                      </ImgBox>
                      <CardWrapper>
                        <CardContent
                          sx={{
                            flex: '1 1 100%',
                            padding: '5px 10px',
                          }}
                        >
                          <Typography
                            component="h5"
                            sx={{
                              fontSize: '12px',
                              color: colors.grey3,
                            }}
                          >
                            {it.prefecture}
                          </Typography>
                          <Typography
                            variant="h6"
                            mb={1}
                            component="h6"
                            sx={{ fontSize: '12px', fontWeight: 700 }}
                          >
                            {it.name}
                          </Typography>

                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                          >
                            <Box>
                              <Typography
                                component="span"
                                fontSize="12px"
                                color={colors.grey3}
                              >
                                1泊
                              </Typography>
                              {it.availability &&
                              _.get(currentDateItem, 'remain_room_count') &&
                              _.get(currentDateItem, 'lowest_price') ? (
                                <MoneyItem
                                  amount={_.toInteger(
                                    _.get(currentDateItem, 'lowest_price'),
                                  )}
                                  hasMin={activeMarker === idx && isMobile}
                                />
                              ) : (
                                ''
                              )}
                            </Box>
                            <Button
                              component={Link}
                              to={`reservation/${it.id}${qs ? '?' + qs : ''}`}
                              variant="contained"
                              size="large"
                              sx={{
                                fontSize: fontsizes.main,
                                fontWeight: 'normal',
                                padding: '8px 12px',
                                background: colors.blue,
                                position: 'absolute',
                                right: '15px',
                                bottom: '12px',
                                color: colors.white + '!important',
                              }}
                            >
                              予約する
                            </Button>
                          </Stack>
                        </CardContent>
                      </CardWrapper>
                    </Stack>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Wrapper>
    </MapContainer>
  );
};

export default GoogleMap;

const Marker = styled(Box)<IMarker>`
  background-color: ${p => p.color};
  position: absolute;
  top: -16px;
  backfacevisibility: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${p =>
    p.active ? '4px 15px' : p.color === colors.blue ? '2px 8px' : '2px 8px'};
  border-radius: 2px;
  white-space: nowrap;
  font-size: ${p => (p.active ? fontsizes.heading3 : fontsizes.main)};
  text-align: center;
  transition: all 0.15s;
  &:after {
    border-right: solid 4px transparent;
    border-left: solid 4px transparent;
    border-top: solid 8px ${p => p.color};
    transform: translateX(-50%);
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
  }
  .money-item {
    justify-content: center;
    color: ${colors.white};
    line-height: 1.2;
    font-size: inherit !important;
    span {
      font-size: inherit !important;
    }
  }

  ${p => (p.active ? 'z-index: 9' : '')};
  background: ${p => (p.active ? colors.white : p.color)};
  color: ${p => (p.active ? colors.grey1 : colors.white)}!important;
  transform: translate(-50%, -50%);
  &:after {
    border-top: solid 8px ${p => (p.active ? colors.white : p.color)};
  }
  .money-item {
    color: ${p => (p.active ? colors.grey1 : colors.white)}!important;
  }
`;

const MapContainer = styled(Box)`
  .gmnoprint {
    button.gm-control-active {
      background: url(${ZoomInIcon}) center no-repeat !important;
      img {
        display: none;
      }
      &:first-child {
        background: url(${ZoomOutIcon}) center no-repeat !important;
      }
    }
  }
  .gm-fullscreen-control {
    left: calc(50% + 30px) !important;
    top: 30px !important;
    display: none !important;
  }
  .gm-bundled-control-on-bottom {
    bottom: auto !important;
    top: 30px !important;
  }
  ${dMedia.tablet`
     .gm-fullscreen-control, .gm-bundled-control-on-bottom {
       top: 8px !important;
     }
  `}
`;

const Wrapper = styled(Box)`
  display: none;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  /** slider */
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    height: 118px;
    .MuiPaper-root {
      height: inherit;
    }
  }

  ${dMedia.tablet`
    display: block;
    overflow: hidden;
  `}
`;

const CardWrapper = styled(Box)`
  .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
  .money-item {
    color: ${colors.orange};
  }
`;
const ImgBox = styled(Box)`
  display: flex;
  width: 96px;
  height: 64px;
  margin-top: 10px;
`;
