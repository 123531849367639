import { Box, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import _ from 'lodash';

import { History } from '@mui/icons-material';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { BoxHeader } from 'app/components/BoxHeader';
import { CardBox } from 'app/components/CardBox';
import { useDispatch, useSelector } from 'react-redux';
import { useHotelHistorySlice } from './slice';
import { selectHistoryData, selectGetStatus } from './slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { IHotelState } from './slice/types';
import { RequestStatus } from 'constants/API';
import { Loader } from 'app/components/Loading';
import { checkIsRequesting } from 'utils/helper';

import { fontsizes } from 'constants/styles';
import styled from 'styled-components/macro';

export interface IHistoryPageProps {}

export default function HistoryPage(props: IHistoryPageProps) {
  const dispatch = useDispatch();
  const { getHotelHistory } = useHotelHistorySlice().actions;
  const { userSessionData } = useSelector(selectGlobal);

  const data: IHotelState[] = useSelector(selectHistoryData);
  const { account_id } = userSessionData.account;

  const getHistoryStatus: RequestStatus = useSelector(selectGetStatus);

  const isLoading = checkIsRequesting([getHistoryStatus]);

  useEffect(() => {
    dispatch(getHotelHistory(account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>過去泊まった宿 | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="過去泊まった宿  | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Loader open={isLoading} />
      <Container sx={{ margin: '0 auto' }}>
        <Box sx={{ maxWidth: '562px', margin: ' 5rem auto' }}>
          <BoxHeader
            fontSize={24}
            icon={
              <History
                sx={{
                  boxSizing: 'content-box',
                  fontSize: {
                    xs: fontsizes.heading2,
                    md: fontsizes.heading1,
                  },
                }}
              />
            }
          >
            泊まったことのある宿
          </BoxHeader>
          <Wrapper mt={2} sx={{ maxWidth: '400px', margin: '2rem auto' }}>
            {data?.map((it, idx) => (
              <CardBox key={idx} {...it} />
            ))}
            {_.isEmpty(data) && !isLoading ? (
              <Typography>
                条件に合致する泊まったことのある宿は見つかりませんでした
              </Typography>
            ) : (
              ''
            )}
          </Wrapper>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

const Wrapper = styled(Box)`
  > div {
    margin-bottom: 3rem;
  }
`;
