import APIs from 'APIs';
import { StatusCode } from 'constants/API';
import _ from 'lodash';
import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';

import Slice from '.';

function* getMetaDataFlow({ payload }) {
  let errorMess;
  try {
    const response = yield call(
      APIs.getPrice,
      payload.hotel_slug,
      _.omit(payload, ['hotel_slug']),
    );
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getMetaDataSuccess,
        payload: {
          range: moment(payload.checkin_date).format('YYYY-MM'),
          data: response.data,
        },
      });
    } else {
      yield put({ type: Slice.getMetaDataFail.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: Slice.getMetaDataFail.type, payload: { errorMess } });
  }
}

function* getStockDataFlow({ payload }) {
  let errorMess;
  try {
    const response = yield call(APIs.getHotelStockCalendar, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getStockDataSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getStockDataFail.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: Slice.getStockDataFail.type, payload: { errorMess } });
  }
}

function* getCalendarDataFlow({ payload }) {
  let errorMess;
  try {
    const response = yield call(
      APIs.getStockCalendar,
      _.omit(payload, ['hotel_slug']),
    );
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getCalendarDataSuccess,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.getCalendarDataFail.type,
        payload: { errorMess },
      });
    }
  } catch (error) {
    yield put({ type: Slice.getCalendarDataFail.type, payload: { errorMess } });
  }
}

function* getMetaDataWatcher() {
  yield takeLatest(Slice.getMetaDataRequest, getMetaDataFlow);
  yield takeLatest(Slice.getStockDataRequest, getStockDataFlow);
  yield takeLatest(Slice.getCalendarDataRequest, getCalendarDataFlow);
}

export default getMetaDataWatcher;
