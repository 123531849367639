// UI
import { Box, Stack } from '@mui/material';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { OrangeTag } from '../atoms/OrangeTag';
import SingleImage from '../SingleImage';
import { MoneyItem } from '../MoneyItem';
import { useHistory, useLocation } from 'react-router';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down-icon.svg';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from '@mui/material';
import { colors, fontsizes } from 'constants/styles';
import {
  AccountBox,
  Add,
  ArrowDropDown,
  CalendarToday,
  Remove,
} from '@mui/icons-material';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useDispatch } from 'react-redux';
import CollapsePanel, { ButtonPosition } from '../CollapsePanel';
import { Link } from 'react-router-dom';
import Path from 'config/clientPath';
import _ from 'lodash';

import { useWindowDimensions } from 'utils/useWindowDimensions';
import {
  getAdultCount,
  getChildrenCount,
  getCountGuest,
  getStringCheckinOutDate,
  getTotalPriceOfRoom,
  getUrlPlansPage,
  renderPaymentMethods,
  renderPeriodCancel,
} from 'utils/helper';
import { ICartItem } from 'types/common';
import BoxTotalMoney from './BoxTotalMoney';
import CancelPolicy from '../CancelPolicy';
import { useOrderInfoContext } from 'app/OrderInfoContext';

interface ICartItemProps {
  data: ICartItem;
  cart_item_key: number;
}

export enum IPaginatePosition {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
}

export function CartItem({
  data: {
    images,
    name,
    reservation: { arrival_date, checkout_date },
    reservation,
    plan_name,
    room_name,
    meal,
    totalPrice,
    id,
    guests,
    local_hotel_id,
    checkin_times,
    cancellation_fee_policies,
    max_guests,
    meals,
    payment_method,
    selected_payment_method,
    hotel_name,
    pricesDetail,
  },
  cart_item_key,
  ...props
}: ICartItemProps) {
  const { width: pageWidth } = useWindowDimensions(0);
  const isMediumMobile = pageWidth <= 475;
  const { cart_items } = useSelector(selectGlobal);
  const history = useHistory();
  const defaultFee = [''];
  const feeInfo =
    meals && !!meals.length ? [...defaultFee, ...meals] : defaultFee;

  const { changeShowCardPreviewStatus } = useGlobalSlice().actions;
  const dispatch = useDispatch();

  const hideOnPCProps = { display: { xs: 'flex', sm: 'none' } };
  const hideOnSPProps = { display: { xs: 'none', sm: 'flex' } };

  const renderPolicyItem = () => {
    return <CancelPolicy data={cancellation_fee_policies}></CancelPolicy>;
  };
  const { activeTab, setActiveTab, activeCartItem, allPlans, setAllPlans } =
    useOrderInfoContext();

  // const transformUrlPlanPage = () => {
  //   // cart_item_key;
  //   return getUrlPlansPage(local_hotel_id, {
  //     guest_count: +getCountGuest('male', guests),
  //     child: +getCountGuest('child', guests),
  //     cart_item_key: cart_item_key.toString(),
  //     checkin_date: arrival_date,
  //     checkout_date,
  //   });
  // };

  return (
    <Container spacing={{ xs: 5, sm: 2 }}>
      <Stack
        spacing={2}
        alignItems="center"
        sx={{ padding: '40px 40px 0 40px' }}
      >
        <WrapImage
          sx={{
            width: '100%',
            alignItems: {
              sm: 'center',
              xs: 'unset',
            },
          }}
        >
          <ContentReserve mb={2}>{`予約 ${cart_item_key + 1}`}</ContentReserve>
          <Stack
            sx={{
              flexDirection: {
                sm: 'row',
                xs: 'column',
              },
            }}
            alignSelf={'flex-start'}
            spacing={2}
            className="image-box"
          >
            <SingleImage
              backgroundImage={_.first(images)?.path || _.first(images)}
              width={120}
              height={120}
            />
            <Stack
              spacing={1}
              sx={{
                marginLeft: {
                  sm: '16px !important',
                  xs: 'unset',
                },
                marginTop: {
                  sm: 'unset !important',
                },
              }}
            >
              {/* <Box sx={{ ...hideOnSPProps }}>
              <OrangeTag title={'残り一室'} leftItemsCount={1} />
            </Box> */}
              <RoomName>{hotel_name}</RoomName>
              <Stack direction={'row'} spacing={'5px'}>
                <Icon>
                  <img src={'/img/calendar.png'} alt="img" />
                </Icon>
                <Desc>
                  {getStringCheckinOutDate(arrival_date, checkout_date)}
                </Desc>
              </Stack>
              <Stack direction={'row'} spacing={'5px'}>
                <Icon>
                  <img src={'/img/person.png'} alt="img" />
                </Icon>
                <Desc>{`大人${getAdultCount(guests)}名　子供${getChildrenCount(
                  guests,
                )}名`}</Desc>
              </Stack>
              {/* <RoomDesc sx={{ ...hideOnSPProps }}>{`（定員${
                max_guests ? +max_guests : 0
              }名）`}</RoomDesc> */}
            </Stack>
          </Stack>
        </WrapImage>
      </Stack>
      {/* for PC */}
      <Stack
        spacing={2}
        sx={{ padding: '0 40px 0 40px' }}
        // sx={{ ...hideOnSPProps, padding: '0 40px 0 40px' }}
      >
        <Divider></Divider>
        <AccommodationInfo>宿泊情報</AccommodationInfo>

        <NameItem spacing={'10px'} direction={'row'}>
          <TitlePlanName>部屋名</TitlePlanName>
          <PlanName>{room_name}</PlanName>
        </NameItem>
        <NameItem spacing={'10px'} direction={'row'}>
          <TitlePlanName>プラン名</TitlePlanName>
          <PlanName>{plan_name}</PlanName>
        </NameItem>
        <Divider></Divider>

        <AccommodationInfo>食事内容</AccommodationInfo>
        <IncludedFee>{meals ? meals.join('、') : ''}</IncludedFee>
        <Divider></Divider>

        <AccommodationInfo>決済情報</AccommodationInfo>
        <CreditCardPay>{`決済方法：${renderPaymentMethods(
          selected_payment_method ? selected_payment_method : payment_method,
        )}`}</CreditCardPay>
        <BoxTotalMoney
          totalPrice={totalPrice || 0}
          title={'価格'}
          pricesDetail={pricesDetail}
        ></BoxTotalMoney>
        <Divider></Divider>

        {renderPolicyItem()}

        <WrapButton
          sx={{
            height: '36px',
            backgroundColor: colors.blue,
            fontSize: '15px',
            lineHeight: '20px',
            fontWeight: '500',
            color: colors.white,
            marginTop: '32px !important',
            ...hideOnPCProps,
          }}
          variant="contained"
          onClick={() => {
            dispatch(changeShowCardPreviewStatus(false));
          }}
        >
          戻る
        </WrapButton>
      </Stack>

      {/* for SP */}
      {/* <Stack
        spacing={{ xs: 5 }}
        sx={{ ...hideOnPCProps, padding: '0 40px 0 40px' }}
      >
        <Stack spacing={{ xs: 4 }}>
          <TitleBigSP>宿泊情報</TitleBigSP>
          <Stack spacing={{ xs: 1 }}>
            <TitleSP>宿泊日</TitleSP>
            <ContentSP>2泊</ContentSP>
            <ContentSP>2021/08/17(火) ~ 2021/08/19(木)</ContentSP>
          </Stack>

          <Stack spacing={{ xs: 1 }}>
            <TitleSP>人数</TitleSP>
            <ContentSP>大人2名（男性、女性）子供2名（男性、女性）</ContentSP>
          </Stack>

          <Stack spacing={{ xs: 1 }}>
            <TitleSP>プラン名</TitleSP>
            <ContentSP>通常ご宿泊プラン</ContentSP>
          </Stack>

          <GrayBoxSP>
            <Stack spacing={{ xs: 1 }}>
              <TitleSP>食事内容</TitleSP>
              <ContentSP>朝食（朝食）</ContentSP>
              <ContentSP>夕食（夕食</ContentSP>
            </Stack>
          </GrayBoxSP>

          <Stack spacing={{ xs: 1 }}>
            <TitleSP>部屋名</TitleSP>
            <ContentSP>VMG グランド（定員4名）</ContentSP>
          </Stack>
          <WrapDivider></WrapDivider>
          <Stack spacing={{ xs: 4 }}>
            <TitleBigSP>ご宿泊料</TitleBigSP>
            <Stack spacing={{ xs: 1 }}>
              <TitleSP>決済方法</TitleSP>
              <ContentSP>カード決済</ContentSP>
            </Stack>
            <BoxTotalMoney
              totalPrice={totalPrice}
              title={'小計'}
            ></BoxTotalMoney>
            {renderPolicyItem()}
          </Stack>
        </Stack>
        
      </Stack> */}
    </Container>
  );
}

const WrapDivider = styled(Divider)`
  width: calc(100% + 80px);
  margin-left: -40px !important;
  ${dMedia.sm`
    // width: calc(100vw);
    // margin-left: -40px !important;
  `}
`;
const WrapButton = styled(Button)`
  /* width: calc(100% + 32px);
  margin-left: -16px !important; */
  /* ${dMedia.sm`
    width: calc(100vw - 32px);
    margin-left: -24px !important;
  `} */
`;

const Container = styled(Stack)``;

const WrapCancelFee = styled(Box)`
  width: 121px;
  flex-shrink: 0;
  margin-left: 10px;
`;

const WrapPolicy = styled(Box)`
  width: 207px;
  justify-content: space-between;
  margin: 0 auto;
`;

const WrapImage = styled(Stack)`
  /* padding: 0 8px 8px 8px;
  max-width: 256px;
  margin: 0 0 0 0 0; */
  .image-box {
    ${dMedia.tablet`
    width: 240px !important;
    margin: 0 auto
  `}
  }
  .single-img {
    ${dMedia.tablet`
        width: 240px !important;
        height: 180px !important
      `}
  }
`;
const ContentReserve = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #333333;
  }
`;
const RoomName = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
`;
const RoomDesc = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #4f4f4f;
  }
`;

const IncludedFee = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;
const CreditCardPay = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;
const IncludedMeal = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 14px;
    color: ${colors.heading};
  }
`;
const TitleBigSP = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    color: ${colors.grey1};
  }
`;
const TitleSP = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
`;
const ContentSP = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;

const AccommodationInfo = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.heading};
  }
`;
const PersonInfo = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
    max-width: 198px;
  }
`;
const Change = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: ${colors.blue};
    padding: 0 4px;
    a {
      text-decoration: none;
    }
  }
`;
const CheckInOutTime = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;

const CancelTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.grey3};
  }
`;
const CancelInfo = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;
// const CancelPolicy = styled(Typography)`
//   &.MuiTypography-root {
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
//     color: ${colors.default};
//     margin-bottom: 8px;
//     text-align: center;
//   }
// `;
const GrayBoxSP = styled(Box)`
  background: ${colors.grey6};
  padding: 16px;
  border-radius: 8px;
`;
const GrayBoxBig = styled(Box)`
  background: ${colors.grey6};
  padding: 16px 56.5px;
  border-radius: 8px;
  ${dMedia.tablet`
    padding: 16px 16.5px;
  `}
`;
const CalendarArea = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GrayBoxSmall = styled(Box)`
  background: ${colors.grey6};
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
  &.calendar_box {
    padding: 20px 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const CollapsedButton = styled(Button)`
  outline: none !important;
  background: white !important;
  box-shadow: none !important;
  border: 1px solid ${colors.grey4};
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .read_more_text {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: ${colors.grey1};
  }
  .MuiTouchRipple-root {
    display: none;
  }
`;

const CheckInTime = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    padding: 8px 0 0 35px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;

const Desc = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #333333;
    padding-top: 4px;
  }
`;

const Icon = styled(Box)``;

const NameItem = styled(Stack)``;

const TitlePlanName = styled(Typography)`
  && {
    flex-shrink: 0;
    width: 60px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }
`;

const PlanName = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }
`;
