import * as React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';

import { useDispatch, useSelector } from 'react-redux';
import FieldValue from './FieldValue';
import SubTitle from './SubTitle';
import Notification from './Notification';
import ProfileWrapper from './ProfileWrapper';
import {
  Typography,
  Checkbox,
  Box,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  FormControl,
  // Select,
  // MenuItem,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { GENDER_PROFILE } from 'constants/common';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { Loader } from 'app/components/Loading';
import { CommonLink } from 'app/components/Link';
import {
  checkIsRequesting,
  getEmploymentNameBool,
  getEmploymentNameText,
} from 'utils/helper';
// import { getAddressByZip } from 'japan-address-autofill';

import { fontsizes, colors } from 'constants/styles';
import { RequestStatus } from 'constants/API';
import Path from 'config/clientPath';

interface IProfileForm {
  email: string;
  old_password: string;
  name: string;
  kana: string;
  gender: number;
  postal_code: string;
  telephone: string;
  // city: string;
  address: string;
  employment_name: number | string;
  // prefecture_code: string;
}

export default function ProfilePage() {
  const [mode, setMode] = React.useState('view');
  const { userSessionData, getAccountInfoStatus, editProfileStatus } =
    useSelector(selectGlobal);
  const { editProfile, getAccountInfoRequest } = useGlobalSlice().actions;
  const dispatch = useDispatch();

  const {
    kana,
    gender,
    telephone,
    // prefecture_code,
    postal_code,
    // city,
    address,
    old_password,
    email,
    account_id,
    name,
    employment_name,
  } = userSessionData.account;

  React.useEffect(() => {
    dispatch(getAccountInfoRequest(account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      kana,
      name,
      gender,
      old_password,
      postal_code,
      telephone,
      address,
      email,
      employment_name: getEmploymentNameBool(employment_name),
      account_id,
    },
  });

  const renderGenderText = () => {
    if (gender === 'male') {
      return '男性';
    }
    if (gender === 'female') {
      return '女性';
    }
    if (gender === '') {
      return '無回答';
    }
    return '';
  };

  React.useEffect(() => {
    if (editProfileStatus === RequestStatus.SUCCESS) {
      setMode('view');
    }
  }, [editProfileStatus]);

  const handleEditProfileSubmit = (data: any) => {
    // data.employment_name = '';
    data.employment_name = getEmploymentNameText(data.employment_name);
    // data.prefecture_code = '01';
    dispatch(editProfile(data));
  };
  const isLoading = checkIsRequesting([
    getAccountInfoStatus,
    editProfileStatus,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {mode === 'view' ? '会員情報' : '会員情報編集'} | VMG HOTELS & UNIQUE
          VENUES
        </title>
        <meta
          name="description"
          content={mode === 'view' ? '会員情報' : '会員情報編集'}
        />
      </Helmet>
      <Header />
      <ProfileWrapper>
        <Loader open={isLoading} />
        {mode === 'view' && (
          <>
            <TitleWrapper>
              <Typography
                sx={{
                  fontWeight: '700',
                  fontSize: fontsizes.heading3,
                  lineHeight: '24px',
                  letterSpacing: '1px',
                  marginRight: '24px',
                }}
              >
                お客様情報
              </Typography>
              <RedirectBtn
                onClick={() => {
                  setMode('edit');
                  reset({
                    kana,
                    name,
                    gender,
                    old_password,
                    postal_code,
                    telephone,
                    address,
                    email,
                    employment_name: getEmploymentNameBool(employment_name),
                    account_id,
                  });
                }}
              >
                編集する
              </RedirectBtn>
            </TitleWrapper>
            <FieldData>
              <SubTitle text="氏名（カナ）" />
              <FieldValue text={kana} />
            </FieldData>
            <FieldData>
              <SubTitle text="性別" />
              <FieldValue text={renderGenderText()} />
            </FieldData>
            <FieldData>
              <SubTitle text="当日ご連絡のつく電話番号" />
              <FieldValue text={telephone} />
            </FieldData>
            <FieldData>
              <SubTitle text="郵便番号" />
              <FieldValue text={postal_code} />
            </FieldData>
            <FieldData>
              <SubTitle text="住所（番地・建物名）" />
              <FieldValue text={address} />
            </FieldData>
            <CheckboxGroup>
              <Checkbox
                checked={getEmploymentNameBool(employment_name)}
                disabled
              />
              <SubTitle
                marginBottom={'0px'}
                text="メールマガジンの配信を希望する"
              />
            </CheckboxGroup>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: fontsizes.main,
                lineHeight: '20px',
                marginTop: 0,
                color: '#757575',
                marginBottom: {
                  xs: '32px',
                  md: '24px',
                },
              }}
            >
              ※アカウントにご登録の方へ、VMG会員ご優待プランや、メールマガジンにてお得な情報などをご案内しております。
            </Typography>
          </>
        )}
        {mode === 'edit' && (
          <Box
            sx={{
              maxWidth: '375px',
              margin: '0 auto',
            }}
            id="edit_profile_form"
            component="form"
            onSubmit={handleSubmit(handleEditProfileSubmit)}
          >
            <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <SubTitle text="メールアドレス" marginBottom="8px" />
              <TextField
                {...register<keyof IProfileForm>('email')}
                fullWidth
                type="email"
                variant="outlined"
                size="small"
                placeholder="例）abcd@vmg.co.jp"
                error={errors.email ? true : false}
                helperText={errors.email?.message}
                disabled
              />
            </Box>
            <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <CommonLink to={Path.RESET_PASSWORD}>
                パスワードを忘れた方
              </CommonLink>
            </Box>
            <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <SubTitle text="氏名（漢字）" marginBottom="8px" />
              <TextField
                {...register<keyof IProfileForm>('name')}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="例）田中 太郎"
              />
              <Notification text="※氏名の間にスペースをいれてください" />
            </Box>
            <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <SubTitle text="氏名（カナ）" marginBottom="8px" />
              <TextField
                {...register<keyof IProfileForm>('kana')}
                fullWidth
                variant="outlined"
                inputProps={{ pattern: '^[ァ-ヿ\\s　0-9０-９]+$' }}
                size="small"
                placeholder="例）タナカ タロウ"
              />
              <Notification text="※氏名の間にスペースをいれてください" />
            </Box>
            <RadioBox>
              <SubTitle text="性別" marginBottom="8px" />
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <RadioGroup
                        row
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                      >
                        {GENDER_PROFILE.map(v => (
                          <FormControlLabel
                            key={v.id}
                            value={v.value}
                            control={<Radio />}
                            label={v.label}
                          />
                        ))}
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </RadioBox>
            <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <SubTitle text="当日ご連絡のつく電話番号" marginBottom="8px" />
              <TextField
                {...register<keyof IProfileForm>('telephone')}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="例）0312345678"
                type="tel"
              />
              <Notification text="※ハイフンなしで入力ください" />
            </Box>
            <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <SubTitle text="郵便番号" marginBottom="8px" />
              <TextField
                {...register<keyof IProfileForm>('postal_code')}
                fullWidth
                sx={{
                  width: '240px',
                }}
                variant="outlined"
                size="small"
                placeholder="例）12345678"
              />
              <Notification text="※ハイフンは抜いて入力してください" />
            </Box>
            {/* <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <SubTitle text="住所（都道府県・市区町村）" marginBottom="8px" />
              <TextField
                {...register<keyof IProfileForm>('city')}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="半角数字、ハイフンなしで入力してください"
              />
              <Notification text="※ハイフンは抜いて入力してください" />
            </Box>
            <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <SubTitle text="住所（都道府県・市区町村）" marginBottom="8px" />
              <TextField
                {...register<keyof IProfileForm>('prefecture_code')}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="郵便番号を入力すると自動で選択されます"
                error={errors.prefecture_code ? true : false}
                helperText={errors.prefecture_code?.message}
                onChange={updatePrefecture}
              />
            </Box> */}
            <Box
              sx={{
                marginBottom: '24px',
              }}
            >
              <SubTitle text="住所（番地・建物名）" marginBottom="8px" />
              <TextField
                {...register<keyof IProfileForm>('address')}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="番地、建物名は全角で入力してください"
              />
            </Box>
            <Box
              sx={{
                marginBottom: '40px',
              }}
            >
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="employment_name"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <CheckboxGroup>
                        <Checkbox
                          checked={!!value}
                          onBlur={onBlur}
                          onChange={e => {
                            onChange(e);
                          }}
                        />
                        <SubTitle
                          marginBottom={'0px'}
                          text="メールマガジンの配信を希望する"
                        />
                      </CheckboxGroup>
                    );
                  }}
                />
              </FormControl>
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: fontsizes.main,
                  lineHeight: '20px',
                  marginTop: 0,
                  color: '#757575',
                  marginBottom: {
                    xs: '32px',
                    md: '24px',
                  },
                }}
              >
                ※アカウントにご登録の方へ、VMG会員ご優待プランや、メールマガジンにてお得な情報などをご案内しております。
              </Typography>
            </Box>
            <SummaryBtn
              variant="contained"
              size="large"
              form="edit_profile_form"
              type="submit"
              sx={{
                background: colors.blue,
                marginBottom: '16px',
                fontSize: '15px',
                lineHeight: '20px',
              }}
            >
              登録する
            </SummaryBtn>
            <SummaryBtn
              variant="contained"
              size="large"
              sx={{
                background: colors.white,
                color: '#333',
                fontSize: '15px',
                lineHeight: '20px',
                '&:hover': {
                  backgroundColor: 'unset',
                  boxShadow: 'unset',
                  cursor: 'pointer',
                },
                boxShadow: 'unset',
              }}
              onClick={() => setMode('view')}
            >
              キャンセル
            </SummaryBtn>
          </Box>
        )}
      </ProfileWrapper>
      <Footer />
    </>
  );
}

const CheckboxGroup = styled(Box)`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .css-ncoufy-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled {
    color: ${colors.grey1};
  }
  .MuiCheckbox-root {
    padding: 0;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .css-ncoufy-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-ncoufy-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: ${colors.heading};
  }
`;

const TitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @media screen and (max-width: 900px) {
    margin-bottom: 32px;
  }
`;

const RedirectBtn = styled.a`
  padding: 4px 8px;
  background-color: ${colors.grey6};
  border-radius: 16px;
  color: #2f80ed;
  font-size: ${fontsizes.main};
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const FieldData = styled(Box)`
  margin: 32px 0;
`;

const SummaryBtn = styled(Button)`
  padding: 10px 0;
  height: 40px;
  width: 100%;
  box-shadow: none;
  cursor: pointer;
`;

const RadioBox = styled.div`
  margin-bottom: 24px;
  .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: ${fontsizes.main};
    line-height: 14px;
    color: ${colors.heading};
  }
  .css-wsgg39-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: ${colors.heading};
  }
`;
