import { createSelector } from '@reduxjs/toolkit';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.login || initialState;

export const selectLogin = createSelector(
  [selectSlice, selectGlobal],
  (loginState, globalState) => ({
    ...loginState,
    ...globalState,
  }),
);
