import { Box } from '@mui/material';
import { CardMedia } from '@mui/material';
import { Typography } from '@mui/material';

import PriceBox from '../PriceBox';
import { AddToCartButton } from 'app/components/Buttons/AddToCartButton';

import { colors } from 'constants/styles';
import { ReserveButton } from 'app/components/Buttons/ReserveButton';

interface Props {
  data: {
    packages: {
      name: string;
      amount: number;
    }[];
    url: string;
    title: string;
  };
}

export default function SpecialOfferItem({
  data: { packages, url, title },
  data,
}: Props) {
  return (
    <Box
      sx={{
        border: `1px solid ${colors.grey6}`,
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: '#FCFCFC',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '120px 1fr',
          },
          gap: {
            xs: '16px',
            md: '24px',
          },
          marginBottom: '16px',
        }}
      >
        <CardMedia
          image={url}
          sx={{
            borderRadius: '4px',
            objectFit: 'cover',
            width: '120px',
            height: '80px',
          }}
        />
        <Typography
          component="a"
          sx={{
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '20px',
            color: colors.blue,
            textDecoration: 'underline',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            md: 'unset',
          },
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'max-content max-content',
            gap: '8px',
            height: 'max-content',
            marginBottom: {
              xs: '16px',
              md: '0',
            },
          }}
        >
          {packages.map((item, index) => (
            <PriceBox key={index} name={item.name} amount={item.amount} />
          ))}
        </Box>
        <Box
          sx={{
            gridTemplateRows: 'max-content max-content',
            gap: '8px',
            display: 'grid',
          }}
        >
          <ReserveButton item={{ ...data, addition_info: {} }} isBuyNow />
          <AddToCartButton item={{ ...data, addition_info: {} }} />
        </Box>
      </Box>
    </Box>
  );
}
