/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import Path from 'config/clientPath';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle } from 'styles/global-styles';
import { getToken, isHavingToken } from 'utils/localStorage';
import { useRole } from 'utils/role-hook';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import { GlobalContainer } from './pages/GlobalContainer';
import { HomePage } from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import HistoryPage from './pages/HistoryPage';
import HotelDetailsPage from './pages/HotelDetailsPage';
import { PlanAndRoomPage } from './pages/PlanAndRoomPage';
import CartPage from './pages/CartPage';
import SpecialOfferPage from './pages/SpecialOfferPage';
import MapPage from './pages/MapPage';
import ProfilePage from './pages/ProfilePage';
import PaymentPage from './pages/PaymentPage';
import OrderSuccessPage from './pages/OrderSuccessPage';
import { OrderHistory } from './pages/OrderHistory';
import SystemErrorPage from './pages/SystemErrorPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SpecifiedCommercialPage from './pages/SpecifiedCommercialPage';
import MaintenancePage from './pages/MaintenancePage';
import LoginModal from './components/Modal/LoginModal';
import { OrderInfoPage } from './pages/OrderInfoPage';
import { RegistrationInfoPage } from './pages/RegistrationInfoPage';
import { RegisterModal } from './components/RegisterModal';
import ConfirmOrderModal from './components/ConfirmOrderModal';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ScrollToTop from './pages/ScrollToTop';
import TagManager from 'react-gtm-module';
import { GTM_DEFAULT, GTM_SEARCH_DEFAULT } from 'constants/GTM';
import LoginAfterRegisterModal from './components/Modal/LoginAfterRegisterModal';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useDispatch, useSelector } from 'react-redux';

// css for slider swiper
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper.scss';
// import { CancelOrder } from './pages/CancelOrder';
import { AdditionInfoModal } from './components/Modal/AdditionInfoModal';
import { selectGlobal } from './pages/GlobalContainer/slice/selectors';
import { changeErrors } from './pages/GlobalContainer/slice/actions';
import { useCheckoutSlice } from './pages/CheckoutPage/slice';
import useGlobalFunc from './pages/GlobalContainer/useGlobalFunc';
import { Loader } from './components/Loading';
import { checkIsRequesting, getGtmParams } from 'utils/helper';
import { RegisterMembershipPage } from './pages/RegisterMembershipPage';
import { OrderStatusModal } from './components/Modal/OrderStatusModal';
import { CancelOrderPage } from './pages/CancelOrderPage';
import { Alert, Button, Snackbar } from '@mui/material';
import OrderInfoContextProvider from './OrderInfoContext';
import ConfirmPage from './pages/ConfirmPage';
import { useHistory, useLocation } from 'react-router';
import { GoogleTag } from './pages/GoogleTag';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const ROUTE_LIST = [
  {
    component: (
      <Route key={Path.HOME} exact path={Path.HOME} component={HomePage} />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Login page */
    component: (
      <Route key={Path.LOGIN} exact path={Path.LOGIN} component={LoginPage} />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Search page */
    component: (
      <Route
        key={Path.SEARCH}
        exact
        path={Path.SEARCH}
        component={SearchPage}
      />
    ),
    role: '',
    useRegex: true,
  },

  {
    /* History page */
    component: (
      <ProtectedRoute
        key={Path.HOTEL_HISTORY}
        exact
        path={Path.HOTEL_HISTORY}
        component={HistoryPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Rooms List page */
    component: (
      <Route
        key={Path.ROOMS_LIST}
        exact
        path={Path.ROOMS_LIST}
        component={PlanAndRoomPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Plans List page */
    component: (
      <Route
        key={Path.PLANS_LIST}
        exact
        path={Path.PLANS_LIST}
        component={PlanAndRoomPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Plans List page */
    component: (
      <Route
        key={Path.PLANS_MODAL}
        exact
        path={Path.PLANS_MODAL}
        component={PlanAndRoomPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Plans List page */
    component: (
      <Route
        key={Path.ROOM_MODAL}
        exact
        path={Path.ROOM_MODAL}
        component={PlanAndRoomPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    // Special Offer page
    component: (
      <ProtectedRoute
        key={Path.SPECIAL_OFFER}
        exact
        path={Path.SPECIAL_OFFER}
        component={SpecialOfferPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    // Cart Page
    component: (
      <Route key={Path.CART} exact path={Path.CART} component={CartPage} />
    ),
    /* Component page */
    role: '',
    useRegex: true,
  },
  {
    component: (
      <ProtectedRoute
        key={Path.PROFILE}
        exact
        path={Path.PROFILE}
        component={ProfilePage}
      />
    ),
  },
  {
    /* Map page */
    component: (
      <Route key={Path.MAP} exact path={Path.MAP} component={MapPage} />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Payment page */
    component: (
      <Route
        key={Path.PAYMENT}
        exact
        path={Path.PAYMENT}
        component={PaymentPage}
      />
    ),
    role: '',
    useRegex: true,
  },

  {
    /* Confirm page */
    component: (
      <Route
        key={Path.CONFIRM}
        exact
        path={Path.CONFIRM}
        component={ConfirmOrderModal}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Order Success page */
    component: (
      <Route
        key={Path.SUCCESS}
        exact
        path={Path.SUCCESS}
        component={OrderSuccessPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Order Success page */
    component: (
      <Route
        key={Path.SYSTEM_ERROR}
        exact
        path={Path.SYSTEM_ERROR}
        component={SystemErrorPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Order Success page */
    component: (
      <Route
        key={Path.PRIVACY_POLICY}
        exact
        path={Path.PRIVACY_POLICY}
        component={PrivacyPolicyPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Order Success page */
    component: (
      <Route
        key={Path.SPECIFIED_COMMERCIAL}
        exact
        path={Path.SPECIFIED_COMMERCIAL}
        component={SpecifiedCommercialPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Order Success page */
    component: (
      <Route
        key={Path.MAINTENANCE}
        exact
        path={Path.MAINTENANCE}
        component={MaintenancePage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Order History page */
    component: (
      <ProtectedRoute
        key={Path.ORDER_HISTORY}
        exact
        path={Path.ORDER_HISTORY}
        component={OrderHistory}
      />
    ),
    role: '',
    useRegex: true,
  },

  {
    /* Order Info page */
    component: (
      <Route
        key={Path.ORDER_INFO}
        exact
        path={Path.ORDER_INFO}
        component={OrderInfoPage}
      />
    ),
    role: '',
    useRegex: true,
  },

  {
    /* Account Info Registration page */
    component: (
      <Route
        key={Path.REGISTRATION_INFO}
        exact
        path={Path.REGISTRATION_INFO}
        component={RegistrationInfoPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Account Info reset password page */
    component: (
      <Route
        key={Path.RESET_PASSWORD}
        exact
        path={Path.RESET_PASSWORD}
        component={ForgotPasswordPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Account Info change password page */
    component: (
      <Route
        key={Path.CHANGE_PASSWORD}
        exact
        path={Path.CHANGE_PASSWORD}
        component={ChangePasswordPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    component: (
      <Route
        key={Path.NO_NEED_LOGIN_CANCEL_ORDER_DETAIL}
        exact
        path={Path.NO_NEED_LOGIN_CANCEL_ORDER_DETAIL}
        component={CancelOrderPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    component: (
      <ProtectedRoute
        key={Path.CANCEL_ORDER_DETAIL}
        exact
        path={Path.CANCEL_ORDER_DETAIL}
        component={CancelOrderPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Hotel details page */
    component: (
      <Route
        key={Path.HOTEL_DETAILS}
        exact
        path={Path.HOTEL_DETAILS}
        component={HotelDetailsPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    component: (
      <Route
        key={Path.REGISTER_MEMBERSHIP}
        exact
        path={Path.REGISTER_MEMBERSHIP}
        component={RegisterMembershipPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Put at end of Router list */
    component: <Route key={'NotFoundPage'} component={NotFoundPage} />,
    role: '',
    useRegex: true,
  },
];

export function App() {
  const { i18n } = useTranslation();
  const { hasRole } = useRole();
  const { errors, addItemToCartStatus } = useSelector(selectGlobal);
  const { changeShowLoginModalStatus, changeIsRedirectAfterLogin, logout } =
    useGlobalSlice().actions;
  const dispatch = useDispatch();
  const { handleLogout, getHotelStockStatus } = useGlobalFunc();
  // const location = useLocation();

  const { cancelOrderRequest, resetCheckoutStatus } =
    useCheckoutSlice().actions;

  React.useEffect(() => {
    if (errors) {
      // reset status error and open login modal
      dispatch(changeErrors(null));
      // handleLogout();
      dispatch(changeIsRedirectAfterLogin(false));
      dispatch(changeShowLoginModalStatus(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  React.useEffect(() => {
    if (!getToken()) {
      // dispatch(resetCheckoutStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken()]);

  React.useEffect(() => {
    // const gtmTimeout = setTimeout(() => {
    TagManager.initialize(getGtmParams(GTM_DEFAULT));
    TagManager.initialize(getGtmParams(GTM_SEARCH_DEFAULT));
    // }, 1000);

    return () => {
      // clearTimeout(gtmTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = checkIsRequesting([addItemToCartStatus]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s"
        defaultTitle="VMG Hotel"
        htmlAttributes={{ lang: i18n.language }}
      >
        <script
          type="text/javascript"
          src={`${process.env.REACT_APP_SBS_TOKEN_GENERATE_URL}`}
        ></script>
        <meta name="description" content="VMG Hotel" />
      </Helmet>
      <OrderInfoContextProvider>
        <GlobalContainer>
          <AdditionInfoModal></AdditionInfoModal>
          <LoginAfterRegisterModal />
          <LoginModal />
          <OrderStatusModal />
          <RegisterModal />
          <GoogleTag />
          {/* <ConfirmOrderModal /> */}
          <ScrollToTop />
          <Loader open={isLoading} />
          <Switch>
            {ROUTE_LIST.map(it => {
              if (it.role && isHavingToken()) {
                if (hasRole(it.role, it.useRegex)) {
                  return it.component;
                }
                return null;
              }
              return it.component;
            })}
          </Switch>
        </GlobalContainer>
      </OrderInfoContextProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
}
