import styled from 'styled-components/macro';

import { Typography, Box, Stack } from '@mui/material';

import { fontsizes, colors } from 'constants/styles';
import SubTitle from '../SubTitle';

import { MoneyItem } from 'app/components/MoneyItem';
import ConfirmModal from 'app/components/ConfirmModal';
import { useState } from 'react';
import _ from 'lodash';
import SingleImage from 'app/components/SingleImage';
import moment from 'moment';
import { ICartItem } from 'types/common';
import { getStringCheckinOutDate } from 'utils/helper';
interface Props {
  data: ICartItem;
  cart_key: number;
  onClick: (id: number) => void;
}

export default function CartItem({
  data: {
    images,
    name,
    reservation: { arrival_date, checkout_date },
    reservation,
    plan_name,
    room_name,
    meals,
    totalPrice,
    id,
  },
  cart_key,
  onClick,
}: Props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const getDayMonthYear = _date => {
    const date = _date ? moment(_date, 'YYYY-MM-DD') : moment();
    return date.format('YYYY/MM/DD(ddd)');
  };

  return (
    <BoxCart>
      <ConfirmModal
        title={'カートから削除'}
        content={'この予約をカートから削除してもよろしいですか？'}
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onAgree={() => {
          setShowConfirmModal(false);
          onClick(cart_key);
        }}
      />
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Label>{`予約 ${cart_key + 1}`}</Label>
        <DeleteBtn
          onClick={() => {
            setShowConfirmModal(true);
          }}
        >
          削除する
        </DeleteBtn>
      </Stack>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
          },
          gap: '16px',
          paddingBottom: '16px',
          borderBottom: `1px solid ${colors.grey}`,
        }}
      >
        <MainInfo>
          {images && _.first(images) && (
            <Img src={_.first(images)?.path}></Img>
            // <SingleImage
            //   width={240}
            //   height={180}
            //   backgroundImage={_.first(images)?.path}
            // ></SingleImage>
          )}

          <SubTitle text="部屋名" />
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: fontsizes.main,
              lineHeight: '20px',
              color: colors.grey1,
            }}
          >
            {room_name}
          </Typography>
        </MainInfo>
        <SubInfo>
          <SubTitle text="宿泊日" />
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: fontsizes.main,
              lineHeight: '20px',
              color: colors.grey1,
              marginBottom: '16px',
            }}
          >
            {reservation?.number_of_stay}泊 <br />
            {getStringCheckinOutDate(arrival_date, checkout_date)}
          </Typography>
          <SubTitle text="プラン名" />
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: fontsizes.main,
              lineHeight: '20px',
              color: colors.grey1,
            }}
          >
            {plan_name}
          </Typography>
          <MealContent>
            <SubTitle text="食事内容" />
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: fontsizes.main,
                lineHeight: '20px',
                color: colors.grey1,
              }}
            >
              {meals ? meals.join('、') : '-'}
            </Typography>
          </MealContent>
        </SubInfo>
      </Box>
      <CurrencyBox>
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: fontsizes.main,
            lineHeight: '20px',
            color: colors.default,
          }}
        >
          合計
        </Typography>
        <MoneyItem amount={totalPrice} isBold={true} fontSize={24} />
      </CurrencyBox>
    </BoxCart>
  );
}

const Label = styled(Typography)`
  && {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;

const BoxCart = styled.div`
  padding: 24px 16px;
  border: 1px solid ${colors.grey};
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
`;

const DeleteBtn = styled.div`
  padding: 8px 12px;
  background-color: ${colors.grey6};
  border-radius: 8px;
  color: ${colors.blue};
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  width: max-content;
  cursor: pointer;
  /* margin-left: auto; */
  margin-bottom: 16px;
`;

const MainInfo = styled.div``;

const SubInfo = styled.div``;

const Img = styled.img`
  margin-bottom: 16px;
  width: 100%;
  height: 180px;
  object-fit: cover;
`;

const MealContent = styled.div`
  margin-top: 16px;
  padding: 16px;
  background-color: ${colors.grey6};
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;

const CurrencyBox = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .money-item span {
    font-weight: 700;
  }
`;
