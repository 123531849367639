import styled from 'styled-components/macro';

import { fontsizes, colors } from 'constants/styles';
import { Button, Link, Stack } from '@mui/material';
import { Typography, Box } from '@mui/material';
import { useHistory } from 'react-router';
import Path from 'config/clientPath';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';

import { MoneyItem } from 'app/components/MoneyItem';
import {
  getCheckStockParams,
  getUrlRoomsPage,
  lodash,
  validatePaymentMethod,
} from 'utils/helper';
import _ from 'lodash';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import React from 'react';
import { RequestStatus } from 'constants/API';

interface Props {
  total: number;
}

export default function CartSummary({ total = 0, ...props }: Props) {
  const history = useHistory();
  const {
    alertConflictPaymentMethod,
    checkHotelStock,
    hotelInStockStatus,
    displayErrorMessages,
    outOfStockMess,
    checkInventory,
    checkInventoryStatus,
    errorCode,
    changeShowOrderStatusModal,
    // getHotelStockStatus,
  } = useGlobalFunc();

  const { cart_items, getHotelStockStatus } = useSelector(selectGlobal);

  const {
    addItemToCart,
    setSuccessMessages,
    changeShowLoginModalStatus,
    setErrorMessages,
  } = useGlobalSlice().actions;
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(selectGlobal);
  const [stockStatuses, setStockStatuses] = React.useState<any>([]);

  React.useEffect(() => {
    setStockStatuses(Object.values(getHotelStockStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHotelStockStatus]);

  // React.useEffect(() => {
  //   if (checkInventoryStatus === RequestStatus.ERROR) {
  //     changeShowOrderStatusModal(true);
  //     // displayErrorMessages([outOfStockMess]);
  //     return;
  //   }
  //   if (checkInventoryStatus === RequestStatus.SUCCESS) {
  //     // handle on all hotels is in stock
  //     // if (isLoggedIn) {
  //     //   window.location.href = Path.ORDER_INFO;
  //     // } else {
  //     //   dispatch(changeShowLoginModalStatus(true));
  //     // }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [checkInventoryStatus]);

  React.useEffect(() => {
    const isCheckStockDone =
      !lodash.isEmpty(stockStatuses) &&
      lodash.every(stockStatuses, v => {
        return v === RequestStatus.SUCCESS || v === RequestStatus.ERROR;
      });

    // const abc = lodash.every(stockStatuses, RequestStatus.SUCCESS);

    if (!isCheckStockDone) {
      return;
    }
    // const checkInventoryParams = _.map(cart_items, v => {
    //   let checkStockParams = getCheckStockParams(v);
    //   return { ...checkStockParams };
    // });
    // // handle check inventory of all items in cart after check stock of each item
    // checkInventory(checkInventoryParams);
    // // handle after call api check stock for all hotels are done
    // const allHotelsInStock = lodash.every(
    //   Object.values(hotelInStockStatus),
    //   v => {
    //     return v;
    //   },
    // );

    // if (!allHotelsInStock) {
    //   displayErrorMessages([outOfStockMess]);
    //   return;
    // }
    // // handle on all hotels is in stock
    // if (isLoggedIn) {
    //   window.location.href = Path.ORDER_INFO;
    // } else {
    //   dispatch(changeShowLoginModalStatus(true));
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockStatuses]);

  return (
    <Box {...props}>
      <TotalBox spacing={1}>
        <WrapBox>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: fontsizes.main,
              lineHeight: '24px',
              color: colors.grey1,
              marginRight: '5px',
            }}
          >
            総合計金額 :
          </Typography>
          <MoneyItem amount={total} isBold fontSize={24} />
        </WrapBox>
        <Line></Line>
        <WrapItems>
          {cart_items.map((v, k) => {
            return (
              <Items key={k}>
                <FirstItem>{`予約 ${k + 1}:`}</FirstItem>
                <LastItem>
                  <MoneyItem amount={v?.totalPrice || 0} isBold fontSize={18} />
                </LastItem>
              </Items>
            );
          })}
        </WrapItems>
      </TotalBox>
      <SummaryBtn
        variant="contained"
        size="large"
        sx={{
          background: colors.blue,
        }}
        onClick={() => {
          const validateResult = validatePaymentMethod(cart_items);
          const { isError } = validateResult;

          // do not allow redirect to checkout page if has conflict about payment method
          if (isError) {
            alertConflictPaymentMethod();
            return;
          }
          // // checkHotelStock();
          // // new spec 03/12/2022: call 1 api check inventory instead of multiple api
          // const checkInventoryParams = _.map(cart_items, v => {
          //   let checkStockParams = getCheckStockParams(v);
          //   return { ...checkStockParams };
          // });
          // // handle check inventory of all items in cart after check stock of each item
          // checkInventory(checkInventoryParams);
          // // validatePaymentMethod(cart_items);

          if (isLoggedIn) {
            window.location.href = Path.ORDER_INFO;
          } else {
            dispatch(changeShowLoginModalStatus(true));
          }
        }}
      >
        お申し込み手続きへ
      </SummaryBtn>

      <SummaryBtn
        variant="outlined"
        sx={{
          background: colors.white,
          color: colors.blue,
          border: `1px solid ${colors.blue}`,
          boxShadow: 'unset',
          margin: {
            xs: '16px 0 0',
          },
          '&:hover': {
            backgroundColor: 'unset',
          },
        }}
        onClick={() => {
          const hotel_id = _.isEmpty(cart_items)
            ? 0
            : _.last(cart_items)?.local_hotel_id;
          window.location.href = hotel_id
            ? getUrlRoomsPage(hotel_id)
            : Path.SEARCH;
        }}
      >
        別の予約を続ける
      </SummaryBtn>
      <Box mt={1}>
        <NoteText>
          カートへの追加はご予約希望枠の確保にはなりません。カートに追加されていたとしても、ご予約完了までに他のお客様が先にご予約された場合はご予約をお受けできませんこと、予めご了承ください。
        </NoteText>
      </Box>
    </Box>
  );
}

const NoteText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    color: #e11414;
    line-height: 20px;
  }
`;

const TotalBox = styled(Stack)`
  padding: 16px 32px;
  background-color: ${colors.grey6};
  display: flex;
  border-radius: 4px;
  margin-bottom: 40px;
`;

const WrapBox = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  .money-item span {
    font-weight: 700;
  }
`;

const Line = styled.div`
  height: 2px;
  background: #bdbdbd;
  width: 100%;
`;
const WrapItems = styled(Stack)`
  align-items: flex-end;
  .money-item span {
    font-weight: 700;
  }
`;
const Items = styled(Box)`
  display: flex;
  align-items: baseline;
`;
const FirstItem = styled(Box)``;
const LastItem = styled(Box)`
  width: 100px;
  display: flex;
  justify-content: flex-end;
`;

const SummaryBtn = styled(Button)`
  padding: 10px 0;
  height: 40px;
  width: 100%;
  box-shadow: none;
  cursor: pointer;
`;
