// UI
import { Box, Stack } from '@mui/material';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down-icon.svg';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from '@mui/material';
import { colors, fontsizes } from 'constants/styles';
import {
  AccountBox,
  Add,
  ArrowDropDown,
  CalendarToday,
  Remove,
} from '@mui/icons-material';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { dMedia } from 'styles/media';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Path from 'config/clientPath';
// swiper
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/slider/arrow_left.svg';
import { ReactComponent as ArrowLeft40 } from 'assets/icons/slider/arrow_left_40.svg';
import { ReactComponent as ArrowLeftSPIcon } from 'assets/icons/slider/arrow_left_sp.svg';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { getTotalPriceOfRoom } from 'utils/helper';
import { MoneyItem } from 'app/components/MoneyItem';
import { CartItem } from 'app/components/CardPreview/CartItem';
import { SingleCartItem } from './SingleCartItem';
import { useEffect } from 'react';

// import Swiper core and required modules
SwiperCore.use([Navigation, Pagination]);

interface ICartInfo {}

// const renderMoneyItem = () => {
//   return (
//     <WrapMoney direction="row" justifyContent={'space-between'}>
//       <Total>合計</Total>
//       <MoneyItem amount={203000} fontSize={24} isBold />
//     </WrapMoney>
//   );
// };

const renderPolicyItem = () => {
  return (
    <GrayBoxBig>
      <CancelPolicy>キャンセル規定</CancelPolicy>
      <WrapPolicy display="flex">
        <Box>
          <CancelTitle>取消日</CancelTitle>
          <CancelInfo>21~4日前</CancelInfo>
          <CancelInfo>21~4日前</CancelInfo>
        </Box>
        <WrapCancelFee>
          <CancelTitle>取消料率</CancelTitle>
          <CancelInfo>ご宿泊料金の10％</CancelInfo>
          <CancelInfo>ご宿泊料金の10％</CancelInfo>
        </WrapCancelFee>
      </WrapPolicy>
    </GrayBoxBig>
  );
};

export enum IPaginatePosition {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
}

export function CartInfo({ ...props }: ICartInfo) {
  const location = useLocation();
  // resetDeletedCartItems;
  const { cart_items, cart_items_deleted } = useSelector(selectGlobal);
  const cart_data =
    location.pathname === Path.SUCCESS ? cart_items_deleted : cart_items;

  const { resetDeletedCartItems } = useGlobalSlice().actions;

  const { width: pageWidth } = useWindowDimensions(0);
  const isMediumMobile = pageWidth <= 475;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetDeletedCartItems());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container
      spacing={{ xs: 5, sm: 3 }}
      mb={5}
      className="container_items_cart"
    >
      {cart_data.map((data, k) => {
        return (
          <WrapCartData>
            <SingleCartItem data={data} cart_item_key={k}></SingleCartItem>
          </WrapCartData>
        );
      })}
      {/* {cart_data.map((data, k) => {
        return (
          <WrapCartData>
            <CartItem data={data} cart_item_key={k}></CartItem>
          </WrapCartData>
        );
      })} */}
    </Container>
  );
}

const WrapCartData = styled(Box)`
  /* padding: 40px 40px 40px 40px; */
  padding-bottom: 40px;
  border: 1px solid ${colors.grey};
  border-radius: 8px;
  ${dMedia.tablet`
    padding: 0 0 0px 0;
    border: none;
  `}
`;

const WrapDivider = styled(Divider)`
  width: calc(100% + 80px);
  margin-left: -40px !important;
  ${dMedia.sm`
    width: calc(100vw);
    margin-left: -40px !important;
  `}
`;
const WrapButton = styled(Button)`
  width: calc(100% + 32px);
  margin-left: -16px !important;
  ${dMedia.sm`
    width: calc(100vw - 32px);
    margin-left: -24px !important;
  `}
`;

const Container = styled(Stack)`
  height: fit-content;
  max-width: 431px;
  /* border: 1px solid ${colors.grey}; */
  /* border-radius: 8px; */
  /* padding: 40px 40px 40px 40px; */
  ${dMedia.tablet`
    border: none;
    width: 100%;
  `}
`;

const WrapCancelFee = styled(Box)`
  width: 114px;
  flex-shrink: 0;
  margin-left: 10px;
`;

const WrapPolicy = styled(Box)`
  width: 207px;
  justify-content: space-between;
  margin: 0 auto;
`;

// const WrapMoney = styled(Stack)`
//   background: ${colors.grey6};
//   border-radius: 8px;
//   padding: 8px;
//   align-items: center;
//   .money-item {
//     span {
//       font-weight: bold;
//       line-height: 20px;
//     }
//   }
// `;
const WrapImage = styled(Stack)`
  padding: 0 8px 8px 8px;
  max-width: 256px;
  margin: 0 0 0 0 0;
`;
const ContentReserve = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: ${colors.grey1};
    text-align: center;
    align-self: flex-start;
    ${dMedia.tablet`
      margin-left: -8px;
      align-self: center;
  `}
  }
`;
const RoomName = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.grey1};
  }
`;
const RoomDesc = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: ${colors.grey2};
  }
`;
const Total = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.default};
  }
`;
const IncludedFee = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;
const CreditCardPay = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.grey1};
  }
`;
const IncludedMeal = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 14px;
    color: ${colors.heading};
  }
`;
const TitleBigSP = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    color: ${colors.grey1};
  }
`;
const TitleSP = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
`;
const ContentSP = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;

const AccommodationInfo = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
`;
const PersonInfo = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
    max-width: 198px;
  }
`;
const Change = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: ${colors.blue};
    padding: 0 4px;
    a {
      text-decoration: none;
    }
  }
`;
const CheckInOutTime = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;
const StayPeriod = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 14px;
    color: ${colors.heading};
  }
`;
const CancelTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.grey3};
  }
`;
const CancelInfo = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;
const CancelPolicy = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.default};
    margin-bottom: 8px;
    text-align: center;
  }
`;
const GrayBoxSP = styled(Box)`
  background: ${colors.grey6};
  padding: 16px;
  border-radius: 8px;
`;
const GrayBoxBig = styled(Box)`
  background: ${colors.grey6};
  padding: 16px 56.5px;
  border-radius: 8px;
  ${dMedia.tablet`
    padding: 16px 16.5px;
  `}
`;
const GrayBoxSmall = styled(Box)`
  background: ${colors.grey6};
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
`;

const CollapsedButton = styled(Button)`
  outline: none !important;
  background: white !important;
  box-shadow: none !important;
  border: 1px solid ${colors.grey4};
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .read_more_text {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: ${colors.grey1};
  }
  .MuiTouchRipple-root {
    display: none;
  }
`;
