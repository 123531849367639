import { Typography, Box, Button, Link } from '@mui/material';
import { TagList } from 'app/components/TagList';
import Parser from 'html-react-parser';

import { fontsizes, colors } from 'constants/styles';
import CollapsibleBox from 'app/components/CollapsibleBox';
import { Tag } from 'app/pages/HotelDetailsPage/slice/types';
import { useHistory } from 'react-router';
import Path from 'config/clientPath';
import { getUrlPlansPage } from 'utils/helper';
import { IParamTypes } from 'types/common';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface IProps {
  hotelIntroData: {
    name: string;
    mainTags: Tag[];
    title: string;
    content: string;
    detail_url?: string;
  };
  qs: string;
}

export default function HotelIntro(props: IProps) {
  const history = useHistory();
  const { hotelIntroData } = props;
  let { slug } = useParams<IParamTypes>();

  const renderButton = () => {
    return (
      hotelIntroData?.detail_url && (
        <ButtonViewDetail
          onClick={() => window.open(hotelIntroData?.detail_url, '_blank')}
          variant="contained"
          color="inherit"
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '16px',
              textAlign: 'center',
              color: '#333',
              textDecoration: 'none',
            }}
          >
            ホテルのHPを見る
          </Typography>
        </ButtonViewDetail>
      )
    );
  };

  return (
    <Box
      sx={{
        marginTop: {
          xs: '45px',
          md: '110px',
        },
      }}
    >
      <Box
        sx={{
          padding: {
            xs: '0 30px',
            md: 'unset',
          },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: '700',
            fontSize: {
              xs: fontsizes.heading3,
              md: fontsizes.heading1,
            },
            lineHeight: {
              xs: '18px',
              md: '40px',
            },
            color: colors.default,
            marginBottom: '16px',
          }}
        >
          {hotelIntroData.name}
        </Typography>
        <TagList
          data={hotelIntroData.mainTags.slice(0, 5).map(item => item.tag_name)}
          type={1}
        />
      </Box>
      <Typography
        sx={{
          fontWeight: '700',
          fontSize: {
            xs: fontsizes.main,
            md: fontsizes.heading2,
          },
          lineHeight: {
            xs: '18px',
            md: '40px',
          },
          color: colors.heading,
          margin: '40px 0',
        }}
      >
        {hotelIntroData.title}
      </Typography>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
        component={'span'}
      >
        {!!hotelIntroData.content && Parser(hotelIntroData.content)}
      </Box>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        <CollapsibleBox
          className={'collapse_box'}
          readMoreText={'続きを読む'}
          children={!!hotelIntroData.content && Parser(hotelIntroData.content)}
          isCollapsible={true}
          showEndIcon={true}
        />
      </Box>
      {renderButton()}
      <Button
        variant="contained"
        size="large"
        sx={{
          background: colors.blue,
          boxShadow: 'none',
          width: '100%',
          marginTop: '50px',
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
        onClick={() => {
          history.push(`${getUrlPlansPage(slug)}?${props.qs}`);
        }}
      >
        プラン一覧を見る
      </Button>
    </Box>
  );
}

const ButtonViewDetail = styled(Button)`
  && {
    outline: none !important;
    background: white !important;
    box-shadow: none !important;
    border: 1px solid ${colors.grey5};
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    max-width: 562px;
    width: 100%;
    margin: 40px auto 0 auto;
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #333333;
      text-decoration: none;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }
`;
