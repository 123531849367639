/* eslint-disable jsx-a11y/anchor-has-content */
import { Divider, List, ListItem, ListItemText, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Modal from 'app/components/Modal';
import { useClickOutside } from 'app/hooks/useClickOutSide';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import useGlobalFunc from 'app/pages/GlobalContainer/useGlobalFunc';
import { ReactComponent as CloseIcon } from 'assets/icons/header/close.svg';
import { ReactComponent as HamburgerIcon } from 'assets/icons/header/hamburger.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/header/phone.svg';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import Path from 'config/clientPath';
import {
  AUTH_MENU_LIST,
  MENU_LIST_GUEST,
  MENU_LIST_GUEST_MOBILE,
  MENU_LIST_LOGGED_IN,
  MENU_LIST_LOGGED_IN_MOBILE,
} from 'constants/menu';
import { colors } from 'constants/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IParamTypes } from 'types/common';
import { getUrlPlansPage } from 'utils/helper';
import { getRecentlyHotel } from 'utils/localStorage';
import { useWindowDimensions } from 'utils/useWindowDimensions';

import { CartInfo, CartInfoPosition } from '../atoms/CartInfo';
import HotelCard from './HotelCard';

// import { LanguageSwitch } from 'app/components/LanguageSwitch';

interface IHeader {}

const Header = (props: IHeader) => {
  const history = useHistory();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showHotelCard, setShowHotelCard] = React.useState<boolean>(false);
  const openHotelList = Boolean(anchorEl);
  const handleClickHotelList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [clientRef] = useClickOutside(() => setAnchorEl(null));
  const handleCloseList = () => {
    setAnchorEl(null);
    setShowMenuSP(false);
  };

  const { isLoggedIn, hotelList } = useSelector(selectGlobal);
  const {
    login,
    logout,
    changeShowRegisterModalStatus,
    changeShowLoginModalStatus,
    getHotelListRequest,
  } = useGlobalSlice().actions;
  const dispatch = useDispatch();
  const { handleLogout } = useGlobalFunc();

  const [showMenuSP, setShowMenuSP] = useState(false);
  const { width: pageWidth } = useWindowDimensions(0);
  const isMobile = pageWidth <= 768;

  const hideOnPCProps = { display: { xs: 'flex', sm: 'none' } };

  useEffect(() => {
    dispatch(getHotelListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { slug } = useParams<IParamTypes>();

  const renderQAMenu = () => {
    return (
      <ListItem
        disablePadding
        component={NavLink}
        to={{
          pathname: 'https://www.vmg.co.jp/hotels/reservation/faq/',
        }}
        target="_blank"
      >
        <ListItemText primary="Q&A" />
      </ListItem>
    );
  };

  const renderMenuItem = item => {
    // register page (=> show modal), other page => navigate to path
    const renderNormalProps = () => {
      if (item.path === Path.ACCOUNT_REGISTRATION) {
        return {
          onClick: () => {
            dispatch(changeShowRegisterModalStatus(true));
          },
        };
      }
      if (item.isHotelList) {
        return {
          onClick: () => {
            window.location.href = item.path;
          },
          ref: clientRef,
        };
      }
      if (item.menuName === 'ホテル詳細') {
        return {
          onClick: () => {
            window.location.href = `${Path.RESERVATION_PREFIX}/${
              slug ? slug : getRecentlyHotel()
            }/`;
          },
        };
      }
      if (item.menuName === 'プラン一覧') {
        return {
          onClick: () => {
            window.location.href = `${getUrlPlansPage(
              slug ? slug : getRecentlyHotel(),
            )}`;
          },
        };
      }
      return {
        component: 'a',
        href: window.location.origin + item.path,
      };
    };

    return (
      <ListItem
        disablePadding
        key={item.path}
        className={item.className}
        {...renderNormalProps()}
        onMouseEnter={() => {
          if (item.menuName === 'ホテル一覧' && !showHotelCard) {
            setShowHotelCard(true);
          }
        }}
        onMouseLeave={() => {
          if (item.menuName === 'ホテル一覧' && showHotelCard) {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
              setShowHotelCard(false);
            }, 300);
          }
        }}
      >
        <Tooltip title={item.menuName !== 'ホテル一覧' ? item.menuName : ''}>
          <Stack
            spacing={'10px'}
            sx={{
              position: 'relative',
            }}
            direction="row"
            alignItems={'center'}
          >
            {item.path === Path.CART ? (
              <Box style={{ position: 'relative' }}>
                {item.icon}
                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  <CartInfo position={CartInfoPosition.TOP} />
                </Box>
              </Box>
            ) : (
              item.icon
            )}
            <ListItemText sx={{ ...hideOnPCProps }} primary={item.menuName} />
            {item.isHotelList && (
              <Box
                id="basic-menu"
                sx={{
                  position: 'absolute',
                  display: showHotelCard ? 'block' : 'none',
                  top: '35px',
                  zIndex: 100000,
                  right: 0,
                  paddingTop: '5px',
                }}
              >
                <Box
                  sx={{
                    width: '450px',
                    maxHeight: '700px',
                    overflow: 'auto',
                    backgroundColor: '#fff',
                    color: '#202223',
                    padding: '10px 15px 0',
                    boxShadow:
                      'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
                  }}
                >
                  {!isMobile ? (
                    hotelList.map((item, index) => (
                      <HotelCard
                        onClose={() => handleCloseList()}
                        key={index}
                        data={item}
                      />
                    ))
                  ) : (
                    <Modal
                      title={''}
                      className="hotel-list"
                      open={openHotelList}
                      onClose={() => handleCloseList()}
                    >
                      {hotelList.map((item, index) => (
                        <HotelCard
                          onClose={() => handleCloseList()}
                          key={index}
                          data={item}
                        />
                      ))}
                    </Modal>
                  )}
                </Box>
              </Box>
            )}
            {item.path === Path.CART && (
              <Box sx={{ ...hideOnPCProps }}>
                <CartInfo />
              </Box>
            )}
          </Stack>
        </Tooltip>
      </ListItem>
    );
  };

  return (
    <Box>
      <AppBarCustom
        position="static"
        square
        color="inherit"
        className={showMenuSP ? 'show' : ''}
      >
        <Toolbar>
          <RightHeader>
            <Link to={Path.SEARCH}>
              <Logo></Logo>
            </Link>
            <ToggleMenuSP onClick={() => setShowMenuSP(!showMenuSP)}>
              {showMenuSP ? <CloseIcon /> : <HamburgerIcon />}
            </ToggleMenuSP>
          </RightHeader>
          <nav>
            <List>
              {/* <LanguageSwitch></LanguageSwitch> */}
              {isLoggedIn && !isMobile && (
                <>
                  {MENU_LIST_LOGGED_IN.map((item, k) => {
                    return renderMenuItem(item);
                  })}
                </>
              )}
              {isLoggedIn && isMobile && (
                <>
                  {MENU_LIST_LOGGED_IN_MOBILE.map((item, k) => {
                    return renderMenuItem(item);
                  })}
                  <ListItem
                    disablePadding
                    // component={NavLink}
                    // to={Path.LOGOUT}
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    <ListItemText
                      className={`logout_btn`}
                      primary="ログアウト"
                    />
                  </ListItem>
                  {renderQAMenu()}
                </>
              )}
              {!isLoggedIn && !isMobile && (
                <>
                  {AUTH_MENU_LIST.map((item, k) => {
                    if (item.path === Path.ACCOUNT_REGISTRATION) {
                      return (
                        <RegisterButton
                          variant="outlined"
                          key={k}
                          onClick={() => {
                            setShowMenuSP(false);
                            dispatch(changeShowRegisterModalStatus(true));
                          }}
                        >
                          {item.menuName}
                        </RegisterButton>
                      );
                    } else {
                      return (
                        <LoginButton
                          sx={{
                            margin: '0 10px',
                          }}
                          key={k}
                          color="inherit"
                          onClick={() => {
                            // dispatch(login(true));
                            setShowMenuSP(false);
                            dispatch(changeShowLoginModalStatus(true));
                          }}
                        >
                          {item.menuName}
                        </LoginButton>
                      );
                    }
                  })}
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {MENU_LIST_GUEST.map((item, k) => {
                    return renderMenuItem(item);
                  })}
                </>
              )}
              {!isLoggedIn && isMobile && (
                <>
                  {MENU_LIST_GUEST_MOBILE.map((item, k) => {
                    return renderMenuItem(item);
                  })}
                  {AUTH_MENU_LIST.map((item, k) => {
                    return renderMenuItem(item);
                  })}
                  {renderQAMenu()}
                </>
              )}
            </List>
          </nav>
          <Contact className="contact">
            <PhoneIcon />
            <div>
              <div>電話で問い合わせる</div>
              <div>0120-210-289</div>
            </div>
          </Contact>
        </Toolbar>
      </AppBarCustom>
    </Box>
  );
};

Header.defaultProps = {
  isLoggedIn: true,
};

export default Header;

const AppBarCustom = styled(AppBar)` 
  z-index: 20;
  &.MuiAppBar-root {
    box-shadow: none;
    border-bottom: 1px solid #e0e0e0;
    padding: 0 24px 0 24px;
  }
  .MuiButton-root {
    padding: 9px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  nav {
    margin-left: auto;
    .MuiList-root {
      padding: 0;
      display: flex;
    }
    .MuiListItem-root {
      cursor: pointer;
    }
  }
  }

  /* Responsive min width */
  @media (min-width: 600px) {
    .MuiToolbar-root {
      min-height: 79px;
      padding: 0;
    }
  }
  @media (min-width: 769px) {
    .MuiListItem-root {
      width: auto;
      padding-left: 40px;
      cursor: pointer;
      &.icons_no_login{
        margin-left: 32px;
      }
    }
    /* nav{
      .hideOnPC{
        display: none
      }
    } */
  }

  /* max width */
  @media (max-width: 768px) {
    nav {
      display: none;
    }
    &.MuiAppBar-root {
      padding: 0 16px 0 16px;
    }
    .MuiToolbar-root {
      flex-direction: column;
      padding: 0
    }
    &.show {
      .contact{
        display: flex;
        svg {
          margin-right: 10px;
        }
      }
      position: fixed;
      height: 100%;
      nav {
        display: flex;
        justify-content: center;
        margin: 40px 0 16px 0;
        width: 100%;
        .MuiListItemText-root{
          span{
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: ${colors.grey1}
          }
          &.logout_btn span{
            color: #BDBDBD
          }
        }
      }
      .MuiList-root{
        flex-direction: column;
        width: 100%
      }
      .MuiListItem-root {
        height: 40px;
        margin-bottom: 16px;
        svg{
          width: 24px;
          height: 24px;
        }
        justify-content: center;
        text-align: center;
        align-items: center;
      }
    }
  }
  
  
`;

const LoginButton = styled(Button)`
  margin: 0 32px 0 8px;
`;

const RegisterButton = styled(Button)`
  color: #2f80ed !important;
  border-color: #2f80ed !important;
`;

const ToggleMenuSP = styled(Box)`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-left: auto;
  }
`;

const Contact = styled(Box)`
  box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
  border-radius: 4px;
  border: 1px solid rgb(0 0 0 / 5%);
  height: 52px;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #333333;
  display: none;
  align-items: center;
`;

const RightHeader = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    height: 80px;
    flex: auto;
    a {
      width: 50%;
      svg {
        width: 100%;
      }
    }
  }
`;
