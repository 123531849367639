// UI
import { Box, Stack, Typography, Link } from '@mui/material';
import CollapsePanel from 'app/components/CollapsePanel';
import { colors } from 'constants/styles';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { selectPlanAndRoom } from '../slice/selectors';
import moment from 'moment';
import { PriceItem } from 'app/components/PriceItem';
import {
  getCheckInOutTime,
  getStringCheckoutTimeFromArray,
  renderPaymentMethods,
  renderPeriodCancel,
} from 'utils/helper';
import { PAYMENT_METHODS } from 'constants/common';
// import { Link } from 'react-router-dom';

interface IPlanDetail {}

const PlanDetail = (props: IPlanDetail) => {
  const moreInfo = [
    { title: 'キャンセルポリシー' },
    { title: 'お子様について' },
    { title: '注意事項' },
  ];
  const { detail_plan, planDetailModalStatus, getPlanDetailStatus } =
    useSelector(selectPlanAndRoom);

  const priceInfoMore: any[] = [];

  if (detail_plan?.hotel_id != 20005) {
    priceInfoMore.push({
      titleInfo: "＜2食付プラン＞",
      priceInfo: [
        {
          title: "小学生（お子様ミニコース・布団あり）",
          price: 12350,
        },
        {
          title: "幼児（お子様プレート・布団あり） ",
          price: 9350,
        },
        {
          title: "幼児（お子様プレート・添い寝）",
          price: 5750,
        },
        {
          title: "幼児（布団のみ）",
          price: 5500,
        },
        {
          title: "幼児（食事なし・添い寝）",
          price: 0,
        },
      ],
    });
  }
  priceInfoMore.push({
    titleInfo: "＜朝食付プラン＞",
    priceInfo: [
      {
        title: "小学生（朝食あり・布団あり）",
        price: detail_plan?.hotel_id == 20005 ? 9350 : 9050,
      },
      {
        title: "幼児（朝食あり・布団あり）",
        price: 7150,
      },
      {
        title: "幼児（朝食あり・添い寝）",
        price: 3550,
      },
      {
        title: "幼児（布団のみ）",
        price: 5500,
      },
      {
        title: "幼児（食事なし・添い寝）",
        price: 0,
      },
    ],
  });
  
  priceInfoMore.push({
    titleInfo: "＜素泊まりプラン＞",
    priceInfo: [
      {
        title: "小学生（布団あり）",
        price: 7400,
      },
      {
        title: "幼児（布団あり）",
        price: 5500,
      },
      {
        title: "幼児（添い寝）",
        price: 0,
      },
    ],
  });

  const renderPriceInfoArea = (v1, k1) => {
    return (
      <Stack spacing={1} key={k1}>
        <TitleInfo>{v1.titleInfo}</TitleInfo>
        {v1.priceInfo.map((v2, k2) => {
          return v2?.title ? (
            <WrapPrice key={k2}>
              <SubTitleInfo>{v2.title}</SubTitleInfo>
              <PriceItemCustom>
                <PriceItem amount={v2.price} isBold />
              </PriceItemCustom>
            </WrapPrice>
          ) : (
            <Box className="divider_price"></Box>
          );
        })}
      </Stack>
    );
  };

  return (
    <Stack spacing={4} sx={{ width: '100%', maxWidth: '288px' }}>
      <Item spacing={1}>
        <Title variant="h4">食事内容</Title>
        <Desc>{detail_plan?.meals?.join(', ')}</Desc>
      </Item>
      <Item spacing={1}>
        <Title variant="h4">支払方法</Title>
        <Desc>{renderPaymentMethods(detail_plan?.payment_method)}</Desc>
      </Item>
      {detail_plan?.checkin_times && (
        <Item spacing={1}>
          <Title variant="h4">チェックイン時間</Title>
          <Desc>
            {getStringCheckoutTimeFromArray(detail_plan?.checkin_times)}
            {/* {['10:08:31.158Z', '00:40:37.433Z']
              .map((v, k) => {
                return renderTime(v);
              })
              .join(', ')} */}
          </Desc>
        </Item>
      )}

      {detail_plan?.checkout_times && (
        <Item spacing={1}>
          <Title variant="h4">チェックアウト時間</Title>
          <Desc>{getCheckInOutTime(detail_plan?.checkout_times)}</Desc>
        </Item>
      )}
      <WrapCollapseData spacing={2}>
        {/* cancel policy */}
        <Box>
          <CollapsePanel viewMoreText={'キャンセルポリシー'} isOpen={true}>
            <GrayBox mt={2} spacing={1}>
              <UnderTitle variant="h4">キャンセル規定</UnderTitle>
              <Box display="flex" justifyContent={'space-between'}>
                <Box pr={2}>
                  <GrayBoxDesc variant="h4">取消日</GrayBoxDesc>
                  {detail_plan?.cancellation_fee_policies.map(item => (
                    <DetailContent>{`${renderPeriodCancel(
                      item.period_start,
                      item.period_end,
                    )}日前`}</DetailContent>
                  ))}
                </Box>
                <Box>
                  <GrayBoxDesc>取消料率</GrayBoxDesc>
                  {detail_plan?.cancellation_fee_policies.map(item => (
                    <DetailContent>{`ご宿泊料金の${item.cancellation_fee}%`}</DetailContent>
                  ))}
                </Box>
              </Box>
            </GrayBox>
          </CollapsePanel>
        </Box>

        {/* price for children */}
        <Box>
          <CollapsePanel viewMoreText={'お子様について'}>
            <MoreInfoArea spacing={2}>
              <Stack spacing={1}>
                <SubTitleInfo>お子様料金は以下にて承ります。</SubTitleInfo>
                <SubTitleInfo>
                  ※添い寝は6歳までとさせていただきます。
                </SubTitleInfo>
              </Stack>
              {priceInfoMore.map((v1, k1) => {
                return renderPriceInfoArea(v1, k1);
              })}
            </MoreInfoArea>
          </CollapsePanel>
        </Box>

        {/*  precautions */}
        {/* <Box>
          <CollapsePanel viewMoreText={'注意事項'}>
            <Stack spacing={2}>
              <DescInfo>
                歴史的建造物の保存を目的として当時の趣や風情を出来るだけ残している施設のため、断熱性や機密性が完全ではありません。予めご理解の程お願い申し上げます。
              </DescInfo>

              <Box>
                <TitleInfo>【わんちゃんとのご宿泊条件】</TitleInfo>
                <DescInfo>
                  わんちゃんとのご宿泊は、「ペットと泊まれる」と記載ある客室のみ可能です。他のお部屋はご利用いただけませんのでご注意ください。
                </DescInfo>
                <DescInfo>
                  また、以下の「宿泊滞在同意書」を必ずご確認いただき、同意の上ご利用ください。
                </DescInfo>
                <DescInfo>
                  同意書に必要事項を記入し、ワクチン注射の接種証明書及び狂犬病予防注射済証のコピーと共に
                </DescInfo>
                <DescInfo>
                  FAXまたはメール添付、もしくは当日持参していただきますようお願い申し上げます。
                </DescInfo>
                <WrapLink>
                  <TitleInfo>
                    ＜宿泊滞在同意書＞　
                    <Link
                      href="http://nipponia-sawara.jp/documents/dog_agreements.pdf"
                      target="_blank"
                    >
                      nipponia-sawara.jp/documents/dog_agreements.pdf
                    </Link>
                  </TitleInfo>
                </WrapLink>
              </Box>
            </Stack>
          </CollapsePanel>
        </Box> */}
      </WrapCollapseData>
    </Stack>
  );
};

export default PlanDetail;

const UnderTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.default};
    text-align: center;
  }
`;
const GrayBoxDesc = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.grey3};
  }
`;
const DetailContent = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
`;
const Desc = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;
const Item = styled(Stack)``;
const MoreInfoArea = styled(Stack)`
  .divider_price {
    margin-top: 16px !important;
  }
`;

const WrapCollapseData = styled(Stack)`
  ${dMedia.tablet`
      margin-top: 16px !important;
    `}
`;
const GrayBox = styled(Stack)`
  background: ${colors.grey6};
  border-radius: 8px;
  padding: 16px 40px;
`;

const TitleInfo = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
`;

const SubTitleInfo = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.heading};
  }
`;

const PriceItemCustom = styled(Box)`
  .price_item {
    width: 50px;
    margin-left: 10px;
    span {
      /* font-weight: 500; */
      font-size: 14px;
      line-height: 16px;
      color: ${colors.heading};
    }
  }
`;

const WrapPrice = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const DescInfo = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;

const WrapLink = styled(Box)`
  display: flex;
  a {
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
`;
