import { toast, ToastContent } from 'react-toastify';

const ToastMessage = {
  success(msg: ToastContent) {
    toast.success(`${msg}`, {
      toastId: 'toast_add_cart_success',
      position: 'top-right',
      autoClose: 3000,
      closeButton: false,
      // autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
    });
  },
  inform(msg: ToastContent) {
    toast.info(`${msg}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  warn(msg: ToastContent) {
    toast.warn(`${msg}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  error(msg: ToastContent) {
    toast.error(msg, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
};

export default ToastMessage;
