import { fontsizes, colors } from 'constants/styles';
import { Typography } from '@mui/material';

interface Props {
  text: string;
  marginBottom?: string;
}

export default function SubTitle({ text, marginBottom }: Props) {
  return (
    <Typography
      sx={{
        fontWeight: '500',
        fontSize: fontsizes.main,
        color: colors.heading,
        marginBottom: marginBottom ? marginBottom : '8px',
        lineHeight: '16px',
      }}
    >
      {text}
    </Typography>
  );
}
