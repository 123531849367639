import { Box, Stack, Typography } from '@mui/material';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-icon.svg';
import Path from 'config/clientPath';
import { colors, fontsizes } from 'constants/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { dMedia } from 'styles/media';
import { checkIsAllowInCheckoutPage } from 'utils/helper';

interface Props {}
interface ListItemProps {
  active: boolean;
}

export const CheckoutHeader = ({ ...props }: Props) => {
  const { currentCheckoutStep, cart_items, cart_items_deleted } =
    useSelector(selectGlobal);
  const history = useHistory();

  useEffect(() => {
    const pathName = window.location.pathname;
    let usedCartItems = cart_items;
    // only order success page use cart_items_deleted
    if (pathName === Path.SUCCESS) {
      usedCartItems = cart_items_deleted;
    }
    if (!checkIsAllowInCheckoutPage(usedCartItems)) {
      history.push(Path.CART);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CheckoutSteps = [
    {
      step: 1,
      text1: 'アカウント',
      text2: '情報',
    },
    {
      step: 2,
      text1: 'ご予約',
      text2: '情報',
    },
    {
      step: 3,
      text1: '決済',
      text2: '情報',
    },
    {
      step: 4,
      text1: '決済',
      text2: '完了',
    },
  ];
  return (
    <Box sx={{ backgroundColor: colors.grey6 }} pl={2} pr={2}>
      <List direction="row" justifyContent="center" alignItems="center">
        {CheckoutSteps.map((it, idx) => (
          <React.Fragment key={idx}>
            <ListItem
              variant="h4"
              active={currentCheckoutStep === it.step}
              fontSize={fontsizes.smallText}
            >{`${it.step}.${it.text1}\n${it.text2}`}</ListItem>
            {idx < CheckoutSteps.length - 1 ? <ArrowIcon /> : ''}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

const List = styled(Stack)`
  padding: 22px 0;
  gap: 16px;

  ${dMedia.tablet`
    padding: 16px 0;
  `}

  ${dMedia.sm`
    gap: 8px;
  `};
`;

const ListItem = styled(Typography)<ListItemProps>`
  color: ${colors.grey1};
  opacity: ${props => (props.active ? 1 : 0.3)};
  text-align: center;
  white-space: pre-line;
`;
