import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import searchSaga from './saga';
import { SearchState } from './types';
import _ from 'lodash';

export const initialState: SearchState = {
  searchStatus: RequestStatus.IDLE,
  getPriceStatus: RequestStatus.IDLE,
  areaStatus: RequestStatus.IDLE,
  tagStatus: RequestStatus.IDLE,
  data: null,
  areas: null,
  tags: null,
  errorMess: null,
  checkAvailabilityStatus: {},
  availData: null,
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchRequest: (state, action: PayloadAction<any>) => {
      state.searchStatus = RequestStatus.REQUESTING;
    },
    searchSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.searchStatus = RequestStatus.SUCCESS;
        state.data = _.map(action.payload, it => ({
          ...it,
          availability: true,
        }));

        state.availData = action.payload;
      }
    },
    searchFail: (state, action: PayloadAction<any>) => {
      if (action) state.searchStatus = RequestStatus.ERROR;
    },

    checkAvailabilityRequest: (state, action: PayloadAction<any>) => {
      const status = state.checkAvailabilityStatus;
      status[action.payload.id] = RequestStatus.REQUESTING;
      state.checkAvailabilityStatus = status;
    },
    checkAvailabilitySuccess: (state, action: PayloadAction<any>) => {
      const { checkAvailabilityStatus, availData, data } = state;
      checkAvailabilityStatus[action.payload.hotel_id] = RequestStatus.SUCCESS;
      state.checkAvailabilityStatus = checkAvailabilityStatus;
      if (!action.payload.data.available) {
        const idx = _.findIndex(state.data, { id: +action.payload.hotel_id });
        if (idx > -1 && data) {
          data[idx] = { ..._.cloneDeep(data[idx]), availability: false };
        }
        state.availData = _.filter(
          availData,
          val => val.id !== +action.payload.hotel_id,
        );
        state.data = data;
      }
    },
    checkAvailabilityFail: (state, action: PayloadAction<any>) => {
      if (action) {
        const { checkAvailabilityStatus, availData } = state;
        checkAvailabilityStatus[action.payload.hotel_id] = RequestStatus.ERROR;
        state.checkAvailabilityStatus = checkAvailabilityStatus;
        state.availData = _.filter(
          availData,
          val => val.id !== +action.payload.hotel_id,
        );
      }
    },
    getPriceRequest: (state, action: PayloadAction<any>) => {
      state.getPriceStatus = RequestStatus.REQUESTING;
    },
    getPriceSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getPriceStatus = RequestStatus.SUCCESS;
      }
    },
    getPriceFail: (state, action: PayloadAction<any>) => {
      if (action) state.getPriceStatus = RequestStatus.ERROR;
    },
    areaRequest: (state, action: PayloadAction<any>) => {
      state.areaStatus = RequestStatus.REQUESTING;
    },

    areaSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.areaStatus = RequestStatus.SUCCESS;
        state.areas = action.payload;
      }
    },

    areaFail: (state, action: PayloadAction<any>) => {
      if (action) state.areaStatus = RequestStatus.ERROR;
    },

    tagRequest: (state, action: PayloadAction<any>) => {
      state.tagStatus = RequestStatus.REQUESTING;
    },

    tagSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.tagStatus = RequestStatus.SUCCESS;
        state.tags = action.payload;
      }
    },

    tagFail: (state, action: PayloadAction<any>) => {
      if (action) state.tagStatus = RequestStatus.ERROR;
    },

    resetSearchRequestStatus: state => {
      state.searchStatus = RequestStatus.IDLE;
      state.areaStatus = RequestStatus.IDLE;
      state.tagStatus = RequestStatus.IDLE;
      state.errorMess = [];
      state.checkAvailabilityStatus = {};
    },

    resetAreaRequestStatus: state => {
      state.areaStatus = RequestStatus.IDLE;
      state.errorMess = [];
    },

    resetTagRequestStatus: state => {
      state.tagStatus = RequestStatus.IDLE;
      state.errorMess = [];
    },
  },
});

export default slice.actions;

export const useSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: searchSaga });
  return { actions: slice.actions };
};
