import Path from 'config/clientPath';
import Roles from 'constants/roles';
import moment from 'moment';
import { getExpiredTime } from 'utils/helper';
import _ from 'lodash';

export const ROUTES_INFO = {
  [Path.CUSTOMERS]: {
    useRegex: true,
    role: Roles.CUSTOMER_ALL,
  },
};

export const GENDERS = [
  {
    id: 1,
    label: '男性',
    value: '1',
  },
  {
    id: 2,
    label: '女性',
    value: '2',
  },
];

export const GENDERS_ENG = [
  {
    id: 1,
    label: '男性',
    value: 'male',
  },
  {
    id: 2,
    label: '女性',
    value: 'female',
  },
  {
    id: 3,
    label: '無回答',
    value: null,
  },
];

export const TYPE_TAB_PLAN = 0;
export const TYPE_TAB_ROOM = 1;

export const TYPE_SORT_PRICE = 'price';
export const TYPE_SORT_SUGGESTION = 'suggestion';

export const SORT_TYPES = [
  {
    label: 'おすすめ順',
    value: TYPE_SORT_SUGGESTION,
  },
  {
    label: '価格順',
    value: TYPE_SORT_PRICE,
  },
];

export const LIMIT_ITEMS = {
  PLAN_ROOM_IMAGE: 5,
  TAG: 5,
};

export const hideOnPCProps = { display: { xs: 'flex', sm: 'none' } };
export const hideOnSPProps = { display: { xs: 'none', sm: 'flex' } };

// export const SORT_NO_STATUS = 0;
export const DESC_SORT_STATUS = 0;
export const ASC_SORT_STATUS = 1;

export const SORT_STATUSES_NUMBER = {
  // [SORT_NO_STATUS]: null,
  [DESC_SORT_STATUS]: 'desc',
  [ASC_SORT_STATUS]: 'asc',
};

export const TEXT_SORT_PRICE_DESC = TYPE_SORT_PRICE + ' desc';
export const TEXT_SORT_PRICE_ASC = TYPE_SORT_PRICE + ' asc';

export const PRICE_SORT_STATUSES_NUMBER = {
  // [SORT_NO_STATUS]: null,
  [DESC_SORT_STATUS]: TEXT_SORT_PRICE_DESC,
  [ASC_SORT_STATUS]: TEXT_SORT_PRICE_ASC,
};

export const PRICE_SORT_STATUSES_STRING = {
  [TEXT_SORT_PRICE_DESC]: 0,
  [TEXT_SORT_PRICE_ASC]: 1,
};

export const DEFAULT_STORE_HOTEL_ID = 99;

export const MEALS = [
  {
    label: 'すべて',
    value: 0,
  },
  {
    label: '2食付',
    value: 1,
  },
  {
    label: '朝食付',
    value: 2,
  },
  {
    label: '素泊まり',
    value: 3,
  },
];
export const DEFAULT_MEAL = 0;

export const initCustomerInfo = {
  guest: {
    employment_name: '',
    birthday: '',
    address: '',
    gender: '',
    // gender: GENDERS_ENG[0]?.value,
    prefecture_code: '01',
    city: ' ',
    // name_kanji
    name: '',
    telephone: '',
    // name_kata
    kana: '',
    postal_code: '',
    email: '',
    age: 0,
    zipcode: '',
    visit_by_car: false,
    // order info
    male_adult_count: 0,
    female_adult_count: 0,
    // persist guest data of form data, to get old guest data of form when press back on browser
    cacheFormData: {},
    // cacheFormData: {enquetes: {}, paymentInfo:{}},
    // data for all tabs of order info
    // enquetes for tabs 0, 1,...
    // enquetes{0: [], 1: []}
    // enquetes: {},
    // allGuests: {},
    // check_in_time for tabs 0, 1,...
    // check_in_time: {0: '', 1: ''},

    // payment: {
    //   payment_method: '',
    //   softbank_payment_token: '',
    //   softbank_payment_token_key: '',
    //   softbank_payment_merchant_id: '',
    //   softbank_payment_service_id: '',
    // },

    // properties are not used
    // mean: '',
    // age_and_gender: '',
    // allergies: '',
    // field_not_good: '',
    // use_purpose: '',
    // receive_email: true,
    // agree_policy: false,
    // architect_note: false,
    // others: '',
  },
  expired_time: moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
};

export const PAYMENT_METHODS = [
  {
    label: 'オンライン事前決済',
    value: 'softbank_payment',
  },
  {
    label: '現地決済',
    value: 'front_desk',
  },
];

export const LIMIT_PLANS_DISPLAY = 2;

export const DEFAULT_GUEST_COUNT = 2;

export const BEFORE_PAID_PAYMENT_TYPE = 'BEFORE_PAID_PAYMENT_TYPE';
export const AFTER_PAID_PAYMENT_TYPE = 'AFTER_PAID_PAYMENT_TYPE';

export const GENDER_PROFILE = [
  {
    id: 1,
    label: '男性',
    value: 'male',
  },
  {
    id: 2,
    label: '女性',
    value: 'female',
  },
  {
    id: 3,
    label: '無回答',
    value: '',
  },
];

export const CHILD_PRICE = {
  child_A: 'price_child_a',
  child_B: 'price_child_b',
  child_C: 'price_child_c',
  child_D: 'price_child_d',
  child_E: 'price_child_e',
  child_F: 'price_child_f',
};

export const CART_HANDLE_TYPE = {
  RESERVED: 'RESERVED',
  ADD_CART: 'ADD_CART',
  ADDITION_MODAL: 'ADDITION_MODAL',
};

export const ORDER_ERROR_INFO = {
  out_of_stock: {
    title: 'ご希望のご予約枠のエラーが発生しました',
    msg: 'お客様のご予約より先に、他のお客様がご購入されたか、ご予約の保持期限が過ぎてしまった可能性がございます。恐れ入りますが、再度日程とプランをご確認し、ご予約に進んでいただきますよう、お願い申し上げます。',
  },
  plan_rate_changes: {
    title: 'ご希望のご予約枠のエラーが発生しました',
    msg: 'お客様のご予約より先に、他のお客様がご購入されたか、ご予約の保持期限が過ぎてしまった可能性がございます。恐れ入りますが、再度日程とプランをご確認し、ご予約に進んでいただきますよう、お願い申し上げます。',
  },
  payment_error: {
    title: '決済上のエラーが発生しました',
    msg: '決済・ご予約は、まだ完了しておりません。恐れ入りますが、今一度カード情報を正しくご確認いただき、再度ご入力いただくか、別のカードでの決済をお試しくださいますよう、お願い申し上げます。',
  },
  fatal_error: {
    title: 'ご予約が完了しておりません',
    msg: '決済上のエラーにより、決済、ご予約が完了しておりません。恐れ入りますが、今一度カード情報を正しくご確認いただき、再度ご入力いただくか、別のカードでの決済をお試しくださいますよう、お願い申し上げます。',
  },
};

export const DETAIL_PRICES = [
  {
    key: 'adult',
    label: '大人',
  },
  {
    key: 'child_a',
    label: '小学生',
  },
  {
    key: 'child_b',
    label: '乳幼児（食事あり・寝具あり）',
  },
  {
    key: 'child_c',
    label: '乳幼児（食事あり・添い寝）',
  },
  {
    key: 'child_d',
    label: '乳幼児（食事なし・寝具あり）',
  },
  {
    key: 'child_e',
    label: '乳幼児（食事なし・添い寝）',
  },
];

export const GTM_CLASS_NAME = 'gtm_hotel';

export const FORM_ERROR_TEXT = {
  PASS_NOT_MATCH: 'パスワードが一致しません。',
  PASSWORD_RULE:
    'パスワードは大文字・小文字の英字、数字を組み合わせた8文字以上でご入力ください。',
};

export const SUCCESS_MESS = {
  REGISTER_ACCOUNT: 'アカウント登録完了しました。',
};

export const ENQUETE_PLACEHOLDERS = {
  eh10006: {
    placeholder: 'メッセージプレートのご希望等',
  },
  eh10002: {
    placeholder:
      '例）1人青魚が苦手です。加熱すれば食べられます。1人甲殻類アレルギー、エキスもNGです。1人ヴィーガンです。',
  },
  eh10003: {
    placeholder: '',
  },
  eh10004: {
    placeholder: '',
  },
};

export const ENQUETE_IDS_HAS_PLACEHOLDER = _.keys(ENQUETE_PLACEHOLDERS);

export const BINARY_FLAG = {
  TRUE: true,
  FALSE: false,
};
