import {
  Box,
  CardContent,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { BoxHeader } from 'app/components/BoxHeader';
import CheckBoxItem from 'app/components/CheckBoxItem';
import { CommonButton } from 'app/components/CommonButton';
import { CommonLink } from 'app/components/Link';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import Path from 'config/clientPath';
import { colors } from 'constants/styles';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';

interface IEmailForm {
  email: string;
  password: string;
}

interface IProps {
  className?: string;
  submitForm: (formData: any) => void;
  id?: string;
}

const EmailForm = ({ className, ...props }: IProps) => {
  const dispatch = useDispatch();

  const { changeShowLoginModalStatus } = useGlobalSlice().actions;
  const history = useHistory();

  const handleLoginSubmit = formData => {
    // dispatch(login());
    // history.push(Path.SEARCH);
    props.submitForm(formData);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Container className={className}>
      <FormContainer>
        <CardContent
          className="body"
          sx={{
            maxWidth: {
              md: '375px',
              xs: '100%',
            },
            margin: {
              md: '0 auto',
            },
            padding: {
              md: '80px 0',
              xs: '0',
            },
          }}
        >
          <BoxHeader fontSize={18}>予約キャンセルの認証</BoxHeader>
          <Box
            sx={{
              marginTop: {
                md: 5,
                xs: 3,
              },
            }}
            component="form"
            id={props.id || 'email_form'}
            onSubmit={handleSubmit(handleLoginSubmit)}
          >
            <Stack spacing={4}>
              <Stack spacing={1}>
                <Typography gutterBottom color={colors.mainContent}>
                  メールアドレス
                </Typography>
                <TextField
                  {...register<keyof IEmailForm>('email', {
                    required: 'こちらは必須項目です。',
                  })}
                  type="email"
                  placeholder="例）abcd@vmg.co.jp"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                />
              </Stack>
              <Stack direction="column" justifyContent="space-between">
                <CommonButton
                  className="white-btn"
                  variant="contained"
                  size="large"
                  type="submit"
                  form={props.id || 'email_form'}
                  sx={{
                    background: colors.blue,
                    boxShadow: 'none',
                    fontSize: '15px',
                    marginBottom: 1,
                    lineHeight: '20px',
                    padding: '10px',
                  }}
                >
                  予約キャンセル
                </CommonButton>
              </Stack>
            </Stack>
          </Box>
        </CardContent>
      </FormContainer>
    </Container>
  );
};

EmailForm.defaultProps = {};
export default EmailForm;

const Container = styled(Box)``;

const FormContainer = styled(Box)`
  max-width: 800px;
  margin: 0 auto;
  background: ${colors.white};
  .MuiCardContent-root {
    ${dMedia.tablet`
        padding: 3rem 1rem 2rem;
      `}
  }

  .MuiTypography-gutterBottom {
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
  }
  .MuiInputBase-input {
    font-size: 14px;
  }
  .MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    /* border-color: inherit; */
    border-width: 1px;
  }
  .MuiButton-root {
    font-weight: 500;
    display: block;
    a {
      color: inherit;
      display: block;
      text-decoration: none;
    }
  }

  a {
    text-align: center;
  }
`;
