import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import * as React from 'react';
import styled from 'styled-components';
import { dMedia } from 'styles/media';

export interface IModalProps {
  open: boolean;
  onClose: () => void;
  children: any;
  title?: string;
  className?: string;
  hasCloseIcon?: boolean;
  unsetTitle?: boolean;
  paddingTopTitle?: string;
}

export default function Modal({ hasCloseIcon = true, ...props }: IModalProps) {
  return (
    <ModalDialog
      open={props.open}
      maxWidth="md"
      fullWidth
      scroll="paper"
      className={`common_modal ${props.className}`}
    >
      <DialogTitle className="dialog-title">
        {!props.unsetTitle && (
          <Typography
            component="span"
            textAlign="center"
            sx={{
              display: 'block',
              fontSize: '24px',
              fontWeight: 700,
              padding: '40px 0 0',
              paddingTop: props.paddingTopTitle
                ? props.paddingTopTitle
                : '40px',
            }}
          >
            {props.title}
          </Typography>
        )}
        {hasCloseIcon && <CloseIcon onClick={props.onClose} />}
      </DialogTitle>
      <DialogContent id={'dialog_content'}>{props.children}</DialogContent>
    </ModalDialog>
  );
}

const ModalDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 800px;
    border-radius: 8px;
    .MuiDialogTitle-root {
      position: relative;
      svg {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 20px;
        top: 30px;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
      overflow-y: auto;
      border-radius: 0;
    }
  }
  .MuiDialogContent-root {
    padding: 0px 24px !important;
    ${dMedia.tablet`
      overflow-y: initial;
      padding: 0 6px!important;
    `}
  }
  &.room_list_modal {
    .MuiDialogTitle-root {
      padding-bottom: 15px;
    }
    &.plan_order  h2.MuiTypography-root > span {
      margin: 0 3rem 0 1rem;
    }
    .MuiDialogContent-root {
      position: relative;
      padding: 0px 40px !important;
      ${dMedia.tablet`
        padding: 0 16px!important;
      `}
    }
    ${dMedia.tablet`
      .MuiDialogTitle-root {
        padding-bottom: 16px;
      }
    `}
`;
