import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { CommonButton } from '../CommonButton';
import { colors } from 'constants/styles';
import CloseIcon from '@mui/icons-material/Close';

interface IConfirmModal {
  open: boolean;
  onClose: () => void;
  onAgree: () => void;
  title: string;
  content: string;
}

const ConfirmModal = ({
  title,
  open,
  content,
  onClose,
  onAgree,
  ...props
}: IConfirmModal) => {
  return (
    <ModalDialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>{content}</Box>
        <Stack spacing={2} mt={2}>
          <CommonButton
            variant="contained"
            size="large"
            type="submit"
            sx={{
              background: colors.blue,
              boxShadow: 'none',
              color: colors.white,
            }}
            onClick={() => onAgree()}
          >
            OK
          </CommonButton>
          <CommonButton
            variant="contained"
            size="large"
            sx={{
              background: 'transparent',
              boxShadow: 'none',
              color: colors.default,
            }}
            onClick={() => onClose()}
          >
            キャンセル
          </CommonButton>
        </Stack>
      </DialogContent>
    </ModalDialog>
  );
};

ConfirmModal.defaultProps = {};
export default ConfirmModal;

const ModalDialog = styled(Dialog)`
  text-align: center;
`;
