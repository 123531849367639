import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';

import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { Box, Stack, Typography } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import _ from 'lodash';

import CartSummary from 'app/pages/CartPage/components/CartSummary';
import CartItem from 'app/pages/CartPage/components/CartItem';
import { BoxHeader } from 'app/components/BoxHeader';

import { fontsizes } from 'constants/styles';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from '../GlobalContainer/slice';
import {
  checkIsRequesting,
  getProductsHasPrice,
  getTotalPriceOfRoom,
  lodash,
} from 'utils/helper';
import useGlobalFunc from '../GlobalContainer/useGlobalFunc';
import { defaultCustomerInfo, useCheckoutSlice } from '../CheckoutPage/slice';
import { Loader } from 'app/components/Loading';
import { RequestStatus } from 'constants/API';
import { useWindowDimensions } from 'utils/useWindowDimensions';

const data = [
  {
    id: 1,
    roomImg:
      'https://i.vdoc.vn/data/image/2021/09/13/100-hinh-nen-background-zoom-dep-mien-phi-4.jpg',
    roomName: 'VMG グランド（定員4名）',
    date: '2泊',
    range: '2021/08/17(火) ~ 2021/08/19(木)',
    planName: '通常ご宿泊プラン',
    meal: ['朝食（朝食）', '夕食（夕食）'],
    price: 203000,
  },
  {
    id: 2,
    roomImg:
      'https://i.vdoc.vn/data/image/2021/09/13/100-hinh-nen-background-zoom-dep-mien-phi-4.jpg',
    roomName: 'VMG グランド（定員4名）',
    date: '2泊',
    range: '2021/08/17(火) ~ 2021/08/19(木)',
    planName: '通常ご宿泊プラン',
    meal: ['朝食（朝食）', '夕食（夕食）'],
    price: 203000,
  },
];

export default function CartPage() {
  const { width: pageWidth } = useWindowDimensions(0);
  const isLargerMd = pageWidth >= 900;

  const [items, setItems] = useState(data);
  const { cart_items } = useSelector(selectGlobal);
  const { changeCustomerInfo, createOrderRequest, resetCheckInventoryStatus } =
    useCheckoutSlice().actions;

  const { removeItemToCart } = useGlobalSlice().actions;
  const { changeCartItemInfo, getHotelStockStatus, resetCheckHotelStock } =
    useGlobalFunc();
  const dispatch = useDispatch();

  const renderCartSummary = () => {
    return cart_items.length ? (
      <CartSummaryBox total={getTotalPriceOfRoom(cart_items)}></CartSummaryBox>
    ) : (
      ''
    );
  };

  React.useEffect(() => {
    // remove cart items if has price === 0
    if (!!!getProductsHasPrice(cart_items || [])?.length) {
      changeCartItemInfo([]);
    }
    // dispatch(changeCurrentCheckoutStep(4));
    return () => {
      resetCheckHotelStock();
      resetCheckInventoryStatus();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = checkIsRequesting([...Object.values(getHotelStockStatus)]);

  return (
    <>
      <Helmet>
        <title>カート | VMG HOTELS & UNIQUE VENUES</title>
        <meta
          name="description"
          content="カート | VMG HOTELS & UNIQUE VENUES"
        />
      </Helmet>
      <Header />
      <Loader open={isLoading} />

      <Box
        sx={{
          maxWidth: {
            xs: 'unset',
            md: '832px',
          },
          marginLeft: {
            xs: 0,
            md: 'calc((100% - 832px) * 0.62)',
          },
          marginTop: '40px',
          padding: {
            xs: '0 16px',
            md: 0,
          },
          minHeight: {
            md: `calc(100vh - 250px)`,
            xs: `100vh`,
          },
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'flex',
              md: 'grid',
            },
            gridTemplateColumns: {
              xs: '1fr',
              md: '532px 1fr',
            },
            gap: '32px',
            marginTop: '40px',
            flexDirection: {
              xs: 'column-reverse',
              md: 'unset',
            },
          }}
        >
          <Box>
            <BoxHeader
              fontSize={24}
              maxWidth="532px"
              icon={
                <ShoppingCart
                  sx={{
                    boxSizing: 'content-box',
                    fontSize: {
                      xs: fontsizes.heading2,
                      md: fontsizes.heading1,
                    },
                  }}
                />
              }
            >
              カート
            </BoxHeader>

            <Box
              sx={{
                display: {
                  xs: 'flex',
                  md: 'grid',
                },
                gridTemplateColumns: {
                  xs: '1fr',
                  md: '532px 1fr',
                },
                gap: '32px',
                marginTop: '40px',
                flexDirection: {
                  xs: 'column-reverse',
                  md: 'unset',
                },
              }}
            >
              <Box>
                {cart_items.map((item, key) => (
                  <CartItem
                    cart_key={key}
                    data={item}
                    onClick={cart_key => {
                      dispatch(
                        changeCustomerInfo({ ...defaultCustomerInfo.guest }),
                      );
                      dispatch(
                        removeItemToCart({
                          key: cart_key,
                          local_id: item?.local_id,
                        }),
                      );
                    }}
                  ></CartItem>
                ))}
                {_.isEmpty(cart_items) ? (
                  <Typography>
                    条件に合致するデータは見つかりませんでした
                  </Typography>
                ) : (
                  ''
                )}
              </Box>

              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {!isLargerMd && renderCartSummary()}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {isLargerMd && renderCartSummary()}
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

const CartSummaryBox = styled(CartSummary)`
  @media screen and (max-width: 900px) {
    margin-top: 0;
  }
`;
