// UI
import { Box } from '@mui/material';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/slider/arrow_left.svg';
import { ReactComponent as ArrowLeft40 } from 'assets/icons/slider/arrow_left_40.svg';
import { ReactComponent as ArrowLeftSPIcon } from 'assets/icons/slider/arrow_left_sp.svg';
import { IImage } from 'constants/commonTypes';
import { colors } from 'constants/styles';
import styled, { css } from 'styled-components';
import _ from 'lodash';

// swiper
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowDimensions } from 'utils/useWindowDimensions';
// import Swiper core and required modules
SwiperCore.use([Navigation, Pagination]);

export const iconPrevSizes = {
  40: <ArrowLeft40 />,
};

export enum IPaginatePosition {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
}
interface IImageSlider {
  images: Array<IImage>;
  sliderWidth?: string | number;
  paginatePosition?: IPaginatePosition;
  normalPaginateColor?: string;
  activePaginateColor?: string;
  className?: string;
  iconPrevSize?: number;
  objectFit?: any;
}

const ImageSlider = ({
  images = [],
  sliderWidth,
  paginatePosition = IPaginatePosition.OUTSIDE,
  normalPaginateColor = colors.grey5,
  activePaginateColor = colors.grey1,
  className,
  iconPrevSize,
  objectFit = 'contain',
  ...props
}: IImageSlider) => {
  const isOnlySingleSlide = sliderWidth ? true : false;
  const _sliderWidth = sliderWidth ? sliderWidth : '100%';

  const { width: pageWidth } = useWindowDimensions(0);
  const isMediumMobile = pageWidth <= 475;

  const iconPrev = isMediumMobile ? (
    <ArrowLeftSPIcon />
  ) : iconPrevSize ? (
    iconPrevSizes[iconPrevSize]
  ) : (
    <ArrowLeftIcon />
  );

  return (
    <Container
      className={className}
      style={{ width: _sliderWidth }}
      isOnlySingleSlide={isOnlySingleSlide}
      paginatePosition={paginatePosition}
      normalPaginateColor={normalPaginateColor}
      activePaginateColor={activePaginateColor}
      sx={{
        marginBottom:
          paginatePosition === IPaginatePosition.OUTSIDE ? '25px' : 0,
      }}
    >
      {_.isEmpty(images) ? (
        <DefaultSlider
          style={{ width: _sliderWidth, paddingBottom: 'calc(100% * 3 / 4)' }}
        ></DefaultSlider>
      ) : (
        <Swiper
          centeredSlides={true}
          loop={true}
          slidesPerView={1}
          spaceBetween={0}
          pagination={{
            clickable: true,
            el: '.swiper_pagination_custom',
            type: 'bullets',
          }}
          navigation={{
            nextEl: '.swiper_button_next_custom',
            prevEl: '.swiper_button_prev_custom',
          }}
          breakpoints={{
            // when window width is >= 769px
            769: {
              slidesPerView: isOnlySingleSlide ? 1 : 2,
              spaceBetween: isOnlySingleSlide ? 0 : 24,
            },
          }}
        >
          <div className="swiper_pagination_custom"></div>
          <div className="swiper_button_next_custom">
            {iconPrev}
            {/* {isMediumMobile ? (
            <ArrowLeftSPIcon style={{ transform: `rotate(180deg)` }} />
          ) : iconPrevSlide ? (
            iconPrevSlide
          ) : (
            <ArrowLeftIcon style={{ transform: `rotate(180deg)` }} />
          )} */}
          </div>
          <div className="swiper_button_prev_custom">
            {iconPrev}
            {/* {isMediumMobile ? <ArrowLeftSPIcon /> : <ArrowLeftIcon />} */}
          </div>
          {images.map((image, k) => {
            return (
              <SwiperSlide key={k}>
                <img
                  src={image.src}
                  alt="Hotel"
                  style={{ objectFit: objectFit }}
                />
                {/* <SingleImage
                width={'100%'}
                height={396}
                backgroundImage={image.src}
              ></SingleImage> */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Container>
  );
};

export default ImageSlider;

const Container = styled(Box)<{
  isOnlySingleSlide?: Boolean;
  paginatePosition?: IPaginatePosition;
  normalPaginateColor?: String;
  activePaginateColor?: String;
}>`
  .swiper-container {
    position: static;
    .swiper-wrapper {
      position: static;
    }
  }
  position: relative;
  .swiper-slide {
    background: #fff;
    height: auto;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .swiper_pagination_custom {
    /* .swiper-container-horizontal { */
    &.swiper-pagination-bullets {
      position: absolute;
      bottom: -25px;
      width: 100%;
      text-align: center;
      .swiper-pagination-bullet {
        margin-right: 14px;
        opacity: 1;
        ${props => {
          return `background: ${props.normalPaginateColor}`;
        }}
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
        ${props => {
          return `background: ${props.activePaginateColor}`;
        }};
      }
    }
  }

  .swiper_button_next_custom,
  .swiper_button_prev_custom {
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
  }
  .swiper_button_next_custom {
    right: 15%;
    svg {
      transform: rotate(180deg);
    }
  }
  .swiper_button_prev_custom {
    left: 15%;
  }

  @media (max-width: 768px) {
    .swiper_button_next_custom {
      right: 16px;
    }
    .swiper_button_prev_custom {
      left: 16px;
    }
  }

  ${props =>
    props.isOnlySingleSlide &&
    css`
      .swiper_button_next_custom {
        right: 16px;
      }
      .swiper_button_prev_custom {
        left: 16px;
      }
    `}
  ${props =>
    props.paginatePosition === IPaginatePosition.INSIDE &&
    css`
      .swiper_pagination_custom {
        /* .swiper-container-horizontal { */
        &.swiper-pagination-bullets {
          bottom: 10px;
          z-index: 2;
        }
      }
    `}
`;

const DefaultSlider = styled(Box)`
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
  background-color: #f3f3f3;
`;
