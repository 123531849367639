import { createSelector } from '@reduxjs/toolkit';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.plan_and_room || initialState;

export const selectPlanAndRoom = createSelector(
  [selectSlice, selectGlobal],
  (planAndRoomState, globalState) => {
    return {
      ...planAndRoomState,
      ...globalState,
    };
  },
);
