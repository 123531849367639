// import { Helmet } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import NotFound from 'app/components/NotFoundPage';

export function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404</title>
        <meta name="description" content="404" />
      </Helmet>
      <Header />
      <NotFound />
      <Footer />
    </>
  );
}
