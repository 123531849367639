import styled from 'styled-components/macro';

export const LoadingBackgound = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  display: flex;
  z-index: 99999;
  align-items: center;
  justify-content: center;
  background-color: #d7d7f16e;
`;
