// UI
import { Box, Stack, Typography } from '@mui/material';
import {
  PRICE_SORT_STATUSES_STRING,
  DESC_SORT_STATUS,
  PRICE_SORT_STATUSES_NUMBER,
  SORT_TYPES,
  TYPE_SORT_PRICE,
  TYPE_SORT_SUGGESTION,
  ASC_SORT_STATUS,
  SORT_STATUSES_NUMBER,
} from 'constants/common';
import { colors } from 'constants/styles';
import _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { usePlanAndRoomSlice } from 'app/pages/PlanAndRoomPage/slice';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down_black.svg';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

interface ISuggestTitle {}

const SuggestTitle = (props: ISuggestTitle) => {
  const { tab_type, plans, rooms } = useSelector(selectPlanAndRoom);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [priceSortStatus, setPriceSortStatus] =
    React.useState(DESC_SORT_STATUS);
  const [sortType, setSortType] = React.useState(TYPE_SORT_SUGGESTION);
  const {
    actions: { sortPlans },
  } = usePlanAndRoomSlice();

  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    if (params?.order) {
      setSortType(TYPE_SORT_PRICE);
      setPriceSortStatus(
        PRICE_SORT_STATUSES_STRING[params.order] ?? DESC_SORT_STATUS,
      );
    } else {
      setSortType(TYPE_SORT_SUGGESTION);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container direction="row">
      {SORT_TYPES.map((v, k) => {
        return (
          <Title
            variant="h4"
            key={k}
            className={sortType === v.value ? 'active_order' : ''}
            onClick={() => {
              let newSortType = TYPE_SORT_PRICE;
              if (v.value === TYPE_SORT_PRICE) {
                // if old sort type is price, level up sort status
                // else keep old (sort status)
                let newSortStatus =
                  sortType === TYPE_SORT_PRICE
                    ? (priceSortStatus + 1) %
                      Object.keys(SORT_STATUSES_NUMBER).length
                    : priceSortStatus;
                const sortText = PRICE_SORT_STATUSES_NUMBER[newSortStatus];
                // check new sort status is over available statuses or not (only has 3 status)
                newSortStatus = sortText ? newSortStatus : DESC_SORT_STATUS;
                setPriceSortStatus(newSortStatus);

                let params = queryString.parse(window.location.search);
                let newParams = {
                  ...params,
                  order: sortText ? sortText : '',
                };
                let qs = queryString.stringify(_.omitBy(newParams, v => !v));
                history.push(`${location.pathname}?${qs}`);
              } else {
                newSortType = TYPE_SORT_SUGGESTION;

                let params = queryString.parse(window.location.search);
                let qs = queryString.stringify(_.omit(params, ['order']));
                history.push(`${location.pathname}?${qs}`);
              }
              setSortType(newSortType);
            }}
          >
            {v.label}
            {v.value === TYPE_SORT_PRICE &&
              sortType === TYPE_SORT_PRICE &&
              (priceSortStatus === DESC_SORT_STATUS ||
                priceSortStatus === ASC_SORT_STATUS) && (
                <SortStatusIcon
                  style={{
                    transform:
                      priceSortStatus === ASC_SORT_STATUS
                        ? 'rotate(180deg)'
                        : 'none',
                  }}
                >
                  <ArrowIcon />
                </SortStatusIcon>
              )}
          </Title>
        );
      })}
    </Container>
  );
};

export default SuggestTitle;

export const SortStatusIcon = styled(Box)`
  margin-left: 4px;
  display: flex;
  align-items: center;
`;

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const Container = styled(Stack)`
  .active_order {
    background: ${colors.grey6};
  }
`;
