import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { GTM } from 'constants/GTM';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';

import { Box, Button } from '@mui/material';
import Address from './components/Address';
import PhoneDetails from './components/PhoneDetails';
import SuggestPlan from './components/SuggestPlan';
import ImageSlider from 'app/components/ImageSlider';
import HotelIntro from './components/HotelIntro';
import HotelCalendar from './components/HotelCalendar';
import EquipmentList from 'app/components/EquipmentList';
import Gallery from './components/Gallery';
import { colors } from 'constants/styles';
import { useHistory, useLocation } from 'react-router';
import { Loader } from 'app/components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { useHotelSlice } from './slice';
import {
  selectHotelDetails,
  selectSuggestPlanByHotel,
  selectGetDetailStatus,
  selectGetSuggestPlanStatus,
} from './slice/selectors';
import { selectPlanAndRoom } from 'app/pages/PlanAndRoomPage/slice/selectors';
import { IHotelDetails, ISuggestPlan } from './slice/types';
import { getGtmParams, getUrlPlansPage, removeOldGtm } from 'utils/helper';
import { RequestStatus } from 'constants/API';
import { checkIsRequesting } from 'utils/helper';
import queryString from 'query-string';
import omitBy from 'lodash/omitBy';
import TagManager from 'react-gtm-module';
import styled from 'styled-components';
import { GTM_CLASS_NAME } from 'constants/common';

export interface IParamTypes {
  slug?: string;
}

export default function HotelDetailsPage() {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const [paramsList, setParamsList] = useState(
    queryString.parse(window.location.search),
  );
  const { getPlanDetailStatus } = useSelector(selectPlanAndRoom);
  const qs = queryString.stringify({ ...omitBy(paramsList, v => !v) });
  let { slug } = useParams<IParamTypes>();

  const { getHotelDetails, getSuggestPlan } = useHotelSlice().actions;
  const data: IHotelDetails = useSelector(selectHotelDetails);
  const suggestPlantData: ISuggestPlan[] = useSelector(
    selectSuggestPlanByHotel,
  );
  const statusDetailRequest: RequestStatus = useSelector(selectGetDetailStatus);
  const statusSuggestPlanRequest: RequestStatus = useSelector(
    selectGetSuggestPlanStatus,
  );
  const isLoading = checkIsRequesting([
    statusDetailRequest,
    statusSuggestPlanRequest,
    getPlanDetailStatus,
  ]);

  const {
    top_images,
    name,
    latitude,
    longitude,
    phonenumber,
    room_count,
    checkin_time,
    checkout_time,
    tags,
    address,
    gallery,
    title,
    content,
    post_code,
    checkin_time_end,
    checkin_time_start,
    detail_url,
    parking,
    stay_image_url,
    with_pets_url,
    google_map_url,
  } = data;

  useEffect(() => {
    dispatch(getHotelDetails(slug));
    // dispatch(getSuggestPlan(slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const renderGTMId = () => {
    let hotelItem = GTM.find(item => item.slug === slug);
    if (hotelItem) {
      return hotelItem.tagId;
    }
    return null;
  };

  useEffect(() => {
    return () => {
      removeOldGtm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let otherGTM = renderGTMId();
    // let gtmTimeout;
    if (otherGTM) {
      removeOldGtm();
      TagManager.initialize(getGtmParams(otherGTM, GTM_CLASS_NAME));
      // gtmTimeout = setTimeout(
      //   () => TagManager.initialize(getGtmParams(otherGTM, GTM_CLASS_NAME)),
      //   1000,
      // );
    }
    return () => {
      // gtmTimeout && clearTimeout(gtmTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const equipmentListData = {
    room_count,
    checkin_time,
    checkout_time,
    tags,
    checkin_time_end,
    checkin_time_start,
    parking,
    stay_image_url,
    with_pets_url,
  };

  const hotelIntroData = {
    name,
    mainTags: tags.filter(item => item.is_main === 1),
    title,
    content,
    detail_url,
  };

  return (
    <>
      <Helmet>
        <title>{`${name} | VMG HOTELS & UNIQUE VENUES`}</title>
        <meta
          name="description"
          content={`${name} | VMG HOTELS & UNIQUE VENUES`}
        />
        <style>{`
          body .page__footer {
            margin-bottom: 5rem;
          }
        `}</style>

        {/* <script
          className="test_abc"
          async
          src={`https://www.googletagmanager.com/gtag/js?id=GTM-5NW2XPL`}
        /> */}
        {/* <script id="test_abcd">
          {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', 'GTM-5NW2XPL');
        `}
        </script> */}
        {/* <head>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-5NW2XPL');
          `,
            }}
          />
        </head>
        <body>
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src=""https://www.googletagmanager.com/ns.html?id=GTM-5NW2XPL""
height=""0"" width=""0"" style=""display:none;visibility:hidden""></iframe>`,
            }}
          />
        </body> */}
      </Helmet>
      <Header />
      <Loader open={isLoading} />
      <Box mt={5}>
        {!!top_images.length && (
          <ImageSlider
            images={top_images
              .slice(0, 5)
              .sort((a, b) =>
                a.view_order > b.view_order
                  ? 1
                  : b.view_order > a.view_order
                  ? -1
                  : 0,
              )
              .map(item => {
                return { src: item.image };
              })}
          ></ImageSlider>
        )}
      </Box>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '720px',
          },
          margin: {
            xs: 'unset',
            md: '0 auto',
          },
          padding: {
            xs: '0 16px 80px',
            md: '0 0 80px',
          },
        }}
      >
        <HotelIntro hotelIntroData={hotelIntroData} qs={qs} />
        {/* <SuggestPlan qs={qs} suggestPlans={suggestPlantData} /> */}
        <HotelCalendar paramsList={paramsList}></HotelCalendar>
        <Address
          address={address}
          postCode={post_code}
          urlMap={
            google_map_url
              ? google_map_url
              : `https://www.google.com/maps/search/?api=1&query=${name},${latitude},${longitude}`
          }
        />
        <PhoneDetails name={name} phoneNumber={phonenumber} />
        <EquipmentList
          equipmentListData={equipmentListData}
          slug={data?.slug}
        />
        <Gallery
          galleryData={gallery
            .slice()
            .sort((a, b) =>
              a.view_order > b.view_order
                ? 1
                : b.view_order > a.view_order
                ? -1
                : 0,
            )}
        ></Gallery>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: {
            xs: 'unset',
            md: '80px',
          },
          boxShadow: {
            xs: 'unset',
            md: '0px -4px 10px rgb(0 0 0 / 10%)',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: {
            xs: 'unset',
            md: 'fixed',
          },
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 1000,
          margin: {
            xs: '-20px 0 80px',
            md: 'unset',
          },
        }}
      >
        <Button
          variant="contained"
          size="large"
          sx={{
            background: colors.blue,
            boxShadow: 'none',
            width: {
              xs: 'calc(100% - 32px)',
              md: '611px',
            },
          }}
          onClick={() => {
            history.push(`${getUrlPlansPage(slug)}?${qs}`);
          }}
        >
          プラン一覧を見る
        </Button>
      </Box>

      <Footer />
    </>
  );
}
