import APIs from 'APIs';
import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';

import Slice from '.';

function* updateAccountFlow({ payload }) {
  let errorMess = ['Oops..Change password failed!'];
  try {
    const response = yield call(APIs.updateAccount, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateAccountSuccess,
        payload: {},
      });
    } else {
      const { data, status } = response.response;
      if (status === StatusCode.BAD_REQUEST) {
        // errorMess = Object.keys(data).map(k => {
        //   return data[k].join(', ');
        // });
        errorMess =
          data.message instanceof Array ? data.message : [data.message];
      }
      yield put({
        type: Slice.updateAccountFail.type,
        payload: { errorMess },
      });
    }
  } catch (error) {
    yield put({ type: Slice.updateAccountFail.type, payload: { errorMess } });
  }
}

function* updateAccountWatcher() {
  yield takeLatest(Slice.updateAccountRequest, updateAccountFlow);
}

export default updateAccountWatcher;
