// UI
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from 'constants/styles';
import { dMedia } from 'styles/media';
import { renderPeriodCancel } from 'utils/helper';
import { ICancelInfo } from 'types/common';

interface ICancelPolicy {
  data?: Array<ICancelInfo>;
}

const CancelPolicy = ({ data, ...props }: ICancelPolicy) => {
  return (
    <GrayBoxBig>
      <CancelPolicyTitle>キャンセル規定</CancelPolicyTitle>
      <WrapPolicy display="flex">
        <Box>
          <CancelTitle>取消日</CancelTitle>
          {data?.map((item, k) => (
            <CancelInfo key={k}>{`${renderPeriodCancel(
              item.period_start,
              item.period_end,
            )}日前`}</CancelInfo>
          ))}
        </Box>
        <WrapCancelFee>
          <CancelTitle>取消料率</CancelTitle>
          {data?.map(item => (
            <CancelInfo>{`ご宿泊料金の${item.cancellation_fee}%`}</CancelInfo>
          ))}
        </WrapCancelFee>
      </WrapPolicy>
    </GrayBoxBig>
  );
};

export default CancelPolicy;

const WrapCancelFee = styled(Box)`
  width: 121px;
  flex-shrink: 0;
  margin-left: 10px;
`;

const WrapPolicy = styled(Box)`
  width: 207px;
  justify-content: space-between;
  margin: 0 auto;
`;

const GrayBoxBig = styled(Box)`
  background: ${colors.grey6};
  padding: 16px 56.5px;
  border-radius: 8px;
  ${dMedia.tablet`
    padding: 16px 16.5px;
  `}
`;

const CancelTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.grey3};
  }
`;
const CancelInfo = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey1};
  }
`;

const CancelPolicyTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.default};
    margin-bottom: 8px;
    text-align: center;
  }
`;
