import { Stack, Box } from '@mui/material';
import { colors, fontsizes } from 'constants/styles';
import React from 'react';
import styled from 'styled-components/macro';
import { dMedia } from 'styles/media';

interface Props {
  type?: number;
  data?: string[];
}

export const TagList = (props: Props) => {
  const { data } = props;
  const type = props.type ?? 1;

  return (
    <List
      direction="row"
      spacing={type === 1 ? 1 : 2}
      alignItems="center"
      className={`taglist-${type}`}
    >
      {data?.map((it, idx) => (
        <ListItem
          component="span"
          className="tag-item"
          borderRadius={0.5}
          key={idx}
        >
          {it}
        </ListItem>
      ))}
    </List>
  );
};

const List = styled(Stack)`
  padding: 0;
  flex-wrap: wrap;
  .tag-item {
    overflow: hidden;
  }
  &.taglist-1 {
    .tag-item {
      padding: 2px 6px;
      font-size: ${fontsizes.smallText};
      color: ${colors.greenText};
      background: ${colors.lightGreen};
      border: 1px solid ${colors.greenText};
    }
  }
  &.taglist-2 {
    .tag-item {
      min-width: 80px;
      padding: 2px 5px;
      text-align: center;
      color: ${colors.white};
      background: ${colors.grey3};
      font-size: ${fontsizes.xsText};
      ${dMedia.tablet`
        background: ${colors.grey4};
      `}
    }
  }
  &.taglist-3 {
    .tag-item {
      min-width: 105px;
      padding: 2px 5px;
      text-align: center;
      color: ${colors.default};
      background: ${colors.orange};
      font-size: ${fontsizes.xsText};
    }
  }
`;

const ListItem = styled(Box)`
  font-size: ${fontsizes.smallText};
  white-space: nowrap;
  margin-bottom: 8px !important;
  margin-right: 8px !important;
  margin-left: 0 !important;
`;
