import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import loginSaga from './saga';
import { LoginState } from './types';
import { RequestStatus } from 'constants/API';
import { ISuccessPayload } from './types';
import { deleteAllCookies } from 'utils/helper';

export const initialState: LoginState = {
  loginStatus: RequestStatus.IDLE,
  accessToken: '',
  errorMess: [],
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginRequest: (state, action: PayloadAction<any>) => {
      deleteAllCookies();
      if (action) state.loginStatus = RequestStatus.REQUESTING;
    },
    loginSuccess: (state, action: PayloadAction<ISuccessPayload>) => {
      state.accessToken = action.payload.access;
      state.loginStatus = RequestStatus.SUCCESS;
    },
    loginFail: (state, action: PayloadAction<any>) => {
      if (action) state.loginStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    resetLoginStatus: state => {
      state.errorMess = [];
      state.loginStatus = RequestStatus.IDLE;
    },
  },
});

export default slice.actions;

export const useLoginSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: loginSaga });
  return { actions: slice.actions };
};
