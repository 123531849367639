import { Box, Button, Typography } from '@mui/material';
import Path from 'config/clientPath';
import { colors } from 'constants/styles';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { matchPath, useHistory, useLocation, useParams } from 'react-router';
import _ from 'lodash';
import { getGtmParams, removeOldGtm } from 'utils/helper';
import TagManager from 'react-gtm-module';
import { GTM_CLASS_NAME } from 'constants/common';
import { GTM, HOTEL_SLUGS } from 'constants/GTM';

export interface IParamTypes {
  slug?: string;
}

export function GoogleTag() {
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    const currentPath = location?.pathname;

    if (!currentPath) {
      return;
    }
    const regexCheckTaggedPage = [
      {
        path: Path.HOTEL_DETAILS,
        regex: RegExp(`${Path.RESERVATION_PREFIX}\\/.+/`),
      },
      {
        path: Path.PLANS_LIST,
        regex: RegExp(`${Path.RESERVATION_PREFIX}\\/.+${Path.PLANS}/`),
      },
      {
        path: Path.ROOMS_LIST,
        regex: RegExp(`${Path.RESERVATION_PREFIX}\\/.+${Path.ROOMS}/`),
      },
      {
        path: Path.PLANS_MODAL,
        regex: RegExp(`${Path.RESERVATION_PREFIX}\\/.+${Path.PLANS}/[0-9]+/`),
      },
      {
        path: Path.ROOM_MODAL,
        regex: RegExp(`${Path.RESERVATION_PREFIX}\\/.+${Path.ROOMS}/[0-9]+/`),
      },
    ];
    let isTaggedPage = false;
    let isTaggedPageKey = '';

    _.map(regexCheckTaggedPage, (v, k) => {
      const urlInfo: any = matchPath(currentPath, {
        path: v.path,
      });
      const slug = urlInfo?.params?.slug;

      if (v.regex.test(currentPath) && _.includes(HOTEL_SLUGS, slug)) {
        isTaggedPage = true;
        isTaggedPageKey = k;
      }
    });

    if (isTaggedPage) {
      return;
    }
    removeOldGtm();
    _.map(GTM, v => {
      TagManager.initialize(getGtmParams(v.tagId, GTM_CLASS_NAME));
    });
    return () => {
      // clearTimeout(gtmTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return <></>;
}
