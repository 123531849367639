const lightTheme = {
  primary: '#2F80ED',
  text: '#202223',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: '#FFF',
  inputBackground: '#EEEEEE',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: '#828282',
  borderLight: 'rgba(58,52,51,0.05)',
  fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
};

const darkTheme: Theme = {
  primary: '#2F80ED',
  text: '#202223',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: '#FFF',
  inputBackground: '#EEEEEE',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: '#828282',
  borderLight: 'rgba(58,52,51,0.05)',
  fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
};

export type Theme = typeof lightTheme;

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};
