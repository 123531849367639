import styled from 'styled-components/macro';

import { Typography } from '@mui/material';
import { BoxHeader } from 'app/components/BoxHeader';
import { dMedia } from 'styles/media';
import { fontsizes, colors } from 'constants/styles';

interface IAddress {
  urlMap: string;
  address: string;
  postCode: string;
}

export default function Address(props: IAddress) {
  return (
    <>
      <BoxHeader fontSize={18}>住所</BoxHeader>
      <Content>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: {
              xs: fontsizes.main,
              md: fontsizes.heading3,
            },
            lineHeight: {
              xs: '18px',
              md: '32px',
            },
            color: colors.heading,
            marginBottom: '16px',
            marginTop: 0,
          }}
        >
          〒{props.postCode}
          <br />
          {props.address}
        </Typography>
        <MapLink href={props.urlMap} target="_blank">
          Goolgleマップで見る＞
        </MapLink>
      </Content>
    </>
  );
}

const Content = styled.div`
  padding: 40px 15px 0;
  margin-bottom: 50px;
  ${dMedia.tablet`
    padding: 0;
    padding-top: 40px
    `};
`;

const MapLink = styled.a`
  font-weight: 400;
  font-size: ${fontsizes.main};
  line-height: 20px;
  color: ${colors.blue};
`;
